import { initialUserRewards } from "../initial/initialUserRewards"
import { TypeKeys } from "../actions/actionTypes"

export const userRewardsReducer = (state = initialUserRewards(), action) => {
  switch (action.type) {
    case TypeKeys.GET_USER_REWARDS_REQUEST:
      return { ...state, userRewards: [], loading: true }
    case TypeKeys.GET_USER_REWARDS_SUCCESS:
      return { ...state, userRewards: action.userRewards, loading: false }
    case TypeKeys.CREATE_USER_REWARD_REQUEST:
      return { ...state, loading: true }
    case TypeKeys.CREATE_USER_REWARD_SUCCESS:
      return { ...state, userRewards: action.userRewards, loading: false }
    case TypeKeys.DESTROY_USER_REWARD_REQUEST:
      return { ...state, loading: true }
    case TypeKeys.DESTROY_USER_REWARD_SUCCESS:
      return { ...state, userRewards: action.userRewards, loading: false }
    default:
    return { ...state }
  }
}

export default userRewardsReducer
