import React, { useEffect } from "react";
import EducationCategoryForm from "./EducationCategoryForm"
import { useNavigate } from "react-router-dom"
import { connect } from 'react-redux'
import { createEducationCategoryRequest } from "../../actions/actionCreators/educationCategoriesActions"

const EducationCategoryNew = ({ createEducationCategory, companies, history, status }) => {
  const navigate = useNavigate();

  useEffect(() => {
    status === 'success' && navigate('/education_categories')
  }, [status]);

  const educationCategory = {
    title: "",
    company: {
      value: (companies.length > 0) ? companies[0].id : undefined,
      label: (companies.length > 0) ? companies[0].title : undefined
    }
  }

  return(<EducationCategoryForm key={+ new Date()} educationCategory={educationCategory}
    companies={companies} type={"new"} history={history} submit={createEducationCategory}
    ></EducationCategoryForm>)
}

const mapStateToProps = (state) => ({
  companies: state.profileReducer.companies,
  status: state.educationCategoriesReducer.requestStatus
})

const mapDispatchToProps = (dispatch) => ({
  createEducationCategory: (query) => dispatch(createEducationCategoryRequest(query)),
})

export default connect(mapStateToProps, mapDispatchToProps)(EducationCategoryNew)
