import { takeLatest, put, call, delay } from 'redux-saga/effects'
import { TypeKeys } from '../actions/actionTypes'
import { getUserIndicatorsSuccess } from '../actions/actionCreators/userIndicatorsActions'
import callApi from '../utils/callApi'
import config from '../config'

function* loadUserIndicators(action) {
  const res = yield call(callApi, `${config.BASE_URL}/admin/api/v1/users/${action.id}/user_indicators`)
  const userIndicators = res.data
  const userIndicatorsData = userIndicators.map((userIndicator) => ({
    id: userIndicator.id,
    monthTarget: userIndicator.month_target,
    plannedTarget: userIndicator.planned_target,
    controlled: userIndicator.controlled,
    team: {
      id: userIndicator.team.id,
      title: userIndicator.team.title
    },
    performanceIndicator: {
      id: userIndicator.performance_indicator.id,
      title: userIndicator.performance_indicator.title
    }
  }))
  yield put(getUserIndicatorsSuccess(userIndicatorsData))
}

export const userIndicatorsSagas = [
  takeLatest([ TypeKeys.GET_USER_INDICATORS_REQUEST], loadUserIndicators)
]
