import React from 'react'
import { Link } from 'react-router-dom'
import { List, ListItem, ListItemAvatar, Avatar, ListItemText, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
	avatarFont: {
		width: theme.spacing(5),
		height: theme.spacing(5),
		letterSpacing: '-1px',
		backgroundColor: theme.palette.primary.main,
		[theme.breakpoints.down('sm')]: {
			width: theme.spacing(3),
			height: theme.spacing(3),
			fontSize: '0.8rem',
		},
	},
	ignoreVisited: {
		color: theme.palette.common.black,
		textDecoration: 'none',
	},
	noMargin: {
		padding: 0,
	},
}))

const UserBlock = ({ user, role }) => {
	const classes = useStyles()

	const link = role === 'user' ? `/users/${user.id}/edit` : `/users/${user.id}`

	if (user) {
		return (
			<List className={classes.noMargin}>
				<ListItem disableGutters={true} component={Link} to={link}>
					<ListItemAvatar>
						<Avatar src={user.avatar} className={classes.avatarFont} color='primary'>
							{`${user.firstName[0]} ${user.lastName[0]}`}
						</Avatar>
					</ListItemAvatar>
					<ListItemText
						primary={
							<Typography component='span' variant='body2' className={classes.ignoreVisited}>
								{user.firstName} {user.lastName}
							</Typography>
						}
						secondary={user.phone}
					/>
				</ListItem>
			</List>
		)
	} else {
		return <div />
	}
}

export default UserBlock
