
import { TypeKeys } from '../actionTypes'

export const getChecklistsRequest = (categoryId) => ({
  type: TypeKeys.GET_CHECKLISTS_REQUEST, categoryId
})

export const getChecklistsSuccess = (checklists) => ({
  type: TypeKeys.GET_CHECKLISTS_SUCCESS,
  checklists
})

export const getChecklistRequest = (categoryId, id) => ({
  type: TypeKeys.GET_CHECKLIST_REQUEST, categoryId, id
})

export const getChecklistSuccess = (checklist) => ({
  type: TypeKeys.GET_CHECKLIST_SUCCESS,
  checklist
})

export const createChecklistRequest = (query) => ({ type: TypeKeys.CREATE_CHECKLIST_REQUEST, query })

export const createChecklistSuccess = (checklist) => ({
  type: TypeKeys.CREATE_CHECKLIST_SUCCESS,
  checklist
})

export const updateChecklistRequest = (query) => ({ type: TypeKeys.UPDATE_CHECKLIST_REQUEST, query })

export const updateChecklistSuccess = (checklist) => ({
  type: TypeKeys.UPDATE_CHECKLIST_SUCCESS,
  checklist
})
