import { takeLatest, put, call } from 'redux-saga/effects'
import { TypeKeys } from '../actions/actionTypes'
import { getDivisionsSuccess, getDivisionSuccess, createDivisionSuccess,
  updateDivisionSuccess }
  from '../actions/actionCreators/divisionsActions'
import callApi from '../utils/callApi'
import config from '../config'

const mapDivision = (division) => {
  return {
    id: division.id,
    title: division.title,
    company: {
      value: division.company.id,
      label: division.company.title
    },
    createdAt: division.created_at,
    updatedAt: division.updated_at,
  }
}

function* loadDivisions(action) {
  const res = yield call(callApi, `${config.BASE_URL}/admin/api/v1/divisions`)
  yield put(getDivisionsSuccess(res.data.map(mapDivision)))
}

function* loadDivision(action) {
  const res = yield call(callApi,
    `${config.BASE_URL}/admin/api/v1/divisions/${action.id}`)
  yield put(getDivisionSuccess(mapDivision(res.data)))
}

function* createDivision(action) {
  const res = yield call(callApi,
    `${config.BASE_URL}/admin/api/v1/divisions`,
    { method: 'POST', data: action.query.data })
  yield put(createDivisionSuccess(mapDivision(res.data)))
}

function* updateDivision(action) {
  const res = yield call(callApi,
    `${config.BASE_URL}/admin/api/v1/divisions/${action.query.divisionId}`,
    { method: 'PUT', data: action.query.data })
  yield put(updateDivisionSuccess(mapDivision(res.data)))
}

export const divisionsSagas = [
  takeLatest([TypeKeys.GET_DIVISIONS_REQUEST], loadDivisions),
  takeLatest([TypeKeys.GET_DIVISION_REQUEST], loadDivision),
  takeLatest([TypeKeys.CREATE_DIVISION_REQUEST], createDivision),
  takeLatest([TypeKeys.UPDATE_DIVISION_REQUEST], updateDivision),
]
