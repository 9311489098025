export const initialAreas = () => {
  return {
    areas: [],
    area: {
      id: undefined,
      title: "",
      company: {}
    },
    loading: true
  }
}
