import { TypeKeys } from '../actionTypes'

export const getReportsRequest = () => ({ type: TypeKeys.GET_REPORTS_REQUEST })

export const getReportsSuccess = (reports) => ({
  type: TypeKeys.GET_REPORTS_SUCCESS,
  reports
})

export const createReportRequest = (query) => ({ type: TypeKeys.CREATE_REPORT_REQUEST, query })

export const createReportSuccess = (reports) => ({
  type: TypeKeys.CREATE_REPORT_SUCCESS,
  reports
})
