import React from "react";
import Checklist from "./Checklist"
import { useParams } from "react-router-dom"
import { connect } from 'react-redux'
import { useEffect } from "react";
import { createChecklistRequest } from "../../actions/actionCreators/checklistsActions"
import { getChecklistCategoryRequest } from "../../actions/actionCreators/checklistCategoriesActions"
import {v4 as uuidv4} from 'uuid';

const ChecklistNew = ({ loading, history, createChecklist, checklistCategory }) => {
  const params = useParams()

  const checklist = {
    payload: {
      uuid: uuidv4(),
      title: undefined,
      blocks: []
    },
    checklist_category: {
      id: checklistCategory.id,
      title: checklistCategory.title
    }
  }

  return(
    <Checklist key={+ new Date()} checklist={checklist}
      type={"new"} history={history} submit={createChecklist}></Checklist>
  )
}

const mapStateToProps = (state) => ({
  checklistCategory: state.checklistCategoriesReducer.checklistCategory,
})

const mapDispatchToProps = (dispatch) => ({
  loadChecklistCategory: (id) => dispatch(getChecklistCategoryRequest(id)),
  createChecklist: (query) => dispatch(createChecklistRequest(query)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ChecklistNew)
