import { takeLatest, put, call, delay } from 'redux-saga/effects'
import { TypeKeys } from '../actions/actionTypes'
import { getUserResultsSuccess } from '../actions/actionCreators/userResultsActions'
import callApi from '../utils/callApi'
import config from '../config'

function* loadUserResults(action) {
  const res = yield call(callApi, `${config.BASE_URL}/admin/api/v1/users/${action.id}/results`)
  const results = res.data
  const resultsData = results.map((result) => ({
    id: result.id,
    date: result.date,
    plan: result.plan,
    value: result.value,
    team: {
      id: result.team.id,
      title: result.team.title
    },
    performanceIndicator: result.performance_indicator ? {
      id: result.performance_indicator.id,
      title: result.performance_indicator.title
    } : null,
    createdAt: result.created_at
  }))
  yield put(getUserResultsSuccess(resultsData))
}

export const userResultsSagas = [
  takeLatest([ TypeKeys.GET_USER_RESULTS_REQUEST], loadUserResults)
]
