import { initialAwardTypes } from "../initial/initialAwardTypes.js"
import { TypeKeys } from "../actions/actionTypes"

export const awardTypesReducer = (state = initialAwardTypes(), action) => {
  switch (action.type) {
    case TypeKeys.GET_AWARD_TYPES_REQUEST:
      return { ...state, awardTypes: [], loading: true }
    case TypeKeys.GET_AWARD_TYPES_SUCCESS:
      return { ...state, awardTypes: action.awardTypes, loading: false }
    case TypeKeys.GET_AWARD_TYPE_REQUEST:
      return { ...state, awardType: initialAwardTypes().awardType, loading: true }
    case TypeKeys.GET_AWARD_TYPE_SUCCESS:
      return { ...state, awardType: action.awardType, loading: false }
    case TypeKeys.CREATE_AWARD_TYPE_REQUEST:
      return { ...state, awardType: initialAwardTypes().awardType, loading: true }
    case TypeKeys.CREATE_AWARD_TYPE_SUCCESS:
      return { ...state, awardType: action.awardType, loading: false }
    case TypeKeys.UPDATE_AWARD_TYPE_REQUEST:
      return { ...state, awardTypes: initialAwardTypes().awardType, loading: true }
    case TypeKeys.UPDATE_AWARD_TYPE_SUCCESS:
      return { ...state, awardType: action.awardType, loading: false }
    case TypeKeys.GET_AWARD_HISTORY_REQUEST:
      return { ...state, awardHistory: [], loading: true }
    case TypeKeys.GET_AWARD_HISTORY_SUCCESS:
      return { ...state, awardHistory: action.awardHistory, historyPagination: {...action.pagination }, loading: false }
    default:
    return { ...state }
  }
}

export default awardTypesReducer
