import React from "react"
import { Link, useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { useState, useEffect } from "react"
import { getAwardHistoryRequest } from "../../actions/actionCreators/awardTypesActions"
import moment from 'moment'

import { makeStyles, useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import clsx from 'clsx';

import { Table, TableBody, TableCell, TableContainer, TableHead,
  TableRow } from '@mui/material';
import { Paper, Grid, Typography, Toolbar, Container, Hidden,
  Fab, Box, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@mui/material';
import {Add, ExpandMore} from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';

import UserChip from "../../components/Main/UserChip"
import Pagination from '../../components/Main/Pagination'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingBottom: theme.spacing(8),
    paddingTop: theme.spacing(2)
  },
  paper: {
    padding: theme.spacing(2)
  },
  fab: {
    [theme.breakpoints.down('md')]: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  },
  ignoreVisited: {
    color: theme.palette.common.black,
    textDecoration: "none"
  },
}));

const AwardTypesPanel = (props) => {

  const { location, awardHistory, history, loading, role, pagination, loadAwardHistory } = props

  const query = useParams();

  useEffect(() => {
    loadAwardHistory(query)
  }, [query.toString()]);

  const classes = useStyles()
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))
  const mapEvent = (event) => {
    switch(event) {
    case 'create':
      return "Создание"
    case 'destroy':
      return "Удаление"
    case 'update':
      return "Изменение"
    default:
      return ""
    }
  }
  return (
    <Container className={classes.root} disableGutters={true}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Grid container spacing={2} >
              <Grid item xs={12}>
                <Typography variant="h4">
                  История изменений
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Table className={classes.table} size={mobile ? "" : "small"} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Действие</TableCell>
                  <TableCell align="left">Награда</TableCell>
                  <TableCell align="left">Пользователь</TableCell>
                  <TableCell align="left">Дата</TableCell>
                  <TableCell align="left">Администратор</TableCell>
                  { role === "super_admin" && <TableCell align="left" >Компания</TableCell> }
                </TableRow>
              </TableHead>
              <TableBody>
                { loading &&
                  <TableRow>
                    <TableCell colSpan={5} align="center"><CircularProgress/></TableCell>
                  </TableRow> }
                { !loading && awardHistory.map((history) => {
                  const { id, createdAt, event, admin, company, award } = history
                  const { awardType, user} = award
                  return(
                    <TableRow key={id}>
                      <TableCell align="left">
                        {mapEvent(event)}
                      </TableCell>
                      <TableCell align="left">
                        {awardType}
                      </TableCell>
                      <TableCell align="left">
                        <UserChip user={user}/>
                      </TableCell>
                      <TableCell align="left">
                        { createdAt && moment.utc(createdAt, "YYYY-MM-DDTHH:mm:ss.SSSSZ").local().format('DD.MM.YYYY HH:mm:ss') }
                      </TableCell>
                      <TableCell align="left">
                        <UserChip user={admin}/>
                      </TableCell>
                      { role === "super_admin" && <TableCell align="left">{company}</TableCell>}
                    </TableRow>
                )}
              )}
              </TableBody>
            </Table>
            <Box className={classes.buttonGroup}>
              { pagination.totalPages > 1 && <Pagination data={pagination} location={location}/>}
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}

const mapStateToProps = (state) => ({
  awardHistory: state.awardTypesReducer.awardHistory,
  loading: state.awardTypesReducer.loading,
  pagination: state.awardTypesReducer.historyPagination,
  role: state.profileReducer.role
})

const mapDispatchToProps = (dispatch) => ({
  loadAwardHistory: (query) => dispatch(getAwardHistoryRequest(query))
})

export default connect(mapStateToProps, mapDispatchToProps)(AwardTypesPanel)
