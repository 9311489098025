export const initialTeam = () => {
  return {
    team: {
      id: undefined,
      adminId: undefined,
      createdAt: undefined,
      archived: undefined,
      archivedAt: undefined,
      title: undefined,
      admin: {
        firstName: undefined,
        lastName: undefined,
        id: undefined,
        avatar: undefined,
      },
      membersCount: undefined,
      teamAvatarUrl: undefined,
      parentId: undefined,
      users: [],
      guests: [],
      performanceIndicators: [],
      externalInvites: [],
      parentTeam: {
        id: undefined,
        createdAt: undefined,
        title: undefined,
        admin: undefined,
        membersCount: undefined
      },
      childTeams: []
    },
    teamStats: {
      teams: [],
    },
    loading: true,
    statsLoading: true
  }
}
