import React from "react";
import AreaForm from "./AreaForm"
import { useParams } from "react-router-dom"
import { connect } from 'react-redux'
import { useEffect } from "react";
import { getAreaRequest, updateAreaRequest }
  from "../../actions/actionCreators/areasActions"

const AreaEdit = ({ area, loadArea, updateArea, history, companies }) => {
  const params = useParams()
  useEffect(() => {
    loadArea(params.id)
  }, [params.id]);

  if (area) {
    return(<AreaForm key={`${area.id}-${area.updatedAt}`} area={area}
      submit={updateArea} type={"edit"} history={history} companies={companies}></AreaForm>)
  } else {
    return(<div></div>)
  }
}

const mapStateToProps = (state) => ({
  area: state.areasReducer.area,
  companies: state.profileReducer.companies
})

const mapDispatchToProps = (dispatch) => ({
  loadArea: (id) => dispatch(getAreaRequest(id)),
  updateArea: (query) => dispatch(updateAreaRequest(query)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AreaEdit)
