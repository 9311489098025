import React from 'react'
import LinearProgress from '@mui/material/LinearProgress'
import { Grid, Typography } from '@mui/material'
import { styled } from '@mui/styles'

/**
 * @export
 * @param {{
 *  blocks: {
 *    uuid: string;
 *    title: string;
 *    average_percentage: number;
 *  }[]
 * }} props
 */
export default function ChecklistsProgresses(props) {
	const { blocks } = props
	return (
		<Grid container paddingRight={2}>
			{blocks.map((item, index) => (
				<>
					<Grid item xs={3}>
						<Typography component='span' variant='body1'>
							{item.title}
						</Typography>
					</Grid>
					<Grid item container xs={7} display='flex' alignItems='center'>
						<Progress variant='determinate' value={item.average_percentage} />
					</Grid>
					<Grid item xs={2} alignItems='center'>
						<Typography style={{ textAlign: 'center' }}>{item.average_percentage}</Typography>
					</Grid>
				</>
			))}
		</Grid>
	)
}

const Progress = styled(LinearProgress)(() => ({
	minHeight: 10,
	width: '100%',
}))
