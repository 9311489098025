import React from "react";
import KpiChannelForm from "./KpiChannelForm"
import { useParams } from "react-router-dom"
import { connect } from 'react-redux'
import { useEffect } from "react";
import { getKpiChannelRequest, updateKpiChannelRequest }
  from "../../actions/actionCreators/kpiChannelsActions"

const KpiChannelEdit = ({ kpiChannel, loadKpiChannel, updateKpiChannel, history, companies }) => {
  const params = useParams()
  useEffect(() => {
    loadKpiChannel(params.id)
  }, [params.id]);

  if (kpiChannel) {
    return(<KpiChannelForm key={`${kpiChannel.id}-${kpiChannel.updatedAt}`} kpiChannel={kpiChannel}
      submit={updateKpiChannel} type={"edit"} history={history} companies={companies}></KpiChannelForm>)
  } else {
    return(<div></div>)
  }
}

const mapStateToProps = (state) => ({
  kpiChannel: state.kpiChannelsReducer.kpiChannel,
  companies: state.profileReducer.companies
})

const mapDispatchToProps = (dispatch) => ({
  loadKpiChannel: (id) => dispatch(getKpiChannelRequest(id)),
  updateKpiChannel: (query) => dispatch(updateKpiChannelRequest(query)),
})

export default connect(mapStateToProps, mapDispatchToProps)(KpiChannelEdit)
