import React, { useCallback, useEffect } from 'react'
import { useParams } from 'react-router'
import { useSelector } from 'react-redux'
import {
	Breadcrumbs,
	CircularProgress,
	Grid,
	ListSubheader,
	MenuItem,
	Paper,
	Typography,
} from '@mui/material'
import { useDispatch } from 'react-redux'
import { getTeamRequest } from '../../../actions/actionCreators'
import { makeStyles } from '@mui/styles'
import { Link } from 'react-router-dom'
import { ButtonsBlock, Select } from './compoennts/atoms'
import { ChecklistTableRow, ChecklistsProgresses } from './compoennts/moleculs'
import { useChecklistsDashboard } from './hooks'
import { TableChecklists } from './compoennts/organisms'

const selectors = {
	currentTeam: state => state.teamReducer.team,
	// getDashboardLoading: state => state.dashboardReducer.loading,
}

export default function ChecklistsPage() {
	const dispatch = useDispatch()

	// ------- Текущая команда панели ---------
	const params = useParams()
	const team = useSelector(selectors.currentTeam)

	useEffect(() => {
		dispatch(getTeamRequest(params.teamId))
	}, [params.teamId])

	// ---------- выбор месяца ----------------

	const dashboard = useChecklistsDashboard(params.teamId, team)

	useEffect(() => {
		dashboard.getCategories()
	}, [])

	const renderRow = useCallback(({ item }) => <ChecklistTableRow item={item} key={item.id} />, [])

	// ----------------------------------------

	const classes = useStyles()
	return (
		<>
			<Grid container className={classes.spacing4}>
				<Breadcrumbs aria-label='breadcrumb' sx={{ marginBottom: '16px' }}>
					<Link color='inherit' to='/dashboard'>
						Аналитика
					</Link>
					<Link color='inherit' to={`/dashboard/${team.id}`}>
						{team.title || '...'}
					</Link>
					<Typography color='textPrimary'>Витрина чек-листов</Typography>
				</Breadcrumbs>

				<Grid item xs={12} component={Paper} marginBottom={2}>
					<Grid container className={classes.spacing2}>
						<Grid item xs={12} sx={{ marginBottom: '16px' }}>
							<Grid container>
								<Grid item xs={6}>
									<Typography component='span' variant='h4'>
										Заполнение чек-листов
									</Typography>
								</Grid>
								<Grid item xs={6} marginBottom={2}>
									<Grid container>
										<Grid item xs={6}>
											<Select
												title='Категория чек-листов'
												id='checklistsCategories'
												value={dashboard.checklists.current?.title}
												onChange={dashboard.checklists.changeCurrent}
												items={dashboard.filterd}
												renderItem={({ item }) => [
													// Использовал массив, так как Fragment не может быть использован для MenuItem'ов ???

													<ListSubheader key={item.id}>{item.title}</ListSubheader>,
													item.checklists.map(checklist => (
														<MenuItem key={checklist.id} value={checklist}>
															{checklist.title}
														</MenuItem>
													)),
												]}
											/>
										</Grid>
										<Grid item xs={6}>
											<Select
												title='Команда'
												id='team'
												disabled={!dashboard.checklists.current}
												value={dashboard.innerTeams.current?.title}
												onChange={dashboard.innerTeams.changeCurrent}
												items={dashboard.innerTeams.list}
												renderItem={({ item }) => (
													<MenuItem key={item?.id || 'null_value'} value={item}>
														{item.title}
													</MenuItem>
												)}
											/>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={6} paddingRight={4}>
									<Typography component='span' variant='body1'>
										Витрина отображает количество созданных чек-листов и результат их заполнения
										(процент правильных ответов)
									</Typography>
								</Grid>
								<Grid item xs={6}>
									<ButtonsBlock
										isMonthCurrent={dashboard.isMonthCurrent}
										onPressMonthCurrent={dashboard.setMonthCurrent}
										onPressMonthPast={dashboard.setMonthPast}
										onPressDownload={dashboard.download}
									/>
								</Grid>
								{dashboard.isLoadingData ? (
									<Grid
										item
										xs={12}
										display='flex'
										alignItems='center'
										justifyContent='center'
										padding={4}
									>
										<CircularProgress />
									</Grid>
								) : (
									dashboard.response && (
										<>
											<Grid item xs={6} marginTop={4}>
												<ChecklistsProgresses
													blocks={dashboard.response.data.header_data.blocks_data}
												/>
											</Grid>
											<Grid item xs={6} paddingTop={4}>
												<Typography>
													Всего чек-листов:{' '}
													{dashboard.response.data.header_data.checklist_data.total_ucls}
												</Typography>
												<Typography>
													Всего участников:{' '}
													{dashboard.response.data.header_data.checklist_data.users_count}
												</Typography>
												<Typography>
													Чек-листов на участника:{' '}
													{dashboard.response.data.header_data.checklist_data.ucl_per_user}
												</Typography>
											</Grid>
										</>
									)
								)}
							</Grid>
						</Grid>
					</Grid>
				</Grid>

				{!!dashboard.response && !dashboard.isLoadingData && (
					<Grid item xs={12} component={Paper}>
						<Grid container className={classes.spacing4}>
							<TableChecklists
								data={dashboard.response.data.teams_data}
								team={team}
								renderRow={renderRow}
							/>
						</Grid>
					</Grid>
				)}
			</Grid>
		</>
	)
}

const useStyles = makeStyles(theme => ({
	spacing4: { padding: theme.spacing(4) },
	spacing2: { padding: theme.spacing(2) },
	column: { flexDirection: 'column' },
	red: { backgroundColor: 'red' },
	buttonGroup: {
		paddingTop: theme.spacing(2),
	},
}))
