import React, { useState } from "react"
import { Link } from "react-router-dom"
import { Paper, Grid, Typography, Divider, TextField, Button, MenuItem,
  FormControl, InputLabel, Select, Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(4),
    '& .MuiTextField-root': {
      marginTop: theme.spacing(1),
      // width: '25ch',
    }
  },
  paper: {
    padding: theme.spacing(2)
  },
  idHeader: {
    marginBottom: theme.spacing(2),
  },
  margin: {
    margin: theme.spacing(0),
    marginRight:  theme.spacing(4),
  },
  formControl: {
    marginTop:  theme.spacing(0),
  },
  buttonMargin: {
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    textDecoration: "none"
  },
  fab: {
    [theme.breakpoints.down('md')]: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  },
}));

const AreaForm = ({ area, companies, submit, type, history }) => {

  const { id } = area

  const [values, setValues] = useState({
    title: area.title,
    company: area.company
  });

  const mapCompanies = (cs) => {
    return cs.map((option, index) => <MenuItem key={index} value={option.id} label={option.title}>{option.title}</MenuItem> )
  }

  const setTitleValue = (e) => {
    setValues({ ...values, title: e.target.value })
  }
  const setCompanyValue = (e, value) => {
    setValues({ ...values, company: {value: value.props.value, label: value.props.label} })
  }
  const submitAction = () => {
    submit({
      areaId: area.id,
      data: {
        title: values.title,
        company_id: values.company.value
      }
    })
    history.goBack()
  }

  const classes = useStyles()

  return(
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Typography variant="h6" sx={{marginBottom: "16px"}}>
            { type==="edit" && "Изменить эрию участников" }
            { type==="new" && "Создать эрию участников" }
          </Typography>
          <form noValidate autoComplete="off">
            <Grid container spacing={2}>
              <Grid item sm={12}>
                <TextField required variant="outlined" fullWidth label="Название" value={values.title} onChange={setTitleValue} />
              </Grid>
              {(companies.length > 1) &&
                <Grid item sm={12}>
                  <FormControl fullWidth variant="outlined" className={classes.formControl}>
                    <InputLabel id="demo-simple-select-outlined-label">Компания</InputLabel>
                    <Select
                      fullWidth
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={values.company.value}
                      onChange={setCompanyValue}
                      label="Компания"
                      disabled={companies.length === 1}
                    >
                    { mapCompanies(companies) }
                    </Select>
                  </FormControl>
                </Grid>}
              <Grid item sm={12}>
                <Stack spacing={2} direction="row">
                  <Button className={classes.buttonMargin} variant="outlined" to={`/areas`} component={Link}>
                    Назад
                  </Button>
                  <Button className={classes.buttonMargin} variant="contained" onClick={submitAction} color="primary">
                    { type==="edit" && "Сохранить" }
                    { type==="new" && "Создать" }
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default AreaForm;
