import { takeLatest, put, call } from 'redux-saga/effects'
import { TypeKeys } from '../actions/actionTypes'
import { getCompetitionsSuccess } from '../actions/actionCreators/competitionsActions'
import callApi from '../utils/callApi'
import config from '../config'

function* loadCompetitions(action) {
  const res = yield call(callApi, `${config.BASE_URL}/admin/api/v1/competitions?company_id=${action.companyId}`)
  const competitionsData = res.data.map((c) => ({
    value: c.value,
    label: c.label
  }))
  yield put(getCompetitionsSuccess(competitionsData))
}

export const competitionsSagas = [
  takeLatest([TypeKeys.GET_COMPETITIONS_REQUEST], loadCompetitions)
]
