import React from "react";
import { Grid, Typography, Breadcrumbs, Divider } from '@mui/material';
import { SimpleTreeView, TreeItem } from '@mui/x-tree-view';
import { ExpandMore, ChevronRight } from '@mui/icons-material';
import TeamChip from "../../components/Main/TeamChip";

const TeamTree = (props) => {
  const { parentTeams, childTeams, subtree } = props
  const renderTree = team => {
    return(
      <TreeItem key={team.id} itemId={team.id.toString()} label={<TeamChip team={team} />}>
        { (team.children && team.children.length > 0 ) ?
          team.children.map(renderTree) : null
        }
      </TreeItem>
    )
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6">
          Родительские команды
        </Typography>
      </Grid>
      <Grid item xs={12}>
        { parentTeams.length > 0 ?
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            { parentTeams.map(team => {
              return(
                <TeamChip key={team.id} team={team}/>
              )
            })}
          </Breadcrumbs> :
          <Typography variant="body2">
            Нет
          </Typography>
        }
      </Grid>
      <Grid item xs={12}>
        <Divider/>
      </Grid>
      <Grid item xs={12}>
        { childTeams.length > 0 ?
          <SimpleTreeView slots={{expandIcon: ChevronRight, collapseIcon: ExpandMore}}>
            <TreeItem itemId={'0'} label={<Typography variant="h6">
              Дочерние команды
            </Typography>}>
              { subtree.map(renderTree)}
            </TreeItem>
          </SimpleTreeView>
        : <Typography variant="h6">
          Нет дочерних команд
        </Typography>}
      </Grid>
    </Grid>
  )
}

export default TeamTree
