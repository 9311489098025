import { ReactComponent as error } from './error.svg'
import { ReactComponent as progress } from './progress.svg'
import { ReactComponent as success } from './success.svg'
import { ReactComponent as download } from './download.svg'
import { ReactComponent as info } from './info.svg'
import { ReactComponent as trash } from './trash.svg'

export default {
	error,
	progress,
	success,
	download,
	info,
	trash,
}
