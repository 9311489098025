import { initialTeams } from "../initial/initialTeams.js"
import { TypeKeys } from "../actions/actionTypes"

export const teamsReducer = (state = initialTeams(), action) => {
  switch (action.type) {
    case TypeKeys.GET_TEAMS_REQUEST:
      return { ...state, teams: [], loading: true }
    case TypeKeys.GET_TEAMS_SUCCESS:
      return { ...state, teams: action.teams, loading: false, pagination: {...action.pagination } }
    case TypeKeys.GET_ROOT_TEAMS_REQUEST:
      return { ...state, teams: [], loading: true }
    case TypeKeys.GET_ROOT_TEAMS_SUCCESS:
      return { ...state, teams: action.teams, loading: false }
    default:
    return { ...state }
  }
}

export default teamsReducer
