import { initialNewsfeeds } from "../initial/initialNewsfeeds.js"
import { TypeKeys } from "../actions/actionTypes"

export const newsfeedsReducer = (state = initialNewsfeeds(), action) => {
  switch (action.type) {
    case TypeKeys.GET_NEWSFEED_EDITORS_REQUEST:
      return { ...state, loading: true }
    case TypeKeys.GET_NEWSFEED_EDITORS_SUCCESS:
      return { ...state, editors: action.editors, loading: false }
    case TypeKeys.GET_NEWSFEED_TEAMS_REQUEST:
      return { ...state, loading: true }
    case TypeKeys.GET_NEWSFEED_TEAMS_SUCCESS:
      return { ...state, rootTeams: action.teams, loading: false }
    case TypeKeys.GET_NEWSFEED_MESSAGES_REQUEST:
      return { ...state, loading: true }
    case TypeKeys.GET_NEWSFEED_MESSAGES_SUCCESS:
      return { ...state, messages: action.messages, pagination: {...action.pagination }, loading: false }
    case TypeKeys.CREATE_NEWSFEED_MESSAGE_REQUEST:
      return { ...state, loading: true, status: 'processing' }
    case TypeKeys.CREATE_NEWSFEED_MESSAGE_SUCCESS:
      return { ...state, messageIds: action.messages, loading: false, status: 'success' }
    case TypeKeys.CREATE_NEWSFEED_MESSAGE_ERROR:
      return { ...state, errors: action.errors, loading: false, status: 'error' }
    default:
    return { ...state }
  }
}

export default newsfeedsReducer
