import { initialPerformanceIndicators } from "../initial/initialPerformanceIndicators.js"
import { TypeKeys } from "../actions/actionTypes"

export const performanceIndicatorsReducer = (state = initialPerformanceIndicators(), action) => {
  switch (action.type) {
    case TypeKeys.GET_PERFORMANCE_INDICATORS_REQUEST:
      return { ...state, performanceIndicators: [], loading: true }
    case TypeKeys.GET_PERFORMANCE_INDICATORS_SUCCESS:
      return { ...state, performanceIndicators: action.performanceIndicators, loading: false }
    case TypeKeys.UPDATE_PERFORMANCE_INDICATOR_REQUEST:
      return { ...state, loading: true }
    case TypeKeys.UPDATE_PERFORMANCE_INDICATOR_SUCCESS:
      return { ...state, performanceIndicators: action.performanceIndicators, loading: false }
    default:
    return { ...state }
  }
}

export default performanceIndicatorsReducer
