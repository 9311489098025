import { initialUsers } from '../initial/initialUsers.js'
import { TypeKeys } from '../actions/actionTypes'

export const usersReducer = (state = initialUsers(), action) => {
	switch (action.type) {
		// -------------

		case TypeKeys.GET_USERS_REQUEST:
			return { ...state, users: [], loading: true, status: 'processing' }
		case TypeKeys.GET_USERS_SUCCESS:
			return {
				...state,
				users: action.users,
				loading: false,
				pagination: { ...action.pagination },
				status: 'processing',
			}
		// -------------
		case TypeKeys.GET_USER_REQUEST:
			return { ...state, user: initialUsers().user, loading: true, status: 'processing' }
		case TypeKeys.GET_USER_SUCCESS:
			return { ...state, user: action.user, status: 'ready', loading: false }
		case 'GET_USER_ERROR':
			return { ...state, loading: false }
		// -------------
		case TypeKeys.GET_USER_STATS_REQUEST:
			return { ...state, userStats: initialUsers().userStats, statsLoading: true }
		case TypeKeys.GET_USER_STATS_SUCCESS:
			return { ...state, userStats: action.user, statsLoading: false }
		// -------------
		case TypeKeys.GET_USERS_LIST_REQUEST:
			return { ...state, usersList: [], loading: true }
		case TypeKeys.GET_USERS_LIST_SUCCESS:
			return { ...state, usersList: action.users, loading: false }
		// -------------
		case TypeKeys.CREATE_USER_REQUEST:
			return { ...state, loading: true, status: 'processing' }
		case TypeKeys.CREATE_USER_SUCCESS:
			return { ...state, user: action.user, loading: false, status: 'success' }
		// -------------
		case TypeKeys.UPDATE_USER_REQUEST:
			return { ...state, loading: true, status: 'processing' }
		case TypeKeys.UPDATE_USER_SUCCESS:
			return { ...state, user: action.user, loading: false, status: 'success' }
		case TypeKeys.UPDATE_USER_ERROR:
			return { ...state, errors: action.errors, loading: false, status: 'error' }
		default:
			return { ...state }
	}
}

export default usersReducer
