import { TypeKeys } from '../actionTypes'

export const getTeamsRequest = (query) => ({ type: TypeKeys.GET_TEAMS_REQUEST, query })
export const getTeamsSuccess = (teams, pagination) => ({
  type: TypeKeys.GET_TEAMS_SUCCESS,
  teams,
  pagination
})

export const getTeamsListRequest = (companyId) => ({ type: TypeKeys.GET_TEAMS_LIST_REQUEST, companyId })
export const getTeamsListSuccess = (teams) => ({
  type: TypeKeys.GET_TEAMS_LIST_SUCCESS,
  teams
})

export const getRootTeamsRequest = () => ({ type: TypeKeys.GET_ROOT_TEAMS_REQUEST })
export const getRootTeamsSuccess = (teams) => ({
  type: TypeKeys.GET_ROOT_TEAMS_SUCCESS,
  teams
})

export const getTeamRequest = (id) => ({ type: TypeKeys.GET_TEAM_REQUEST, id })
export const getTeamSuccess = (team) => ({
  type: TypeKeys.GET_TEAM_SUCCESS,
  team
})

export const getTeamFail = (team) => ({
  type: TypeKeys.GET_TEAM_FAIL,
  team
})

export const getTeamStatsRequest = (id) => ({ type: TypeKeys.GET_TEAM_STATS_REQUEST, id })
export const getTeamStatsSuccess = (team) => ({ type: TypeKeys.GET_TEAM_STATS_SUCCESS, team})
export const getTeamStatsFail = (team) => ({ type: TypeKeys.GET_TEAM_STATS_FAIL, team})

export const createTeamRequest = (query) => ({ type: TypeKeys.CREATE_TEAM_REQUEST, query })
export const createTeamSuccess = (team) => ({
  type: TypeKeys.CREATE_TEAM_SUCCESS,
  team
})

export const updateTeamRequest = (query) => ({ type: TypeKeys.UPDATE_TEAM_REQUEST, query })
export const updateTeamSuccess = (team) => ({
  type: TypeKeys.UPDATE_TEAM_SUCCESS,
  team
})
