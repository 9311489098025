import { takeLatest, put, call, delay } from 'redux-saga/effects'
import { TypeKeys } from '../actions/actionTypes'
import { getKeyDatesSuccess } from '../actions/actionCreators/keyDatesActions'
import callApi from '../utils/callApi'
import config from '../config'

function* loadKeyDates(action) {
  const res = yield call(callApi, `${config.BASE_URL}/admin/api/v1/users/${action.id}/key_dates`)
  const keyDates = res.data
  const keyDatesData = keyDates.map((keyDate) => ({
    id: keyDate.id,
    date: keyDate.date,
    closedAt: keyDate.closed_at,
      interimAt: keyDate.interim_at,
      dayOff: keyDate.day_off,
      team: {
        id: keyDate.team.id,
        title: keyDate.team.title
      },
    }))
  yield put(getKeyDatesSuccess(keyDatesData))
}

export const keyDatesSagas = [
  takeLatest([ TypeKeys.GET_KEY_DATES_REQUEST], loadKeyDates)
]
