import axios from 'axios'
import config from '../config'
import { history } from '../reducers'
import { getToken } from './getToken'
import Logger from './Logger'

export const HTTPMethod = {
	DELETE: 'DELETE',
	GET: 'GET',
	HEAD: 'HEAD',
	POST: 'POST',
	PUT: 'PUT',
}

const defaultParams = {
	cancelToken: undefined,
	method: HTTPMethod.GET,
}

export function callSignInApi(url, params = defaultParams) {
	const source = axios.CancelToken.source()
	const { headers, ...restParams } = params
	restParams.cancelToken = source.token
	const customHeaders = { Accept: 'application/json' }

	const axiosPromise = axios({
		url,
		...restParams,
		headers: customHeaders,
	})
		.then(response => {
			return response
		})
		.catch(e => {
			if (e.response.status === 401) {
				localStorage.clear()
			}
			return e.response
		})

	const response = Object.defineProperty(axiosPromise, 'cancel', {
		enumerable: true,
		value: source.cancel,
	})

	return response
}

export default function callApi(url, params = defaultParams) {
	let token = ''
	if (url !== `${config.BASE_URL}/oauth/token`) {
		token = getToken()
	}
	const source = axios.CancelToken.source()
	const { headers, ...restParams } = params
	restParams.cancelToken = source.token
	const customHeaders = {
		Accept: 'application/json',
		Authorization: `Bearer ${token}`,
	}

	Logger.curl(url, {
		headers: customHeaders,
		method: restParams.method,
		body: JSON.stringify(restParams.data),
	})

	const axiosPromise = axios({
		url,
		...restParams,
		headers: customHeaders,
	})
		.then(res => {
			Logger.collapsedLog(`callback API ${url.replace(config.BASE_URL + '/api/v1/', '')}`, res)
			return res
		})
		.catch(e => {
			if (e.response.status === 401) {
				history.push('/login')
				localStorage.clear()
			}
			Logger.collapsedError(`callback API ${url.replace(config.BASE_URL + '/api/v1/', '')}`, e)
			return e.response
		})

	const response = Object.defineProperty(axiosPromise, 'cancel', {
		enumerable: true,
		value: source.cancel,
	})

	return response

}
