import React, { useCallback, useContext } from 'react'
import { makeStyles } from '@mui/styles'
import { Divider, Grid, Paper, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { TableUploads } from './components/organisms'
import { useFile, useSelect, useTable } from './hooks'
import { SelectFile, SelectType, ModalUploadError } from './components/moleculs'
import { Popup } from '../../components'

export default function UploadFilesPage() {
	const classes = useStyles()

	const select = useSelect()
	const table = useTable()
	const file = useFile(table, select)

	const popup = useContext(Popup.Context)

	const openErrorModal = useCallback(
		upload =>
			popup.set({
				Component: ModalUploadError,
				props: {
					upload,
					onClickOk: popup.dismiss,
				},
				isDark: true,
			}),
		[]
	)

	return (
		<Grid container spacing={2} className={classes.container}>
			<Grid item xs={12}>
				<Typography mb={2} variant='h4'>
					Загрузка файлов
				</Typography>

				<Grid item mb={1}>
					<Paper style={styles.br15}>
						<Grid container className={classes.header}>
							<Grid item xs={6} className={classes.left}>
								<SelectFile
									onChangeInput={file.get}
									onClick={file.open}
									inputRef={file.inputRef}
									file={file.item}
								/>
							</Grid>
							<Divider orientation='vertical' flexItem style={styles.divider} />
							<Grid item xs={6} className={classes.left}>
								<SelectType data={select.data} onChange={select.set} value={select.value} />
							</Grid>
						</Grid>
					</Paper>
				</Grid>

				<Grid item xs={5} flexDirection='row'>
					<LoadingButton
						disabled={!file.isReady}
						onClick={file.upload}
						loading={file.loading}
						className={classes.button}
						variant='contained'
						fullWidth
					>
						Загрузить
					</LoadingButton>
				</Grid>
			</Grid>

			<Grid item xs={12}>
				<Typography mb={2} variant='h4'>
					История загрузок
				</Typography>
				{table.data.length > 0 && (
					<TableUploads
						//
						data={table.data}
						className={classes.br15}
						style={styles.table}
						onClickError={openErrorModal}
					/>
				)}
			</Grid>
		</Grid>
	)
}

const styles = {
	divider: {
		marginRight: '-1px',
		backgroundColor: '#B3C6D4',
	},
	table: {
		paddingRight: 24,
		paddingLeft: 24,
		paddingBottom: 14,
		paddingTop: 14,
		borderRadius: 15,
	},
	br15: {
		borderRadius: 15,
	},
}

const useStyles = makeStyles(theme => ({
	container: {
		flexGrow: 1,
		padding: theme.spacing(4),
	},
	br15: {
		borderRadius: 15,
	},
	title: {
		marginBottom: 16,
	},
	divider: {
		marginRight: '-1px',
		backgroundColor: '#B3C6D4',
	},
	header: {
		paddingTop: 12,
	},
	left: {
		padding: '6px 10px 22px 30px',
	},
	button: {
		borderRadius: 10,
	},
}))
