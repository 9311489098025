import React, { useCallback, useState } from 'react'
import { IconButton, TableCell, TableRow } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import ChecklistMemberRow from './ChecklistMemberRow'

/**
 * @export
 * @param {{ item: ChecklistsDashboard, level?: number, isTop: boolean }} props
 */
export default function ChecklistTableRow(props) {
	const { item, level = 0, isTop } = props
	const [isOpen, setIsOpen] = useState(false)
	const toggle = useCallback(() => setIsOpen(v => !v), [])

	const isHaveChildren = !!item.children.length
	const isHaveMembers = !!item.data.members.length

	return (
		<>
			<TableRow>
				{/* Команда */}
				<TableCell
					style={{
						...styles.cell,
						paddingLeft: level * 16,
						fontWeight: isTop || level > 0 ? '400' : '700',
					}}
				>
					{item.title}
				</TableCell>
				{/* Администратор */}
				<TableCell style={styles.cell}>{!isTop ? item.admin : ''}</TableCell>
				{/* Кнопка */}
				<TableCell style={styles.cell}>
					{(isHaveChildren || isHaveMembers) && !isTop && (
						<IconButton aria-label='expand row' size='small' onClick={toggle}>
							{isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
						</IconButton>
					)}
				</TableCell>
				{/* Всего сотрудников */}
				<TableCell style={{ ...styles.cell, fontWeight: 700 }} align='left'>
					{isTop || isHaveChildren ? item.data.subtree_users_count : item.data.users_count}
				</TableCell>
				{/* Заполнено чек-листов */}
				<TableCell style={{ ...styles.cell, fontWeight: 700 }} align='left'>
					{isTop || isHaveChildren ? item.data.subtree_ucls : item.data.team_ucls}
				</TableCell>
				{/* Чек-листов на 1 сотрудника */}
				<TableCell style={{ ...styles.cell, fontWeight: 700 }} align='left'>
					{isTop || isHaveChildren ? item.data.subtree_ucl_per_user : item.data.team_ucl_per_user}
				</TableCell>
				{/* Процент */}
				<TableCell style={{ ...styles.cell, fontWeight: 700 }} align='left'>
					{isTop || isHaveChildren ? item.data.subtree_mean_percentage : item.data.team_percentage}
				</TableCell>
			</TableRow>

			{isOpen &&
				!isTop &&
				item.data.members.map(member => (
					<ChecklistMemberRow key={member?.id} item={member} level={level + 1} />
				))}
			{isOpen &&
				!isTop &&
				isHaveChildren &&
				item.children.map(item => (
					<ChecklistTableRow key={item.id} item={item} level={level + 1} />
				))}
		</>
	)
}

const styles = {
	cell: {
		padding: 0,
		paddingTop: 15,
		paddingBottom: 15,
	},
}
