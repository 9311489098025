import { createContext, useCallback, useState } from 'react'
import { Logger } from '../../utils'

export const PopupContext = createContext({
	options: {
		Component: null,
		isDark: false,
		props: {},
	},
	isVisible: false,
	set(options) {
		Logger.error('Context Error. PopupContext.set is not defined')
	},
	update(options) {
		Logger.error('Context Error. PopupContext.update is not defined')
	},
	dismiss() {
		Logger.error('Context Error. PopupContext.dismiss is not defined')
	},
})

export function usePopupContext() {
	const [options, set] = useState({
		Component: null,
		isDark: false,
		props: {},
	})

	const isVisible = options.Component !== null

	const update = useCallback(
		next =>
			set(prev => ({
				...prev,
				...next,
			})),
		[]
	)

	const dismiss = useCallback(
		() =>
			set({
				Component: null,
				props: {},
				isDark: false,
			}),
		[]
	)

	return {
		options,
		set,
		update,
		isVisible,
		dismiss,
	}
}
