import React from "react"
import EducationBlockForm from "./EducationBlockForm"
import { useParams } from "react-router-dom"
import { connect } from 'react-redux'
import { useEffect } from "react"
import { getEducationBlockRequest, updateEducationBlockRequest } from "../../actions/actionCreators/educationBlocksActions"

const EducationBlockEditForm = (props) => {
  const { educationBlock, educationBlockLoading, status } = props
  const { loadEducationBlock, updateEducationBlockRequest, history } = props
  const params = useParams()

  useEffect(() => {
    loadEducationBlock(params.educationCategoryId, params.educationId, params.id)
  }, [params.id]);

  if (educationBlock) {
    return(
      <EducationBlockForm key={`${educationBlock.id}-${educationBlock.updatedAt}`} educationBlock={educationBlock}
      type={"edit"} history={history} submit={updateEducationBlockRequest}
      loading={educationBlockLoading} status={status}></EducationBlockForm>)
  } else {
    return(<div></div>)
  }
}

const mapStateToProps = (state) => ({
  educationBlock: state.educationBlocksReducer.educationBlock,
  educationBlockLoading: state.educationBlocksReducer.loading,
  status: state.educationBlocksReducer.requestStatus
})

const mapDispatchToProps = (dispatch) => ({
  loadEducationBlock: (categoryId, educationId, id) => dispatch(getEducationBlockRequest(categoryId, educationId, id)),
  updateEducationBlockRequest: (query) => dispatch(updateEducationBlockRequest(query))
})

export default connect(mapStateToProps, mapDispatchToProps)(EducationBlockEditForm)
