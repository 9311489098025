import React, { useEffect } from "react"
import ChecklistCategoryForm from "./ChecklistCategoryForm"
import { useParams } from "react-router-dom"
import { connect } from 'react-redux'
import { getChecklistCategoryRequest, updateChecklistCategoryRequest }
  from "../../actions/actionCreators/checklistCategoriesActions"

const ChecklistCategoryEdit = ({ checklistCategory, loadChecklistCategory,
  updateChecklistCategory, history, companies }) => {

  const params = useParams()
  useEffect(() => { loadChecklistCategory(params.id) }, [params.id]);

  if (checklistCategory) {
    return(<ChecklistCategoryForm checklistCategory={checklistCategory}
      key={`${checklistCategory.id}-${checklistCategory.updatedAt}`}
      submit={updateChecklistCategory} type={"edit"} history={history}
      companies={companies}/>)
  } else {
    return(<div></div>)
  }
}

const mapStateToProps = (state) => ({
  checklistCategory: state.checklistCategoriesReducer.checklistCategory,
  companies: state.profileReducer.companies
})

const mapDispatchToProps = (dispatch) => ({
  loadChecklistCategory: (id) => dispatch(getChecklistCategoryRequest(id)),
  updateChecklistCategory: (query) => dispatch(updateChecklistCategoryRequest(query)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ChecklistCategoryEdit)
