import React from "react";
import moment from 'moment'
import 'moment/locale/ru'
import PropTypes from 'prop-types';
import { useEffect, useState } from "react";
import { connect } from 'react-redux'
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import clsx from 'clsx';
import { Table, TableBody, TableCell, TableContainer, TableHead, TextField,
  TableRow, TableFooter, IconButton, Avatar, List, ListItem, Breadcrumbs,
  ListItemText, ListItemAvatar, AppBar, Container, ButtonGroup,
  Paper, Grid, Typography, Hidden, Button, Collapse, Box, Chip, CircularProgress
  } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import GroupIcon from '@mui/icons-material/Group';
import GetAppIcon from '@mui/icons-material/GetApp';
import PersonIcon from '@mui/icons-material/Person';
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingBottom: theme.spacing(6),
    paddingTop: theme.spacing(4)
  },
  paper: {
    marginBottom: theme.spacing(6)
  },
  buttonGroup: {
    paddingTop: theme.spacing(2)
  },
  noPadding: {padding: theme.spacing(0)},
  fab: {
    [theme.breakpoints.down('md')]: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  }
}));

function compareByDate(a, b) {
  if (a.data.plans_updated_at < b.data.plans_updated_at) {
    return -1;
  }
  if (a.data.plans_updated_at > b.data.plans_updated_at) {
    return 1;
  }
  return 0;
}

function Row(props) {
  const { team, depth } = props;
  const [open, setOpen] = React.useState(false);

  const padding = makeStyles((theme) => ({
    teamPadding: { padding: theme.spacing(0), paddingLeft: theme.spacing(depth) },
    userPadding: { padding: theme.spacing(0), paddingLeft: theme.spacing(depth+2) },
    small: { width: theme.spacing(4), height: theme.spacing(4) },
    noPadding: {padding: theme.spacing(0)},
    tableIcon: { paddingRight: theme.spacing(1), paddingTop: theme.spacing(1) }
  }))();

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <Link className={padding.teamPadding} to={`/dashboard/${team.id}/team_summary`}>{team.title}</Link>
        </TableCell>
        <TableCell>{team.admin}</TableCell>
        <TableCell>
          {team.children.length > 0 && <IconButton className={padding.noPadding} aria-label="expand row"
            size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>}
        </TableCell>
        { team.children_size === 0 ?
          <TableCell colSpan={3}>
            {team.data.plans_updated_at ?
              `${moment(team.data.plans_updated_at).fromNow()} (${moment(team.data.plans_updated_at).format('DD.MM.YYYY')})`
              : 'Никогда' }
          </TableCell> :
          <React.Fragment>
            <TableCell align="right">{team.data.leaves_size}</TableCell>
            <TableCell align="right">{team.data.updated_this_month}</TableCell>
            <TableCell align="right">{team.data.updated_long_ago}</TableCell>
          </React.Fragment>
        }
      </TableRow>
      {open && team.children.sort(compareByDate).map((team) => (
        <Row key={team.id} team={team} depth={depth+2}/>
      ))}
    </React.Fragment>
  );
}

const PlansUpdatedTable = (props) => {
  moment.locale('ru')
  const classes = useStyles()
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))
  const { loading, team } = props

  return (
    props.loading ? <CircularProgress/> :
      <Grid item xs={12}>
        <Table aria-label="collapsible table" size="small">
          <TableHead>
            <TableRow>
              <TableCell colSpan={3}></TableCell>
              <TableCell colSpan={3} align="center">Руководители, изменившие план</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Название</TableCell>
              <TableCell>Администратор</TableCell>
              <TableCell/>
              <TableCell>Всего</TableCell>
              <TableCell>В этом месяце</TableCell>
              <TableCell>Ранее</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
              <TableCell>Дивизион</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell align="right">{team.data.leaves_size}</TableCell>
              <TableCell align="right">{team.data.updated_this_month}</TableCell>
              <TableCell align="right">{team.data.updated_long_ago}</TableCell>
            </TableRow>
            {team.children.sort(compareByDate).map((team) => (<Row key={team.id} team={team} depth={2}/>))}
          </TableBody>
        </Table>
      </Grid>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(PlansUpdatedTable)
