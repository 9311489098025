import { initialChecklists } from "../initial/initialChecklists"
import { TypeKeys } from "../actions/actionTypes"

export const checklistsReducer = (state = initialChecklists(), action) => {
  switch (action.type) {
    case TypeKeys.GET_CHECKLISTS_REQUEST:
      return { ...state, checklists: initialChecklists().checklists, loading: true }
    case TypeKeys.GET_CHECKLISTS_SUCCESS:
      return { ...state, checklists: action.checklists, loading: false }
    case TypeKeys.GET_CHECKLIST_REQUEST:
      return { ...state, checklist: initialChecklists().checklist, loading: true }
    case TypeKeys.GET_CHECKLIST_SUCCESS:
      return { ...state, checklist: action.checklist, loading: false }
    case TypeKeys.CREATE_CHECKLIST_REQUEST:
      return { ...state, checklist: initialChecklists().checklist, loading: true }
    case TypeKeys.CREATE_CHECKLIST_SUCCESS:
      return { ...state, checklist: action.checklist, loading: false }
    case TypeKeys.UPDATE_CHECKLIST_REQUEST:
      return { ...state, checklist: initialChecklists().checklist, loading: true }
    case TypeKeys.UPDATE_CHECKLIST_SUCCESS:
      return { ...state, checklist: action.checklist, loading: false }
    default:
    return { ...state }
  }
}

export default checklistsReducer
