import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { connect } from 'react-redux'
import { getTasksRequest } from "../../actions/actionCreators/tasksActions"

import moment from 'moment'
import Pagination from '../../components/Main/Pagination'
import TeamChip from "../../components/Main/TeamChip"
import TeamSelect from "../../components/Main/TeamSelect"
import UserChip from "../../components/Main/UserChip"
import UserBlock from "../../components/Main/UserBlock"
import { makeStyles, useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import clsx from 'clsx';

import { Table, TableBody, TableCell, TableContainer, TableHead,
  TableRow, TableFooter } from '@mui/material'
import { Paper, Grid, Typography, Toolbar, Container, Hidden,
  Fab, TextField, InputAdornment, Button, Box, Chip, Avatar,
  FormControlLabel, Checkbox, Divider, Select, MenuItem, FormControl,
  InputLabel, Tooltip } from '@mui/material'
import { List, ListItem, ListItemAvatar, ListItemText } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import SearchIcon from '@mui/icons-material/Search'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import DoneIcon from '@mui/icons-material/Done'
import CircularProgress from '@mui/material/CircularProgress'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import WatchLaterIcon from '@mui/icons-material/WatchLater'
import CancelIcon from '@mui/icons-material/Cancel'
import InventoryIcon from '@mui/icons-material/Inventory'

import { orange, red } from '@mui/material/colors'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4),
    },
  },
  divider: {
    margin: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2)
  },
  buttonGroup: {
    paddingTop: theme.spacing(2)
  },
  fab: {
  },
  teamChip: {
    marginLeft: theme.spacing(1),
    // marginTop: theme.spacing(1),
    '&:hover': { cursor: "pointer" },
  },
  avatarFont: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    letterSpacing: "-1px",
    fontSize: "0.8rem",
    backgroundColor: theme.palette.primary.main,
    margin: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      fontSize: "0.8rem"
    },
  },
  ignoreVisited: {
    color: theme.palette.common.blue,
    // textDecoration: 'none'
  },
  noWrap: {
    whiteSpace: "nowrap"
  }
}));

const TasksPanel = (props) => {

  const { tasks, pagination, loading, companies } = props

  const [collapsed, setCollapsed] = useState(false)
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search)

  const [title, setTitle] = useState(query.get("title") || "")
  const [assignee, setAssignee] = useState(query.get("assignee") || "")
  const [reporter, setReporter] = useState(query.get("reporter") || "")
  const [status, setStatus] = useState(query.get("workflow_state") || "")
  const [teamId, setTeamId] = useState(query.get("team_id") || "")
  const [blocked, setBlocked] = useState(query.get("blocked") || false)

  useEffect(() => {
    props.loadTasks(query)
  }, [query.toString()]);

  const toggleCollapse = () => setCollapsed(!collapsed)
  const toggleBlocked = (e) => setBlocked(e.target.checked)

  const changeTitle = (e) => setTitle(e.target.value)
  const changeAssignee = (e) => setAssignee(e.target.value)
  const changeReporter = (e) => setReporter(e.target.value)
  const changeStatus = (e) => setStatus(e.target.value)
  const changeTeamId = (v) => setTeamId(v.value)

  const genSearchUri = () => {
    const newQuery = new URLSearchParams(location.search)
    newQuery.set("page", 1)
    blocked ? newQuery.set("blocked", blocked) : newQuery.delete("blocked")
    title ? newQuery.set("title", title) : newQuery.delete("title")
    assignee ? newQuery.set("assignee", assignee) : newQuery.delete("assignee")
    reporter ? newQuery.set("reporter", reporter) : newQuery.delete("reporter")
    status ? newQuery.set("workflow_state", status) : newQuery.delete("workflow_state")
    teamId ? newQuery.set("team_id", teamId) : newQuery.delete("team_id")
    return `${location.pathname}?${newQuery.toString()}`
  }

  const performSearch = (key) => {
    if (key.key === "Enter") {
      navigate(genSearchUri())
    }
  }

  const classes = useStyles()
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Grid container spacing={2} >
            <Grid item xs={12}>
              <Typography variant="h4" sx={{marginBottom:"16px"}}>
                Задачи&nbsp;
                <Link to={`/tasks/new`}>
                  <Fab color="primary" aria-label="add" size={"small"} className={classes.fab}>
                    <AddIcon />
                  </Fab>
                </Link>
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} >
            <Grid item xs={12} sm={4}>
              <TextField id="title" label="Название" fullWidth variant="outlined" value={title}
                onChange={changeTitle} onKeyPress={performSearch} size="small"/>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField id="assignee" label="Исполнитель" fullWidth variant="outlined" value={assignee}
                onChange={changeAssignee} onKeyPress={performSearch} size="small"/>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField id="reporter" label="Автор" fullWidth variant="outlined" value={reporter}
                onChange={changeReporter} onKeyPress={performSearch} size="small"/>
            </Grid>
            <Grid item xs={6} sm={4}>
              <FormControl size="small" fullWidth>
                <InputLabel id="demo-select-small">Статус</InputLabel>
                <Select value={status} onChange={changeStatus} label="Статус" size="small">
                  <MenuItem value=""><em>Любой</em></MenuItem>
                  <MenuItem value={"active"}>В процессе</MenuItem>
                  <MenuItem value={"complete"}>Выполнено</MenuItem>
                  <MenuItem value={"rejected"}>Не выполнено</MenuItem>
                  <MenuItem value={"archived"}>Архивировано</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={4}>
              <TeamSelect key={companies} companyIds={companies.map(c => c.id)}
                changeTeam={changeTeamId} value={teamId} allowEmpty/>
            </Grid>
            <Grid item xs={6} sm={4}>
              <Button fullWidth variant="contained" color="primary" className="SearchButton" onClick={() => navigate(genSearchUri())}>
                Искать
              </Button>
            </Grid>
          </Grid>
          <Divider sx={{marginTop:"32px", marginBottom:"32px"}} />
          <Table className={classes.table} aria-label="simple table" size="small">
            <TableHead>
              <TableRow>
                <TableCell>Статус</TableCell>
                <TableCell>Название</TableCell>
                <Hidden smDown>
                  <TableCell>Исполнитель</TableCell>
                  <TableCell>Автор</TableCell>
                  <TableCell>Срок</TableCell>
                  <TableCell>Команда</TableCell>
                </Hidden>
              </TableRow>
            </TableHead>
            <TableBody>
              { loading &&
                <TableRow>
                  <TableCell colSpan={7} align="center"><CircularProgress/></TableCell>
                </TableRow> }
              { tasks.map((task) => {
                const { id, title, createdAt, updatedAt, assignee, reporter, localWorkflowState, workflowState,
                  team, performanceIndicator, dueDate, participant } = task
                return(
                  <TableRow key={id}>
                    <TableCell>
                      { workflowState === "completed" && <Tooltip title={localWorkflowState}><CheckCircleIcon color="success"/></Tooltip>}
                      { workflowState === "active" && <Tooltip title={localWorkflowState}><WatchLaterIcon sx={{ color: orange[500] }}/></Tooltip>}
                      { workflowState === "rejected" && <Tooltip title={localWorkflowState}><CancelIcon sx={{ color: red[500] }}/></Tooltip>}
                      { workflowState === "archived" && <Tooltip title={localWorkflowState}><InventoryIcon color="disabled"/></Tooltip>}
                    </TableCell>
                    <TableCell>
                      {participant ? <Typography
                        component={Link} to={`/tasks/${id}`}
                        variant="body1"
                        className={classes.ignoreVisited}>
                        {title}
                      </Typography> : <Typography variant="subtitle2" sx={{color:"#333"}}>{title}</Typography>}
                      <Hidden>
                        <Typography variant="body2" sx={{color:"#666"}}>{`${assignee.firstName} ${assignee.lastName}`}</Typography>
                      </Hidden>
                    </TableCell>
                    <Hidden smDown>
                      <TableCell><UserChip user={assignee}/></TableCell>
                      <TableCell><UserChip user={reporter}/></TableCell>
                      <TableCell>
                        <Typography variant="body2" className={classes.ignoreVisited}>
                          {moment(dueDate).format('DD.MM.YYYY')}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <TeamChip team={team}/>
                      </TableCell>
                    </Hidden>
                  </TableRow>
                )}
              )}
            </TableBody>
          </Table>
          <Box className={classes.buttonGroup}>
            { pagination.totalPages > 1 && <Pagination data={pagination} location={location}/>}
          </Box>
        </Paper>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = (state) => ({
  tasks: state.tasksReducer.tasks,
  pagination: state.tasksReducer.pagination,
  loading: state.tasksReducer.loading,
  companies: state.profileReducer.companies,
})

const mapDispatchToProps = (dispatch) => ({
  loadTasks: (query) => dispatch(getTasksRequest(query))
})

export default connect(mapStateToProps, mapDispatchToProps)(TasksPanel)
