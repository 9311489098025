import { useCallback, useEffect, useState } from 'react'
import { Upload } from '../modals'

export default function useTable() {
	const [data, setData] = useState([])
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(null)

	const push = useCallback(item => setData(data => [item, ...data]), [])
	const updateData = async () => {
		setLoading(true)
		try {
			setData((await Upload.getUploads()).sort(sortByDate))
		} catch (error) {
			setError(error)
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		updateData()
	}, [])

	return { data, loading, error, push, updateData }
}

const sortByDate = (upload1, upload2) => {
	const date1 = new Date(upload1.created_at)
	const date2 = new Date(upload2.created_at)
	if (date1 > date2) {
		return -1
	} else if (date1 < date2) {
		return 1
	} else {
		return 0
	}
}

// export const mock = [
//   new Upload({
//     created_at: new Date(),
//     errors: 'TEST TEST TEST TEST TEST TEST TEST TEST ',
//     id: 1,
//     status: 'created',
//     upload_type: {
//       id: 1,
//       company_id: 42,
//       title: 'mock',
//       // data_class: DC
//     },
//     user: {
//       first_name: 'Никита',
//       last_name: 'Железнов',
//     },
//   }),
//   new Upload({
//     created_at: new Date(),
//     errors: 'TEST TEST TEST TEST TEST TEST TEST TEST ',
//     id: 1,
//     status: 'fail',
//     upload_type: {
//       id: 1,
//       company_id: 42,
//       title: 'mock',
//       // data_class: DC
//     },
//     user: {
//       first_name: 'Никита',
//       last_name: 'Железнов',
//     },
//   }),
//   new Upload({
//     created_at: new Date(),
//     errors: 'TEST TEST TEST TEST TEST TEST TEST TEST ',
//     id: 1,
//     status: 'processed',
//     upload_type: {
//       id: 1,
//       company_id: 42,
//       title: 'mock',
//       // data_class: DC
//     },
//     user: {
//       first_name: 'Никита',
//       last_name: 'Железнов',
//     },
//   }),
// ]
