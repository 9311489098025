import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { getEducationCategoriesRequest } from "../../actions/actionCreators/educationCategoriesActions"

import { FormControl, InputLabel, Select, MenuItem, CircularProgress, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
const useStyles = makeStyles((theme) => ({}));

const EducationCategoriesSelect = ({ companies, educationCategoriesList, loading,
  loadEducationCategories, changeEducationCategory, value, label, allowEmpty,
  companyId }) => {

  const classes = useStyles()

  const changeSelectedEducationCategory = (e, value) =>
    changeEducationCategory({
      value: value.props.value, label: value.props.label
    })

  const educationCategories = educationCategoriesList
    .filter((ed) => ed.company.value === companyId)
    .map((ed) => ({ value: ed.id, label: `${ed.title}` }))

  useEffect(() => {
    companyId !== "" && loadEducationCategories()
  }, [companyId]);

  if (companyId === "") {
    return (<Typography>Выберите команду</Typography>)
  }

  if (loading) {
    return (<CircularProgress />)
  }

  if (educationCategories.length === 0) {
    return (<Typography>Категории обучений не найдены</Typography>)
  }

  return (
    <FormControl fullWidth variant="outlined" size="small" className={classes.formControl}>
      <InputLabel>{label ? label : "Категория обучений"}</InputLabel>
      <Select fullWidth value={value} onChange={changeSelectedEducationCategory} label={label ? label : "Категория обучений"}
        error={value === null || value === ''}>
      { educationCategories.map((option) =>
        <MenuItem key={option.value} value={option.value} label={option.label}>
          {option.label}
        </MenuItem>) }
      </Select>
    </FormControl>
  )
}
const mapStateToProps = (state) => ({
  companies: state.profileReducer.companies,
  educationCategoriesList: state.educationCategoriesReducer.educationCategories,
  loading: state.educationCategoriesReducer.loading
})

const mapDispatchToProps = (dispatch) => ({
  loadEducationCategories: (categoryId) => dispatch(getEducationCategoriesRequest(categoryId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(EducationCategoriesSelect)
