import React from "react";
import AwardTypeForm from "./AwardTypeForm"

import { connect } from 'react-redux'
import { createAwardTypeRequest } from "../../actions/actionCreators/awardTypesActions"

const AwardTypeNew = ({ createAwardType, companies, history }) => {
  const awardType = {
    description: "",
    weight: 0,
    company: {
      value: (companies.length > 0) ? companies[0].id : undefined,
      label: (companies.length > 0) ? companies[0].title : undefined
    },
    archived: false
  }
  return(<AwardTypeForm key={+ new Date()} awardType={awardType} companies={companies}
    submit={createAwardType} type={"new"} history={history}></AwardTypeForm>)
}

const mapStateToProps = (state) => ({
  companies: state.profileReducer.companies
})

const mapDispatchToProps = (dispatch) => ({
  createAwardType: (query) => dispatch(createAwardTypeRequest(query)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AwardTypeNew)
