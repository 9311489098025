import React from "react";
import { Link, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { useEffect } from "react";
import { getNewsfeedMessagesRequest } from "../../actions/actionCreators/newsfeedActions"

import { makeStyles, useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import moment from 'moment'
import 'moment/locale/ru'

import Pagination from '../../components/Main/Pagination'

import { Table, TableBody, TableCell, TableHead,
  TableRow } from '@mui/material';
import { Paper, Grid, Typography, Container, Box,
  Button } from '@mui/material';
import {Add} from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2)
  },
  table: {
    marginBottom: theme.spacing(4),
  },
  fab: {
    [theme.breakpoints.down('md')]: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  },
  ignoreVisited: {
    marginLeft: theme.spacing(2),
    color: theme.palette.common.black,
    textDecoration: "none"
  },
}));

const NewsfeedsPanel = (props) => {

  const { messages, loadMessages, pagination } = props
  const location = useLocation()
  const query = new URLSearchParams(location.search)

  useEffect(() => {
    loadMessages(query)
  }, [query.toString()]);

  const classes = useStyles()
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Grid container spacing={2} >
            <Grid item xs container direction="column">
              <Typography variant="h4" sx={{marginBottom: "16px"}}>
                Сообщения в ленту
              </Typography>
            </Grid>
            <Grid item>
              <Link to={`/newsfeeds/new`} className={classes.ignoreVisited}>
                <Button variant="contained">
                  <Add />
                  &nbsp;Добавить сообщение&nbsp;
                </Button>
              </Link>
            </Grid>
          </Grid>
          <Table className={classes.table} size={mobile ? "" : "small"} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Сообщение</TableCell>
                <TableCell>Лента команды</TableCell>
                <TableCell>Команда</TableCell>
                <TableCell>Автор</TableCell>
                <TableCell>Опубликована</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { false &&
                <TableRow>
                  <TableCell colSpan={5} align="center"><CircularProgress/></TableCell>
                </TableRow> }
              { messages.map((m) => {
                const { id, message, news_feed, root_team, author, created_at } = m
                return(
                  <TableRow key={id}>
                    <TableCell component="th" scope="row">
                        <Typography
                          component="span"
                          variant="body1">
                          {id}
                        </Typography>
                    </TableCell>
                    <TableCell align="left">{message}</TableCell>
                    <TableCell align="left">{news_feed}</TableCell>
                    <TableCell align="left">{root_team}</TableCell>
                    <TableCell align="left">{author}</TableCell>
                    <TableCell align="left">{moment(created_at).fromNow()}</TableCell>
                  </TableRow>
              )}
            )}
            </TableBody>
          </Table>
          <Box className={classes.buttonGroup}>
            { pagination.totalPages > 1 && <Pagination data={pagination} location={location}/>}
          </Box>
        </Paper>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = (state) => ({
  messages: state.newsfeedsReducer.messages,
  editors: state.newsfeedsReducer.editors,
  rootTeams: state.newsfeedsReducer.rootTeams,
  pagination: state.newsfeedsReducer.pagination,
})

const mapDispatchToProps = (dispatch) => ({
  loadMessages: (page) => dispatch(getNewsfeedMessagesRequest(page))
})

export default connect(mapStateToProps, mapDispatchToProps)(NewsfeedsPanel)
