import React from "react";
import { useEffect, useCallback } from "react";
import { connect } from 'react-redux'
import { getKeyDatesRequest } from "../../actions/actionCreators/keyDatesActions"

import { makeStyles } from '@mui/styles';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

import moment from 'moment'

const useStyles = makeStyles((theme) => ({
  root: {  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  smallCell: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  }
}));

const KeyDates = (props) => {
  const classes = useStyles();

  const { userId, keyDates, loadKeyDates } = props

  const load = useCallback((userId) => {
    loadKeyDates(userId);
  }, [loadKeyDates])

  useEffect(() => {
    userId && load(userId)
  }, [userId, load]);

  return (
    <Table size="small" className={classes.table} aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell align="left" className={classes.smallCell}>Команда</TableCell>
          <TableCell align="left" className={classes.smallCell}>Дата</TableCell>
          <TableCell align="left" className={classes.smallCell}>Выходной</TableCell>
          <TableCell align="left" className={classes.smallCell}>Промежуточный</TableCell>
          <TableCell align="left" className={classes.smallCell}>День закрыт</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        { keyDates.map((keyDate) => {
          const { id, date, closedAt, interimAt, team, dayOff } = keyDate
          return(
            <TableRow key={id}>
              <TableCell component="th" scope="row" className={classes.smallCell}>{ team.title }</TableCell>
              <TableCell align="left" className={classes.smallCell}>{moment(date).format('DD.MM.YYYY')}</TableCell>
              <TableCell align="left" className={classes.smallCell}>{ dayOff && "Выходной" }</TableCell>
              <TableCell align="left" className={classes.smallCell}>
                { interimAt && moment.utc(interimAt, "YYYY-MM-DDTHH:mm:ss.SSSSZ").local().format('HH:mm:ss') }
                </TableCell>
              <TableCell align="left" className={classes.smallCell}>
                { closedAt && moment.utc(closedAt, "YYYY-MM-DDTHH:mm:ss.SSSSZ").local().format('HH:mm:ss') }
              </TableCell>
            </TableRow>
          )
          }
        )}
      </TableBody>
    </Table>
  )
}

const mapStateToProps = (state) => ({
  keyDates: state.keyDatesReducer.keyDates,
  loading: state.keyDatesReducer.loading
})

const mapDispatchToProps = (dispatch) => ({
  loadKeyDates: (id) => dispatch(getKeyDatesRequest(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(KeyDates)
