export const initialAwardTypes = () => {
  return {
    awardTypes: [],
    awardType: {
      id: undefined,
      description: undefined,
      weight: undefined,
      company: {
        value: undefined,
        label: ""
      },
      archibed: false
    },
    awardHistory: [],
    loading: true,
    historyPagination: {
      totalPages: 0
    }
  }
}
