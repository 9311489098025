import { takeLatest, put, call, delay } from 'redux-saga/effects'
import { TypeKeys } from '../actions/actionTypes'
import { getReportsSuccess } from '../actions/actionCreators/reportsActions'
import callApi from '../utils/callApi'
import config from '../config'

const mapReports = (report) => ({
  id: report.id,
  jobError: report.job_error,
  reportStatus: report.report_status,
  reportType: report.report_type,
  reportParams: report.report_params,
  createdAt: report.created_at,
  updatedAt: report.updated_at
})

function* loadReports(action) {
  const res = yield call(callApi, `${config.BASE_URL}/admin/api/v1/reports`)
  const reports = res.data
  const reportsData = reports.map(mapReports)
  yield put(getReportsSuccess(reportsData))
}

function* createReport(action) {
  const res = yield call(callApi, `${config.BASE_URL}/admin/api/v1/reports`, { method: 'POST', data: action.query.data })
  const reports = res.data
  const reportsData = reports.map(mapReports)
  yield put(getReportsSuccess(reportsData))
}

export const reportsSagas = [
  takeLatest([ TypeKeys.GET_REPORTS_REQUEST ], loadReports),
  takeLatest([ TypeKeys.CREATE_REPORT_REQUEST ], createReport)
]
