import { initialUserPositions } from '../initial/initialUserPositions.js'
import { TypeKeys } from '../actions/actionTypes'

export const userPositionsReducer = (state = initialUserPositions(), action) => {
	switch (action.type) {
		case TypeKeys.GET_USER_POSITIONS_REQUEST:
			return { ...state, userPositions: initialUserPositions().userPositions, loading: true }
		case TypeKeys.GET_USER_POSITIONS_SUCCESS:
			return { ...state, userPositions: action.userPositions, loading: false }
		case TypeKeys.GET_USER_POSITIONS_ERROR:
			return { ...state, loading: false }
		//
		case TypeKeys.GET_USER_POSITION_REQUEST:
			return { ...state, userPosition: initialUserPositions().userPosition, loading: true }
		case TypeKeys.GET_USER_POSITION_SUCCESS:
			return { ...state, userPosition: action.userPosition, loading: false }
		//
		case TypeKeys.CREATE_USER_POSITION_REQUEST:
			return { ...state, userPosition: initialUserPositions().userPosition, loading: true }
		case TypeKeys.CREATE_USER_POSITION_SUCCESS:
			return { ...state, userPosition: action.userPosition, loading: false }
		//
		case TypeKeys.UPDATE_USER_POSITION_REQUEST:
			return { ...state, userPosition: initialUserPositions().userPosition, loading: true }
		case TypeKeys.UPDATE_USER_POSITION_SUCCESS:
			return { ...state, userPosition: action.userPosition, loading: false }
		default:
			return { ...state }
	}
}

export default userPositionsReducer
