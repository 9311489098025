import { TypeKeys } from '../actionTypes'

export const getNewsfeedEditorsRequest = () => ({ type: TypeKeys.GET_NEWSFEED_EDITORS_REQUEST })
export const getNewsfeedEditorsSuccess = (editors) => ({ type: TypeKeys.GET_NEWSFEED_EDITORS_SUCCESS, editors })

export const getNewsfeedTeamsRequest = () => ({ type: TypeKeys.GET_NEWSFEED_TEAMS_REQUEST })
export const getNewsfeedTeamsSuccess = (teams) => ({ type: TypeKeys.GET_NEWSFEED_TEAMS_SUCCESS, teams })

export const getNewsfeedMessagesRequest = (query) => ({ type: TypeKeys.GET_NEWSFEED_MESSAGES_REQUEST, query })
export const getNewsfeedMessagesSuccess = (messages, pagination) => ({
  type: TypeKeys.GET_NEWSFEED_MESSAGES_SUCCESS, messages, pagination
})

export const createNewsfeedMessageRequest = (query) => ({ type: TypeKeys.CREATE_NEWSFEED_MESSAGE_REQUEST, query })
export const createNewsfeedMessageSuccess = (messages) => ({
  type: TypeKeys.CREATE_NEWSFEED_MESSAGE_SUCCESS,
  messages
})
export const createNewsfeedMessageError = (errors) => ({
  type: TypeKeys.CREATE_NEWSFEED_MESSAGE_ERROR,
  errors
})
