import { TypeKeys } from '../actionTypes'

export const getKpiChannelsRequest = () => ({ type: TypeKeys.GET_KPI_CHANNELS_REQUEST })

export const getKpiChannelsSuccess = (kpiChannels) => ({
  type: TypeKeys.GET_KPI_CHANNELS_SUCCESS,
  kpiChannels
})

export const getKpiChannelRequest = (id) => ({ type: TypeKeys.GET_KPI_CHANNEL_REQUEST, id })

export const getKpiChannelSuccess = (kpiChannel) => ({
  type: TypeKeys.GET_KPI_CHANNEL_SUCCESS,
  kpiChannel
})

export const createKpiChannelRequest = (query) => ({ type: TypeKeys.CREATE_KPI_CHANNEL_REQUEST, query })

export const createKpiChannelSuccess = (kpiChannel) => ({
  type: TypeKeys.CREATE_KPI_CHANNEL_SUCCESS,
  kpiChannel
})

export const updateKpiChannelRequest = (query) => ({ type: TypeKeys.UPDATE_KPI_CHANNEL_REQUEST, query })

export const updateKpiChannelSuccess = (kpiChannel) => ({
  type: TypeKeys.UPDATE_KPI_CHANNEL_SUCCESS,
  kpiChannel
})
