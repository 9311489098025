import React from 'react'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { getRootTeamsRequest } from '../../actions/actionCreators/teamsActions'
import { makeStyles, useTheme } from '@mui/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import { Link } from 'react-router-dom'
import {
	Card,
	CardContent,
	CardHeader,
	CardActions,
	Avatar,
	Container,
	Grid,
	Typography,
	Button,
} from '@mui/material'
import GroupIcon from '@mui/icons-material/Group'

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		padding: theme.spacing(4),
	},
	paper: {
		padding: theme.spacing(2),
	},
	buttonGroup: {
		paddingTop: theme.spacing(2),
	},
	fab: {
		[theme.breakpoints.down('md')]: {
			position: 'fixed',
			bottom: theme.spacing(2),
			right: theme.spacing(2),
		},
	},
}))

const IndexPanel = ({ teams, loadRootTeams }) => {
	useEffect(() => {
		loadRootTeams()
	}, [])
	const classes = useStyles()
	const theme = useTheme()

	return (
		<Grid container spacing={2} className={classes.root}>
			{teams.map(team => (
				<Grid item sm={4} xs={12} key={team.id}>
					<Card style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
						<CardHeader
							component={Link}
							to={`/dashboard/${team.id}`}
							avatar={
								<Avatar src={team.teamAvatarUrl}>
									<GroupIcon />
								</Avatar>
							}
							style={{ flexGrow: 1 }}
							title={<Typography variant='h6'>{team.title}</Typography>}
						/>
						<CardContent>
							<Typography variant='body2' color='textSecondary' component='p'>
								{team.admin.firstName} {team.admin.lastName}
							</Typography>
							<Typography variant='body2' color='textSecondary' component='p'>
								Участников: {team.membersCount}
							</Typography>
						</CardContent>
						<CardActions>
							<Button size='small' component={Link} to={`/dashboard/${team.id}`}>
								Открыть панель команды
							</Button>
						</CardActions>
					</Card>
				</Grid>
			))}
		</Grid>
	)
}

const mapStateToProps = state => ({
	teams: state.teamsReducer.teams,
})

const mapDispatchToProps = dispatch => ({
	loadRootTeams: () => dispatch(getRootTeamsRequest()),
})

export default connect(mapStateToProps, mapDispatchToProps)(IndexPanel)
