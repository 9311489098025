import { takeLatest, put, call } from 'redux-saga/effects'
import { TypeKeys } from '../actions/actionTypes'
import { getChecklistCategoriesSuccess, getChecklistCategorySuccess,
  createChecklistCategorySuccess, updateChecklistCategorySuccess }
  from '../actions/actionCreators/checklistCategoriesActions'
import callApi from '../utils/callApi'
import config from '../config'

const mapChecklistCategory = (checklistCategory) => {
  return {
    id: checklistCategory.id,
    title: checklistCategory.title,
    company: {
      value: checklistCategory.company.id,
      label: checklistCategory.company.title
    },
    createdAt: checklistCategory.created_at,
    updatedAt: checklistCategory.updated_at,
  }
}

function* loadChecklistCategories(action) {
  const res = yield call(callApi, `${config.BASE_URL}/admin/api/v1/checklist_categories`)
  yield put(getChecklistCategoriesSuccess(res.data.map(mapChecklistCategory)))
}

function* loadChecklistCategory(action) {
  const res = yield call(callApi,
    `${config.BASE_URL}/admin/api/v1/checklist_categories/${action.id}`)
  yield put(getChecklistCategorySuccess(mapChecklistCategory(res.data)))
}

function* createChecklistCategory(action) {
  const res = yield call(callApi,
    `${config.BASE_URL}/admin/api/v1/checklist_categories`,
    { method: 'POST', data: action.query.data })
  yield put(createChecklistCategorySuccess(mapChecklistCategory(res.data)))
}

function* updateChecklistCategory(action) {
  const res = yield call(callApi,
    `${config.BASE_URL}/admin/api/v1/checklist_categories/${action.query.checklistCategoryId}`,
    { method: 'PUT', data: action.query.data })
  yield put(updateChecklistCategorySuccess(mapChecklistCategory(res.data)))
}

export const checklistCategoriesSagas = [
  takeLatest([TypeKeys.GET_CHECKLIST_CATEGORIES_REQUEST], loadChecklistCategories),
  takeLatest([TypeKeys.GET_CHECKLIST_CATEGORY_REQUEST], loadChecklistCategory),
  takeLatest([TypeKeys.CREATE_CHECKLIST_CATEGORY_REQUEST], createChecklistCategory),
  takeLatest([TypeKeys.UPDATE_CHECKLIST_CATEGORY_REQUEST], updateChecklistCategory),
]
