import { Grid, Paper, Tab, Tabs, TextField } from '@mui/material'
import React from 'react'
import { TabPanel } from '../atoms'
import TeamSelect from '../../../../../components/Main/TeamSelect'

function changeTabInex(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	}
}

export default function FieldNewUser({
	isPasswordError,
	password,
	passwordConfirmation,
	memberTeam,
	teamTitle,
	adminTeam,
	tabIndex,
  companies,

	onChangePasswordValue,
	onChangePasswordConfirmationValue,
	onChangeTabIndexChange,
	onChangeTeamTitleValue,
	onChangeMemberTeamValue,
	onChangeAdminTeamValue,
}) {
	return (
		<div>
			<Grid item sm={6} xs={12}>
				<TextField
					size='small'
					type='password'
					error={isPasswordError}
					fullWidth
					value={password}
					label='Пароль'
					onChange={onChangePasswordValue}
					defaultValue='Hello World'
					helperText={isPasswordError ? 'Пароли не совпадают' : ''}
					variant='outlined'
				/>
			</Grid>
			<Grid item sm={6} xs={12}>
				<TextField
					size='small'
					type='password'
					error={isPasswordError}
					fullWidth
					value={passwordConfirmation}
					label='Подтверждение пароля'
					onChange={onChangePasswordConfirmationValue}
					defaultValue='Hello World'
					helperText={isPasswordError ? 'Пароли не совпадают' : ''}
					variant='outlined'
				/>
			</Grid>
			<Grid item sm={12} xs={12}>
				<Paper square>
					<Tabs
						value={tabIndex}
						indicatorColor='primary'
						textColor='primary'
						onChange={onChangeTabIndexChange}
					>
						<Tab label='Выбрать команду' {...changeTabInex(0)} />
						<Tab label='Создать команду' {...changeTabInex(1)} />
					</Tabs>
					<TabPanel value={tabIndex} index={0}>
						<TeamSelect
							key={companies}
							companyId={companies.map(c => c.id)}
							changeTeam={onChangeMemberTeamValue}
							value={memberTeam.value}
						/>
					</TabPanel>
					<TabPanel value={tabIndex} index={1}>
						<Grid container spacing={2}>
							<Grid item sm={6} xs={12}>
								<TextField
									variant='outlined'
									fullWidth
									size='small'
									label='Имя'
									value={teamTitle}
									onChange={onChangeTeamTitleValue}
								/>
							</Grid>
							<Grid item sm={6} xs={12}>
								<TeamSelect
									label={'Родительская команда'}
									key={companies}
									companyId={companies.map(c => c.id)}
									changeTeam={onChangeAdminTeamValue}
									value={adminTeam.value}
								/>
							</Grid>
						</Grid>
					</TabPanel>
				</Paper>
			</Grid>
		</div>
	)
}
