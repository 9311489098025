import { initialEducations } from "../initial/initialEducations"
import { TypeKeys } from "../actions/actionTypes"

export const educationsReducer = (state = initialEducations(), action) => {
  switch (action.type) {
    case TypeKeys.GET_EDUCATIONS_REQUEST:
      return { ...state, educations: initialEducations().educations,
        loading: true, requestStatus: '' }
    case TypeKeys.GET_EDUCATIONS_SUCCESS:
      return { ...state, educations: action.educations,
        loading: false, requestStatus: '' }
    case TypeKeys.GET_EDUCATION_REQUEST:
      return { ...state, education: initialEducations().education,
        loading: true, requestStatus: '' }
    case TypeKeys.GET_EDUCATION_SUCCESS:
      return { ...state, education: action.education,
        loading: false, requestStatus: '' }
    case TypeKeys.CREATE_EDUCATION_REQUEST:
      return { ...state, education: initialEducations().education,
        loading: true, requestStatus: '' }
    case TypeKeys.CREATE_EDUCATION_SUCCESS:
      return { ...state, education: action.education,
        loading: false, requestStatus: 'success' }
    case TypeKeys.UPDATE_EDUCATION_REQUEST:
      return { ...state, education: initialEducations().education,
        loading: true, requestStatus: '' }
    case TypeKeys.UPDATE_EDUCATION_SUCCESS:
      return { ...state, education: action.education,
        loading: false, requestStatus: 'success' }
    default:
    return { ...state }
  }
}

export default educationsReducer
