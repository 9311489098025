import React from "react";
import TaskTypeForm from "./TaskTypeForm"
import { useParams } from "react-router-dom"
import { connect } from 'react-redux'
import { useEffect } from "react";
import { getTaskTypeRequest, updateTaskTypeRequest }
  from "../../actions/actionCreators/taskTypesActions"

const TaskTypeEdit = ({ taskType, loadTaskType, updateTaskType, history, companies }) => {
  const params = useParams()
  useEffect(() => {
    loadTaskType(params.id)
  }, [params.id]);

  if (taskType) {
    return(<TaskTypeForm key={`${taskType.id}-${taskType.updatedAt}`} taskType={taskType}
      submit={updateTaskType} type={"edit"} history={history} companies={companies}></TaskTypeForm>)
  } else {
    return(<div></div>)
  }
}

const mapStateToProps = (state) => ({
  taskType: state.taskTypesReducer.taskType,
  companies: state.profileReducer.companies
})

const mapDispatchToProps = (dispatch) => ({
  loadTaskType: (id) => dispatch(getTaskTypeRequest(id)),
  updateTaskType: (query) => dispatch(updateTaskTypeRequest(query)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TaskTypeEdit)
