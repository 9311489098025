import React from 'react';
import { Link, useParams } from "react-router-dom";
import PropTypes from 'prop-types';
import { useState, useEffect } from "react";
import moment from 'moment'
import { connect } from 'react-redux'
import { getTaskRequest, updateTaskRequest } from "../../actions/actionCreators/tasksActions"
import { useHistory } from "react-router-dom";
import TeamChip from "../../components/Main/TeamChip"
import UserChip from "../../components/Main/UserChip"
import { Paper, Grid, Typography, Toolbar, Container, Hidden,
  Fab, TextField, InputAdornment, Button, Box, Chip, Avatar,
  Divider, Tabs, Tab, AppBar, Tooltip, Stack } from '@mui/material'
import { Table, TableBody, TableCell, TableContainer, TableHead,
  TableRow, TableFooter } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import EventIcon from '@mui/icons-material/Event'
import PersonIcon from '@mui/icons-material/Person'
import GroupsIcon from '@mui/icons-material/Groups'
import StarIcon from '@mui/icons-material/Star'
import AutoGraphIcon from '@mui/icons-material/AutoGraph'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import WatchLaterIcon from '@mui/icons-material/WatchLater'
import CancelIcon from '@mui/icons-material/Cancel'
import InventoryIcon from '@mui/icons-material/Inventory'
import PostAddIcon from '@mui/icons-material/PostAdd'
import CircularProgress from '@mui/material/CircularProgress'
import { makeStyles, useTheme } from '@mui/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { orange, red } from '@mui/material/colors'

import clsx from 'clsx';
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4),
    },
  },
  paper: {
    // padding: theme.spacing(4),s
  },
  editButton: {
    // marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2)
  },
  button: {
    // marginTop: theme.spacing(2)
  },
  teamChip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    '&:hover': { cursor: "pointer" },
  },
  ignoreVisited: {
    color: theme.palette.common.black
  },
  rotateIcon: {
    width: theme.spacing(12),
    height: theme.spacing(12)
  },
  badgeCustom: {
    color: theme.palette.secondary.main,
    border: "1px solid red",
    padding: theme.spacing(1),
    borderRadius: theme.spacing(4),
    marginLeft: theme.spacing(1)
  }
}));

const TaskDescription = (props) => {

  const classes = useStyles()
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))

  const params = useParams()

  useEffect(() => {
    props.loadTask(params.id)
  }, [params.id]);

  const { id, createdAt, title, assignee, reporter, team, description, performanceIndicator, dueDate,
    localWorkflowState, archivedAt, comments, workflowState, availableEvents,
    additionalRoAttributes, additionalAttributes, taskTypeId, taskType } = props.task

  const updateStatus = (event) => {
    return () => {
      props.updateTask({
        taskId: id,
        data: {workflow_state: event}
      })
    }
  }
  return (
    props.loading ?
      <CircularProgress/>
    : <Grid container spacing={4} className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Grid container spacing={2} align={mobile ? "center" : null}>
            <Grid item xs={12}>
              <Grid container spacing={2} align={mobile ? "center" : null} sx={{padding: '16px', paddingTop: '0px'}}>
                <Grid item md={8} xs={12}>
                  <Typography variant="h4">
                    {title}
                  </Typography>
                </Grid>
                <Grid item md={4} xs={12} align={mobile ? "center" : 'right'}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    endIcon={<EditIcon/>}
                    component={Link} to={`/tasks/${id}/edit`}
                  >
                    Редактировать
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h5">
                    ID: {id}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider/>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography variant="subtitle1" sx={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                    <EventIcon sx={{marginRight: "6px"}}/><strong>Создана</strong>
                  </Typography>
                  <Typography variant="body1" align={'left'}>
                    {moment(createdAt).format('DD.MM.YYYY HH:mm:ss')}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} align={'left'}>
                  <Typography variant="subtitle1" sx={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                    <EventIcon sx={{marginRight: "6px"}}/><strong>Срок выполнения</strong>
                  </Typography>
                  До {moment(dueDate).format('DD.MM.YYYY')}
                </Grid>
                <Grid item xs={12} md={4} sx={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}} align={'left'}>
                  <Typography variant="body1" sx={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                    { workflowState == "completed" && <Tooltip title={localWorkflowState}><CheckCircleIcon color="success" sx={{marginRight: "6px"}}/></Tooltip>}
                    { workflowState == "active" && <Tooltip title={localWorkflowState}><WatchLaterIcon sx={{ color: orange[500], marginRight: "6px" }}/></Tooltip>}
                    { workflowState == "rejected" && <Tooltip title={localWorkflowState}><CancelIcon sx={{ color: red[500], marginRight: "6px" }}/></Tooltip>}
                    { workflowState == "archived" && <Tooltip title={localWorkflowState}><InventoryIcon color="disabled" sx={{marginRight: "6px"}}/></Tooltip>}
                    <span>{localWorkflowState}</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} align={'left'}>
                  <Typography variant="subtitle1" sx={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                    <PersonIcon sx={{marginRight: "6px"}}/><strong>Исполнитель</strong>
                  </Typography>
                  <UserChip user={assignee}/>
                </Grid>
                <Grid item xs={12} md={4} align={'left'}>
                  <Typography variant="subtitle1" sx={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                    <PersonIcon sx={{marginRight: "6px"}}/><strong>Автор</strong>
                  </Typography>
                  <UserChip user={reporter}/>
                </Grid>
                <Grid item xs={12} md={4} align={'left'}>
                  <Typography variant="subtitle1" sx={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                    <GroupsIcon sx={{marginRight: "6px"}}/><strong>Команда</strong>
                  </Typography>
                  <TeamChip team={team}/>
                </Grid>
                {archivedAt && <Grid item xs={12} md={4} align={'left'}>
                  <Typography variant="subtitle1" sx={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                    <EventIcon sx={{marginRight: "6px"}}/><strong>Дата принятия</strong>
                  </Typography>
                  {moment(archivedAt).format('DD.MM.YYYY')}
                </Grid>}
                {performanceIndicator &&
                  <Grid item xs={12} md={4} align={'left'}>
                    <Typography variant="subtitle1" sx={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                      <AutoGraphIcon sx={{marginRight: "6px"}}/><strong>KPI</strong>
                    </Typography>
                    {performanceIndicator.title}
                  </Grid>}
                {taskTypeId &&
                  <Grid item xs={12} md={4} align={'left'}>
                    <Typography variant="subtitle1" sx={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                      <PostAddIcon sx={{marginRight: "6px"}}/><strong>Тип задачи</strong>
                    </Typography>
                    {taskType.name}
                  </Grid>}
                <Grid item xs={12}>
                  <Divider/>
                </Grid>
                <Grid item xs={12} md={4} align={'left'}>
                  <Typography variant="subtitle1" sx={{marginBottom: '0px'}}>Установить статус</Typography>
                  <Stack spacing={2} direction="row" align={'center'}>
                    {availableEvents.map((event) =>
                      <Button size="large" variant="outlined" sx={{color: event.color}} onClick={updateStatus(event.name)}>
                        {event.localized_name}
                      </Button>)}
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Divider/>
                </Grid>
                {additionalRoAttributes.length > 0 && <Grid item xs={12} align={'left'}>
                  <Typography variant="h6" sx={{marginBottom: '0px'}}>Поля для отображения</Typography>
                  <Table className={classes.table} aria-label="simple table" size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Название</TableCell>
                        <TableCell>Значение</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      { additionalRoAttributes.map((attr, index) => {
                        return(
                          <TableRow key={attr.name}>
                            <TableCell width={300}>{attr.title}</TableCell>
                            <TableCell>{attr.value}</TableCell>
                          </TableRow>
                        )}
                      )}
                    </TableBody>
                  </Table>
                </Grid>}
                {additionalAttributes.length > 0 && <Grid item xs={12} align={'left'}>
                  <Typography variant="h6" sx={{marginBottom: '0px'}}>Поля для ввода</Typography>
                  <Table className={classes.table} aria-label="simple table" size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell width={300}>Название</TableCell>
                        <TableCell>Значение</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      { additionalAttributes.map((attr, index) => {
                        const { name, value, title } = attr
                        return(
                          <TableRow key={name}>
                            <TableCell width={300}>{title}</TableCell>
                            <TableCell>{attr.value}</TableCell>
                          </TableRow>
                        )}
                      )}
                    </TableBody>
                  </Table>
                </Grid>}
                <Grid item xs={12} align={'left'}>
                  <Typography variant="h6" sx={{marginBottom: '0px'}}>Описание</Typography>
                  {description}
                </Grid>
                <Grid item xs={12}>
                  <Divider/>
                </Grid>
                <Grid item xs={12} align={'left'}>
                  <Typography variant="h6" sx={{marginBottom: '0px'}}>Комментарии</Typography>
                  {comments.length === 0 && <Typography variant="body1" sx={{marginBottom: '0px'}}>Нет комментариев</Typography>}
                  {comments.map((comment) =>
                    <Paper sx={{marginBottom: '16px', marginTop: '16px', padding: '16px'}} elevation={3}>
                        <Grid container spacing={2}>
                          <Grid item xs={9}>
                            <Typography variant="subtitle1" sx={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                              <PersonIcon sx={{marginRight: "6px"}}/>
                              <strong>{comment.author.firstName} {comment.author.lastName}</strong>
                            </Typography>
                          </Grid>
                          <Grid item xs={3} align={"right"}>
                            {moment(comment.createdAt).format('DD.MM.YYYY HH:mm:ss')}
                          </Grid>
                          <Grid item xs={12}>
                            {comment.replyTo && <Paper sx={{backgroundColor: "#FAFAFA", padding: "16px", marginBottom: '16px'}}>
                              <Typography variant="body2" sx={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                                <PersonIcon sx={{marginRight: "6px"}}/>
                                <strong>{comment.replyTo.author.firstName} {comment.replyTo.author.lastName}</strong>
                              </Typography>
                              {comment.replyTo.comment}
                            </Paper>}
                            <Typography variant="body1">
                              {comment.comment}
                            </Typography>
                          </Grid>
                        </Grid>
                    </Paper>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = (state) => ({
  task: state.tasksReducer.task,
  loading: state.tasksReducer.loading
})

const mapDispatchToProps = (dispatch) => ({
  loadTask: (id) => dispatch(getTaskRequest(id)),
  updateTask: (query) => dispatch(updateTaskRequest(query))
})

export default connect(mapStateToProps, mapDispatchToProps)(TaskDescription);
