import React from 'react'
import { FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material'

/**
 * @param {{
 * 	id: string,
 * 	value: string,
 * 	onChange: Function,
 * 	items: any[]
 * 	title: string
 * 	renderItem: Function
 *  isHaveNull: boolean
 * 	disabled: boolean
 * }} props
 * @return {*}
 */
export default function StyledSelect(props) {
	const { id, items, onChange, value, title, renderItem, isHaveNull, disabled } = props
	return (
		<FormControl variant='standard' fullWidth disabled={disabled}>
			<InputLabel id={`select-label-${id}`}>{title}</InputLabel>
			<Select
				labelId={`select-label-${id}`}
				id={`select-${id}`}
				value={value}
				onChange={onChange}
				onSelect={e => console.log('onSelect', e)}
				label={title}
			>
				{isHaveNull && (
					<MenuItem value={null}>
						<Typography fontStyle='italic'>Все</Typography>
					</MenuItem>
				)}
				{items.map((item, index) => renderItem({ item, index }))}
			</Select>
		</FormControl>
	)
}
