import { initialUserResults } from "../initial/initialUserResults.js"
import { TypeKeys } from "../actions/actionTypes"

export const userResultsReducer = (state = initialUserResults(), action) => {
  switch (action.type) {
    case TypeKeys.GET_USER_RESULTS_REQUEST:
      return { ...state, userResults: [], loading: true }
    case TypeKeys.GET_USER_RESULTS_SUCCESS:
      return { ...state, userResults: action.userResuls, loading: false }
    default:
    return { ...state }
  }
}

export default userResultsReducer
