import { initialMedals } from "../initial/initialMedals.js"
import { TypeKeys } from "../actions/actionTypes"

export const medalsReducer = (state = initialMedals(), action) => {
  switch (action.type) {
    case TypeKeys.GET_MEDALS_REQUEST:
      return { ...state, medals: [], loading: true }
    case TypeKeys.GET_MEDALS_SUCCESS:
      return { ...state, medals: action.medals, loading: false }
    case TypeKeys.GET_MEDAL_REQUEST:
      return { ...state, medal: null, loading: true }
    case TypeKeys.GET_MEDAL_SUCCESS:
      return { ...state, medal: action.medal, loading: false }
    case TypeKeys.UPDATE_MEDAL_REQUEST:
      return { ...state, loading: true }
    case TypeKeys.UPDATE_MEDAL_SUCCESS:
      return { ...state, medals: action.medals, loading: false }
    default:
    return { ...state }
  }
}

export default medalsReducer
