import React, { useState } from "react"
import { Link } from "react-router-dom"
import { Paper, Grid, Typography, Divider, TextField, FormControlLabel,
  Switch, Button, FormControl, InputLabel, Select,
  MenuItem, Stack } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import UserSelect from '../../components/Main/userSelect'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(4),
    '& .MuiTextField-root': {
      marginTop: theme.spacing(1),
      // width: '25ch',
    }
  },
  paper: {
    padding: theme.spacing(2)
  },
  idHeader: {
    marginBottom: theme.spacing(2),
  },
  margin: {
    margin: theme.spacing(0),
    marginRight:  theme.spacing(4),
  },
  buttonMargin: {
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    textDecoration: "none"
  },
  fab: {
    [theme.breakpoints.down('md')]: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  },
}));

const MedalForm = ({ medal, submit, type }) => {

  const { id } = medal

  const [title, setTitle] = useState(medal.title)
  const [description, setDescription] = useState(medal.description)
  const [weight, setWeight] = useState(medal.weight)

  const setTitleValue = (e) => { setTitle(e.target.value) }
  const setDescriptionValue = (e) => { setDescription(e.target.value) }
  const setWeightValue = (e) => { setWeight(e.target.value) }

  const submitAction = () => {
    submit({
      medalId: id,
      data: {
        title: title,
        description: description,
        weight: weight
      }
    })
  }

  const classes = useStyles()
  const theme = useTheme()

  return(
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Typography variant="h6" sx={{marginBottom: "16px"}}>
            { type==="edit" && "Изменить медаль" }
            { type==="new" && "Создать медаль" }
          </Typography>
          <form noValidate autoComplete="off">
            <Grid container spacing={2}>
              <Grid item sm={6}>
                <TextField required variant="outlined" fullWidth label="Название" value={title} onChange={setTitleValue} />
              </Grid>
              <Grid item sm={6}>
                <TextField required variant="outlined" fullWidth label="Описание" value={description} onChange={setDescriptionValue} />
              </Grid>
              <Grid item sm={6}>
                <FormControl fullWidth variant="outlined" size="small" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">Вес</InputLabel>
                  <Select
                    fullWidth
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={weight}
                    onChange={setWeightValue}
                    label="Вес">
                    <MenuItem key="0" value={0} label={0}>0</MenuItem>
                    <MenuItem key="5" value={5} label={5}>5</MenuItem>
                    <MenuItem key="10" value={10} label={10}>10</MenuItem>
                    <MenuItem key="15" value={15} label={15}>15</MenuItem>
                    <MenuItem key="20" value={20} label={20}>20</MenuItem>
                    <MenuItem key="25" value={25} label={25}>25</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={6}/>
              <Grid item sm={12}>
                <Stack spacing={2} direction="row">
                  <Button className={classes.buttonMargin} variant="outlined" to={`/medals`} component={Link}>
                    Назад
                  </Button>
                  <Button className={classes.buttonMargin} variant="contained" onClick={submitAction} color="primary">
                    { type==="edit" && "Сохранить" }
                    { type==="new" && "Создать" }
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default MedalForm;
