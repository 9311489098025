import React from "react";
import editlogo from '../../images/editlogo.svg'
import {Link} from "react-router-dom"


const TeamEdit = () =>
  <div className="TeamEditPanel">
      <div className="DescriptionInfo" style={{ margin: "24px 0 0 24px", width: "887px" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <h2 className="TeamDescriptionName" style={{margin: "0"}}>Редактирование команды</h2>
          <p className="TeamDescriptionID">#ID 12345678</p>
        </div>
        <div>
          <Link to={'/teams/team'} className="Back" style={{ marginLeft: "485px" }}>Назад</Link>
      </div>
      </div>
    <div style={{ display: "flex", margin: "0 0 0 21px" }}>
      <img className="editlogo" src={`${editlogo}`} />
      <div className="EditInfo">
        <p className="EditName">Название</p>
        <form>
          <input className="TeamEditName" type="text" name="name" placeholder="Королевы цветов" />
        </form>
      </div>
    </div>
    <div className="Companys">
      <div className="Company">
        <p className="EditName" >Компания</p>
      </div>
      <div className="Company">
        <p className="EditName">Родительская команда</p>
      </div>
    </div>
    <p className="EditName" style={{ margin: "24px 0 0 24px" }}>Администратор</p>
    <div className="Buttons">
      <Link className="InArchiveButton" to={'/teams'} style={{ textDecoration: 'none', textAlign: "center" }}>
        Отправить в архив
      </Link>
      <Link className="SaveButton" to={'/teams'} style={{ textDecoration: 'none', textAlign: "center" }}>
        Сохранить
      </Link>
    </div>
  </div>
;

export default TeamEdit;
