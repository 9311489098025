import React, { useState, useEffect, useCallback } from 'react'
import {
	Paper,
	Grid,
	Typography,
	TextField,
	FormControlLabel,
	FormControl,
	Select,
	InputLabel,
	MenuItem,
	Button,
	CircularProgress,
	Checkbox,
	Stack,
	Alert,
	AlertTitle,
	Snackbar,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { history } from '../../../reducers'
import UserPositionsSelect from '../../../components/Main/UserPositionsSelect'
import Icon from '../../../components/atomic/atoms/Icon'
import { FieldNewUser, ModalDeleteUser } from './compoenents/organisms'
import { User } from '../modals'

const UserForm = ({
	user,
	teams,
	submit,
	type,
	companies,
	requestStatus,
	loading,
	kpiChannels,
	errors,
	isShowDelete,
}) => {
	const classes = useStyles()

	const { id } = user

	const [open, setOpen] = React.useState(false)
	const handleClose = () => {
		setOpen(false)
	}

	useEffect(() => {
		if (requestStatus === 'success') {
			history.back()
		}
		if (requestStatus === 'error') {
			setOpen(true)
		}
	}, [requestStatus])

	const [values, setValues] = useState({
		showPassword: false,
		firstName: user.firstName,
		lastName: user.lastName,
		phone: user.phone,
		blocked: user.blocked || user.lockedAt,
		password: '',
		passwordConfirmation: '',
		teamTitle: '',
		memberTeam: { value: '' },
		adminTeam: { value: '' },
		externalId: user.externalId,
		kpiChannelId: user.kpiChannelId || '',
		userPositionId: user.userPositionId || '',
	})

	const setFirstNameValue = e => setValues({ ...values, firstName: e.target.value })
	const setLastNameValue = e => setValues({ ...values, lastName: e.target.value })
	const setPhoneValue = e => setValues({ ...values, phone: e.target.value })
	const setBlockedValue = e => setValues({ ...values, blocked: e.target.checked })
	const setPasswordValue = e => setValues({ ...values, password: e.target.value })
	const setPasswordConfirmationValue = e =>
		setValues({ ...values, passwordConfirmation: e.target.value })
	const setTeamTitleValue = e =>
		setValues({ ...values, teamTitle: e.target.value, memberTeam: { value: '' } })
	const setMemberTeamValue = e =>
		setValues({ ...values, memberTeam: e, adminTeam: { value: '' }, teamTitle: '' })
	const setAdminTeamValue = e => setValues({ ...values, adminTeam: e, memberTeam: { value: '' } })
	const setExternalIdValue = e => setValues({ ...values, externalId: e.target.value })
	const setKpiChannelIdValue = e => setValues({ ...values, kpiChannelId: e.target.value })
	const setUserPositionIdValue = option => setValues({ ...values, userPositionId: option.value })

	const checkDisabled = () => {
		if (
			type === 'edit' &&
			(passwordError() || values.firstName === '' || values.lastName === '' || values.phone === '')
		) {
			return true
		}
		if (
			type === 'new' &&
			(passwordError() ||
				values.firstName === '' ||
				values.lastName === '' ||
				values.phone === '' ||
				values.password === '' ||
				values.passwordConfirmation === '' ||
				(values.memberTeam.value === '' && values.teamTitle === ''))
		) {
			return true
		}
		return false
	}
	const passwordError = () => values.password !== values.passwordConfirmation

	const submitAction = () => {
		submit({
			userId: id,
			data: {
				first_name: values.firstName,
				last_name: values.lastName,
				phone: values.phone,
				blocked: values.blocked,
				password: values.password !== '' ? values.password : null,
				password_confirmation:
					values.passwordConfirmation !== '' ? values.passwordConfirmation : null,
				team_id: values.memberTeam.value !== '' ? values.memberTeam.value : null,
				new_team_title: values.teamTitle !== '' ? values.teamTitle : null,
				new_team_parent: values.adminTeam.value !== '' ? values.adminTeam.value : null,
				external_id: values.externalId !== '' ? values.externalId : null,
				kpi_channel_id: values.kpiChannelId,
				user_position_id: values.userPositionId,
			},
		})
	}

	const [tabIndex, setTabIndex] = React.useState(0)

	const handleTabIndexChange = (event, newValue) => setTabIndex(newValue)

	const [isAlertDelete, setAlertDelete] = useState(false)
	const openModalAlertDelete = useCallback(() => setAlertDelete(true), [])
	const closeModalAlertDelete = useCallback(() => setAlertDelete(false), [])

	const deleteProfile = useCallback(async () => {
		closeModalAlertDelete()
		await User.delete()
		localStorage.clear()
		window.location.href = '/login'
	}, [closeModalAlertDelete])

	return (
		<Grid container spacing={2} className={classes.root}>
			<Grid item xs={12}>
				<Paper className={classes.paper}>
					<Typography variant='h6' sx={{ marginBottom: '16px' }}>
						{type === 'edit' && 'Редактирование пользователя'}
						{type === 'new' && 'Создать пользователя'}
					</Typography>
					<Grid container spacing={2}>
						<Grid item sm={6} xs={12}>
							<TextField
								variant='outlined'
								fullWidth
								size='small'
								label='Имя'
								value={values.firstName}
								onChange={setFirstNameValue}
							/>
						</Grid>
						<Grid item sm={6} xs={12}>
							<TextField
								variant='outlined'
								fullWidth
								size='small'
								label='Фамилия'
								value={values.lastName}
								onChange={setLastNameValue}
							/>
						</Grid>
						<Grid item sm={6} xs={12}>
							<TextField
								variant='outlined'
								fullWidth
								size='small'
								disabled={type === 'edit'}
								label='Телефон'
								value={values.phone}
								onChange={setPhoneValue}
							/>
						</Grid>
						<Grid item sm={6} xs={12}>
							<TextField
								variant='outlined'
								fullWidth
								size='small'
								label='Табельный номер'
								value={values.externalId}
								onChange={setExternalIdValue}
							/>
						</Grid>
						<Grid item sm={6} xs={12}>
							<FormControl
								fullWidth
								variant='outlined'
								size='small'
								className={classes.formControl}
							>
								<InputLabel id='demo-simple-select-outlined-label'>Бизнес</InputLabel>
								<Select
									fullWidth
									labelId='demo-simple-select-outlined-label'
									id='demo-simple-select-outlined'
									value={values.kpiChannelId}
									onChange={setKpiChannelIdValue}
									label={'Бизнес'}
								>
									{kpiChannels.map((option, index) => (
										<MenuItem key={index} value={option.id} label={option.title}>
											{companies.length > 0
												? `${option.company.label} - ${option.title}`
												: option.title}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
						<Grid item sm={6} xs={12}>
							<UserPositionsSelect
								changeUserPosition={setUserPositionIdValue}
								value={values.userPositionId}
							/>
						</Grid>
						<Grid item sm={6} xs={12}>
							<FormControlLabel
								control={
									<Checkbox
										checked={values.blocked}
										className={classes.checkbox}
										onChange={setBlockedValue}
										name='blocked'
										color='primary'
									/>
								}
								label='Заблокирован'
							/>
						</Grid>
						{type === 'new' && (
							<FieldNewUser
								isPasswordError={passwordError()}
								password={values.password}
								passwordConfirmation={values.passwordConfirmation}
								memberTeam={values.memberTeam.value}
								teamTitle={values.teamTitle}
								adminTeam={values.adminTeam.value}
								tabIndex={tabIndex}
								companies={companies}
								onChangePasswordValue={setPasswordValue}
								onChangePasswordConfirmationValue={setPasswordConfirmationValue}
								onChangeTabIndexChange={handleTabIndexChange}
								onChangeTeamTitleValue={setTeamTitleValue}
								onChangeMemberTeamValue={setMemberTeamValue}
								onChangeAdminTeamValue={setAdminTeamValue}
							/>
						)}
						<Grid item sm={8}>
							<Stack spacing={2} direction='row'>
								<Button onClick={() => history.back()} variant='outlined'>
									Назад
								</Button>
								<Button
									disabled={checkDisabled()}
									variant='contained'
									onClick={submitAction}
									color='primary'
								>
									{type === 'edit' && 'Сохранить'}
									{type === 'new' && 'Создать'}
								</Button>
							</Stack>
						</Grid>
						{isShowDelete && (
							<Grid
								item
								sm={4}
								flexDirection='row'
								style={{ justifyContent: 'end', display: 'flex' }}
							>
								<Button
									size='small'
									color='inherit'
									onClick={openModalAlertDelete}
									variant='text'
									startIcon={<Icon name='trash' fill='#9196A8' />}
								>
									Удалить аккаунт
								</Button>
								<ModalDeleteUser
									isOpen={isAlertDelete}
									onClose={closeModalAlertDelete}
									onClickDelete={deleteProfile}
								/>
							</Grid>
						)}
					</Grid>
				</Paper>
			</Grid>
			<Snackbar
				open={open}
				autoHideDuration={6000}
				onClose={handleClose}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			>
				<Alert severity='error' onClose={handleClose}>
					<AlertTitle>Ошибка</AlertTitle>
					{errors.join(', ')}
				</Alert>
			</Snackbar>
		</Grid>
	)
}

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		padding: theme.spacing(4),
		'& .MuiTextField-root': {
			// marginTop: theme.spacing(1),
			// width: '25ch',
		},
	},
	checkbox: {
		marginLeft: theme.spacing(2),
	},
	paper: {
		padding: theme.spacing(2),
	},
	idHeader: {
		marginBottom: theme.spacing(4),
	},
	margin: {
		margin: theme.spacing(0),
		marginRight: theme.spacing(4),
	},
	buttonMargin: {
		marginRight: theme.spacing(2),
		marginTop: theme.spacing(2),
		textDecoration: 'none',
	},
	fab: {
		[theme.breakpoints.down('md')]: {
			position: 'fixed',
			bottom: theme.spacing(2),
			right: theme.spacing(2),
		},
	},
	teamMargin: {
		marginTop: theme.spacing(1),
	},
}))

export default UserForm
