export const initialKpiChannels = () => {
  return {
    kpiChannels: [],
    kpiChannel: {
      id: undefined,
      title: "",
      company: {}
    },
    loading: true
  }
}
