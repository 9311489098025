import React from "react";
import { useEffect } from "react";
import { connect } from 'react-redux'
import { getReportsRequest } from "../../actions/actionCreators/reportsActions"
import ReportRow from "./ReportRow"
import NewReportForm from "./NewReportForm"
import { makeStyles, useTheme } from '@mui/styles';

import { Table, TableBody, TableCell, TableHead, TableRow, Container,
  Paper, Grid, Typography, Hidden, Divider } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(4)
  },
  divider: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2)
  },
  buttonGroup: {
    paddingTop: theme.spacing(2)
  },
  fab: {
    [theme.breakpoints.down('md')]: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  },
}));

const ReportsPanel = ({ reports, loading, loadReports }) => {
  const activeReports = (r) => r.reportStatus === 'in_progress' || r.reportStatus === 'queue'
  useEffect(() => { loadReports() }, []);
  useEffect(() => {
    if (reports.filter(activeReports).length === 0) return;
    const timeoutId = setTimeout(() => { loadReports() }, 5000);
    return () => { clearTimeout(timeoutId); };
  }, [5000, loading]);

  const classes = useStyles();
  const theme = useTheme();

  return (
    <Grid container className={classes.root} >
      <Paper className={classes.paper}>
        <Grid item xs={12}>
          <NewReportForm/>
        </Grid>
        <Grid item xs={12} className={classes.divider}>
          <Divider/>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">Доступные отчеты</Typography>
        </Grid>
        <Grid item xs={12}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Статус</TableCell>
                <TableCell align="left">Тип отчета</TableCell>
                <TableCell align="left">Компания</TableCell>
                <Hidden mdDown>
                  <TableCell align="left">Параметры отчета</TableCell>
                  <TableCell align="left">Создан</TableCell>
                  <TableCell align="left">Обновлен</TableCell>
                </Hidden>
                <TableCell align="left">Ссылка</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { reports.map((report) => <ReportRow key={report.id} report={report}/>) }
            </TableBody>
          </Table>
        </Grid>
      </Paper>
    </Grid>
  )
}

const mapStateToProps = (state) => ({
  reports: state.reportsReducer.reports,
  loading: state.reportsReducer.loading
})

const mapDispatchToProps = (dispatch) => ({
  loadReports: () => dispatch(getReportsRequest())
})

export default connect(mapStateToProps, mapDispatchToProps)(ReportsPanel)
