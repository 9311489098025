import React, { useCallback, useState } from 'react'
import { Box, Button, Grid, TextField, Typography } from '@mui/material'

export default function DeleteUserForm({ onClose, onClickDelete }) {
	const [value, setValue] = useState('')
	const handleInputChange = useCallback(e => setValue(e.target.value), [])

	const isError = value !== 'УДАЛИТЬ'

	const done = useCallback(() => {
		if (!isError) {
			onClickDelete()
		}
	}, [isError])

	return (
		<Box
			sx={{
				position: 'relative',
				borderRadius: 4,
				width: 400,
				bgcolor: 'background.paper',
				boxShadow: theme => theme.shadows[5],
				p: 2,
			}}
		>
			<Grid container spacing={4}>
				<Grid item xs={12}>
					<Typography id='modal-alert-delete' variant='h6' component='h6'>
						Удаление аккаунта
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography id='modal-alert-delete-account' sx={{ mt: 2 }}>
						Для подтверждения удаления аккаунта напишите в поле ниже слово “УДАЛИТЬ”
					</Typography>
				</Grid>

				<Grid item xs={12}>
					<TextField
						variant='outlined'
						error={isError}
						helperText=''
						value={value}
						onChange={handleInputChange}
						fullWidth
					/>
				</Grid>

				<Grid item xs={6}>
					<Button fullWidth size='large' onClick={onClose}>
						Отменить
					</Button>
				</Grid>
				<Grid item xs={6}>
					<Button
						disabled={isError}
						fullWidth
						size='large'
						onClick={done}
						variant='contained'
						color='error'
					>
						Подтвердить
					</Button>
				</Grid>
			</Grid>
		</Box>
	)
}
