export const initialEducations = () => {
  return {
    educations: [],
    education: {
      id: undefined,
      educationCategory: {}
    },
    loading: true
  }
}
