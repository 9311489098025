import React from 'react'
import { useEffect, useCallback } from "react";
import { connect } from 'react-redux'

import {TextField, FormControl, CircularProgress, InputLabel,
  Select, MenuItem} from '@mui/material';

import { getUsersListRequest } from "../../actions/actionCreators/usersActions"

const UserSelect = ({ loadUsersList, users, changeUser, value, loading, teamId, allowEmpty }) => {
  const load = useCallback((teamId) => { loadUsersList(teamId) }, [loadUsersList])
  useEffect(() => { load(teamId) }, [load, teamId]);

  const changeSelectedUser = (e, value) => changeUser({value: value.props.value, label: value.props.label})

  return (
    <FormControl fullWidth variant="outlined" size="small">
      <InputLabel>Пользователь</InputLabel>
      <Select fullWidth value={value} onChange={changeSelectedUser} label={"Пользователь"} error={!allowEmpty && (value === null || value === '')}>
        {allowEmpty && <MenuItem key={-1} value={null} label={'Все команды'}>
          Не выбран
        </MenuItem>}
      { users.map((option, index) =>
        <MenuItem key={index} value={option.value} label={option.label}>{option.label}</MenuItem>) }
      </Select>
    </FormControl>
  )
}
const mapStateToProps = (state) => ({
  users: state.usersReducer.usersList,
  loading: state.usersReducer.loading
})

const mapDispatchToProps = (dispatch) => ({
  loadUsersList: (teamId) => dispatch(getUsersListRequest(teamId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(UserSelect)
