import { TypeKeys } from '../actionTypes'

export const getUserEducationsRequest = (query) => ({ type: TypeKeys.GET_USER_EDUCATIONS_REQUEST, query })
export const getUserEducationsSuccess = (userEducations, pagination) => ({
  type: TypeKeys.GET_USER_EDUCATIONS_SUCCESS,
  userEducations, pagination
})

export const getUserEducationRequest = (id) => ({ type: TypeKeys.GET_USER_EDUCATION_REQUEST, id })
export const getUserEducationSuccess = (userEducation) => ({
  type: TypeKeys.GET_USER_EDUCATION_SUCCESS,
  userEducation
})

export const assignUserEducationsRequest = (query) => ({ type: TypeKeys.ASSIGN_USER_EDUCATIONS_REQUEST, query })
export const assignUserEducationsSuccess = (status) => ({
  type: TypeKeys.ASSIGN_USER_EDUCATIONS_SUCCESS,
  status
})
export const assignUserEducationsError = (status) => ({
  type: TypeKeys.ASSIGN_USER_EDUCATIONS_ERROR,
  status
})
