import React from 'react'

import { useEffect, useCallback } from "react";
import { getCompetitionsRequest } from "../../actions/actionCreators/competitionsActions"
import { connect } from 'react-redux'

import { FormControl, InputLabel, Select, MenuItem, CircularProgress, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({}));

const CompetitionSelect = ({ companyId, competitionsList, changeCompetition, value, loadCompetitions, loading }) => {

  const classes = useStyles()
  const changeSelectedCompetition = (e, value) => changeCompetition({value: value.props.value, label: value.props.label})
  const load = useCallback((companyId) => { loadCompetitions(companyId) }, [loadCompetitions])
  useEffect(() => {
    companyId && load(companyId)
  }, [companyId, load]);

  if (loading) {
    return (<CircularProgress />)
  }

  if (competitionsList.length === 0) {
    return (<Typography>Акций не найдено</Typography>)
  }

  return (
    <FormControl fullWidth variant="outlined" size="small" className={classes.formControl}>
      <InputLabel id="demo-simple-select-outlined-label">Акция</InputLabel>
      <Select
        fullWidth
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={value}
        onChange={changeSelectedCompetition}
        label="Акция"
      >
      { competitionsList.map((option, index) => <MenuItem key={index} value={option.value} label={option.label}>{option.label}</MenuItem>) }
      </Select>
    </FormControl>
  )
}
const mapStateToProps = (state) => ({
  competitionsList: state.competitionsReducer.competitionsList,
  loading: state.competitionsReducer.loading
})

const mapDispatchToProps = (dispatch) => ({
  loadCompetitions: (companyId) => dispatch(getCompetitionsRequest(companyId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CompetitionSelect)
