import React from "react";
import UserPositionForm from "./UserPositionForm"
import { useParams } from "react-router-dom"
import { connect } from 'react-redux'
import { useEffect } from "react";
import { getUserPositionRequest, updateUserPositionRequest }
  from "../../actions/actionCreators/userPositionsActions"

const UserPositionEdit = ({ userPosition, loadUserPosition, updateUserPosition, history, companies }) => {
  const params = useParams()
  useEffect(() => {
    loadUserPosition(params.id)
  }, [params.id]);

  if (userPosition) {
    return(<UserPositionForm key={`${userPosition.id}-${userPosition.updatedAt}`} userPosition={userPosition}
      submit={updateUserPosition} type={"edit"} history={history} companies={companies}></UserPositionForm>)
  } else {
    return(<div></div>)
  }
}

const mapStateToProps = (state) => ({
  userPosition: state.userPositionsReducer.userPosition,
  companies: state.profileReducer.companies
})

const mapDispatchToProps = (dispatch) => ({
  loadUserPosition: (id) => dispatch(getUserPositionRequest(id)),
  updateUserPosition: (query) => dispatch(updateUserPositionRequest(query)),
})

export default connect(mapStateToProps, mapDispatchToProps)(UserPositionEdit)
