import React, { memo, useCallback } from 'react'
import { makeStyles } from '@mui/styles'
import {
	Paper,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
} from '@mui/material'
import styled from '@emotion/styled'
import { ButtonBase, Icon } from '../../../../components/atomic/atoms'
import { DarkThemeColor, LightThemeColor } from '../../consts'

export default memo(function TableUploads({ className, data, style, onClickError }) {
	const classes = useStyles()
	return (
		<Paper className={className} style={style}>
			<TableContainer>
				<Table aria-label='caption table'>
					<TableHead>
						<TableRow>
							<TableCell align='left' className={classes.withRightBorder}>
								Сотрудник
							</TableCell>
							<TableCell align='left' className={classes.withRightBorder}>
								Тип файла
							</TableCell>
							<TableCell align='left' className={classes.withRightBorder}>
								Дата загрузки
							</TableCell>
							<TableCell align='left'>Статус</TableCell>
						</TableRow>
					</TableHead>

					<TableBody>
						{data.map((upload, index, data) => {
							const style = index === data.length - 1 ? styles.withoutBottomBorder : undefined
							return (
								<TableRow key={upload.id}>
									<TableCell className={classes.withRightBorder} style={style}>
										{upload.authorFullName}
									</TableCell>
									<TableCell className={classes.withRightBorder} style={style}>
										{upload.title}
									</TableCell>
									<TableCell className={classes.withRightBorder} style={style}>
										{upload.created_at.format('DD.MM.YYYY в LT')}
									</TableCell>
									<TableCell style={style}>
										<Status upload={upload} onClickError={onClickError} />
									</TableCell>
								</TableRow>
							)
						})}
					</TableBody>
				</Table>
			</TableContainer>
		</Paper>
	)
})

const Status = ({ upload, onClickError }) => {
	const classes = useStyles()
	const { status } = upload

	const handlerClickError = useCallback(
		e => {
			e.preventDefault()
			onClickError(upload)
		},
		[upload]
	)

	return (
		<div className={classes.status}>
			<span className={classes.statusText}>
				<Icon name={status.icon} className={classes.icon} stroke={status.color} />
				<span style={{ color: status.color }}> {status.title}</span>
			</span>
			<div className={classes.errorContainer}>
				{upload.data.status === 'fail' && (
					<ButtonError onClick={handlerClickError}>Где ошибки?</ButtonError>
				)}
			</div>
		</div>
	)
}

// TODO: Почему 2 разных цвета
const ButtonError = styled(ButtonBase)`
	border-radius: 8px;
	border: 1px solid ${DarkThemeColor.GrayText};

	padding: 5px 9px;

	color: ${LightThemeColor.GrayText};
	font-size: 13px;
	font-family: Roboto;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`

const styles = {
	withoutBottomBorder: {
		borderBottomWidth: 0,
	},
}

const useStyles = makeStyles(theme => ({
	withRightBorder: {
		borderRightWidth: 1,
		borderRightColor: 'rgba(224, 224, 224, 1)',
		borderRightStyle: 'solid',
	},
	status: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	statusText: {
		display: 'flex',
		// justifyContent: 'center',
		alignItems: 'center',
	},

	errorContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',

		marginLeft: 5,
	},
	icon: {
		width: 14,
		height: 14,
		marginRight: 5,
	},
}))
