import React from "react"
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { useEffect } from "react"
import { getChecklistCategoriesRequest } from "../../actions/actionCreators/checklistCategoriesActions"

import { makeStyles, useTheme } from '@mui/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import { Table, TableBody, TableCell, TableHead, TableRow, Paper, Grid,
  Typography, Box, Button, CircularProgress } from '@mui/material'
import { Add, Edit } from '@mui/icons-material'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2)
  },
  fab: {
    [theme.breakpoints.down('md')]: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  },
  ignoreVisited: {
    marginLeft: theme.spacing(2),
    color: theme.palette.common.black,
    textDecoration: "none"
  },
}));

const ChecklistCategoriesPanel = (props) => {
  const classes = useStyles()
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))
  const { checklistCategories, loading, companies } = props
  useEffect(() => {
    props.loadChecklistCategories()
  }, []);

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Grid container spacing={2} >
            <Grid item xs container direction="column">
              <Typography variant="h4">
                Категории чек-листов
              </Typography>
            </Grid>
            <Grid item justify="flex-end">
              <Link to={`/checklist_categories/new`} className={classes.ignoreVisited}>
                <Button variant="contained">
                  <Add />
                  &nbsp;Добавить&nbsp;
                </Button>
              </Link>
            </Grid>
          </Grid>
          <Table className={classes.table} size={mobile ? "" : "small"} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell align="left">Название</TableCell>
                { companies.length > 1 && <TableCell align="left" >Компания</TableCell> }
                <TableCell align="left"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { loading &&
                <TableRow>
                  <TableCell colSpan={5} align="center"><CircularProgress/></TableCell>
                </TableRow> }
              { !loading && checklistCategories.map((checklistCategory) => {
                const { id, title, company } = checklistCategory
                return(
                  <TableRow key={id}>
                    <TableCell component="th" scope="row">
                      <Box component={Link} to={`/checklist_categories/${id}`} className={classes.ignoreVisited}>
                        <Typography
                          component="span"
                          variant="body1">
                          {id}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="left">
                      <Box component={Link} to={`/checklist_categories/${id}`} className={classes.ignoreVisited}>
                        <Typography
                          component="span"
                          variant="body1">
                          {title}
                        </Typography>
                      </Box>
                    </TableCell>
                    { companies.length > 1 && <TableCell align="left">{company.label}</TableCell>}
                    <TableCell align="left">
                      <Box component={Link} to={`/checklist_categories/${id}/edit`} className={classes.ignoreVisited}>
                        <Edit />
                      </Box>
                    </TableCell>
                  </TableRow>
              )}
            )}
            </TableBody>
          </Table>
        </Paper>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = (state) => ({
  checklistCategories: state.checklistCategoriesReducer.checklistCategories,
  loading: state.checklistCategoriesReducer.loading,
  companies: state.profileReducer.companies
})

const mapDispatchToProps = (dispatch) => ({
  loadChecklistCategories: () => dispatch(getChecklistCategoriesRequest())
})

export default connect(mapStateToProps, mapDispatchToProps)(ChecklistCategoriesPanel)
