import { initialGuests } from "../initial/initialGuests.js"
import { TypeKeys } from "../actions/actionTypes"

export const guestsReducer = (state = initialGuests(), action) => {
  switch (action.type) {
    case TypeKeys.GET_GUEST_REQUEST:
      return { ...state, guest: initialGuests().guest, loading: true, status: "processing" }
    case TypeKeys.GET_GUEST_SUCCESS:
      return { ...state, guest: action.guest, status: "ready", loading: false }
    case TypeKeys.UPDATE_GUEST_REQUEST:
      return { ...state, loading: true, status: "processing" }
    case TypeKeys.UPDATE_GUEST_SUCCESS:
      return { ...state, guest: action.guest, loading: false, status: "success" }
    default:
    return { ...state }
  }
}

export default guestsReducer
