import React, { useState, useEffect } from "react"

import { Link } from "react-router-dom"
import { Paper, Grid, Typography, Divider, TextField, FormControlLabel,
  Switch, Button, Avatar, Checkbox, Stack } from '@mui/material'
import { makeStyles, useTheme } from '@mui/styles'

import UserSelect from '../../components/Main/userSelect'
import CalendarEdit from './CalendarEdit'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(4),
    '& .MuiTextField-root': {
      marginTop: theme.spacing(1),
      // width: '25ch',
    }
  },
  paper: {
    padding: theme.spacing(2)
  },
  idHeader: {
    marginBottom: theme.spacing(2),
  },
  margin: {
    margin: theme.spacing(0),
    marginRight:  theme.spacing(4),
  },
  buttonMargin: {
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    textDecoration: "none"
  },
  fab: {
    [theme.breakpoints.down('md')]: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  },
}));

const CompanyForm = ({ company, users, submit, type, loadCalendar, updateCalendar, calendar }) => {

  const { id, owner } = company

  const [title, setTitle] = useState(company.title)
  const [sizeLimit, setSizeLimit] = useState(company.sizeLimit)
  const [ownerId, setOwnerId] = useState(owner.id)
  const defaultScreens = {
    chats: false, reports: false, competitions: false,
    ratings: false, library: false, newsfeed: false,
    hall_of_fame: false
  }

  const [avatar, setAvatar] = useState(null)
  const [medal, setMedal] = useState(null)

  const [enabledScreens, setEnabledScreens] = useState({...defaultScreens, ...company.enabledScreens})

  const setSizeValue = (e) => {
    setSizeLimit(e.target.value)
  }
  const setTitleValue = (e) => { setTitle(e.target.value) }
  const setOwnerValue = (e) => { setOwnerId(e.value) }

  const toggleScreen = (name) => (e) => {
    setEnabledScreens({...enabledScreens, [name]: !enabledScreens[name]}) }

  const submitAction = () => {
    submit({
      companyId: id,
      data: {
        owner_id: ownerId,
        title: title,
        size_limit: sizeLimit,
        enabled_screens: enabledScreens,
        medal: medal,
        avatar: avatar
      }
    })
  }

  const updateAvatar = (e) =>  {
    let files = e.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (e) => {
      setAvatar(e.target.result)
    }
  }

  const updateMedal = (e) =>  {
    let files = e.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (e) => {
      setMedal(e.target.result)
    }
  }
  const classes = useStyles()

  return(
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Typography variant="h6" sx={{marginBottom: "16px"}}>
            { type==="edit" && "Изменить компанию" }
            { type==="new" && "Создать Компанию" }
          </Typography>
          <form noValidate autoComplete="off">
            <Grid container spacing={2}>
              <Grid item sm={6}>
                <TextField required variant="outlined" fullWidth label="Название" value={title} onChange={setTitleValue} />
              </Grid>
              <Grid item sm={6}>
                <TextField required variant="outlined" fullWidth label="Лимит пользователей" value={sizeLimit} onChange={setSizeValue} />
              </Grid>
              <Grid item sm={6}>
                { owner && <UserSelect changeUser={setOwnerValue} value={ownerId}/>}
              </Grid>
              <Grid item sm={6}/>
              <Grid item sm={6}>
                {avatar === null ?
                  <img
                    alt="Logo"
                    src={company.avatar}
                    style={{ width: 250, height: 250 }}
                  /> :
                  <img
                    alt="Logo"
                    src={avatar}
                    style={{ width: 250, height: 250 }}
                  />
                }
              </Grid>
              <Grid item sm={6}>
                {medal === null ?
                  <img
                    alt="Logo"
                    src={company.medal}
                    style={{ width: 250, height: 250 }}
                  /> :
                  <img
                    alt="Logo"
                    src={medal}
                    style={{ width: 250, height: 250 }}
                  />
                }
              </Grid>
              <Grid item sm={6}>
                <Button
                  variant="contained"
                  component="label"
                >
                  Загрузить логотип
                  <input
                    type="file"
                    hidden
                    onChange={updateAvatar}
                  />
                </Button>
              </Grid>
              <Grid item sm={6}>
                <Button
                  variant="contained"
                  component="label"
                >
                  Загрузить медаль
                  <input
                    type="file"
                    hidden
                    onChange={updateMedal}
                  />
                </Button>
              </Grid>
              <Grid item md={4} sm={12}>
                <FormControlLabel
                  className={classes.margin}
                  control={
                    <Switch color="primary" checked={enabledScreens.chats}
                      onChange={toggleScreen("chats")}/>}
                  label="Чаты"
                  labelPlacement="end"/>
              </Grid>
              <Grid item md={4} sm={12}>
                <FormControlLabel
                  className={classes.margin}
                  control={
                    <Switch color="primary" checked={enabledScreens.reports}
                    onChange={toggleScreen("reports")}/>}
                  label="Моя отчетность"
                  labelPlacement="end"/>
              </Grid>
              <Grid item md={4} sm={12}>
                <FormControlLabel
                  className={classes.margin}
                  control={
                    <Switch color="primary" checked={enabledScreens.competitions}
                    onChange={toggleScreen("competitions")}/>}
                  label="Акции"
                  labelPlacement="end"/>
              </Grid>
              <Grid item md={4} sm={12}>
                <FormControlLabel
                  className={classes.margin}
                  control={
                    <Switch color="primary" checked={enabledScreens.ratings}
                    onChange={toggleScreen("ratings")}/>}
                  label="Рейтинг"
                  labelPlacement="end"/>
              </Grid>
              <Grid item md={4} sm={12}>
                <FormControlLabel
                  className={classes.margin}
                  control={
                    <Switch color="primary" checked={enabledScreens.library}
                    onChange={toggleScreen("library")}/>}
                  label="Бизнес-хаки"
                  labelPlacement="end"/>
              </Grid>
              <Grid item md={4} sm={12}>
                <FormControlLabel
                  className={classes.margin}
                  control={
                    <Switch color="primary" checked={enabledScreens.newsfeed}
                    onChange={toggleScreen("newsfeed")}/>}
                  label="Лента"
                  labelPlacement="end"/>
              </Grid>
              <Grid item md={4} sm={12}>
                <FormControlLabel
                  className={classes.margin}
                  control={
                    <Switch color="primary" checked={enabledScreens.hall_of_fame}
                    onChange={toggleScreen("hall_of_fame")}/>}
                  label="Зал славы"
                  labelPlacement="end"/>
              </Grid>
              <Grid item sm={12}>
                <Stack spacing={2} direction="row">
                  <Button className={classes.buttonMargin} variant="outlined" to={`/companies`} component={Link}>
                    Назад
                  </Button>
                  <Button className={classes.buttonMargin} variant="contained" onClick={submitAction} color="primary">
                    { type==="edit" && "Сохранить" }
                    { type==="new" && "Создать" }
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
      <CalendarEdit companyId={id}/>
    </Grid>
  )
}

export default CompanyForm
