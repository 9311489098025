import React, {useState, useEffect, useCallback} from "react"
import clsx from 'clsx'
import { makeStyles, useTheme } from '@mui/styles'
import { connect } from 'react-redux'
import { Outlet } from 'react-router-dom'
import { getProfileRequest } from "../actions/actionCreators/profileActions"

import { CssBaseline, AppBar, Toolbar, Typography, Drawer, Container,
  Avatar, Hidden, IconButton, List, ListItem, ListItemText
  } from '@mui/material'
import PropTypes from 'prop-types'
import MenuIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'
import { grey } from '@mui/material/colors'

import NavigationPanel from '../components/Main/NavigationPanel'

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: grey['A100'],
    paddingTop: theme.spacing(7),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(8),
    },
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
    padding: theme.spacing(3),
    },
  },
  closeMenuButton: {
    marginRight: 'auto',
    marginLeft: 0,
  },
  appBarSpacer: theme.mixins.toolbar,
  avatarPadding: {
    marginRight: theme.spacing(2),
  },
  paddingTop: {
    paddingTop: theme.spacing(2),
  }
}));

const AppLayout = ({children, location, profile, loadProfile}) => {
  const classes = useStyles()
  const theme = useTheme()


  const load = useCallback(() => { loadProfile() }, [loadProfile])
  useEffect( () => { load() }, [load]);

  const [mobileOpen, setMobileOpen] = useState(false)
  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen)
  }
  useEffect(() => {
    setMobileOpen(false)
  }, [location])
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar} style={{zIndex: 1300}}>
        <Toolbar>
          <Hidden mdUp implementation="css">
            <IconButton
              color="inherit"
              aria-label="Меню"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}>
              <MenuIcon />
            </IconButton>
          </Hidden>
          <Hidden smDown implementation="css">
            <Avatar className={classes.avatarPadding} src="/logo.png" />
          </Hidden>
          <Typography variant="h6" noWrap>
            Панель управления
          </Typography>
        </Toolbar>
      </AppBar>
      { profile.user && <nav className={classes.drawer}>
        <Hidden mdUp implementation="css">
          <Drawer variant="temporary" open={mobileOpen}
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            onClose={handleDrawerToggle}
            classes={{ paper: classes.drawerPaper, }}
            ModalProps={{ keepMounted: true, }}
            style={{zIndex: 1301}}>
            <IconButton onClick={handleDrawerToggle} className={classes.closeMenuButton}>
              <CloseIcon/>
              <Typography variant="button" noWrap>
                &nbsp;&nbsp;Акселератор продаж
              </Typography>
            </IconButton>
            <NavigationPanel profile={profile}/>
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer className={classes.drawer} variant="permanent"
            classes={{ paper: classes.drawerPaper, }}>
            <div className={classes.toolbar} />
             <NavigationPanel profile={profile}/>
          </Drawer>
        </Hidden>
      </nav>}
      <Outlet/>
    </div>
  )
}

const mapStateToProps = (state) => ({
  profile: state.profileReducer,
})

const mapDispatchToProps = (dispatch) => ({
  loadProfile: () => dispatch(getProfileRequest())
})
export default connect(mapStateToProps, mapDispatchToProps)(AppLayout)
