import { useCallback, useEffect, useMemo, useState } from 'react'
import { ChecklistsDashboard } from '../models'
import moment from 'moment'

function useSelect() {
	const [list, setList] = useState([])
	const [current, setCurrent] = useState(null)
	const changeCurrent = useCallback(event => setCurrent(event.target.value), [])
	return {
		list,
		setList,
		current,
		setCurrent,
		changeCurrent,
	}
}

export default function useChecklistsDashboard(teamID, team) {
	const [response, setResponse] = useState()
	const [isLoadingData, setLoadingData] = useState(false)

	const checklists = useSelect()
	const innerTeams = useSelect()

	const filterd = useMemo(
		() =>
			checklists.list.filter(
				item => item.company?.id === team?.company?.id && item.checklists?.length > 0
			),
		[team, checklists.list]
	)

	// ---------- выбор месяца ----------------

	const [isMonthCurrent, setIsMonthCurrent] = useState(true)
	const setMonthCurrent = useCallback(() => setIsMonthCurrent(true), [])
	const setMonthPast = useCallback(() => setIsMonthCurrent(false), [])

	const month = useMemo(() => {
		const date = isMonthCurrent ? moment() : moment().subtract(1, 'month')
		return {
			start_date: moment(date).startOf('month').format('YYYY-MM-DD'),
			end_date: moment(date).endOf('month').format('YYYY-MM-DD'),
		}
	}, [isMonthCurrent])

	// ----------------------------

	const download = useCallback(() => {}, [])

	/**
	 * Получение данных таблицы
	 */
	const loadTable = async (id, checklist_id, month) => {
		setLoadingData(true)
		try {
			const data = await ChecklistsDashboard.getData(id, {
				checklist_id,
				...month,
			})
			setResponse(data)
			if (id === teamID) innerTeams.setList(data.innerTeams)
		} catch (error) {
			console.error(error)
		} finally {
			setLoadingData(false)
		}
	}

	/**
	 * Получение данных для селектов чек-листов и дивизионов
	 */
	const getCategories = async () => {
		try {
			checklists.setList(await ChecklistsDashboard.getCategoriesChecklists())
		} catch (error) {
			console.log('Ошибка загрузки списка категорий чеклистов', error)
		}
	}

	useEffect(() => {
		if (teamID && checklists.current && month) {
			loadTable(innerTeams.current?.id || teamID, checklists.current.id, month)
		}
	}, [teamID, innerTeams.current, checklists.current, month])

	return {
		response,
		// selectors

		checklists,
		filterd,
		innerTeams,

		// Месяц
		isMonthCurrent,
		setMonthCurrent,
		setMonthPast,

		isLoadingData,

		getCategories,

		download,
	}
}
