import React, { useState, useEffect } from "react"

import { connect } from 'react-redux'
import { Link, useNavigate } from "react-router-dom"
import { Paper, Grid, Typography, Divider, TextField, Button, MenuItem,
  FormControl, InputLabel, Select, Stack, Box, Hidden } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import TeamSelect from "../../components/Main/TeamSelect"
import EventIcon from '@mui/icons-material/Event'
import PersonIcon from '@mui/icons-material/Person'
import GroupsIcon from '@mui/icons-material/Groups'
import AutoGraphIcon from '@mui/icons-material/AutoGraph'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import UserSelect from '../../components/Main/userSelect'
import { Table, TableBody, TableCell, TableContainer, TableHead,
  TableRow, TableFooter } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { getPerformanceIndicatorsRequest } from "../../actions/actionCreators/performanceIndicatorsActions"
import { getTaskTypesRequest } from "../../actions/actionCreators/taskTypesActions"

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4),
    },
  },
  paper: {
    padding: theme.spacing(2)
  },
  idHeader: {
    marginBottom: theme.spacing(2),
  },
  margin: {
    margin: theme.spacing(0),
    marginRight:  theme.spacing(4),
  },
  formControl: {
    marginTop:  theme.spacing(0),
  },
  buttonMargin: {
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    textDecoration: "none"
  },
  fab: {
    [theme.breakpoints.down('md')]: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  },
}));

const TaskForm = ({ task, submit, type, history, companies, kpis, loadKpis,
  loadTaskTypes, taskTypes, key, requestStatus }) => {

  const navigate = useNavigate();

  const [title, setTitle] = useState(task.title)
  const [teamId, setTeamId] = useState(task.team.id)
  const [assigneeId, setAssigneeId] = useState(task.assignee.id)
  const [dueDate, setDueDate] = useState(task.dueDate)
  const [kpiId, setKpiId] = useState(task.performanceIndicatorId)
  const [taskTypeId, setTaskTypeId] = useState(task.taskTypeId)
  const [description, setDescription] = useState(task.description)
  const [additionalRoAttributes, setAdditionalRoAttributes] = useState(task.additionalRoAttributes)
  const [additionalAttributes, setAdditionalAttributes] = useState(task.additionalAttributes)

  useEffect(() => {
    if (teamId && teamId !== '') {
     loadKpis(teamId)
     loadTaskTypes()
    }
  }, [teamId]);

  const setTeamIdValue = (v) => {
    setAssigneeId('')
    setKpiId('')
    setTeamId(v.value)
  }

  const setAssigneeIdValue = (v) => { setAssigneeId(v.value) }
  const setTitleValue = (e) => { setTitle(e.target.value) }
  const setDueDateValue = (e) => { setDueDate(e) }
  const setKpiIdValue = (e) => { setKpiId(e.target.value) }
  const setTaskTypeIdValue = (e) => {
    setTaskTypeId(e.target.value)
    if (e.target.value && e.target.value !== '') {
      const taskType = taskTypes.find((tt) => tt.id === e.target.value)
      if (taskType) {
        setAdditionalRoAttributes(taskType.readOnlyAtts)
        setAdditionalAttributes(taskType.atts)
      }
    }
  }
  const setDescriptionValue = (e) => { setDescription(e.target.value) }
  const setRoAttrValue = (index) => {
    return (e) => {
      setAdditionalRoAttributes(
        additionalRoAttributes.map(
          (att, attIndex) => index === attIndex ? {...att, value: e.target.value} : att
        )
      )
    }
  }
  const setAttrValue = (index) => {
    return (e) => {
      setAdditionalAttributes(
        additionalAttributes.map(
          (att, attIndex) => index === attIndex ? {...att, value: e.target.value} : att
        )
      )
    }
  }
  const submitAction = () => {
    submit({
      taskId: task.id,
      data: {
        title: title,
        team_id: teamId,
        assignee_id: assigneeId,
        due_date: dueDate,
        performance_indicator_id: kpiId,
        description: description,
        additional_ro_attributes: additionalRoAttributes,
        additional_attributes: additionalAttributes,
        task_type_id: taskTypeId
      }
    })
  }

  useEffect(() => {
    if (requestStatus === "success") {
      type === 'edit' ? navigate(`/tasks/${task.id}`) : navigate(`/tasks`)
    }
  },[requestStatus])

  const taskValid = () => {
    return title !== '' && teamId !== '' && assigneeId !== '' && dueDate !== ''
  }

  const companyIds = companies.map(c => c.id)

  const classes = useStyles()
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))

  return(
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Typography variant="h6" sx={{marginBottom: "16px"}}>
            { type==="edit" && "Изменить задачу" }
            { type==="new" && "Создать задачу" }
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField fullWidth required variant="outlined" size="small" fullWidth label="Название" value={title} onChange={setTitleValue}
                error={title === null || title === ''}/>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="subtitle1" sx={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                <EventIcon sx={{marginRight: "6px"}}/><strong>Срок выполнения</strong>
              </Typography>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker fullWidth disablePast={true}
                  inputFormat="yyyy-MM-DD" error={dueDate === null || dueDate === ''}
                  value={dueDate} onChange={setDueDateValue}
                  renderInput={(params) => <TextField size="small" fullWidth {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="subtitle1" sx={{display: 'flex', alignItems: 'center', flexWrap: 'wrap', marginBottom: "8px"}}>
                <GroupsIcon sx={{marginRight: "6px"}}/><strong>Команда</strong>
              </Typography>
              <TeamSelect companyIds={companyIds} changeTeam={setTeamIdValue} value={teamId}/>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="subtitle1" sx={{display: 'flex', alignItems: 'center', flexWrap: 'wrap', marginBottom: "8px"}}>
                <PersonIcon sx={{marginRight: "6px"}}/><strong>Исполнитель</strong>
              </Typography>
              {teamId && <UserSelect changeUser={setAssigneeIdValue} value={assigneeId || ''} teamId={teamId}/>}
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="subtitle1" sx={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                <AutoGraphIcon sx={{marginRight: "6px"}}/><strong>KPI</strong>
              </Typography>
              <FormControl fullWidth variant="outlined" size="small" className={classes.formControl}>
                {teamId && <Select fullWidth value={kpiId} onChange={setKpiIdValue} displayEmpty>
                  <MenuItem value={""} label={'Без KPI'}>
                    <i>Без KPI</i>
                  </MenuItem>
                  { kpis.map((option, index) => <MenuItem key={index} value={option.id} label={option.title}>{option.title}</MenuItem>) }
                </Select>}
              </FormControl>
            </Grid>
            { taskTypes.length > 0 && <Grid item xs={12}>
              <Typography variant="h6" sx={{marginBottom: '0px'}}>Тип задачи</Typography>
              <FormControl fullWidth variant="outlined" size="small" className={classes.formControl}>
                <Select fullWidth displayEmpty value={taskTypeId} onChange={setTaskTypeIdValue}>
                  <MenuItem value={''} label={'Без типа'}>
                    <i>Без типа</i>
                  </MenuItem>
                  { taskTypes.map((option, index) => <MenuItem key={index} value={option.id} label={option.name}>{option.name}</MenuItem>) }
                </Select>
              </FormControl>
            </Grid>}
            {additionalRoAttributes.length > 0 && <Grid item xs={12}>
              <Typography variant="h6" sx={{marginBottom: '0px'}}>Поля для отображения</Typography>
              <Table className={classes.table} aria-label="simple table" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Идентификатор</TableCell>
                    <TableCell>Название</TableCell>
                    <Hidden smDown>
                      <TableCell>Значение</TableCell>
                    </Hidden>
                  </TableRow>
                </TableHead>
                <TableBody>
                  { additionalRoAttributes.map((attr, index) => {
                    return(
                      <React.Fragment>
                        <TableRow key={attr.name}>
                          <TableCell width={300} sx={{borderBottomWidth: mobile ? 0 : '1px'}}>{attr.name}</TableCell>
                          <TableCell width={300} sx={{borderBottomWidth: mobile ? 0 : '1px'}}>{attr.title}</TableCell>
                          <Hidden smDown>
                            <TableCell>
                              <TextField fullWidth required variant="outlined" size="small" fullWidth
                                value={attr.value} onChange={setRoAttrValue(index)} sx={{marginBottom: '8px'}}
                                error={attr.value === null || attr.value === ''}/>
                              </TableCell>
                            </Hidden>
                        </TableRow>
                        <Hidden>
                          <TableRow key={attr.name}>
                            <TableCell colSpan={2}>
                              <TextField fullWidth required variant="outlined" size="small" fullWidth
                                value={attr.value} onChange={setRoAttrValue(index)} sx={{marginBottom: '8px'}}
                                error={attr.value === null || attr.value === ''}/>
                              </TableCell>
                          </TableRow>
                        </Hidden>
                      </React.Fragment>
                    )}
                  )}
                </TableBody>
              </Table>
            </Grid>}
            {additionalAttributes.length > 0 && <Grid item xs={12}>
              <Typography variant="h6" sx={{marginBottom: '0px'}}>Поля для ввода</Typography>
              <Table className={classes.table} aria-label="simple table" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell width={300}>Идентификатор</TableCell>
                    <TableCell width={300}>Название</TableCell>
                    <TableCell>Значение</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  { additionalAttributes.map((attr, index) => {
                    const { name, value, title } = attr
                    return(
                      <TableRow key={name}>
                        <TableCell width={300}>{name}</TableCell>
                        <TableCell width={300}>{title}</TableCell>
                        <TableCell>
                          <TextField fullWidth required variant="outlined" size="small" fullWidth
                            value={value} onChange={setAttrValue(index)} sx={{marginBottom: '8px'}}/>
                        </TableCell>
                      </TableRow>
                    )}
                  )}
                </TableBody>
              </Table>
            </Grid>}
            <Grid item xs={12}>
              <Typography variant="h6" sx={{marginBottom: '0px'}}>Описание</Typography>
              <TextField required fullWidth multiline rows={4} value={description} onChange={setDescriptionValue}/>
            </Grid>
            <Grid item sm={12}>
              <Stack spacing={2} direction="row">
                <Button className={classes.buttonMargin} variant="outlined" to={`/tasks`} component={Link}>
                  Назад
                </Button>
                <Box sx={{ m: 1, position: 'relative' }}>
                <Button className={classes.buttonMargin} variant="contained" onClick={submitAction} color="primary"
                  disabled={!taskValid() || requestStatus === "processing"}>
                  { type==="edit" && "Сохранить" }
                  { type==="new" && "Создать" }
                </Button>
                {requestStatus === "processing" && (
                  <CircularProgress
                    size={24}
                    sx={{position: 'absolute', top: '50%', left: '50%', marginTop: '-12px', marginLeft: '-12px'}}
                  />
                )}
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}
const mapStateToProps = (state) => ({
  kpis: state.performanceIndicatorsReducer.performanceIndicators,
  kpisLoading: state.performanceIndicatorsReducer.loading,
  user: state.profileReducer.user,
  taskTypes: state.taskTypesReducer.taskTypes,
  taskTypesLoading: state.taskTypesReducer.loading,
  requestStatus: state.tasksReducer.status
})

const mapDispatchToProps = (dispatch) => ({
  loadKpis: (id) => dispatch(getPerformanceIndicatorsRequest(id)),
  loadTaskTypes: () => dispatch(getTaskTypesRequest())
})

export default connect(mapStateToProps, mapDispatchToProps)(TaskForm)
