import { initialKeyDates } from "../initial/initialKeyDates.js"
import { TypeKeys } from "../actions/actionTypes"

export const keyDatesReducer = (state = initialKeyDates(), action) => {
  switch (action.type) {
    case TypeKeys.GET_KEY_DATES_REQUEST:
      return { ...state, loading: true }
    case TypeKeys.GET_KEY_DATES_SUCCESS:
      return { ...state, keyDates: action.keyDates, loading: false }
    default:
    return { ...state }
  }
}

export default keyDatesReducer
