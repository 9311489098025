import React from "react"
import { Link, useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { useState, useEffect } from "react"
import { getIntegrationTokenHistoryRequest } from "../../actions/actionCreators/integrationTokensActions"
import moment from 'moment'

import { makeStyles, useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import clsx from 'clsx';

import { Table, TableBody, TableCell, TableContainer, TableHead,
  TableRow, IconButton, Collapse, Button } from '@mui/material';
import { Paper, Grid, Typography, Toolbar, Container, Hidden,
  Fab, Box, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@mui/material';
import {Add, ExpandMore} from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AutorenewIcon from '@mui/icons-material/Autorenew';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingBottom: theme.spacing(8),
    paddingTop: theme.spacing(2)
  },
  paper: {
    padding: theme.spacing(2)
  },
  fab: {
    [theme.breakpoints.down('md')]: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  },
  ignoreVisited: {
    color: theme.palette.common.black,
    textDecoration: "none"
  }
}));

const HistoryRow = ({historyEvent}) => {
  const [open, setOpen] = React.useState(false);
  const { id, payload, worker_payload, action_name, created_at, request_uuid } = historyEvent;

  return(
    <React.Fragment key={id}>
      <TableRow sx={{ '& > *': { borderBottom: 'none' } }}>
        <TableCell align="left">
          { request_uuid }
        </TableCell>
        <TableCell align="left">
          { action_name }
        </TableCell>
        <TableCell align="left">
          { payload.success ? "Успешно" : "Ошибка" }
        </TableCell>
        <TableCell align="left">
          { worker_payload && (worker_payload.success ? "Успешно" : "Ошибка") }
        </TableCell>
        <TableCell align="left">
          { created_at && moment.utc(created_at, "YYYY-MM-DDTHH:mm:ss.SSSSZ").local().format('DD.MM.YYYY HH:mm:ss') }
        </TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
            >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow sx={{ '& > *': { borderBottom: 'none' } }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              {Object.entries(payload.params).length > 0 && <Typography variant="h6">
                Request query params
              </Typography>}
              {payload.params && Object.entries(payload.params).length > 0 &&
                <Paper variant="outlined" sx={{backgroundColor: "#EEE", fontFamily: ["Lucida Console", "Courier New", "monospace"], p: "16px"}}>
                {Object.entries(payload.params).map((param) =>
                  <div>
                    <strong>{param[0]}: </strong>
                    {Array.isArray(param[1]) ?
                      `[${param[1].join(', ')}]` :
                      param[1]
                    }
                  </div>
                )}
                </Paper>
              }
              {payload.body && <Typography variant="h6">
                Request body
              </Typography>}
              {payload.body && <Paper variant="outlined" sx={{whiteSpace: "pre-wrap", backgroundColor: "#EEE", fontFamily: ["Lucida Console", "Courier New", "monospace"], p: "16px"}}>
                {payload.body.length > 0 && payload.body.map((line) => <div>{line}</div>)}
              </Paper>}
              {payload.error && <Typography variant="h6">
                Error
              </Typography>}
              {payload.error && <Paper variant="outlined" sx={{backgroundColor: "#EEE", fontFamily: ["Lucida Console", "Courier New", "monospace"], p: "16px"}}>
                <div>{payload.error}</div>
              </Paper>}
              {false && payload.backtrace && <Typography variant="h6">
                Error backtrace
              </Typography>}
              {false && payload.backtrace && <Paper variant="outlined" sx={{backgroundColor: "#EEE", fontFamily: ["Lucida Console", "Courier New", "monospace"], p: "16px"}}>
                {payload.backtrace.length > 0 && payload.backtrace.map((line) => <div>{line}</div>)}
              </Paper>}
              { worker_payload && <React.Fragment>
                {worker_payload.error_data && <React.Fragment>
                  <Typography variant="h6">
                    Worker error data
                  </Typography>
                  <Paper variant="outlined" sx={{whiteSpace: "pre-wrap", backgroundColor: "#EEE", fontFamily: ["Lucida Console", "Courier New", "monospace"], p: "16px"}}>
                    {JSON.stringify(worker_payload.error_data, null, 2)}
                  </Paper>
                </React.Fragment>}
                {worker_payload.backtrace && <React.Fragment>
                  <Typography variant="h6">
                    Worker error backtrace
                  </Typography>
                  <Paper variant="outlined" sx={{whiteSpace: "pre-wrap", backgroundColor: "#EEE", fontFamily: ["Lucida Console", "Courier New", "monospace"], p: "16px"}}>
                    {worker_payload.backtrace.length > 0 && worker_payload.backtrace.map((line) => <div>{line}</div>)}
                  </Paper>
                </React.Fragment>}
              </React.Fragment>}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}
const IntegrationTokenHistory = (props) => {

  const { location, events, history, loading, role, loadIntegrationTokenHistory } = props

  const params = useParams()
  useEffect(() => {
    loadIntegrationTokenHistory(params.id)
  }, [params.id]);

  const classes = useStyles()
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Container className={classes.root} disableGutters={true}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Grid container spacing={2} >
              <Grid item xs={12}>
                <Typography variant="h4">
                  История запросов
                </Typography>
                <Button sx={{marginTop: "8px"}} variant="outlined" to={`/integration_tokens`} component={Link}>
                  Назад
                </Button>
                <Button variant="contained" sx={{mt: "8px", ml: "8px"}}
                  onClick={() => loadIntegrationTokenHistory(params.id)}>
                  <AutorenewIcon />
                  Обновить
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Table className={classes.table} size={mobile ? "" : "small"} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">UUID</TableCell>
                  <TableCell align="left">Действие</TableCell>
                  <TableCell align="left">Статус запроса</TableCell>
                  <TableCell align="left">Статус фоновой обработки</TableCell>
                  <TableCell align="left">Дата</TableCell>
                  <TableCell align="left"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                { loading &&
                  <TableRow>
                    <TableCell colSpan={5} align="center"><CircularProgress/></TableCell>
                  </TableRow> }
                { !loading && events.map((historyEvent) => <HistoryRow historyEvent={historyEvent}/>)}
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}

const mapStateToProps = (state) => ({
  events: state.integrationTokensReducer.events,
  loading: state.integrationTokensReducer.loading,
})

const mapDispatchToProps = (dispatch) => ({
  loadIntegrationTokenHistory: (id) => dispatch(getIntegrationTokenHistoryRequest(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationTokenHistory)
