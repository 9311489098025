import { initialReports } from "../initial/initialReports.js"
import { TypeKeys } from "../actions/actionTypes"

export const reportsReducer = (state = initialReports(), action) => {
  switch (action.type) {
    case TypeKeys.GET_REPORTS_REQUEST:
      return { ...state, loading: true }
    case TypeKeys.GET_REPORTS_SUCCESS:
      return { ...state, reports: action.reports, loading: false }
    case TypeKeys.CREATE_REPORT_REQUEST:
      return { ...state, loading: true }
    case TypeKeys.CREATE_REPORT_SUCCESS:
      return { ...state, reports: action.reports, loading: false }
    default:
    return { ...state }
  }
}

export default reportsReducer
