import React, { useContext, useEffect } from 'react'
import Modal from './Modal'
import { PopupContext } from './context'
import { useLocation } from 'react-router'

export default function Popup() {
	const { options, dismiss, isVisible } = useContext(PopupContext)
	const { Component, isDark, props } = options

	const location = useLocation()
	useEffect(dismiss, [location])

	return (
		<Modal isDark={isDark} isVisible={isVisible} onDismiss={dismiss}>
			{isVisible && <Component {...props} />}
		</Modal>
	)
}
