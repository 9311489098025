export const initialUserEducations = () => {
  return {
    userEducations: [],
    userEducation: {
      id: undefined,
      user: {},
      answers: [],
      education: {
        educationCategory: {}
      }
    },
    loading: true,
    pagination: {
      totalPages: 0
    },
    status: '',
    code: []
  }
}
