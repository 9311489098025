import { TypeKeys } from '../actionTypes'

export const getUserPositionsRequest = () => ({ type: TypeKeys.GET_USER_POSITIONS_REQUEST })

export const getUserPositionsSuccess = userPositions => ({
	type: TypeKeys.GET_USER_POSITIONS_SUCCESS,
	userPositions,
})

export const getUserPositionsError = () => ({
	type: TypeKeys.GET_USER_POSITIONS_ERROR,
})

export const getUserPositionRequest = id => ({ type: TypeKeys.GET_USER_POSITION_REQUEST, id })

export const getUserPositionSuccess = userPosition => ({
	type: TypeKeys.GET_USER_POSITION_SUCCESS,
	userPosition,
})

export const createUserPositionRequest = query => ({
	type: TypeKeys.CREATE_USER_POSITION_REQUEST,
	query,
})

export const createUserPositionSuccess = userPosition => ({
	type: TypeKeys.CREATE_USER_POSITION_SUCCESS,
	userPosition,
})

export const updateUserPositionRequest = query => ({
	type: TypeKeys.UPDATE_USER_POSITION_REQUEST,
	query,
})

export const updateUserPositionSuccess = userPosition => ({
	type: TypeKeys.UPDATE_USER_POSITION_SUCCESS,
	userPosition,
})
