import React from "react";

import { makeStyles } from '@mui/styles';
import { Typography, Grid, Divider } from '@mui/material';
import { Table, TableBody, TableCell, TableHead,
  TableRow } from '@mui/material'

import moment from 'moment'

const useStyles = makeStyles((theme) => ({
  root: {  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  teamBlock: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
}));

const RunRate = (props) => {
  const classes = useStyles();
  const { runRate } = props;
  const monthName = moment(runRate.date).format('MMMM');
  const monthNameCap = monthName.charAt(0).toUpperCase() + monthName.slice(1)

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h4">{monthNameCap}</Typography>
        <Divider />
      </Grid>
      { runRate.rr.teams.map( (rrTeam) => {
        const { team } = rrTeam
        return(
            <Grid key={team+"-"+runRate.date} className={classes.teamBlock} item xs={12}>
              <Typography variant="h5">Команда: {team}</Typography>
              <Grid container spacing={2}>
                { rrTeam.performance_indicators.map((performanceIndicator) => {
                  const { performance_indicator, days_left, month_target, plan_left, plan_percent,
                    record, result_mean, result_median, result_sum, run_rate, tomorrow_plan,
                    total_days, worked_days } = performanceIndicator
                  return(
                      <Grid key={team+"-"+runRate.date+"-"+performance_indicator} item xs={12}>
                        <Table size="small" className={classes.table} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell align="left" className={classes.smallCell}>KPI: {performance_indicator}</TableCell>
                              <TableCell align="left" className={classes.smallCell}></TableCell>
                              <TableCell align="left" className={classes.smallCell}></TableCell>
                              <TableCell align="left" className={classes.smallCell}></TableCell>
                              <TableCell align="left" className={classes.smallCell}></TableCell>
                              <TableCell align="left" className={classes.smallCell}></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell component="th" scope="row" className={classes.smallCell}>План месяца</TableCell>
                              <TableCell align="left" className={classes.smallCell}>{month_target}</TableCell>
                              <TableCell component="th" scope="row" className={classes.smallCell}>План на завтра</TableCell>
                              <TableCell align="left" className={classes.smallCell}>{tomorrow_plan}</TableCell>
                              <TableCell component="th" scope="row" className={classes.smallCell}>План осталось</TableCell>
                              <TableCell align="left" className={classes.smallCell}>{plan_left}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell component="th" scope="row" className={classes.smallCell}>Процент выполнения</TableCell>
                              <TableCell align="left" className={classes.smallCell}>{plan_percent*100}%</TableCell>
                              <TableCell component="th" scope="row" className={classes.smallCell}>Средний результат</TableCell>
                              <TableCell align="left" className={classes.smallCell}>{Math.round(result_mean, 2)}</TableCell>
                              <TableCell component="th" scope="row" className={classes.smallCell}>Медиана</TableCell>
                              <TableCell align="left" className={classes.smallCell}>{result_median}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell component="th" scope="row" className={classes.smallCell}>RR</TableCell>
                              <TableCell align="left" className={classes.smallCell}>{run_rate}</TableCell>
                              <TableCell component="th" scope="row" className={classes.smallCell}>Рекорд</TableCell>
                              <TableCell align="left" className={classes.smallCell}>{record}</TableCell>
                              <TableCell component="th" scope="row" className={classes.smallCell}>Сумма за месяц</TableCell>
                              <TableCell align="left" className={classes.smallCell}>{result_sum}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell component="th" scope="row" className={classes.smallCell}>Дней осталось</TableCell>
                              <TableCell align="left" className={classes.smallCell}>{days_left > 0 ? days_left : 0}</TableCell>
                              <TableCell component="th" scope="row" className={classes.smallCell}>Дней всего</TableCell>
                              <TableCell align="left" className={classes.smallCell}>{total_days}</TableCell>
                              <TableCell component="th" scope="row" className={classes.smallCell}>Дней отработано</TableCell>
                              <TableCell align="left" className={classes.smallCell}>{worked_days}</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Grid>
                  )}
                )}
            </Grid>
          </Grid>
        )
      })}
      <Divider />
    </Grid>
  )
}

export default RunRate
