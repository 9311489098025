import { TypeKeys } from '../actionTypes'

export const getChecklistCategoriesRequest = () => ({ type: TypeKeys.GET_CHECKLIST_CATEGORIES_REQUEST })

export const getChecklistCategoriesSuccess = (checklistCategories) => ({
  type: TypeKeys.GET_CHECKLIST_CATEGORIES_SUCCESS,
  checklistCategories
})

export const getChecklistCategoryRequest = (id) => ({ type: TypeKeys.GET_CHECKLIST_CATEGORY_REQUEST, id })

export const getChecklistCategorySuccess = (checklistCategory) => ({
  type: TypeKeys.GET_CHECKLIST_CATEGORY_SUCCESS,
  checklistCategory
})

export const createChecklistCategoryRequest = (query) => ({ type: TypeKeys.CREATE_CHECKLIST_CATEGORY_REQUEST, query })

export const createChecklistCategorySuccess = (checklistCategory) => ({
  type: TypeKeys.CREATE_CHECKLIST_CATEGORY_SUCCESS,
  checklistCategory
})

export const updateChecklistCategoryRequest = (query) => ({ type: TypeKeys.UPDATE_CHECKLIST_CATEGORY_REQUEST, query })

export const updateChecklistCategorySuccess = (checklistCategory) => ({
  type: TypeKeys.UPDATE_CHECKLIST_CATEGORY_SUCCESS,
  checklistCategory
})
