import { takeLatest, put, call } from 'redux-saga/effects'
import { TypeKeys } from '../actions/actionTypes'
import { getPerformanceIndicatorsSuccess, updatePerformanceIndicatorSuccess }
  from '../actions/actionCreators/performanceIndicatorsActions'
import callApi from '../utils/callApi'
import config from '../config'

const mapPerformanceIndicator = (performanceIndicator) => {
  return {
    id: performanceIndicator.id,
    title: performanceIndicator.title,
    kpiChannel: performanceIndicator.kpi_channel,
    kpiChannelId: performanceIndicator.kpi_channel_id,
    long: performanceIndicator.long,
    recalculate: performanceIndicator.recalculate,
    teamId: performanceIndicator.team_id,
    priority: performanceIndicator.priority,
    createdAt: performanceIndicator.created_at,
    updatedAt: performanceIndicator.updated_at,
    parent: performanceIndicator.parent,
  }
}

function* loadPerformanceIndicators(action) {
  const res = yield call(callApi, `${config.BASE_URL}/admin/api/v1/teams/${action.teamId}/performance_indicators`)
  yield put(getPerformanceIndicatorsSuccess(res.data.map(mapPerformanceIndicator)))
}

function* updatePerformanceIndicator(action) {
  const res = yield call(callApi,
    `${config.BASE_URL}/admin/api/v1/teams/${action.teamId}/performance_indicators/${action.performanceIndicatorId}`,
    { method: 'PUT', data: action.query })
  yield put(updatePerformanceIndicatorSuccess(res.data.map(mapPerformanceIndicator)))
}

export const performanceIndicatorsSagas = [
  takeLatest([TypeKeys.GET_PERFORMANCE_INDICATORS_REQUEST], loadPerformanceIndicators),
  takeLatest([TypeKeys.UPDATE_PERFORMANCE_INDICATOR_REQUEST], updatePerformanceIndicator),
]
