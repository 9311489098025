import { TypeKeys } from '../actionTypes'

export const getCalendarRequest = (year, month, companyId) => ({
  type: TypeKeys.GET_CALENDAR_REQUEST,
  year, month, companyId
})

export const getCalendarSuccess = (calendar) => ({
  type: TypeKeys.GET_CALENDAR_SUCCESS,
  calendar
})

export const updateCalendarRequest = (year, month, companyId, data) => ({
  type: TypeKeys.UPDATE_CALENDAR_REQUEST, year, month, companyId, data
})

export const updateCalendarSuccess = (calendar) => ({
  type: TypeKeys.UPDATE_CALENDAR_SUCCESS,
  calendar
})
