import React, { useEffect } from "react"
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { makeStyles, useTheme, withStyles } from '@mui/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import clsx from 'clsx'
import { Breadcrumbs, Grid, Typography, CircularProgress, Paper } from '@mui/material'
import { useParams } from 'react-router-dom'
import { getTeamRequest } from "../../actions/actionCreators/teamsActions"

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  paper: {
    // padding: theme.spacing(4),
  },
  buttonGroup: {
    paddingTop: theme.spacing(2)
  },
}));

const DashboardLayout = (props) => {
  const classes = useStyles()
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))
  const params = useParams();
  const { title, header, table, loading } = props
  const { loadTeam, currentTeam } = props
  useEffect(() => {
    loadTeam(params.teamId);
  }, [params.teamId]);

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} component={Paper} className={classes.paper}>
        <Grid container className={classes.root}>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb" sx={{marginBottom: "16px"}}>
              <Link color="inherit" to={"/dashboard"}>Аналитика</Link>
              <Link color="inherit" to={`/dashboard/${currentTeam.id}`}>{currentTeam.title || "..."}</Link>
              <Typography color="textPrimary">{title}</Typography>
            </Breadcrumbs>
          </Grid>
          {header}
          {table}
        </Grid>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = (state) => ({
  currentTeam: state.teamReducer.team,
})

const mapDispatchToProps = (dispatch) => ({
  loadTeam: (id) => dispatch(getTeamRequest(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DashboardLayout)
