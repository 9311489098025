export const initialGuests = () => {
  return {
    guest: {
        id: undefined,
        user: {},
        team: {},
        guestHiddenIndicators: [],
        availableIndicators: [],
        guestHiddenTeams: [],
        availableTeams: []
    },
    loading: true,
    status: undefined
  }
}
