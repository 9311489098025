import { TypeKeys } from '../actionTypes'

export const getIntegrationTokensRequest = () => ({ type: TypeKeys.GET_INTEGRATION_TOKENS_REQUEST })

export const getIntegrationTokensSuccess = (integrationTokens) => ({
  type: TypeKeys.GET_INTEGRATION_TOKENS_SUCCESS,
  integrationTokens
})

export const getIntegrationTokenHistoryRequest = (id) => ({ type: TypeKeys.GET_INTEGRATION_TOKEN_HISTORY_REQUEST, id })

export const getIntegrationTokenHistorySuccess = (events) => ({
  type: TypeKeys.GET_INTEGRATION_TOKEN_HISTORY_SUCCESS,
  events
})

export const getIntegrationTokenRequest = (id) => ({ type: TypeKeys.GET_INTEGRATION_TOKEN_REQUEST, id })

export const getIntegrationTokenSuccess = (integrationToken) => ({
  type: TypeKeys.GET_INTEGRATION_TOKEN_SUCCESS,
  integrationToken
})

export const createIntegrationTokenRequest = (query) => ({ type: TypeKeys.CREATE_INTEGRATION_TOKEN_REQUEST, query })

export const createIntegrationTokenSuccess = (integrationToken) => ({
  type: TypeKeys.CREATE_INTEGRATION_TOKEN_SUCCESS,
  integrationToken
})

export const updateIntegrationTokenRequest = (query) => ({ type: TypeKeys.UPDATE_INTEGRATION_TOKEN_REQUEST, query })

export const updateIntegrationTokenSuccess = (integrationToken) => ({
  type: TypeKeys.UPDATE_INTEGRATION_TOKEN_SUCCESS,
  integrationToken
})
