import React, { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { Paper, Grid, Typography, Divider, TextField, Button, MenuItem,
  FormControl, InputLabel, Select, Stack } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(4),
    '& .MuiTextField-root': {
      marginTop: theme.spacing(1),
      // width: '25ch',
    }
  },
  paper: {
    padding: theme.spacing(2)
  },
  idHeader: {
    marginBottom: theme.spacing(2),
  },
  margin: {
    margin: theme.spacing(0),
    marginRight:  theme.spacing(4),
  },
  formControl: {
    marginTop:  theme.spacing(0),
  },
  buttonMargin: {
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    textDecoration: "none"
  },
  fab: {
    [theme.breakpoints.down('md')]: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  },
}));

const EducationForm = ({ educationCategory, education, submit, type, history }) => {

  const { id } = educationCategory
  const navigate = useNavigate();
  const [values, setValues] = useState({
    title: education.title,
    threshold: education.threshold,
  });

  const mapCompanies = (cs) => {
    return cs.map((option, index) => <MenuItem key={index} value={option.id} label={option.title}>{option.title}</MenuItem> )
  }

  const setTitleValue = (e) => {
    setValues({ ...values, title: e.target.value })
  }
  const setThresholdValue = (e) => {
    setValues({ ...values, threshold: e.target.value })
  }
  const setCompanyValue = (e, value) => {
    setValues({ ...values, company: {value: value.props.value, label: value.props.label} })
  }
  const submitAction = () => {
    submit({
      educationCategoryId: educationCategory.id,
      educationId: education.id,
      data: {
        title: values.title,
        image: image,
        threshold: values.threshold,
      }
    })
    navigate(`/education_categories/${educationCategory.id}`)
  }

  const [image, setImage] = useState(null)
  const updateImage = (e) =>  {
    let files = e.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (e) => {
      setImage(e.target.result)
    }
  }

  const classes = useStyles()

  return(
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Typography variant="h4" sx={{marginBottom: "16px"}}>
            { type==="edit" && "Изменить обучение" }
            { type==="new" && "Создать обучение" }
          </Typography>
          <form noValidate autoComplete="off">
            <Grid container spacing={2}>
              <Grid item sm={12}>
                <TextField required variant="outlined" fullWidth label="Название"
                  value={values.title} onChange={setTitleValue} />
              </Grid>
              <Grid item sm={12}>
                <TextField required variant="outlined" fullWidth label="Порог прохождения"
                  type="number" value={values.threshold} onChange={setThresholdValue} />
              </Grid>
              {(image || education.imageUrl) && <Grid item sm={12}>
                {image ?
                  <img
                    alt="Logo"
                    src={image}
                    style={{ width: 600, height: 300 }}
                  /> :
                  <img
                    alt="Logo"
                    src={education.imageUrl}
                    style={{ width: 600, height: 300 }}
                  />
                }
              </Grid> }
              <Grid item sm={12}>
                <Button
                  variant="contained"
                  component="label"
                >
                  Загрузить обложку
                  <input
                    type="file"
                    hidden
                    onChange={updateImage}
                  />
                </Button>
              </Grid>
              <Grid item sm={12}>
                <Button className={classes.buttonMargin} variant="outlined" to={`/education_categories/${educationCategory.id}`} component={Link}>
                  Назад
                </Button>
                <Button className={classes.buttonMargin} variant="contained" sx={{ml: "8px"}}
                  onClick={submitAction} color="primary" disabled={values.title === ''}>
                  { type==="edit" && "Сохранить" }
                  { type==="new" && "Создать" }
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default EducationForm;
