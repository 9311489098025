import React from "react";
import CompanyForm from "./CompanyForm"

import { connect } from 'react-redux'
import { createCompanyRequest } from "../../actions/actionCreators/companiesActions"

const CompanyNew = ({ createCompany }) => {
  const company = {
    title:"",
    sizeLimit: 0,
    ownerId: null,
    owner: {}
  }
  return(<CompanyForm key={+ new Date()} company={company}
    submit={createCompany} type={"new"}></CompanyForm>)
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
  createCompany: (query) => dispatch(createCompanyRequest(query)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CompanyNew)
