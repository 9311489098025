export const initialChecklists = () => {
  return {
    checklists: [],
    checklist: {
      id: undefined,
      payload: {
        title: undefined,
        blocks: [],
      },
      checklist_category: {}
    },
    loading: true
  }
}
