import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { getUserPositionsRequest } from "../../actions/actionCreators/userPositionsActions"
import { FormControl, InputLabel, Select, MenuItem, CircularProgress, Typography,
  FormControlLabel, Checkbox, FormLabel, FormGroup } from '@mui/material';
import { makeStyles } from '@mui/styles';
const useStyles = makeStyles((theme) => ({}));

const UserPositionsSelect = ({ companies, userPositions, loading, loadUserPositions,
    changeUserPosition, value, label, allowEmpty, companyId }) => {

  const classes = useStyles()
  const [positions, setPositions] = useState([])

  useEffect(() => {
    setPositions(userPositions.map((p) => {
      let users = ''
      switch (true) {
        case p.usersCount === 1:
          users = 'пользователь'
          break;
        case p.usersCount > 1 && p.usersCount < 5:
          users = 'пользователя'
          break;
        default:
          users = 'пользователей'
      }
      let title = `${p.title} (${p.usersCount} ${users})`
      if (companies.length > 0) {
        title += ` / ${p.company.label}`
      }
      if (!p.approved) {
        title += ` / Не одобрена`
      }
      return {
        value: p.id,
        label: title,
        approved: p.approved,
        usersCount: p.usersCount
      }
    }))
  }, [userPositions]);

  const changeSelectedUserPosition = (e, value) => changeUserPosition({value: value.props.value, label: value.props.label})

  useEffect(() => {
    loadUserPositions()
  }, []);

  if (loading) {
    return (<CircularProgress />)
  }

  if (userPositions.length === 0) {
    return (<Typography>Должности не найдены</Typography>)
  }

  return (
    <FormControl fullWidth variant="outlined" size="small" className={classes.formControl}>
      <InputLabel>{label ? label : "Выберите должность"}</InputLabel>
      <Select fullWidth value={value} onChange={changeSelectedUserPosition} label={label ? label : "Выберите должность"}
        error={value === null || value === ''}>
      { positions.map((option) =>
          <MenuItem key={option.value} value={option.value} label={option.label} disabled={!option.approved}>
            {option.label}
          </MenuItem>
        )
      }
      </Select>
    </FormControl>
  )
}
const mapStateToProps = (state) => ({
  companies: state.profileReducer.companies,
  userPositions: state.userPositionsReducer.userPositions,
  loading: state.userPositionsReducer.loading
})

const mapDispatchToProps = (dispatch) => ({
  loadUserPositions: () => dispatch(getUserPositionsRequest()),
})

export default connect(mapStateToProps, mapDispatchToProps)(UserPositionsSelect)
