export const Colors = {
	Accent: '#29C7D5',
	Additional: '#2F80ED',
	Green: '#59AB26',
	Orange: '#DC7F25',
	Red: '#D13535',
	Blue: '#2F80ED', // TODO: нет в макете

	White: '#FFFFFF',
	Black: '#000000',
}

export const CustomColors = {
	blue: '#2564B9',
}

export const DarkThemeColor = {
	White: 'white',
	Black: 'black',

	Background: '#07080C',
	Header_Input: '#1D212F',
	RegularText: '#FCFCFC',
	GrayText: '#9196a8',
	TabBar_Popup: '#252C48',
	Dividers: '#485375',
	Message: '#373E56',
	Comments: '#232C47',
	Icons: '#FFFFFF',
}

export const LightThemeColor = {
	White: 'white',
	Black: 'black',

	Background: '#FCFCFC',
	Header_Input: '#E0E5EA',
	RegularText: '#333333',
	GrayText: '#737B9B',
	TabBar_Popup: '#DCE5EE',
	Dividers: '#B3C6D4',
	Message: '#DEE4E8',
	Comments: '#EEEEEE',
	Icons: '#2F80ED',
}
