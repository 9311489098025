import React from "react";
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { useState, useEffect } from "react";
import { getCompaniesRequest } from "../../actions/actionCreators/companiesActions"
import moment from 'moment'

import { makeStyles, useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import clsx from 'clsx';

import { Table, TableBody, TableCell, TableContainer, TableHead,
  TableRow } from '@mui/material';
import { Paper, Grid, Typography, Toolbar, Container, Hidden,
  Fab, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import UserBlock from "../../components/Main/UserBlock"
import CircularProgress from '@mui/material/CircularProgress'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2)
  },
  fab: {
    [theme.breakpoints.down('md')]: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  },
  ignoreVisited: {
    color: theme.palette.common.black,
    textDecoration: "none"
  },
}));

const CompaniesPanel = (props) => {

  const { location, companies, history, loading } = props

  useEffect(() => {
    props.loadCompanies()
  }, []);

  const classes = useStyles()
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Grid container spacing={2} >
            <Grid item xs={11}>
              <Typography variant="h4" sx={{marginBottom: "16px"}}>
                Компании
              </Typography>
            </Grid>
            <Grid item xs={1} justify='flex-end'>
              <Link to={`/companies/new`}>
                <Fab color="primary" aria-label="add" size={mobile ? "" : "small"} className={classes.fab}>
                  <AddIcon />
                </Fab>
              </Link>
            </Grid>
          </Grid>
          <Table className={classes.table} size={mobile ? "" : "small"} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell align="left">Название</TableCell>
                <TableCell align="left">Владелец</TableCell>
                <Hidden mdDown>
                  <TableCell align="left" >Лимит пользователей</TableCell>
                  <TableCell align="left">Создана</TableCell>
                  <TableCell align="left">Обновлена</TableCell>
                </Hidden>
              </TableRow>
            </TableHead>
            <TableBody>
              { loading &&
                <TableRow>
                  <TableCell colSpan={5} align="center"><CircularProgress/></TableCell>
                </TableRow> }
              { companies.map((company) => {
                const { id, title, sizeLimit, owner, createdAt, updatedAt } = company
                return(
                  <TableRow key={id}>
                    <TableCell component="th" scope="row">
                      <Box component={Link} to={`/companies/${id}`} className={classes.ignoreVisited}>
                        <Typography
                          component="span"
                          variant="body1">
                          {id}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="left">
                      <Box component={Link} to={`/companies/${id}`} className={classes.ignoreVisited}>
                        <Typography
                          component="span"
                          variant="body1">
                          {title}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="left">
                      {owner && <UserBlock user={owner}/>}
                    </TableCell>
                    <Hidden mdDown>
                      <TableCell align="left">{sizeLimit}</TableCell>
                      <TableCell align="left">{moment(createdAt).format('DD.MM.YY')}</TableCell>
                      <TableCell align="left">{moment(updatedAt).format('DD.MM.YY')}</TableCell>
                    </Hidden>
                  </TableRow>
              )}
            )}
            </TableBody>
          </Table>
        </Paper>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = (state) => ({
  companies: state.companiesReducer.companies,
  loading: state.companiesReducer.loading
})

const mapDispatchToProps = (dispatch) => ({
  loadCompanies: () => dispatch(getCompaniesRequest())
})

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesPanel)
