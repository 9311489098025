import React from "react";
import { useState, useEffect } from "react";
import { connect } from 'react-redux'
import { getUserRewardsRequest, createUserRewardRequest, destroyUserRewardRequest } from "../../actions/actionCreators/userRewardsActions"
import { getAwardTypesRequest } from "../../actions/actionCreators/awardTypesActions"

import { makeStyles } from '@mui/styles';
import { Table, TableBody, TableCell, TableHead,
  TableRow, Button, Dialog, DialogTitle, DialogContent,
  DialogContentText, DialogActions, FormControl, InputLabel,
  Select, MenuItem } from '@mui/material'
import { Delete } from '@mui/icons-material';
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
  root: {},
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  smallCell: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const UserRewards = (props) => {
  const classes = useStyles();

  const { userId, userRewards, awardTypes, createUserReward,
    loadUserRewards, loadAwardTypes, destroyUserReward } = props;

  const [openCreate, setOpenCreate] = useState(false);
  const handleOpenCreate = () => { setOpenCreate(true); };
  const handleCloseCreate = () => {
    setOpenCreate(false);
    setAward("0")
  };

  const [awardToRemove, setAwardToRemove] = useState(undefined);
  const [selectedAward, setAward] = useState("0");
  const handleAwardChange = (e, value) => { setAward(value.props.value); };

  const [openDestroy, setOpenDestroy] = useState(false);
  const handleOpenDestroy = (id) => {
    return () => {
      setAwardToRemove(id);
      setOpenDestroy(true);
    }
  };
  const handleCloseDestroy = () => {
    setAwardToRemove(undefined);
    setOpenDestroy(false);
  };
  const destroyReward = () => {
    if (awardToRemove !== undefined) {
      destroyUserReward({ userId: userId, awardId: awardToRemove })
    }
    handleCloseDestroy();
  }

   const createUserAward = () => {
     createUserReward({ userId: userId, data: { award_type_id: selectedAward }});
     handleCloseCreate();
   }

  useEffect(() => {
    userId && loadUserRewards(userId);
    loadAwardTypes();
  }, [userId]);

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleOpenCreate}>
        Выдать награду
      </Button>
      <Dialog open={openCreate} onClose={handleCloseCreate} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Выдать награду</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Выберите награду из списка
          </DialogContentText>
          <FormControl fullWidth variant="outlined" className={classes.formControl}>
           <InputLabel id="demo-simple-select-outlined-label">Награда</InputLabel>
           <Select fullWidth
             labelId="demo-simple-select-outlined-label"
             id="demo-simple-select-outlined"
             value={selectedAward}
             onChange={handleAwardChange}
             label="Награда"
           >
            <MenuItem disabled value={"0"}>Выберите награду</MenuItem>
            {awardTypes.filter((award) => !award.archived).map((award) =>
              <MenuItem value={award.id}>{award.description} ({award.weight})</MenuItem>
            )}
           </Select>
         </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCreate} color="primary">
            Отмена
          </Button>
          <Button onClick={createUserAward} disabled={selectedAward === "0"} color="primary">
            Выдать
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openDestroy} onClose={handleCloseDestroy} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Удалить награду</DialogTitle>
        <DialogContent>
          <DialogContentText>Вы уверены что хотите удалить награду?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDestroy} color="primary">Отмена</Button>
          <Button onClick={destroyReward} disabled={awardToRemove === undefined} color="primary">Удалить</Button>
        </DialogActions>
      </Dialog>
      <Table size="small" className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left" className={classes.smallCell}>Награда</TableCell>
            <TableCell align="left" className={classes.smallCell}>Создан</TableCell>
            <TableCell align="left" className={classes.smallCell}>Выдал</TableCell>
            <TableCell align="left" className={classes.smallCell}>Coins</TableCell>
            <TableCell align="left" className={classes.smallCell}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          { userRewards.map((userReward) => {
            const { id, medal, weight, createdAt, award_type, from_user } = userReward
            return(
              <TableRow key={id}>
                <TableCell align="left" className={classes.smallCell}>{ medal ? medal : award_type }</TableCell>
                <TableCell align="left" className={classes.smallCell}>
                  { createdAt && moment.utc(createdAt, "YYYY-MM-DDTHH:mm:ss.SSSSZ").local().format('DD.MM.YYYY HH:mm:ss') }
                </TableCell>
                <TableCell align="left" className={classes.smallCell}>{ from_user }</TableCell>
                <TableCell align="left" className={classes.smallCell}>{ weight }</TableCell>
                <TableCell align="left" className={classes.smallCell}>
                  {from_user && <Button color="primary" onClick={handleOpenDestroy(id)}>
                    <Delete/>
                  </Button>}
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </div>
  )
}

const mapStateToProps = (state) => ({
  awardTypes: state.awardTypesReducer.awardTypes,
  userRewards: state.userRewardsReducer.userRewards
})

const mapDispatchToProps = (dispatch) => ({
  loadUserRewards: (id) => dispatch(getUserRewardsRequest(id)),
  createUserReward: (query) => dispatch(createUserRewardRequest(query)),
  destroyUserReward: (query) => dispatch(destroyUserRewardRequest(query)),
  loadAwardTypes: () => dispatch(getAwardTypesRequest())
})

export default connect(mapStateToProps, mapDispatchToProps)(UserRewards)
