import React from "react";
import CompanyForm from "./CompanyForm"
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { useEffect } from "react";
import { getCompanyRequest, updateCompanyRequest }
  from "../../actions/actionCreators/companiesActions"

const CompanyEdit = ({ company, loadCompany, updateCompany }) => {
  const params = useParams()
  useEffect(() => {
    loadCompany(params.id)
  }, [params.id]);

  if (company) {
    return(<CompanyForm key={`${company.id}-${company.updatedAt}`} company={company}
      submit={updateCompany} type={"edit"}></CompanyForm>)
  } else {
    return(<div></div>)
  }
}

const mapStateToProps = (state) => ({
  company: state.companiesReducer.company
})

const mapDispatchToProps = (dispatch) => ({
  loadCompany: (id) => dispatch(getCompanyRequest(id)),
  updateCompany: (query) => dispatch(updateCompanyRequest(query)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CompanyEdit)
