export const initialPerformanceIndicators = () => {
  return {
    performanceIndicators: [],
    performanceIndicator: {
      id: undefined,
      title: "",
      companyId: undefined
    },
    loading: true
  }
}
