import { initialIntegrationTokens } from "../initial/initialIntegrationTokens.js"
import { TypeKeys } from "../actions/actionTypes"

export const integrationTokensReducer = (state = initialIntegrationTokens(), action) => {
  switch (action.type) {
    case TypeKeys.GET_INTEGRATION_TOKENS_REQUEST:
      return { ...state, integrationTokens: initialIntegrationTokens().integrationTokens, loading: true, requestStatus: '' }
    case TypeKeys.GET_INTEGRATION_TOKENS_SUCCESS:
      return { ...state, integrationTokens: action.integrationTokens, loading: false, requestStatus: '' }
    case TypeKeys.GET_INTEGRATION_TOKEN_REQUEST:
      return { ...state, integrationToken: initialIntegrationTokens().integrationToken, loading: true, requestStatus: '' }
    case TypeKeys.GET_INTEGRATION_TOKEN_SUCCESS:
      return { ...state, integrationToken: action.integrationToken, loading: false, requestStatus: '' }
    case TypeKeys.CREATE_INTEGRATION_TOKEN_REQUEST:
      return { ...state, integrationToken: initialIntegrationTokens().integrationToken, loading: true , requestStatus: ''}
    case TypeKeys.CREATE_INTEGRATION_TOKEN_SUCCESS:
      return { ...state, integrationToken: action.integrationToken, loading: false, requestStatus: 'success' }
    case TypeKeys.UPDATE_INTEGRATION_TOKEN_REQUEST:
      return { ...state, integrationToken: initialIntegrationTokens().integrationToken, loading: true, requestStatus: '' }
    case TypeKeys.UPDATE_INTEGRATION_TOKEN_SUCCESS:
      return { ...state, integrationToken: action.integrationToken, loading: false, requestStatus: 'success' }
    case TypeKeys.GET_INTEGRATION_TOKEN_HISTORY_REQUEST:
      return { ...state, events: initialIntegrationTokens().events, loading: true, requestStatus: '' }
    case TypeKeys.GET_INTEGRATION_TOKEN_HISTORY_SUCCESS:
      return { ...state, events: action.events, loading: false, requestStatus: '' }
    default:
    return { ...state }
  }
}

export default integrationTokensReducer
