import { initialCompanies } from "../initial/initialCompanies.js"
import { TypeKeys } from "../actions/actionTypes"

export const companiesReducer = (state = initialCompanies(), action) => {
  switch (action.type) {
    case TypeKeys.GET_COMPANIES_REQUEST:
      return { ...state, teams: [], loading: true }
    case TypeKeys.GET_COMPANIES_SUCCESS:
      return { ...state, companies: action.companies, loading: false }
    case TypeKeys.GET_COMPANY_REQUEST:
      return { ...state, company: initialCompanies().company, loading: true }
    case TypeKeys.GET_COMPANY_SUCCESS:
      return { ...state, company: action.company, loading: false }
    case TypeKeys.CREATE_COMPANY_REQUEST:
      return { ...state, loading: true }
    case TypeKeys.CREATE_COMPANY_SUCCESS:
      return { ...state, company: action.companies, loading: false }
    case TypeKeys.UPDATE_COMPANY_REQUEST:
      return { ...state, loading: true }
    case TypeKeys.UPDATE_COMPANY_SUCCESS:
      return { ...state, company: action.companies, loading: false }
    default:
    return { ...state }
  }
}

export default companiesReducer
