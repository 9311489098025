import { takeLatest, put, call } from 'redux-saga/effects'
import { TypeKeys } from '../actions/actionTypes'
import { getAreasSuccess, getAreaSuccess, createAreaSuccess,
  updateAreaSuccess }
  from '../actions/actionCreators/areasActions'
import callApi from '../utils/callApi'
import config from '../config'

const mapArea = (area) => {
  return {
    id: area.id,
    title: area.title,
    company: {
      value: area.company.id,
      label: area.company.title
    },
    createdAt: area.created_at,
    updatedAt: area.updated_at,
  }
}

function* loadAreas(action) {
  const res = yield call(callApi, `${config.BASE_URL}/admin/api/v1/areas`)
  yield put(getAreasSuccess(res.data.map(mapArea)))
}

function* loadArea(action) {
  const res = yield call(callApi,
    `${config.BASE_URL}/admin/api/v1/areas/${action.id}`)
  yield put(getAreaSuccess(mapArea(res.data)))
}

function* createArea(action) {
  const res = yield call(callApi,
    `${config.BASE_URL}/admin/api/v1/areas`,
    { method: 'POST', data: action.query.data })
  yield put(createAreaSuccess(mapArea(res.data)))
}

function* updateArea(action) {
  const res = yield call(callApi,
    `${config.BASE_URL}/admin/api/v1/areas/${action.query.areaId}`,
    { method: 'PUT', data: action.query.data })
  yield put(updateAreaSuccess(mapArea(res.data)))
}

export const areasSagas = [
  takeLatest([TypeKeys.GET_AREAS_REQUEST], loadAreas),
  takeLatest([TypeKeys.GET_AREA_REQUEST], loadArea),
  takeLatest([TypeKeys.CREATE_AREA_REQUEST], createArea),
  takeLatest([TypeKeys.UPDATE_AREA_REQUEST], updateArea),
]
