import Dashboard from './IndexPanel'

export default Dashboard

export { default as Checklists } from './Checklists'

export { default as TeamIndexPanel } from './TeamIndexPanel'
export { default as ClosedDaysDashboard } from './ClosedDays/Dashboard'
export { default as PlansUpdatedDashboard } from './PlansUpdated/Dashboard'
export { default as PlanValueDashboard } from './PlanValue/Dashboard'
export { default as PlanCompleteDashboard } from './PlanComplete/Dashboard'
export { default as ZeroResultsDashboard } from './ZeroResults/Dashboard'
export { default as ZeroTeamsResultsDashboard } from './ZeroTeamsResults/Dashboard'
export { default as ResultsLossDashboard } from './ResultsLoss/Dashboard'
export { default as SocialActivityDashboard } from './SocialActivity/Dashboard'
export { default as TaskStatsDashboard } from './TaskStats/Dashboard'
export { default as TaskTeamStatsDashboard } from './TaskTeamStats/Dashboard'
export { default as Showcase } from './Showcase/Dashboard'
export { default as TeamSummaryDashboard } from './TeamSummaryDashboard'