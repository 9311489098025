import { TypeKeys } from '../actionTypes'

export const getUserRewardsRequest = (id) => ({ type: TypeKeys.GET_USER_REWARDS_REQUEST, id })

export const getUserRewardsSuccess = (userRewards) => ({
  type: TypeKeys.GET_USER_REWARDS_SUCCESS,
  userRewards
})

export const createUserRewardRequest = (query) => ({ type: TypeKeys.CREATE_USER_REWARD_REQUEST, query })

export const createUserRewardSuccess = (userRewards) => ({
  type: TypeKeys.CREATE_USER_REWARD_SUCCESS,
  userRewards
})

export const destroyUserRewardRequest = (query) => ({ type: TypeKeys.DESTROY_USER_REWARD_REQUEST, query })

export const destroyUserRewardSuccess = (userRewards) => ({
  type: TypeKeys.DESTROY_USER_REWARD_SUCCESS,
  userRewards
})
