import { useCallback, useRef, useState } from 'react'
import { Logger, getBase64 } from '../../../utils'
import { Upload } from '../modals'

export default function useFile(table, select) {
	// ---------------- File -------------------

	const [file, setFile] = useState()
	const get = useCallback(e => {
		e.preventDefault()
		const { files } = e.target
		files && files[0] && setFile(files[0])
	}, [])

	// ---------------- Input -------------------

	const inputRef = useRef(null)
	const open = useCallback(() => inputRef.current?.click(), [])

	// ---------------- Upload -------------------

	const [loading, setLoading] = useState(false)
	const { selected } = select
	const isReady = !!(file && selected)

	const upload = useCallback(async () => {
		if (isReady) {
			setLoading(true)
			try {
				table.push(await Upload.uploadFile(await getBase64(file), selected))
			} catch (error) {
				Logger.error('Fail create request', error)
			} finally {
				setLoading(false)
			}
		}
	}, [file, selected, isReady])

	return {
		item: file,
		loading,
		inputRef,
		get,
		open,
		upload,
		isReady,
	}
}
