import React from "react";
import { Link, useParams } from "react-router-dom";

import PropTypes from "prop-types";
import { useState, useEffect } from "react";

import UserChip from "../../components/Main/UserChip";
import PerformanceIndicators from "../../components/Teams/PerformanceIndicators";
import TeamTree from "../../components/Teams/TeamTree";
import Settings from "../../components/Teams/Settings";
import TeamResultCharts from "../../components/Teams/TeamResultCharts";
import moment from "moment";
import { connect } from "react-redux";
import { getTeamRequest } from "../../actions/actionCreators/teamsActions";
import { Paper, Grid, Typography, Container, Button, Box, Avatar,
  Divider, AppBar, Tab, Tabs } from "@mui/material";

import { Table, TableBody, TableCell, TableHead,
  TableRow } from '@mui/material'
import EditIcon from "@mui/icons-material/Edit";

import { Check } from "@mui/icons-material";

import { makeStyles, useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(4),
    paddingTop: theme.spacing(6)
  },
  paper: {
    // padding: theme.spacing(2),
  },
  editButton: {
    // marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2)
  },
  teamChip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    '&:hover': { cursor: "pointer" },
  },
  ignoreVisited: {
    color: theme.palette.common.black
  },
  rotateIcon: {
    width: theme.spacing(12),
    height: theme.spacing(12)
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function applyProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const TeamDescription = (props) => {

  const classes = useStyles()
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))
  const params = useParams();
  const loading = props.loading;

  useEffect(() => {
    props.loadTeam(params.id)
  }, [params.id]);

  let pageNum;
  switch(params.page) {
    case 'kpi':
      pageNum = 1;
      break;
    case 'teams':
      pageNum = 2;
      break;
    case 'stats':
      pageNum = 3;
      break;
    case 'rating':
      pageNum = 4;
      break;
    default:
      pageNum = 0;
      break;
  }

  const [value, setValue] = useState(pageNum);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { id, createdAt, archivedAt, title, parentTeams, admin,
    avatar, teamMembers, guests, externalInvites, performanceIndicators, childTeams, subtree } = props.team

  return (
    !props.loading &&
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Grid container spacing={2}>
            <Grid item md={1} xs={12} align={mobile ? "center" : null}>
              <Avatar src={avatar} sx={{
                width: theme.spacing(8),
                height: theme.spacing(8),
                marginLeft: theme.spacing(2),
                marginBottom: theme.spacing(2),
                letterSpacing: "-1px",
                backgroundColor: theme.palette.primary.main,
                [theme.breakpoints.down('sm')]: {
                  width: theme.spacing(12),
                  height: theme.spacing(12)
                }
              }} color="primary">
                {title[0]}
              </Avatar>
            </Grid>
            <Grid item md={9} xs={8}>
              <Typography variant="h4">
                {title}
              </Typography>
              <Typography variant="body1">
                #ID {id}, Создана {moment(createdAt).format('DD.MM.YYYY')}
                { archivedAt && <p className="InArchive">В архиве с {moment(archivedAt).format('DD.MM.YYYY')}</p>}
              </Typography>
            </Grid>
            <Grid item md={2} xs={4} align="right">
              { false && <Button className={classes.editButton} variant="outlined" size="small" component={Link} to={`/teams/${id}/edit`}>
                <EditIcon/>
              </Button> }
            </Grid>
          </Grid>
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab component="h4" label="Состав" {...applyProps(0)}/>
              <Tab label="KPI" {...applyProps(1)}/>
              <Tab label="Дерево" {...applyProps(2)}/>
              <Tab label="Настройки" {...applyProps(3)}/>
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">
                  Администратор
                </Typography>
              </Grid>
              <Grid item xs={12}>
              { admin ? <UserChip user={admin}/>
                : <Typography variant="body2">
                  Не назначен
                </Typography>
              }
              </Grid>
              <Grid item xs={12}>
                <Divider/>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">
                  Участники
                </Typography>
              </Grid>
              <Grid item xs={12}>
                { teamMembers.length > 0 ?
                  <Table className={classes.table} size="small" aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">ФИО</TableCell>
                        <TableCell align="left">Рабочих дней в месяце</TableCell>
                        <TableCell align="left">Приглашён</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      { teamMembers.map((member) => {
                        const { id, user, monthWorkingDays, createdAt } = member
                        return(
                          <TableRow key={id}>
                            <TableCell align="left">
                              <UserChip key={`member-${user.id}`} user={user}/>
                            </TableCell>
                            <TableCell>{ monthWorkingDays }</TableCell>
                            <TableCell align="left">{moment(createdAt).format('DD.MM.YYYY')}</TableCell>
                          </TableRow>
                        )}
                      )}
                    </TableBody>
                  </Table>
                  : <Typography variant="body2">
                    Нет участников
                  </Typography>
                }
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">
                  Гости
                </Typography>
              </Grid>
              <Grid item xs={12}>
                { guests.length > 0 ?
                  <Table className={classes.table} size="small" aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">ФИО</TableCell>
                        <TableCell align="left">Соруководитель</TableCell>
                        <TableCell align="left">Приглашён</TableCell>
                        {false && <TableCell align="left"></TableCell>}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      { guests.map((guest) => {
                        const { id, user, settingsEnabled, createdAt } = guest
                        return(
                          <TableRow key={id}>
                            <TableCell align="left">
                              <UserChip key={`member-${user.id}`} user={user}/>
                            </TableCell>
                            <TableCell>{ settingsEnabled && <Check/> }</TableCell>
                            <TableCell align="left">{moment(createdAt).format('DD.MM.YYYY')}</TableCell>
                            <TableCell align="left">
                              <Button className={classes.buttonMargin} variant="contained"
                              to={`/teams/${props.team.id}/guests/${guest.id}`} component={Link}>Настроить</Button>
                            </TableCell>
                          </TableRow>
                        )}
                      )}
                    </TableBody>
                  </Table>
                  : <Typography variant="body2">
                    Нет гостей
                  </Typography>
                }
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">
                  Приглашения
                </Typography>
              </Grid>
              <Grid item xs={12}>
              { externalInvites.length > 0 ?
                <Table className={classes.table} size="small" aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">ФИО</TableCell>
                      <TableCell align="left">Телефон</TableCell>
                      <TableCell align="left">Приглашён</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    { externalInvites.map((invite) => {
                      const { id, phone, createdAt } = invite
                      return(
                        <TableRow key={id}>
                          <TableCell align="left">
                            <UserChip key={`invite-${invite.id}`} user={invite} disabled={true}/>
                          </TableCell>
                          <TableCell>{ phone }</TableCell>
                          <TableCell align="left">{moment(createdAt).format('DD.MM.YYYY')}</TableCell>
                        </TableRow>
                      )}
                    )}
                  </TableBody>
                </Table>
                : <Typography variant="body2">
                  Нет приглашений
                </Typography>
              }
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <PerformanceIndicators team={props.team} performanceIndicators={performanceIndicators}/>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <TeamTree parentTeams={parentTeams} childTeams={childTeams} subtree={subtree}/>
          </TabPanel>
          <TabPanel value={value} index={3}>
            <Settings team={props.team}/>
          </TabPanel>
        </Paper>
      </Grid>
    </Grid>
  )
}
const mapStateToProps = (state) => ({
  team: state.teamReducer.team,
  loading: state.teamReducer.loading
})

const mapDispatchToProps = (dispatch) => ({
  loadTeam: (id) => dispatch(getTeamRequest(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TeamDescription);
