import { takeLatest, put, call } from 'redux-saga/effects'
import { TypeKeys } from '../actions/actionTypes'
import { getKpiChannelsSuccess, getKpiChannelSuccess, createKpiChannelSuccess,
  updateKpiChannelSuccess }
  from '../actions/actionCreators/kpiChannelsActions'
import callApi from '../utils/callApi'
import config from '../config'

const mapKpiChannel = (kpiChannel) => {
  return {
    id: kpiChannel.id,
    title: kpiChannel.title,
    company: {
      value: kpiChannel.company.id,
      label: kpiChannel.company.title
    },
    createdAt: kpiChannel.created_at,
    updatedAt: kpiChannel.updated_at,
  }
}

function* loadKpiChannels(action) {
  const res = yield call(callApi, `${config.BASE_URL}/admin/api/v1/kpi_channels`)
  yield put(getKpiChannelsSuccess(res.data.map(mapKpiChannel)))
}

function* loadKpiChannel(action) {
  const res = yield call(callApi,
    `${config.BASE_URL}/admin/api/v1/kpi_channels/${action.id}`)
  yield put(getKpiChannelSuccess(mapKpiChannel(res.data)))
}

function* createKpiChannel(action) {
  const res = yield call(callApi,
    `${config.BASE_URL}/admin/api/v1/kpi_channels`,
    { method: 'POST', data: action.query.data })
  yield put(createKpiChannelSuccess(res.data))
}

function* updateKpiChannel(action) {
  const res = yield call(callApi,
    `${config.BASE_URL}/admin/api/v1/kpi_channels/${action.query.kpiChannelId}`,
    { method: 'PUT', data: action.query.data })
  yield put(updateKpiChannelSuccess(res.data))
}

export const kpiChannelsSagas = [
  takeLatest([TypeKeys.GET_KPI_CHANNELS_REQUEST], loadKpiChannels),
  takeLatest([TypeKeys.GET_KPI_CHANNEL_REQUEST], loadKpiChannel),
  takeLatest([TypeKeys.CREATE_KPI_CHANNEL_REQUEST], createKpiChannel),
  takeLatest([TypeKeys.UPDATE_KPI_CHANNEL_REQUEST], updateKpiChannel),
]
