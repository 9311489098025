import { TypeKeys } from '../actionTypes'

export const getAreasRequest = () => ({ type: TypeKeys.GET_AREAS_REQUEST })

export const getAreasSuccess = (areas) => ({
  type: TypeKeys.GET_AREAS_SUCCESS,
  areas
})

export const getAreaRequest = (id) => ({ type: TypeKeys.GET_AREA_REQUEST, id })

export const getAreaSuccess = (area) => ({
  type: TypeKeys.GET_AREA_SUCCESS,
  area
})

export const createAreaRequest = (query) => ({ type: TypeKeys.CREATE_AREA_REQUEST, query })

export const createAreaSuccess = (area) => ({
  type: TypeKeys.CREATE_AREA_SUCCESS,
  area
})

export const updateAreaRequest = (query) => ({ type: TypeKeys.UPDATE_AREA_REQUEST, query })

export const updateAreaSuccess = (area) => ({
  type: TypeKeys.UPDATE_AREA_SUCCESS,
  area
})
