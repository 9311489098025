import { initialDivisions } from "../initial/initialDivisions.js"
import { TypeKeys } from "../actions/actionTypes"

export const divisionsReducer = (state = initialDivisions(), action) => {
  switch (action.type) {
    case TypeKeys.GET_DIVISIONS_REQUEST:
      return { ...state, divisions: initialDivisions().divisions, loading: true }
    case TypeKeys.GET_DIVISIONS_SUCCESS:
      return { ...state, divisions: action.divisions, loading: false }
    case TypeKeys.GET_DIVISION_REQUEST:
      return { ...state, division: initialDivisions().division, loading: true }
    case TypeKeys.GET_DIVISION_SUCCESS:
      return { ...state, division: action.division, loading: false }
    case TypeKeys.CREATE_DIVISION_REQUEST:
      return { ...state, division: initialDivisions().division, loading: true }
    case TypeKeys.CREATE_DIVISION_SUCCESS:
      return { ...state, division: action.division, loading: false }
    case TypeKeys.UPDATE_DIVISION_REQUEST:
      return { ...state, division: initialDivisions().division, loading: true }
    case TypeKeys.UPDATE_DIVISION_SUCCESS:
      return { ...state, division: action.division, loading: false }
    default:
    return { ...state }
  }
}

export default divisionsReducer
