import { takeLatest, put, call } from 'redux-saga/effects'
import { TypeKeys } from '../actions/actionTypes'
import { getEducationsSuccess, getEducationSuccess,
  createEducationSuccess, updateEducationSuccess }
  from '../actions/actionCreators/educationsActions'
import callApi from '../utils/callApi'
import config from '../config'

const mapEducation = (education) => {
  return {
    id: education.id,
    title: education.title,
    threshold: education.threshold,
    imageUrl: education.image_url,
    educationBlocksSize: education.education_blocks_size,
    educationTestsSize: education.education_tests_size,
    createdAt: education.created_at,
    updatedAt: education.updated_at,
    educationCategory: {
      id: education.education_category.id,
      title: education.education_category.title,
    }
  }
}

function* loadEducations(action) {
  const res = yield call(callApi, `${config.BASE_URL}/admin/api/v1/education_categories/${action.categoryId}/educations`)
  yield put(getEducationsSuccess(res.data.map(mapEducation)))
}

function* loadEducation(action) {
  const res = yield call(callApi,
    `${config.BASE_URL}/admin/api/v1/education_categories/${action.categoryId}/educations/${action.id}`)
  yield put(getEducationSuccess(mapEducation(res.data)))
}

function* createEducation(action) {
  const res = yield call(callApi,
    `${config.BASE_URL}/admin/api/v1/education_categories/${action.query.educationCategoryId}/educations/`,
    { method: 'POST', data: action.query.data })
  yield put(createEducationSuccess(mapEducation(res.data)))
}

function* updateEducation(action) {
  const res = yield call(callApi,
    `${config.BASE_URL}/admin/api/v1/education_categories/${action.query.educationCategoryId}/educations/${action.query.educationId}`,
    { method: 'PUT', data: action.query.data })
  yield put(updateEducationSuccess(mapEducation(res.data)))
}

export const educationsSagas = [
  takeLatest([TypeKeys.GET_EDUCATIONS_REQUEST], loadEducations),
  takeLatest([TypeKeys.GET_EDUCATION_REQUEST], loadEducation),
  takeLatest([TypeKeys.CREATE_EDUCATION_REQUEST], createEducation),
  takeLatest([TypeKeys.UPDATE_EDUCATION_REQUEST], updateEducation),
]
