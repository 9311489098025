import React from "react";
import { Link, useParams, useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { useState, useEffect } from "react";
import moment from 'moment'
import { makeStyles, useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import clsx from 'clsx';
import {v4 as uuidv4} from 'uuid'

import { Paper, Grid, Typography, Toolbar, Container, Hidden, Box, Divider,
  CircularProgress, Button, TextField, ButtonGroup, Breadcrumbs } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Block from './Block'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingBottom: theme.spacing(8),
    paddingTop: theme.spacing(2)
  },
  rootPaper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    paddingTop: theme.spacing(3),
  },
  blockPaper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    borderLeft: "5px solid dodgerblue"
  },
  questionPaper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    borderLeft: "5px solid mediumseagreen"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  accordion: {
    marginBottom: theme.spacing(2)
  }
}));

const Checklist = ({checklist, loading, submit}) => {
  const [payload, setPayload] = useState({})
  const navigate = useNavigate()

  useEffect(() => {
    setPayload(checklist.payload)
  }, [checklist, loading]);

  const addBlock = () => {
    setPayload({...payload, blocks: [...payload.blocks,
        {uuid: uuidv4(), title: "", blocks: [], block_items: []}
    ]})
  }

  const moveBlockUp = (index) => {
    return () => {
      setPayload({...payload,
        blocks:[
          ...(payload.blocks.slice(0, index-1)),
          payload.blocks[index],
          payload.blocks[index-1],
          ...(payload.blocks.slice(index+1))
        ]
      })
    }
  }

  const moveBlockDown = (index) => {
    return () => {
      setPayload({...payload,
        blocks:[
          ...(payload.blocks.slice(0, index)),
          payload.blocks[index+1],
          payload.blocks[index],
          ...(payload.blocks.slice(index+2))
        ]
      })
    }
  }

  const removeChildBlock = (uuid) => {
    return () => {
      if (window.confirm("Вы уверены что хотите удалить раздел? Эта операция необратима.")) {
        setPayload({...payload,
          blocks: payload.blocks.filter((item) => item.uuid !== uuid)
        })
      }
    }
  }

  const updateTitle = (e) => {
    setPayload({...payload, title: e.target.value})
  }

  const treeBlockUpdate = (block, blockToUpdate) => {
    if (block.uuid === blockToUpdate.uuid) { return blockToUpdate }
    else if (Array.isArray(block.blocks)) {
      return {
        ...block, blocks: block.blocks.map((subBlock) => {
          return treeBlockUpdate(subBlock, blockToUpdate)
        })
      }
    }
    else { return block }
  }

  const updateBlock = (blockToUpdate) => {
    setPayload({
      ...payload,
      blocks: payload.blocks.map((block) => {
        return treeBlockUpdate(block, blockToUpdate)
      })
    })
  }

  const classes = useStyles()
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))

  const submitAction = () => {
    submit({
      checklistCategoryId: checklist.checklist_category.id,
      checklistId: checklist.id,
      data: {
        payload: payload
      }
    })
    navigate(`/checklist_categories/${checklist.checklist_category.id}`)
  }

  return (
    <Grid container spacing={2} className={classes.root} >
      {!loading && <Grid item xs={12}>
        <Paper className={classes.rootPaper}>
          <Breadcrumbs aria-label="breadcrumb" sx={{marginBottom: "16px"}}>
            <Link color="inherit" to={"/checklist_categories"}>Категории чек-листов</Link>
            <Link color="inherit" to={`/checklist_categories/${checklist.checklist_category.id}`}>
              {`${checklist.checklist_category.title}`}
            </Link>
            <Typography color="textPrimary">{payload.title === "" ? "Нет названия" : payload.title}</Typography>
          </Breadcrumbs>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h4" sx={{marginBottom: "16px"}}>Чек-лист</Typography>
              <TextField required multiline label="Название чек-листа" value={payload.title}
                variant="outlined" fullWidth sx={{marginBottom: "16px"}} onChange={updateTitle}/>
            </Grid>
          </Grid>
          { payload.blocks && payload.blocks.map((block, index) =>
            <Block key={block.uuid} block={block} classes={classes} updateBlock={updateBlock} offset={0}
            removeBlock={removeChildBlock} moveBlockUp={moveBlockUp(index)}
            moveBlockDown={moveBlockDown(index)} canMoveUp={index === 0}
            canMoveDown={index === payload.blocks.length - 1}/>
          )}
          <Button variant="outlined" sx={{marginTop: "16px"}} onClick={addBlock}>Добавить раздел</Button>
          <Divider sx={{marginTop: "16px"}}/>
          <Button sx={{marginTop: "16px"}} variant="contained" onClick={submitAction}>Сохранить</Button>
          <Button sx={{marginTop: "16px", marginLeft: "16px"}} variant="outlined" component={Link}
            to={`/checklist_categories/${checklist.checklist_category.id}`}>
            Назад
          </Button>
        </Paper>
      </Grid>}
    </Grid>
  )
}

export default Checklist
