export const initialTaskTypes = () => {
  return {
    taskTypes: [],
    taskType: {
      id: undefined,
      name: undefined,
      atts: [],
      readOnlyAtts: [],
      company: {}
    },
    loading: true
  }
}
