export const initialDivisions = () => {
  return {
    divisions: [],
    division: {
      id: undefined,
      title: "",
      company: {}
    },
    loading: true
  }
}
