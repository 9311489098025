import React, { useEffect } from "react"
import { useParams } from "react-router-dom"
import { connect } from 'react-redux'
import { getEducationCategoryRequest } from "../../actions/actionCreators/educationCategoriesActions"
import { getEducationRequest, updateEducationRequest } from "../../actions/actionCreators/educationsActions"
import { getEducationBlocksRequest } from "../../actions/actionCreators/educationBlocksActions"
import { getEducationTestsRequest } from "../../actions/actionCreators/educationTestsActions"
import { makeStyles, useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import moment from 'moment'

import { Link, useNavigate } from "react-router-dom"
import { Paper, Grid, Typography, Divider, TextField, Button, MenuItem,
  FormControl, InputLabel, Select, Stack, Breadcrumbs } from '@mui/material'
import { Table, TableBody, TableCell, TableHead, TableRow, Container, Box,
  CircularProgress } from '@mui/material'
import { Add, Edit } from '@mui/icons-material'
import Blocks from './Blocks'
import Tests from './Tests'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2)
  },
  fab: {
    [theme.breakpoints.down('md')]: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  },
  ignoreVisited: {
    color: theme.palette.common.black,
    textDecoration: "none"
  },
}));

const EducationPanel = ({ education, loadEducation, educationLoading,
  loadEducationBlocks, loadEducationTests, educationBlocksLoading,
  educationTestsLoading, educationBlocks, educationTests,
  updateEducationRequest }) => {

  const classes = useStyles()
  const theme = useTheme()
  const params = useParams()
  useEffect(() => {
    loadEducation(params.educationCategoryId, params.id)
    loadEducationBlocks(params.educationCategoryId, params.id)
    loadEducationTests(params.educationCategoryId, params.id)
  }, [params.educationCategoryId, params.id]);

  return(
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Breadcrumbs aria-label="breadcrumb" sx={{marginBottom: "16px"}}>
            <Link color="inherit" to={"/education_categories"}>Категории</Link>
            <Link color="inherit" to={`/education_categories/${education.educationCategory.id}`}>{education.educationCategory.title}</Link>}
            <Typography color="textPrimary">{education.title}</Typography>
          </Breadcrumbs>
          <Grid container spacing={2} >
            <Grid item xs container direction="column">
              <Typography variant="h4">
                {education.title}
              </Typography>
            </Grid>
          </Grid>
          <Blocks education={education} educationBlocksLoading={educationBlocksLoading}
            educationBlocks={educationBlocks} updateEducationRequest={updateEducationRequest}
            loadEducationBlocks={loadEducationBlocks}/>
          <Tests education={education} educationTestsLoading={educationTestsLoading}
            educationTests={educationTests} updateEducationRequest={updateEducationRequest}
            loadEducationTests={loadEducationTests}/>
        </Paper>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = (state) => ({
  education: state.educationsReducer.education,
  educationLoading: state.educationsReducer.loading,
  educationBlocks: state.educationBlocksReducer.educationBlocks,
  educationBlocksLoading: state.educationBlocksReducer.loading,
  educationTests: state.educationTestsReducer.educationTests,
  educationTestsLoading: state.educationTestsReducer.loading,
  companies: state.profileReducer.companies
})

const mapDispatchToProps = (dispatch) => ({
  loadEducation: (categoryId, id) => dispatch(getEducationRequest(categoryId, id)),
  loadEducationBlocks: (categoryId, id) => dispatch(getEducationBlocksRequest(categoryId, id)),
  loadEducationTests: (categoryId, id) => dispatch(getEducationTestsRequest(categoryId, id)),
  updateEducationRequest: (query) => dispatch(updateEducationRequest(query))
})

export default connect(mapStateToProps, mapDispatchToProps)(EducationPanel)
