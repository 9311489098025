import React from "react";
import moment from 'moment'
import 'moment/locale/ru'
import PropTypes from 'prop-types';
import { useEffect, useState } from "react";
import { connect } from 'react-redux'
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Table, TableBody, TableCell, TableContainer, TableHead, TextField,
  TableRow, TableFooter, IconButton, Avatar, List, ListItem, Breadcrumbs,
  ListItemText, ListItemAvatar, AppBar, Container, ButtonGroup,
  Paper, Grid, Typography, Hidden, Button, Collapse, Box, Chip, CircularProgress
  } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingBottom: theme.spacing(6),
    paddingTop: theme.spacing(4)
  },
  paper: {
    marginBottom: theme.spacing(6)
  },
  buttonGroup: {
    paddingTop: theme.spacing(2)
  },
  fab: {
    [theme.breakpoints.down('md')]: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  }
}));

function Row(props) {
  const { team, depth } = props;
  const [open, setOpen] = React.useState(false);

  const padding = makeStyles((theme) => ({
    teamPadding: { padding: theme.spacing(0), paddingLeft: theme.spacing(depth) },
    userPadding: { padding: theme.spacing(0), paddingLeft: theme.spacing(depth+2) },
    small: { width: theme.spacing(4), height: theme.spacing(4) },
    tableIcon: { paddingRight: theme.spacing(1), paddingTop: theme.spacing(1) },
    greyRow: { backgroundColor: theme.palette.action.hover }
  }))();

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <Link className={padding.teamPadding} to={`/dashboard/${team.id}/team_summary`}>{team.title}</Link>
        </TableCell>
        <TableCell>{team.admin}</TableCell>
        <TableCell>
          { team.children_size > 0 &&<IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton> }
        </TableCell>
          <React.Fragment>
            <TableCell align="right">{team.data.team_all}</TableCell>
            <TableCell align="right">{team.data.team_active}</TableCell>
            <TableCell align="right">{team.data.team_complete}</TableCell>
            <TableCell align="right">{team.data.team_rejected}</TableCell>
            <TableCell align="right">{team.data.team_archived}</TableCell>
          </React.Fragment>
      </TableRow>
      {open &&
        <React.Fragment>
          { team.children_size > 0 && team.children.map((team) => (<Row key={team.id} team={team} depth={depth+2}/>))}
        </React.Fragment>}
    </React.Fragment>
  );
}

const TaskTeamStatsTable = (props) => {
  moment.locale('ru')
  const classes = useStyles()
  const theme = useTheme()
  const { loading, team } = props

  return (
    props.loading ? <CircularProgress/> :
      <Grid item xs={12}>
        <Table aria-label="collapsible table" size="small">
          <TableHead>
            <TableRow>
              <TableCell>Название</TableCell>
              <TableCell>Администратор</TableCell>
              <TableCell/>
              <TableCell align="right">Всего</TableCell>
              <TableCell align="right">В процессе</TableCell>
              <TableCell align="right">Выполнено</TableCell>
              <TableCell align="right">Отклонено</TableCell>
              <TableCell align="right">В архиве</TableCell>
            </TableRow>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
              <TableCell>Дивизион</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell align="right">{team.data.team_all}</TableCell>
              <TableCell align="right">{team.data.team_active}</TableCell>
              <TableCell align="right">{team.data.team_complete}</TableCell>
              <TableCell align="right">{team.data.team_rejected}</TableCell>
              <TableCell align="right">{team.data.team_archived}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {team.children.map((team) => (<Row key={team.id} team={team} depth={2}/>))}
          </TableBody>
        </Table>
      </Grid>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(TaskTeamStatsTable)
