import { takeLatest, put, call } from 'redux-saga/effects'
import { TypeKeys } from '../actions/actionTypes'
import {
	getUserPositionsSuccess,
	getUserPositionSuccess,
	createUserPositionSuccess,
	updateUserPositionSuccess,
	getUserPositionsError,
} from '../actions/actionCreators/userPositionsActions'
import callApi from '../utils/callApi'
import config from '../config'

const mapUserPosition = userPosition => {
	return {
		id: userPosition.id,
		title: userPosition.title,
		approved: userPosition.approved,
		usersCount: userPosition.users_count,
		company: {
			value: userPosition.company.id,
			label: userPosition.company.title,
		},
		createdAt: userPosition.created_at,
		updatedAt: userPosition.updated_at,
	}
}

function* loadUserPositions(action) {
	try {
		const res = yield call(callApi, `${config.BASE_URL}/admin/api/v1/user_positions`)
		yield put(getUserPositionsSuccess(res.data.map(mapUserPosition)))
	} catch (error) {
		yield put(getUserPositionsError())
	}
}

function* loadUserPosition(action) {
	const res = yield call(callApi, `${config.BASE_URL}/admin/api/v1/user_positions/${action.id}`)
	yield put(getUserPositionSuccess(mapUserPosition(res.data)))
}

function* createUserPosition(action) {
	const res = yield call(callApi, `${config.BASE_URL}/admin/api/v1/user_positions`, {
		method: 'POST',
		data: action.query.data,
	})
	yield put(createUserPositionSuccess(mapUserPosition(res.data)))
}

function* updateUserPosition(action) {
	const res = yield call(
		callApi,
		`${config.BASE_URL}/admin/api/v1/user_positions/${action.query.userPositionId}`,
		{ method: 'PUT', data: action.query.data }
	)
	yield put(updateUserPositionSuccess(mapUserPosition(res.data)))
}

export const userPositionsSagas = [
	takeLatest([TypeKeys.GET_USER_POSITIONS_REQUEST], loadUserPositions),
	takeLatest([TypeKeys.GET_USER_POSITION_REQUEST], loadUserPosition),
	takeLatest([TypeKeys.CREATE_USER_POSITION_REQUEST], createUserPosition),
	takeLatest([TypeKeys.UPDATE_USER_POSITION_REQUEST], updateUserPosition),
]
