import React from 'react'
import styled from '@emotion/styled'
import { Button } from '@mui/material'
import { Colors } from '../../consts'

export default ({ upload, onClickOk }) => (
	<Container onClick={e => e.stopPropagation()}>
		<Title>Что-то пошло не так</Title>
		<Caption>
			При загрузке файла произошла ошибка. <br /> Проверьте файл и повторите попытку.
		</Caption>

		<ErrorField>
			<ErrorText>{upload.data.errors}</ErrorText>
		</ErrorField>

		<StyledButton
			//
			onClick={onClickOk}
			variant='contained'
		>
			Ок
		</StyledButton>
	</Container>
)

const Container = styled.div`
	background-color: ${Colors.White};
	width: 438px;
	box-shadow: 0px 2px 4px 0px rgba(14, 22, 43, 0.15);
	border-radius: 15px;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	padding-top: 24px;
	padding-bottom: 24px;
`

const Title = styled.span`
	color: ${Colors.Black};
	text-align: center;

	font-size: 24px;
	font-family: Roboto;
	font-style: normal;
	font-weight: 600;
	line-height: normal;

	margin-bottom: 14px;
`

const Caption = styled.span`
	color: ${Colors.Black};
	text-align: center;

	font-size: 14px;
	font-family: Roboto;
	font-style: normal;
	font-weight: 400;
	line-height: normal;

	margin-bottom: 14px;
`

const ErrorField = styled.div`
	border-radius: 10px;
	background: #e4e7ec;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	padding: 14px 20px;
	width: 310px;
	margin-bottom: 24px;
`

const ErrorText = styled.div`
	border-radius: 10px;
	text-align: center;
	font-size: 14px;
	font-family: Roboto;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`

const StyledButton = styled(Button)`
	width: 100px;
`
