import { takeLatest, put, call } from 'redux-saga/effects'
import { TypeKeys } from '../actions/actionTypes'
import { getEducationCategoriesSuccess, getEducationCategorySuccess,
  createEducationCategorySuccess, updateEducationCategorySuccess }
  from '../actions/actionCreators/educationCategoriesActions'
import callApi from '../utils/callApi'
import config from '../config'

const mapEducationCategory = (educationCategory) => {
  return {
    id: educationCategory.id,
    title: educationCategory.title,
    company: {
      value: educationCategory.company.id,
      label: educationCategory.company.title
    },
    createdAt: educationCategory.created_at,
    updatedAt: educationCategory.updated_at,
  }
}

function* loadEducationCategories(action) {
  const res = yield call(callApi, `${config.BASE_URL}/admin/api/v1/education_categories`)
  yield put(getEducationCategoriesSuccess(res.data.map(mapEducationCategory)))
}

function* loadEducationCategory(action) {
  const res = yield call(callApi,
    `${config.BASE_URL}/admin/api/v1/education_categories/${action.id}`)
  yield put(getEducationCategorySuccess(mapEducationCategory(res.data)))
}

function* createEducationCategory(action) {
  const res = yield call(callApi,
    `${config.BASE_URL}/admin/api/v1/education_categories`,
    { method: 'POST', data: action.query.data })
  yield put(createEducationCategorySuccess(mapEducationCategory(res.data)))
}

function* updateEducationCategory(action) {
  const res = yield call(callApi,
    `${config.BASE_URL}/admin/api/v1/education_categories/${action.query.educationCategoryId}`,
    { method: 'PUT', data: action.query.data })
  yield put(updateEducationCategorySuccess(mapEducationCategory(res.data)))
}

export const educationCategoriesSagas = [
  takeLatest([TypeKeys.GET_EDUCATION_CATEGORIES_REQUEST], loadEducationCategories),
  takeLatest([TypeKeys.GET_EDUCATION_CATEGORY_REQUEST], loadEducationCategory),
  takeLatest([TypeKeys.CREATE_EDUCATION_CATEGORY_REQUEST], createEducationCategory),
  takeLatest([TypeKeys.UPDATE_EDUCATION_CATEGORY_REQUEST], updateEducationCategory),
]
