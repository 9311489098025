import React from "react"
import { Typography, ImageList, ImageListItem, ImageListItemBar, Tooltip } from '@mui/material'
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import { arrayMove, SortableContext, sortableKeyboardCoordinates, rectSortingStrategy, useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import DeleteIcon from '@mui/icons-material/Delete'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

const DraggableImage = (props) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({id: props.image.id});
  const style = { transform: CSS.Transform.toString(transform), transition, overflow: "hidden" };
  return (
    <ImageListItem key={props.image.id} cols={2} rows={3} sx={{margin: "8px"}} ref={setNodeRef} style={style}>
      <img src={props.image.url} loading="lazy" id={`image${props.image.id}`} {...attributes} {...listeners}/>
      <ImageListItemBar
              sx={{background:
                    'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                    'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)'}}
              position="top"
              actionIcon={
                <React.Fragment>
                  <Tooltip title="Открыть в новом окне">
                    <a href={props.image.url} target="_blank" style={{ color: 'white', margin: "0" }}>
                      <OpenInNewIcon sx={{ margin: "8px" }}/>
                    </a>
                  </Tooltip>
                  <Tooltip title="Удалить">
                    <DeleteIcon sx={{ color: 'white', cursor: "pointer", margin: "8px", marginLeft: 0 }}
                      onClick={props.purgeImage}/>
                  </Tooltip>
                </React.Fragment>
              }
              actionPosition="right"
            />
    </ImageListItem>
  );
}

const AttachedImages = ({images, setImages, setDeletedImagesIds}) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  function handleDragEnd(event) {
    const {active, over} = event;

    if (active.id !== over.id) {
      setImages((items) => {
        const oldIndex = items.map(function(i) { return i.id; }).indexOf(active.id);
        const newIndex = items.map(function(i) { return i.id; }).indexOf(over.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  }

  const purgeImage = (id) => {
    return (e) => {
      setImages(old => old.filter(i => i.id !== id))
      setDeletedImagesIds(old => [...old, id])
    }
  }

  return(
    <React.Fragment>
      {images.length > 0 && <Typography variant="h6" sx={{marginBottom: "16px"}}>
        Прикреплённые изображения
      </Typography>}
      {images.length > 0 && <ImageList cols={12} rowHeight={120} gap={2}>
        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
          <SortableContext items={images} strategy={rectSortingStrategy}>
            {images.map(image => <DraggableImage key={image.id} image={image} purgeImage={purgeImage(image.id)}/>)}
          </SortableContext>
        </DndContext>
      </ImageList>}
    </React.Fragment>
  )
}
export default AttachedImages
