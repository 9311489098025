import React, { useState } from "react"
import { Link } from "react-router-dom"
import { Paper, Grid, Typography, Divider, TextField, FormControlLabel,
  Switch, Button, MenuItem, FormControl, InputLabel, Select, Box,
  Checkbox, Stack } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import UserSelect from '../../components/Main/userSelect'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(4),
    '& .MuiTextField-root': {
      marginTop: theme.spacing(1),
      // width: '25ch',
    }
  },
  paper: {
    padding: theme.spacing(2)
  },
  idHeader: {
    marginBottom: theme.spacing(2),
  },
  margin: {
    margin: theme.spacing(0),
    marginRight:  theme.spacing(4),
  },
  buttonMargin: {
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    textDecoration: "none"
  },
  fab: {
    [theme.breakpoints.down('md')]: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  },
}));

const AwardTypeForm = ({ awardType, companies, submit, type, history }) => {

  const { id, description, weight, company } = awardType

  const [values, setValues] = useState({
    description: awardType.description,
    weight: awardType.weight,
    company: awardType.company,
    archived: awardType.archived
  });

  const mapCompanies = (cs) => {
    return cs.map((option, index) => <MenuItem key={index} value={option.id} label={option.title}>{option.title}</MenuItem> )
  }

  const setDescriptionValue = (e) => {
    setValues({ ...values, description: e.target.value })
  }
  const setWeightValue = (e) => {
    setValues({ ...values, weight: e.target.value })
  }
  const setCompanyValue = (e, value) => {
    setValues({ ...values, company: {value: value.props.value, label: value.props.label} })
  }
  const setArchivedValue = (e) => {
    setValues({ ...values, archived: e.target.checked })
  }
  const submitAction = () => {
    submit({
      awardTypeId: awardType.id,
      data: {
        description: values.description,
        weight: values.weight,
        company_id: values.company.value,
        archived: values.archived
      }
    })
    history.goBack()
  }

  const classes = useStyles()
  const theme = useTheme()

  return(
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Typography variant="h6" sx={{marginBottom: "16px"}}>
            { type==="edit" && "Изменить тип награды" }
            { type==="new" && "Создать тип награды" }
          </Typography>
          <form noValidate autoComplete="off">
            <Grid container spacing={2}>
              <Grid item sm={6}>
                <TextField required variant="outlined" fullWidth label="Описание" value={values.description} onChange={setDescriptionValue} />
              </Grid>
              <Grid item sm={6}>
                <TextField required variant="outlined" fullWidth label="Количество коинов" type="number" value={values.weight} onChange={setWeightValue} />
              </Grid>
              {(companies.length > 1) &&
                <Grid item sm={6}>
                  <FormControl fullWidth variant="outlined" size="small" className={classes.formControl}>
                    <InputLabel id="demo-simple-select-outlined-label">Компания</InputLabel>
                    <Select
                      fullWidth
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={values.company.value}
                      onChange={setCompanyValue}
                      label="Компания"
                      disabled={companies.length === 1}
                    >
                    { mapCompanies(companies) }
                    </Select>
                  </FormControl>
                </Grid>}
              <Grid item sm={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.archived}
                      onChange={setArchivedValue}
                      color="primary"
                    />} label="В архиве"/>
              </Grid>
              <Grid item sm={12}>
                <Stack spacing={2} direction="row">
                  <Button className={classes.buttonMargin} variant="outlined" to={`/award_types`} component={Link}>
                    Назад
                  </Button>
                  <Button className={classes.buttonMargin} variant="contained" onClick={submitAction} color="primary">
                    { type==="edit" && "Сохранить" }
                    { type==="new" && "Создать" }
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default AwardTypeForm;
