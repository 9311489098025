import { FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material'
import React from 'react'

export default function SelectType({ data, onChange, value }) {
  return (
    <>
      <Typography variant='h5' mb={1}>
        2. Выберите тип файла
      </Typography>
      <Typography variant='body1' mb={2}>
        Укажите для чего вы хотите загрузить файл
      </Typography>
      <FormControl variant='standard' fullWidth>
        <InputLabel id='select-file-type'>Постановка планов</InputLabel>
        <Select
          label='Постановка планов'
          value={value}
          onChange={onChange}
          labelId='select-file-type'
          id='select-file-type'
        >
          {data.map(({ id, title }) => (
            <MenuItem key={id} value={id}>
              {title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  )
}
