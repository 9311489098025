import { takeLatest, put, call } from 'redux-saga/effects'
import { TypeKeys } from '../actions/actionTypes'
import { getIntegrationTokensSuccess, getIntegrationTokenSuccess, createIntegrationTokenSuccess,
  updateIntegrationTokenSuccess, getIntegrationTokenHistorySuccess }
  from '../actions/actionCreators/integrationTokensActions'
import callApi from '../utils/callApi'
import config from '../config'

const mapIntegrationToken = (integrationToken) => {
  return {
    id: integrationToken.id,
    token: integrationToken.token,
    enabled: integrationToken.enabled,
    company: {
      value: integrationToken.company.id,
      label: integrationToken.company.title
    },
    user: integrationToken.user ? {
      value: integrationToken.user.id,
      firstName: integrationToken.user.first_name,
      lastName: integrationToken.user.last_name
    } : null,
    createdAt: integrationToken.created_at,
    updatedAt: integrationToken.updated_at,
    expiresAt: integrationToken.expires_at,
  }
}

function* loadIntegrationTokens(action) {
  const res = yield call(callApi, `${config.BASE_URL}/admin/api/v1/integration_tokens`)
  yield put(getIntegrationTokensSuccess(res.data.map(mapIntegrationToken)))
}

function* loadIntegrationToken(action) {
  const res = yield call(callApi,
    `${config.BASE_URL}/admin/api/v1/integration_tokens/${action.id}`)
  yield put(getIntegrationTokenSuccess(mapIntegrationToken(res.data)))
}

function* loadIntegrationTokenHistory(action) {
  const res = yield call(callApi,
    `${config.BASE_URL}/admin/api/v1/integration_tokens/${action.id}/history`)
  yield put(getIntegrationTokenHistorySuccess(res.data))
}

function* createIntegrationToken(action) {
  const res = yield call(callApi,
    `${config.BASE_URL}/admin/api/v1/integration_tokens`,
    { method: 'POST', data: action.query.data })
  yield put(createIntegrationTokenSuccess(mapIntegrationToken(res.data)))
}

function* updateIntegrationToken(action) {
  const res = yield call(callApi,
    `${config.BASE_URL}/admin/api/v1/integration_tokens/${action.query.integrationTokenId}`,
    { method: 'PUT', data: action.query.data })
  yield put(updateIntegrationTokenSuccess(mapIntegrationToken(res.data)))
}

export const integrationTokensSagas = [
  takeLatest([TypeKeys.GET_INTEGRATION_TOKENS_REQUEST], loadIntegrationTokens),
  takeLatest([TypeKeys.GET_INTEGRATION_TOKEN_REQUEST], loadIntegrationToken),
  takeLatest([TypeKeys.GET_INTEGRATION_TOKEN_HISTORY_REQUEST], loadIntegrationTokenHistory),
  takeLatest([TypeKeys.CREATE_INTEGRATION_TOKEN_REQUEST], createIntegrationToken),
  takeLatest([TypeKeys.UPDATE_INTEGRATION_TOKEN_REQUEST], updateIntegrationToken),
]
