import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import moment from 'moment'
import 'moment/locale/ru'
import { downloadDashboard } from '../../../utils/downloadFile'
import { getDashboardTaskStatsRequest, dashboardResetRequest } from "../../../actions/actionCreators/dashboardActions";
import { makeStyles, useTheme, withStyles } from '@mui/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import clsx from 'clsx'
import { ButtonGroup, Grid, Typography, Button, Select, FormControl,
  InputLabel, MenuItem } from '@mui/material'
import GetAppIcon from '@mui/icons-material/GetApp'
import TaskTeamStatsTable from "./Table"
import DashboardLayout from '../DashboardLayout'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingBottom: theme.spacing(6),
    paddingTop: theme.spacing(4)
  },
  paper: {
    marginBottom: theme.spacing(6)
  },
  buttonGroup: {
    paddingTop: theme.spacing(2)
  },
}));

const TaskTeamStatsDashboard = ({team, loading, loadTaskStats, callDashboardReset, ...props}) => {

  moment.locale('ru')

  const params = useParams();
  const [dates, setDates] = React.useState(moment().date() > 2 ? {
    startDate: moment().startOf('month').format("yyyy-MM-DD"),
    endDate: moment().subtract(1, 'days').format("yyyy-MM-DD"),
    active: 'thisMonth'} : {
    startDate: moment().subtract(1, 'months').startOf('month').format("yyyy-MM-DD"),
    endDate: moment().subtract(1, 'months').endOf('month').format("yyyy-MM-DD"),
    active: 'lastMonth'});
  const handleChange = (dates) => {
    return () => setDates(dates);
  };
  useEffect(() => {
    loadTaskStats(params.teamId, dates.startDate, dates.endDate)

    return () => {
      callDashboardReset()
    }
  }, [params.teamId, dates]);

  const classes = useStyles()
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))
  const series = () => {
    return [team.data.subtree_active, team.data.subtree_archived, team.data.subtree_complete, team.data.subtree_rejected]
  }

  return (
    <DashboardLayout teamId={params.teamId} teamTitle={team.title} loading={loading}
      title={'Выполнение задач'}
      table={<TaskTeamStatsTable team={team} loading={loading}/>}
      header={
        <Grid item xs={12} sx={{marginBottom: "16px"}}>
          <Grid container>
            <Grid item xs={6}>
              <Typography component="span" variant="h4">
                Выполнение задач по командам
              </Typography>
            </Grid>
            <Grid item xs={6}>
            </Grid>
            <Grid item xs={6}>
              <Typography component="span" variant="body1">
                Витрина отображает работу с задачами по командам
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <ButtonGroup color="primary" aria-label="outlined primary button group">
                {moment().date() > 2 && <Button variant={dates.active === 'thisMonth' ? 'contained' : 'outlined'} onClick={handleChange({
                    startDate: moment().startOf('month').format("yyyy-MM-DD"),
                    endDate: moment().subtract(1, 'days').format("yyyy-MM-DD"),
                    active: 'thisMonth'})}>Этот месяц</Button>}
                <Button variant={dates.active === 'lastMonth' ? 'contained' : 'outlined'} onClick={handleChange({
                    startDate: moment().subtract(1, 'months').startOf('month').format("yyyy-MM-DD"),
                    endDate: moment().subtract(1, 'months').endOf('month').format("yyyy-MM-DD"),
                    active: 'lastMonth'})}>Прошлый месяц</Button>
                <Button onClick={downloadDashboard(params.teamId, dates.startDate, dates.endDate, null, 'task_stats_xlsx')}
                  endIcon={<GetAppIcon/>}>Скачать</Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        </Grid>
      }
      />
  )
}

const mapStateToProps = (state) => ({
  team: state.dashboardReducer.team,
  loading: state.dashboardReducer.loading,
})

const mapDispatchToProps = (dispatch) => ({
  loadTaskStats: (id, startDate, endDate) => dispatch(getDashboardTaskStatsRequest(id, startDate, endDate)),
  callDashboardReset: () => dispatch(dashboardResetRequest()),
})

export default connect(mapStateToProps, mapDispatchToProps)(TaskTeamStatsDashboard)
