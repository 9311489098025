import React, { useEffect } from "react"
import { useParams } from "react-router-dom"
import { connect } from 'react-redux'
import { getEducationCategoryRequest }
  from "../../actions/actionCreators/educationCategoriesActions"
import { getEducationsRequest }
  from "../../actions/actionCreators/educationsActions"
import { makeStyles, useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import moment from 'moment'

import { Link } from "react-router-dom"
import { Paper, Grid, Typography, Button, Breadcrumbs, Table, TableBody,
  TableCell, TableHead, TableRow, Box, CircularProgress  } from '@mui/material'
import { Add, Edit } from '@mui/icons-material'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2)
  },
  fab: {
    [theme.breakpoints.down('md')]: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  },
  ignoreVisited: {
    color: theme.palette.common.black,
    textDecoration: "none"
  },
}));

const EducationCategoryPanel = ({ educationCategory, educations, loadEducationCategory,
  loadEducations, loading, educationsLoading }) => {
  const classes = useStyles()
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))
  const params = useParams()
  useEffect(() => {
    loadEducationCategory(params.id)
    loadEducations(params.id)
  }, [params.id]);

  return(
    <Grid container spacing={2} className={classes.root}>
      {loading ? <CircularProgress/> : <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Breadcrumbs aria-label="breadcrumb" sx={{marginBottom: "16px"}}>
            <Link color="inherit" to={"/education_categories"}>Категории</Link>
            <Typography color="textPrimary">{educationCategory.title}</Typography>
          </Breadcrumbs>
          <Grid container spacing={2} >
            <Grid item xs container direction="column">
              <Typography variant="h4" sx={{marginBottom: "16px"}}>
                { `${educationCategory.title} (${educationCategory.company.label})` }
              </Typography>
            </Grid>
          </Grid>
          <Table className={classes.table} size={mobile ? "" : "small"} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell colSpan={6}>
                  <Typography variant="h6" sx={{marginBottom: "16px"}}>
                    Обучения
                    <Link to={`/education_categories/${educationCategory.id}/educations/new`} className={classes.ignoreVisited}>
                      <Button variant="contained" sx={{marginLeft: "16px", padding: "4px 16px 4px 8px"}}>
                        <Add sx={{marginRight: "8px"}}/>
                        Добавить
                      </Button>
                    </Link>
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell align="left">Название</TableCell>
                <TableCell align="left"></TableCell>
                <TableCell align="left">Создан</TableCell>
                <TableCell align="left">Обновлён</TableCell>
                <TableCell align="left"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { loading &&
                <TableRow>
                  <TableCell colSpan={3} align="center"><CircularProgress/></TableCell>
                </TableRow> }
              { !loading && educations.map((education) => {
                const { id, title, createdAt, updatedAt, educationBlocksSize, educationTestsSize } = education
                return(
                  <TableRow key={id}>
                    <TableCell component="th" scope="row" align="left">
                      <Box component={Link} className={classes.ignoreVisited}
                        to={`/education_categories/${educationCategory.id}/educations/${id}`}>
                        <Typography component="span" variant="body1">
                          {id}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="left">
                      <Box component={Link} className={classes.ignoreVisited}
                        to={`/education_categories/${educationCategory.id}/educations/${id}`}>
                        <Typography
                          component="span"
                          variant="body1">
                          {title}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="left">
                      <Typography
                        component="span"
                        variant="body2">
                        {`Блоков: ${educationBlocksSize}, вопросов:  ${educationTestsSize}`}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography
                        component="span"
                        variant="body1">
                        {moment(createdAt).format('DD.MM.YYYY HH:mm:ss')}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography
                        component="span"
                        variant="body1">
                        {moment(updatedAt).format('DD.MM.YYYY HH:mm:ss')}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Box component={Link} className={classes.ignoreVisited}
                      to={`/education_categories/${educationCategory.id}/educations/${id}/edit`}>
                        <Edit />
                      </Box>
                    </TableCell>
                  </TableRow>
              )}
            )}
            </TableBody>
          </Table>
        </Paper>
      </Grid>}
    </Grid>
  )
}

const mapStateToProps = (state) => ({
  educationCategory: state.educationCategoriesReducer.educationCategory,
  loading: state.educationCategoriesReducer.loading,
  educations: state.educationsReducer.educations,
  educationsLoading: state.educationsReducer.loading,
  companies: state.profileReducer.companies
})

const mapDispatchToProps = (dispatch) => ({
  loadEducationCategory: (id) => dispatch(getEducationCategoryRequest(id)),
  loadEducations: (categoryId) => dispatch(getEducationsRequest(categoryId))
})

export default connect(mapStateToProps, mapDispatchToProps)(EducationCategoryPanel)
