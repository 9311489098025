import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { connect } from 'react-redux'
import { getUsersRequest } from "../../actions/actionCreators/usersActions"

import moment from 'moment'
import Pagination from '../../components/Main/Pagination'
import TeamChip from "../../components/Main/TeamChip"

import { makeStyles, useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import clsx from 'clsx';

import { Table, TableBody, TableCell, TableContainer, TableHead,
  TableRow, TableFooter } from '@mui/material'
import { Paper, Grid, Typography, Toolbar, Container, Hidden,
  Fab, TextField, InputAdornment, Button, Box, Chip, Avatar,
  FormControlLabel, Checkbox, Divider } from '@mui/material'
import { List, ListItem, ListItemAvatar, ListItemText } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DoneIcon from '@mui/icons-material/Done';

import CircularProgress from '@mui/material/CircularProgress'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(4),
  },
  divider: {
    margin: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2)
  },
  buttonGroup: {
    paddingTop: theme.spacing(2)
  },
  fab: {
    [theme.breakpoints.down('md')]: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  },
  teamChip: {
    marginLeft: theme.spacing(1),
    // marginTop: theme.spacing(1),
    '&:hover': { cursor: "pointer" },
  },
  avatarFont: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    letterSpacing: "-1px",
    fontSize: "0.8rem",
    backgroundColor: theme.palette.primary.main,
    margin: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      fontSize: "0.8rem"
    },
  },
  ignoreVisited: {
    color: theme.palette.common.black
  },
  noWrap: {
    whiteSpace: "nowrap"
  }
}));

const UsersPanel = (props) => {

  const { users, pagination, loading } = props

  const [collapsed, setCollapsed] = useState(false)
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search)

  const [userId, setUserId] = useState(query.get("user_id") || "")
  const [name, setName] = useState(query.get("name") || "")
  const [phone, setPhone] = useState(query.get("phone") || "")
  const [team, setTeam] = useState(query.get("team") || "")
  const [blocked, setBlocked] = useState(query.get("blocked") || false)

  useEffect(() => {
    props.loadUsers(query)
  }, [query.toString()]);

  const toggleCollapse = () => setCollapsed(!collapsed)
  const toggleBlocked = (e) => setBlocked(e.target.checked)

  const changeUserId = (e) => setUserId(e.target.value)
  const changeName = (e) => setName(e.target.value)
  const changePhone = (e) => setPhone(e.target.value)
  const changeTeam = (e) => setTeam(e.target.value)

  const genSearchUri = () => {
    const newQuery = new URLSearchParams(location.search)
    newQuery.set("page", 1)
    blocked ? newQuery.set("blocked", blocked) : newQuery.delete("blocked")
    userId ? newQuery.set("user_id", userId) : newQuery.delete("user_id")
    name ? newQuery.set("name", name) : newQuery.delete("name")
    phone ? newQuery.set("phone", phone) : newQuery.delete("phone")
    team ? newQuery.set("team", team) : newQuery.delete("team")
    return `${location.pathname}?${newQuery.toString()}`
  }

  const performSearch = (key) => {
    if (key.key === "Enter") {
      navigate(genSearchUri())
    }
  }

  const classes = useStyles()
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Grid container spacing={2} >
            <Grid item xs={12}>
              <Typography variant="h4" sx={{marginBottom:"16px"}}>
                Пользователи&nbsp;
                { false && <Link to={`/users/new`}>
                  <Fab color="primary" aria-label="add" size={mobile ? "" : "small"} className={classes.fab}>
                    <AddIcon />
                  </Fab>
                </Link> }
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} >
            <Grid item xs={12} sm={4}>
              <TextField label="ФИО" fullWidth variant="outlined" value={name}
                onChange={changeName} onKeyPress={performSearch} size="small"/>
            </Grid>
            <Grid item xs={6} sm={4}>
              <TextField label="ID" fullWidth variant="outlined" value={userId}
                onChange={changeUserId} onKeyPress={performSearch} size="small"/>
            </Grid>
            <Grid item xs={6} sm={4}>
              <TextField label="Телефон" fullWidth variant="outlined" value={phone}
                onChange={changePhone} onKeyPress={performSearch} size="small"/>
            </Grid>
            <Grid item xs={6} sm={4}>
              <TextField label="Команда" fullWidth variant="outlined" value={team}
                onChange={changeTeam} onKeyPress={performSearch} size="small"/>
            </Grid>
            <Grid item xs={6} sm={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={blocked}
                    onChange={toggleBlocked}
                    color="primary"
                  />} label="Заблокирован"/>
            </Grid>
            <Grid item xs={6} sm={4}>
              <Button fullWidth variant="contained" color="primary" className="SearchButton" onClick={() => navigate(genSearchUri())}>
                Искать
              </Button>
            </Grid>
          </Grid>
          <Divider sx={{marginTop:"32px", marginBottom:"32px"}} />
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell align="left">ФИО</TableCell>
                <Hidden mdDown>
                  <TableCell align="left">Команды</TableCell>
                </Hidden>
                <Hidden smDown>
                  <TableCell align="left"></TableCell>
                  <TableCell align="left"></TableCell>
                </Hidden>
              </TableRow>
            </TableHead>
            <TableBody>
              { loading &&
                <TableRow>
                  <TableCell colSpan={4} align="center"><CircularProgress/></TableCell>
                </TableRow> }
              { users.map((user) => {
                const { id, firstName, lastName, phone, createdAt, updatedAt, externalId, kpiChannelTitle,
                  color, createdTeams, teams, avatar, blocked, lockedAt, failedAttempts, userPositionId,
                  userPosition } = user
                return(
                  <TableRow key={id}>
                    <TableCell >{id}</TableCell>
                    <TableCell align="center">
                      <List>
                        <ListItem disableGutters={true} component={Link} to={`/users/${id}`}>
                          <ListItemAvatar>
                            <Avatar src={avatar} className={classes.avatarFont}>
                              {`${firstName && firstName[0]} ${lastName && lastName[0]}`}
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText primary={
                            <Typography
                              component="span"
                              variant="body2"
                              className={classes.ignoreVisited}>
                              {firstName} {lastName}
                            </Typography>} secondary={phone}/>
                        </ListItem>
                      </List>
                    </TableCell>
                    <Hidden mdDown>
                      <TableCell align="left">
                            {createdTeams.length > 0 && <div style={{marginBottom: "16px"}}>
                              <strong>Администратор:</strong>
                              { createdTeams.map(team => <Chip size="small" label={team.title}
                              component={Link} to={`/teams/${team.id}`} key={team.id}
                              className={classes.teamChip} variant="outlined"/>) }
                            </div>}
                          {teams.length > 0 && <div>
                            <strong>Участник:</strong>
                            { teams.map(team =>
                              <Chip size="small" label={team.title} key={team.id}
                              component={Link} to={`/teams/${team.id}`}
                              className={classes.teamChip} variant="outlined"/>)}
                          </div>}
                      </TableCell>
                    </Hidden>
                    <Hidden smDown>
                      <TableCell align="left">
                        {externalId && <div><strong>Табельный&nbsp;номер: </strong>{externalId}</div>}
                        {kpiChannelTitle && <div><strong>Бизнес/канал: </strong>{kpiChannelTitle}</div>}
                        {userPositionId && <div style={{color: userPosition.approved ? "" : "red"}}>
                          <strong>Должность: </strong>{userPosition.title}
                        </div>}
                        {blocked && <Chip size="small" color="secondary" label={"Заблокирован"}/>}
                        {lockedAt && <Chip size="small" color="secondary" label={"Превышены попытки входа"}/>}
                      </TableCell>
                      <TableCell align="left">
                        <Link to={`/users/${id}/edit`}>
                          <EditIcon/>
                        </Link>
                      </TableCell>
                    </Hidden>
                  </TableRow>
                )}
              )}
            </TableBody>
          </Table>
          <Box className={classes.buttonGroup}>
            { pagination.totalPages > 1 && <Pagination data={pagination} location={location}/>}
          </Box>
        </Paper>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = (state) => ({
  users: state.usersReducer.users,
  pagination: state.usersReducer.pagination,
  loading: state.usersReducer.loading
})

const mapDispatchToProps = (dispatch) => ({
  loadUsers: (query) => dispatch(getUsersRequest(query))
})

export default connect(mapStateToProps, mapDispatchToProps)(UsersPanel)
