import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { Paper, Grid, Typography, Divider, TextField, Button, MenuItem,
  FormControl, InputLabel, Select, Stack, ImageList, ImageListItem,
  ImageListItemBar, IconButton, CircularProgress, Alert, AlertTitle, Snackbar } from '@mui/material'
import { makeStyles } from '@mui/styles'
import AttachedImages from './AttachedImages'
import NewImages from './NewImages'
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(4),
    '& .MuiTextField-root': {
      marginTop: theme.spacing(1),
    }
  },
  paper: {
    padding: theme.spacing(2)
  },
  idHeader: {
    marginBottom: theme.spacing(2),
  },
  margin: {
    margin: theme.spacing(0),
    marginRight:  theme.spacing(4),
  },
  formControl: {
    marginTop:  theme.spacing(0),
  },
  buttonMargin: {
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    textDecoration: "none"
  },
  fab: {
    [theme.breakpoints.down('md')]: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  },
}));


const EducationBlockForm = ({ educationBlock, submit, type, history, loading, status }) => {
  const [values, setValues] = useState({
    title: educationBlock.title,
    description: educationBlock.description,
  });

  const setDescriptionValue = (e) => {
    setValues({ ...values, description: e.target.value })
  }
  const setTitleValue = (e) => {
    setValues({ ...values, title: e.target.value })
  }

  const [images, setImages] = useState(educationBlock.images)
  const [newImages, setNewImages] = useState([])
  const [deletedImagesIds, setDeletedImagesIds] = useState([])

  const submitAction = () => {
    submit({
      educationCategoryId: educationBlock.education.educationCategory.id,
      educationId: educationBlock.education.id,
      educationBlockId: educationBlock.id,
      data: {
        title: values.title,
        description: values.description,
        images: newImages,
        image_order: images.map((i) => i.id),
        delete_images: deletedImagesIds
      }
    })
  }

  const [open, setOpen] = React.useState(false)
  const handleClose = () => { setOpen(false) }
  useEffect(() => { status === 'success' && setOpen(true) }, [status]);
  const classes = useStyles()

  return(
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={12}>
      {loading ?  <CircularProgress/> :
        <Paper className={classes.paper}>
          <Typography variant="h4" sx={{marginBottom: "16px"}}>
            { type==="edit" && "Изменить блок обучения" }
            { type==="new" && "Создать блок обучения" }
          </Typography>
          <form noValidate autoComplete="off">
            <Grid container spacing={2}>
              <Grid item sm={12}>
                <TextField required variant="outlined" fullWidth label="Название" value={values.title} onChange={setTitleValue} />
              </Grid>
              <Grid item sm={12}>
                <TextField variant="outlined" fullWidth label="Описание" value={values.description} onChange={setDescriptionValue}
                  multiline rows={3}/>
              </Grid>
              <Grid item sm={12}>
                <AttachedImages images={images} setImages={setImages} setDeletedImagesIds={setDeletedImagesIds}/>
              </Grid>
              <Grid item sm={12}>
                <NewImages images={newImages} setImages={setNewImages}/>
              </Grid>
              <Grid item sm={12}>
                <Button className={classes.buttonMargin} variant="outlined" to={`/education_categories/${educationBlock.education.educationCategory.id}/educations/${educationBlock.education.id}`} component={Link}>
                  Назад
                </Button>
                <Button className={classes.buttonMargin} variant="contained" onClick={submitAction}
                  color="primary" sx={{ml: "8px"}} disabled={values.title === ''}>
                  { type==="edit" && "Сохранить" }
                  { type==="new" && "Создать" }
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>}
      </Grid>

      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert severity="success" onClose={handleClose}>
          <AlertTitle>Успешно</AlertTitle>
          Блок обучения успешно сохранён.
        </Alert>
      </Snackbar>
    </Grid>
  )
}

export default EducationBlockForm
