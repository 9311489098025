import React, { useEffect } from "react"
import EducationCategoryForm from "./EducationCategoryForm"
import { useParams, useNavigate } from "react-router-dom"
import { connect } from 'react-redux'
import { getEducationCategoryRequest, updateEducationCategoryRequest }
  from "../../actions/actionCreators/educationCategoriesActions"

const EducationCategoryEdit = ({ educationCategory, loadEducationCategory,
  updateEducationCategory, history, companies, status }) => {
  const navigate = useNavigate();
  const params = useParams()
  useEffect(() => { loadEducationCategory(params.id) }, [params.id]);
  useEffect(() => {
    status === 'success' && navigate('/education_categories')
  }, [status]);

  if (educationCategory) {
    return(<EducationCategoryForm educationCategory={educationCategory}
      key={`${educationCategory.id}-${educationCategory.updatedAt}`}
      submit={updateEducationCategory} type={"edit"} history={history}
      companies={companies}/>)
  } else {
    return(<div></div>)
  }
}

const mapStateToProps = (state) => ({
  educationCategory: state.educationCategoriesReducer.educationCategory,
  companies: state.profileReducer.companies,
  status: state.educationCategoriesReducer.requestStatus
})

const mapDispatchToProps = (dispatch) => ({
  loadEducationCategory: (id) => dispatch(getEducationCategoryRequest(id)),
  updateEducationCategory: (query) => dispatch(updateEducationCategoryRequest(query)),
})

export default connect(mapStateToProps, mapDispatchToProps)(EducationCategoryEdit)
