import axios, { RawAxiosRequestConfig, AxiosResponse, AxiosError } from 'axios'
import { createBrowserHistory } from 'history'
import { getToken } from './getToken'
import config from '../config'
import Logger from './Logger'

export const HTTPMethod = {
	DELETE: 'DELETE',
	GET: 'GET',
	HEAD: 'HEAD',
	POST: 'POST',
	PUT: 'PUT',
}

/**
 *
 *
 * @export
 * @class Request
 */
export default class Request {
	abortController = new AbortController()
	_promise = null

	/**
	 * Creates an instance of Request.
	 * @param {string} name
	 * @param {string} method
	 * @param {string} url
	 * @param {{GET?: Object, POST?: Object}} [params]
	 * @memberof Request
	 */
	constructor(name, method, url, params = {}) {
		this.name = name
		this.method = method

		this._url = new URL(url, config.BASE_URL)
		if (params.GET) {
			for (let [key, value] of Object.entries(params.GET)) {
				value && this._url.searchParams.append(key, value.toString())
			}
		}

		this.params = params.POST
	}

	get promise() {
		return this._promise
	}

	get headers() {
		return {
			Accept: 'application/json',
			Authorization: `Bearer ${getToken()}`,
		}
	}

	get url() {
		return this._url.href
	}

	get options() {
		const { url, headers, method } = this
		const params = this.params || {}
		return {
			url,
			headers,
			method,
			...params,
			signal: this.abortController.signal,
		}
	}

	async start() {
		Logger.onRequest(this.name, 'REQUEST', this.options)
		Logger.curl(this.url, { ...this.options, body: this.options.data })
		this._promise = axios(this.options)
		return this._promise.then(this.log).catch(axiosErrorHandler(this.defaultErrorHandler))
	}

	async abort() {
		this._promise = null
		this.abortController.abort()
	}

	log = res => {
		Logger.onRequest(this.name, 'SUCCESS', res.data)
		return res
	}

	defaultErrorHandler = res => {
		if (res.type === 'axios-error') {
			if (res.error?.response?.status === 401) {
				localStorage.clear()
				// TODO: add history change
			}
		}
		Logger.onRequest(this.name, 'ERROR', res)
		throw res
	}

	static history = createBrowserHistory()
}

export const axiosErrorHandler = callback => error => {
	callback(
		axios.isAxiosError(error)
			? {
					error: error,
					type: 'axios-error',
			  }
			: {
					error: error,
					type: 'stock-error',
			  }
	)
}
