import { initialUserStories } from "../initial/initialUserStories.js"
import { TypeKeys } from "../actions/actionTypes"

export const userStoriesReducer = (state = initialUserStories(), action) => {
  switch (action.type) {
    case TypeKeys.GET_USER_STORIES_REQUEST:
      return { ...state, userStories: [], loading: true }
    case TypeKeys.GET_USER_STORIES_SUCCESS:
      return { ...state, userStories: action.userStories, loading: false }
    default:
    return { ...state }
  }
}

export default userStoriesReducer
