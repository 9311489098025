import { TypeKeys } from "../actionTypes";

export const getDashboardClosedDatesRequest = (id, startDate, endDate) => ({
  type: TypeKeys.GET_DASHBOARD_CLOSED_DATES_REQUEST,
  id,
  startDate,
  endDate,
});
export const getDashboardClosedDatesSuccess = (team) => ({
  type: TypeKeys.GET_DASHBOARD_CLOSED_DATES_SUCCESS,
  team,
});

export const getDashboardPlansUpdatedRequest = (id, startDate, endDate) => ({
  type: TypeKeys.GET_DASHBOARD_PLANS_UPDATED_REQUEST,
  id,
  startDate,
  endDate,
});
export const getDashboardPlansUpdatedSuccess = (team) => ({
  type: TypeKeys.GET_DASHBOARD_PLANS_UPDATED_SUCCESS,
  team,
});

export const getDashboardPlanValuesRequest = (id, kpiId) => ({
  type: TypeKeys.GET_DASHBOARD_PLAN_VALUES_REQUEST,
  id,
  kpiId,
});
export const getDashboardPlanValuesSuccess = (team) => ({
  type: TypeKeys.GET_DASHBOARD_PLAN_VALUES_SUCCESS,
  team,
});

export const getDashboardPlanCompleteRequest = (
  id,
  kpiId,
  startDate,
  endDate
) => ({
  type: TypeKeys.GET_DASHBOARD_PLAN_COMPLETE_REQUEST,
  id,
  kpiId,
  startDate,
  endDate,
});
export const getDashboardPlanCompletesSuccess = (team) => ({
  type: TypeKeys.GET_DASHBOARD_PLAN_COMPLETE_SUCCESS,
  team,
});

export const getDashboardZeroResultsRequest = (id, startDate, endDate) => ({
  type: TypeKeys.GET_DASHBOARD_ZERO_RESULTS_REQUEST,
  id,
  startDate,
  endDate,
});
export const getDashboardZeroResultsSuccess = (team) => ({
  type: TypeKeys.GET_DASHBOARD_ZERO_RESULTS_SUCCESS,
  team,
});

export const getDashboardZeroTeamsResultsRequest = (
  id,
  startDate,
  endDate
) => ({
  type: TypeKeys.GET_DASHBOARD_ZERO_TEAMS_RESULTS_REQUEST,
  id,
  startDate,
  endDate,
});
export const getDashboardZeroTeamsResultsSuccess = (team) => ({
  type: TypeKeys.GET_DASHBOARD_ZERO_TEAMS_RESULTS_SUCCESS,
  team,
});

export const getDashboardResultsLossRequest = (id, startDate, endDate) => ({
  type: TypeKeys.GET_DASHBOARD_RESULTS_LOSS_REQUEST,
  id,
  startDate,
  endDate,
});
export const getDashboardResultsLossSuccess = (team) => ({
  type: TypeKeys.GET_DASHBOARD_RESULTS_LOSS_SUCCESS,
  team,
});

export const getDashboardSocialActivityRequest = (id, startDate, endDate) => ({
  type: TypeKeys.GET_DASHBOARD_SOCIAL_ACTIVITY_REQUEST,
  id,
  startDate,
  endDate,
});
export const getDashboardSocialActivitySuccess = (team) => ({
  type: TypeKeys.GET_DASHBOARD_SOCIAL_ACTIVITY_SUCCESS,
  team,
});

export const getDashboardTeamSummaryRequest = (id, startDate, endDate) => ({
  type: TypeKeys.GET_DASHBOARD_TEAM_SUMMARY_REQUEST,
  id,
  startDate,
  endDate,
});
export const getDashboardTeamSummarySuccess = (team) => ({
  type: TypeKeys.GET_DASHBOARD_TEAM_SUMMARY_SUCCESS,
  team,
});

export const getDashboardTaskStatsRequest = (id, startDate, endDate) => ({
  type: TypeKeys.GET_DASHBOARD_TASK_STATS_REQUEST,
  id,
  startDate,
  endDate,
});
export const getDashboardTaskStatsSuccess = (team) => ({
  type: TypeKeys.GET_DASHBOARD_TASK_STATS_SUCCESS,
  team,
});

export const getDashboardShowcaseRequest = (id, startDate, endDate) => ({
  type: TypeKeys.GET_DASHBOARD_SHOWCASE_REQUEST,
  id,
  startDate,
  endDate,
});

export const getDashboardShowcaseSuccess = (data) => ({
  type: TypeKeys.GET_DASHBOARD_SHOWCASE_SUCCESS,
  data,
});

export const dashboardResetRequest = () => ({
  type: TypeKeys.DASHBOARD_RESET_REQUEST,
});
export const dashboardResetSuccess = () => ({
  type: TypeKeys.DASHBOARD_RESET_SUCCESS,
});
