import React from "react";
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { useState, useEffect } from "react";
import { getDivisionsRequest } from "../../actions/actionCreators/divisionsActions"
import moment from 'moment'

import { makeStyles, useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import clsx from 'clsx';

import { Table, TableBody, TableCell, TableContainer, TableHead,
  TableRow } from '@mui/material';
import { Paper, Grid, Typography, Toolbar, Container, Hidden,
  Fab, Box, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails,
  Button } from '@mui/material';
import {Add, ExpandMore, History} from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2)
  },
  fab: {
    [theme.breakpoints.down('md')]: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  },
  ignoreVisited: {
    marginLeft: theme.spacing(2),
    color: theme.palette.common.black,
    textDecoration: "none"
  },
}));

const DivisionsPanel = (props) => {

  const { location, divisions, history, loading, companies } = props

  useEffect(() => {
    props.loadDivisions()
  }, []);

  const classes = useStyles()
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Grid container spacing={2} >
            <Grid item xs container direction="column">
              <Typography variant="h4">
                Эрии руководителей
              </Typography>
            </Grid>
            <Grid item justify="flex-end">
              <Link to={`/divisions/new`} className={classes.ignoreVisited}>
                <Button variant="contained">
                  <Add />
                  &nbsp;Добавить&nbsp;
                </Button>
              </Link>
            </Grid>
          </Grid>
          <Table className={classes.table} size={mobile ? "" : "small"} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell align="left">Название</TableCell>
                { companies.length > 1 && <TableCell align="left" >Компания</TableCell> }
              </TableRow>
            </TableHead>
            <TableBody>
              { loading &&
                <TableRow>
                  <TableCell colSpan={5} align="center"><CircularProgress/></TableCell>
                </TableRow> }
              { !loading && divisions.map((division) => {
                const { id, title, company } = division
                return(
                  <TableRow key={id}>
                    <TableCell component="th" scope="row">
                      <Box component={Link} to={`/divisions/${id}`} className={classes.ignoreVisited}>
                        <Typography
                          component="span"
                          variant="body1">
                          {id}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="left">
                      <Box component={Link} to={`/divisions/${id}`} className={classes.ignoreVisited}>
                        <Typography
                          component="span"
                          variant="body1">
                          {title}
                        </Typography>
                      </Box>
                    </TableCell>
                    { companies.length > 1 && <TableCell align="left">{company.label}</TableCell>}
                  </TableRow>
              )}
            )}
            </TableBody>
          </Table>
        </Paper>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = (state) => ({
  divisions: state.divisionsReducer.divisions,
  loading: state.divisionsReducer.loading,
  companies: state.profileReducer.companies
})

const mapDispatchToProps = (dispatch) => ({
  loadDivisions: () => dispatch(getDivisionsRequest())
})

export default connect(mapStateToProps, mapDispatchToProps)(DivisionsPanel)
