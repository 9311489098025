import { takeLatest, put, call } from 'redux-saga/effects'
import { TypeKeys } from '../actions/actionTypes'
import { getUserEducationsSuccess, getUserEducationSuccess, assignUserEducationsSuccess, assignUserEducationsError }
  from '../actions/actionCreators/userEducationsActions'
import callApi from '../utils/callApi'
import config from '../config'

const mapUserEducation = (userEducation) => {
  return {
    id: userEducation.id,
    result: userEducation.result,
    attempt: userEducation.attempt,
    attemptAt: userEducation.attempt_at,
    createdAt: userEducation.created_at,
    education: {
      title: userEducation.education.title,
      educationCategory: userEducation.education.education_category.title,
      threshold: userEducation.education.threshold,
    },
    user: {
        id: userEducation.user.id,
        firstName: userEducation.user.first_name,
        lastName: userEducation.user.last_name,
        phone: userEducation.user.phone,
        avatar: userEducation.user.avatar
    },
    answers: userEducation.answers.map((answer) => ({
      description: answer.description,
      answerOptions: answer.answer_options
    }))
  }
}

function* loadUserEducations(action) {
  const res = yield call(callApi, `${config.BASE_URL}/admin/api/v1/user_educations?${action.query}`)
  const { pagination, user_educations } = res.data
  const paginationData = {
    currentPage: pagination.current_page,
    totalPages: pagination.total_pages,
    nextPage: pagination.next_page,
    prevPage: pagination.prev_page,
    totalCount: pagination.total_count
  }
  yield put(getUserEducationsSuccess(user_educations.map(mapUserEducation), paginationData))
}

function* loadUserEducation(action) {
  const res = yield call(callApi,
    `${config.BASE_URL}/admin/api/v1/user_educations/${action.id}`)
  yield put(getUserEducationSuccess(mapUserEducation(res.data)))
}


function* assignUserEducations(action) {
  const res = yield call(callApi,
    `${config.BASE_URL}/admin/api/v1/user_educations`, { method: 'POST', data: action.query })
  console.log(res)
  if (res.status === 201) {
    yield put(assignUserEducationsSuccess(res.data))
  } else {
    yield put(assignUserEducationsError(res.status))
  }
}

export const userEducationsSagas = [
  takeLatest([TypeKeys.GET_USER_EDUCATIONS_REQUEST], loadUserEducations),
  takeLatest([TypeKeys.GET_USER_EDUCATION_REQUEST], loadUserEducation),
  takeLatest([TypeKeys.ASSIGN_USER_EDUCATIONS_REQUEST], assignUserEducations),
]
