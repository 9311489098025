import moment from 'moment'
import { HTTPMethod, Request } from '../../../utils'
import { Colors } from '../consts'

export default class Upload {
	constructor(data) {
		this.data = data
	}

	get id() {
		return this.data.id
	}

	get authorFullName() {
		const { first_name, last_name } = this.data.user
		return `${first_name} ${last_name}`
	}

	get title() {
		return this.data.upload_type.title
	}

	get created_at() {
		return moment(this.data.created_at)
	}

	get status() {
		switch (this.data.status) {
			case 'created':
				return {
					icon: 'progress',
					color: Colors.Orange,
					title: 'Загрузка',
				}
			case 'fail':
				return {
					icon: 'error',
					color: Colors.Red,
					title: 'Ошибка',
				}
			case 'processed':
				return {
					icon: 'success',
					color: Colors.Green,
					title: 'Загружено',
				}
		}
	}

	static async uploadFile(base64, type) {
		const { data } = await new Request('Upload File', HTTPMethod.POST, '/admin/api/v1/uploads', {
			POST: {
				data: {
					document: base64,
					upload_type_id: type.id.toString(),
				},
			},
		}).start()

		return new Upload(data)
	}

	static async getTypes() {
		const { data } = await new Request(
			'List Upload Types',
			HTTPMethod.GET,
			'/admin/api/v1/upload_types'
		).start()
		return data
	}

	static async getUploads() {
		const { data } = await new Request(
			'List Uploaded Files',
			HTTPMethod.GET,
			'/admin/api/v1/uploads'
		).start()
		return data.map(Upload.create)
	}

	static create(data) {
		return new Upload(data)
	}
}
