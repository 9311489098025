import React from 'react'

import { connect } from 'react-redux'
import { useEffect } from 'react'
import { createUserRequest } from '../../actions/actionCreators/usersActions'
import { getKpiChannelsRequest } from '../../actions/actionCreators/kpiChannelsActions'
import UserForm from './UserForm'

const UserNew = ({ createUser, companies, requestStatus, kpiChannels, loadKpiChannels }) => {
	const user = {
		firstName: '',
		lastName: '',
		teamId: null,
		color: '',
		phone: '',
	}
	useEffect(() => {
		loadKpiChannels()
	}, [])

	return (
		<UserForm
			key={+new Date()}
			user={user}
			submit={createUser}
			type={'new'}
			companies={companies}
			requestStatus={requestStatus}
			kpiChannels={kpiChannels}
		/>
	)
}

const mapStateToProps = state => ({
	companies: state.profileReducer.companies,
	kpiChannels: state.kpiChannelsReducer.kpiChannels,
	requestStatus: state.usersReducer.status,
})

const mapDispatchToProps = dispatch => ({
	createUser: query => dispatch(createUserRequest(query)),
	loadKpiChannels: () => dispatch(getKpiChannelsRequest()),
})

export default connect(mapStateToProps, mapDispatchToProps)(UserNew)
