export const initialUsers = () => {
  return {
    users: [],
    usersList: [],
    user: {
        id: undefined,
        firstName: "",
        lastName: "",
        phone: undefined,
        fullAvatarUrl: undefined,
        createdAt: undefined,
        updatedAt: undefined,
        color: undefined,
        teams: [],
        createdTeams: [],
        guestTeams: [],
        runRate: []
    },
    userStats: {
      teams: []
    },
    loading: true,
    statsLoading: true,
    status: undefined,
    pagination: {
      totalPages: 0
    },
    errors: []
  }
}
