import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { getUserPositionsRequest } from "../../actions/actionCreators/userPositionsActions"
import { FormControl, InputLabel, Select, MenuItem, CircularProgress, Typography,
  FormControlLabel, Checkbox, FormLabel, FormGroup } from '@mui/material';
import { makeStyles } from '@mui/styles';
const useStyles = makeStyles((theme) => ({}));

const UserPositionsCheckbox = ({ companies, userPositionsList, loading, loadUserPositions,
    changeUserPositions, value, label, allowEmpty, companyId }) => {

  const classes = useStyles()
  const [userPositions, setUserPositions] = useState([])

  useEffect(() => {
    setUserPositions(userPositionsList
      .filter((p) => (p.approved && p.company.value === companyId))
      .map((p) => ({
        value: p.id,
        label: p.title,
        selected: false
      })))
  }, [userPositionsList]);

  const changeSelectedUserPositions = (event) => {
    setUserPositions(userPositions.map((p) => {
      return p.value.toString() === event.target.name ? {...p, selected: event.target.checked} : p
    }))
  }

  useEffect(() => {
    changeUserPositions(userPositions)
  }, [userPositions]);

  useEffect(() => {
    companyId !== "" && loadUserPositions()
  }, [companyId]);

  if (companyId === "") {
    return (<Typography>Выберите команду</Typography>)
  }

  if (loading) {
    return (<CircularProgress />)
  }

  if (userPositions.length === 0) {
    return (<Typography>Должности не найдены</Typography>)
  }

  return (
    <FormControl fullWidth component="fieldset" variant="outlined" size="small" className={classes.formControl}>
      <Typography variant="body1">
        Выберите должности
      </Typography>
      <FormGroup>
        { userPositions.map((option) =>
          <FormControlLabel key={option.value}
            control={
              <Checkbox checked={option.checked} onChange={changeSelectedUserPositions} name={option.value.toString()} />
            }
            label={option.label}
          />)}
      </FormGroup>
    </FormControl>
  )
}
const mapStateToProps = (state) => ({
  companies: state.profileReducer.companies,
  userPositionsList: state.userPositionsReducer.userPositions,
  loading: state.userPositionsReducer.loading
})

const mapDispatchToProps = (dispatch) => ({
  loadUserPositions: () => dispatch(getUserPositionsRequest()),
})

export default connect(mapStateToProps, mapDispatchToProps)(UserPositionsCheckbox)
