import React, { useEffect } from "react"
import { useParams } from "react-router-dom"
import { connect } from 'react-redux'
import { getChecklistCategoryRequest }
  from "../../actions/actionCreators/checklistCategoriesActions"
import { getChecklistsRequest }
  from "../../actions/actionCreators/checklistsActions"
import { makeStyles, useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import moment from 'moment'

import { Link } from "react-router-dom"
import { Paper, Grid, Typography, Button, Breadcrumbs, Table, TableBody,
  TableCell, TableHead, TableRow, Box, CircularProgress } from '@mui/material'
import { Add, Edit } from '@mui/icons-material'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2)
  },
  fab: {
    [theme.breakpoints.down('md')]: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  },
  ignoreVisited: {
    color: theme.palette.common.black,
    textDecoration: "none"
  },
}));

const ChecklistCategoryPanel = ({ checklistCategory, checklists, loadChecklistCategory,
  loadChecklists, loading, checklistsLoading }) => {
  const classes = useStyles()
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))
  const params = useParams()
  useEffect(() => {
    loadChecklistCategory(params.id)
    loadChecklists(params.id)
  }, [params.id]);

  return(
    <Grid container spacing={2} className={classes.root}>
      {loading ? <CircularProgress/> : <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Breadcrumbs aria-label="breadcrumb" sx={{marginBottom: "16px"}}>
            <Link color="inherit" to={"/checklist_categories"}>Категории чек-листов</Link>
            <Typography color="textPrimary">{checklistCategory.title}</Typography>
          </Breadcrumbs>
          <Grid container spacing={2} >
            <Grid item xs container direction="column">
              <Typography variant="h6" sx={{marginBottom: "16px"}}>
                { `${checklistCategory.title} (${checklistCategory.company.label})` }
              </Typography>
            </Grid>
            <Grid item justify="flex-end">
              <Link to={`/checklist_categories/${checklistCategory.id}/checklists/new`} className={classes.ignoreVisited}>
                <Button variant="contained">
                  <Add />
                  &nbsp;Добавить чеклист&nbsp;
                </Button>
              </Link>
            </Grid>
          </Grid>
          <Table className={classes.table} size={mobile ? "" : "small"} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell align="left">Название</TableCell>
                <TableCell align="left">Создан</TableCell>
                <TableCell align="left">Обновлён</TableCell>
                <TableCell align="left"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { loading &&
                <TableRow>
                  <TableCell colSpan={3} align="center"><CircularProgress/></TableCell>
                </TableRow> }
              { !loading && checklists.map((checklist) => {
                const { id, payload, createdAt, updatedAt } = checklist
                return(
                  <TableRow key={id}>
                    <TableCell component="th" scope="row" align="left">
                      <Box component={Link} className={classes.ignoreVisited}
                        to={`/checklist_categories/${checklistCategory.id}/checklists/${id}`}>
                        <Typography component="span" variant="body1">
                          {id}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="left">
                      <Box component={Link} className={classes.ignoreVisited}
                        to={`/checklist_categories/${checklistCategory.id}/checklists/${id}`}>
                        <Typography
                          component="span"
                          variant="body1">
                          {payload.title}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="left">
                      <Typography
                        component="span"
                        variant="body1">
                        {moment(createdAt).format('DD.MM.YYYY HH:mm:ss')}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography
                        component="span"
                        variant="body1">
                        {moment(updatedAt).format('DD.MM.YYYY HH:mm:ss')}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Box component={Link} className={classes.ignoreVisited}
                      to={`/checklist_categories/${checklistCategory.id}/checklists/${id}`}>
                        <Edit />
                      </Box>
                    </TableCell>
                  </TableRow>
              )}
            )}
            </TableBody>
          </Table>
        </Paper>
      </Grid>}
    </Grid>
  )
}

const mapStateToProps = (state) => ({
  checklistCategory: state.checklistCategoriesReducer.checklistCategory,
  loading: state.checklistCategoriesReducer.loading,
  checklists: state.checklistsReducer.checklists,
  checklistsLoading: state.checklistsReducer.loading,
  companies: state.profileReducer.companies
})

const mapDispatchToProps = (dispatch) => ({
  loadChecklistCategory: (id) => dispatch(getChecklistCategoryRequest(id)),
  loadChecklists: (categoryId) => dispatch(getChecklistsRequest(categoryId))
})

export default connect(mapStateToProps, mapDispatchToProps)(ChecklistCategoryPanel)
