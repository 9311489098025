import React from 'react'
import { Button, ButtonGroup } from '@mui/material'
import { Icon } from '../../../../../components/atomic/atoms'

/**
 *
 *
 * @param {{
 * 	isMonthCurrent: boolean,
 * 	onPressMonthCurrent: Function,
 * 	onPressMonthPast: Function,
 * 	onPressDownload: Function,
 * }} props
 */
export default function ButtonsBlock(props) {
	const { onPressDownload, onPressMonthCurrent, onPressMonthPast, isMonthCurrent } = props

	return (
		<ButtonGroup size='small' fullWidth aria-label='small button group'>
			<Button
				onClick={onPressMonthCurrent}
				variant={isMonthCurrent ? 'contained' : 'outlined'}
				key='set_is_month_current'
			>
				Этот месяц
			</Button>
			<Button
				onClick={onPressMonthPast}
				variant={isMonthCurrent ? 'outlined' : 'contained'}
				key='set_is_month_past'
			>
				Прошлый месяц
			</Button>
			{/* <Button onPress={onPressDownload} endIcon={<Icon name='download' />} key='three'>
				Скачать
			</Button> */}
		</ButtonGroup>
	)
}
