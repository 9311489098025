import { HTTPMethod, Request } from '../../../../utils'

class TeamChecklistsResult {
	constructor(data, level = 0) {
		this.data = data
		this.level = level
		this.children = data.children.map(item => new TeamChecklistsResult(item, this.level + 1))
	}

	get team() {
		return {
			level: this.level,
			title: this.data.title,
			id: this.data.id,
		}
	}

	get allTeams() {
		return [this.team, this.children.flatMap(({ allTeams }) => allTeams)].flat()
	}
}

export default class ChecklistsDashboard {
	constructor(data) {
		/**
		 *   @type {{
		 *    header_data: {
		 *      blocks_data: {
		 *          uuid: string;
		 *          title: string;
		 *          average_percentage: number;
		 *      }[];
		 *      checklist_data: {
		 *          id: number;
		 *          users_count: number;
		 *          total_ucls: number;
		 *          filled_ucls: number;
		 *          ucl_per_user: number;
		 *      };
		 *  }}}
		 */
		this.data = data
	}

	get innerTeams() {
		return new TeamChecklistsResult(this.data.teams_data).allTeams
	}

	/**
	 * @static
	 * @param {string} teamID
	 * @param {{
	 *     checklist_id: string,
	 *     division_id: string,
	 *     start_date: string,
	 *     end_date: string
	 *   }} options
	 * @memberof ChecklistsDashboard
	 */
	static async getData(teamID, options) {
		const { data } = await new Request(
			'Get Checklist Dashboard Data',
			HTTPMethod.GET,
			`/admin/api/v1/dashboard/${teamID}/checklist_stats`,
			{ GET: options }
		).start()
		return new ChecklistsDashboard(data)
	}

	static async getCategoriesChecklists() {
		const { data } = await new Request(
			'Get Categories Checklists',
			HTTPMethod.GET,
			'/admin/api/v1/checklist_categories/'
		).start()
		return data
	}

	/**
	 * @static
	 * @param {string} categoryID
	 */
	static async getListChecklists(categoryID) {
		const { data } = await new Request(
			'Get List Checklists',
			HTTPMethod.GET,
			`/admin/api/v1/checklist_categories/${categoryID}/checklists`
		).start()
		return data
	}

	static async getListDivisions() {
		const { data } = await new Request(
			'Get List Divisions',
			HTTPMethod.GET,
			'/admin/api/v1/divisions'
		).start()
		return data
	}
}
