
import { TypeKeys } from '../actionTypes'

export const getEducationBlocksRequest = (educationCategoryId, educationId) => ({
  type: TypeKeys.GET_EDUCATION_BLOCKS_REQUEST, educationCategoryId, educationId
})

export const getEducationBlocksSuccess = (educationBlocks) => ({
  type: TypeKeys.GET_EDUCATION_BLOCKS_SUCCESS,
  educationBlocks
})

export const getEducationBlockRequest = (educationCategoryId, educationId, id) => ({
  type: TypeKeys.GET_EDUCATION_BLOCK_REQUEST, educationCategoryId, educationId, id
})

export const getEducationBlockSuccess = (educationBlock) => ({
  type: TypeKeys.GET_EDUCATION_BLOCK_SUCCESS,
  educationBlock
})

export const createEducationBlockRequest = (query) => ({ type: TypeKeys.CREATE_EDUCATION_BLOCK_REQUEST, query })

export const createEducationBlockSuccess = (educationBlock) => ({
  type: TypeKeys.CREATE_EDUCATION_BLOCK_SUCCESS,
  educationBlock
})

export const updateEducationBlockRequest = (query) => ({ type: TypeKeys.UPDATE_EDUCATION_BLOCK_REQUEST, query })

export const updateEducationBlockSuccess = (educationBlock) => ({
  type: TypeKeys.UPDATE_EDUCATION_BLOCK_SUCCESS,
  educationBlock
})
