import { takeLatest, put, call, delay } from 'redux-saga/effects'
import { TypeKeys } from '../actions/actionTypes'
import { getGuestSuccess, updateGuestSuccess } from '../actions/actionCreators/guestsActions'
import callApi from '../utils/callApi'
import config from '../config'

function mapGuest(guest) {
  return {
    id: guest.id,
    createdAt: guest.created_at,
    settingsEnabled: guest.settings_enabled,
    user: {
      id: guest.user.id,
      firstName: guest.user.first_name,
      lastName: guest.user.last_name,
      phone: guest.user.phone,
      fullAvatarUrl: guest.user.full_avatar_url,
      createdAt: guest.user.created_at,
      updatedAt: guest.user.updated_at,
      color: guest.user.color,
      blocked: guest.user.blocked
    },
    team: {
      id: guest.team.id,
      title: guest.team.title,
      adminId: guest.team.admin_id,
      createdAt: guest.team.created_at,
      teamAvatarUrl: guest.team.team_avatar_url,
      archived: guest.team.archived,
      archivedAt: guest.team.archived_at,
      adminNullify: guest.team.admin_nullify,
      weekendAutoClose: guest.team.weekend_auto_close
    },
    availableIndicators: guest.available_indicators.map((indicator) => {
      return {
        id: indicator.id,
        title: indicator.title
      }
    }),
    guestHiddenIndicators: guest.guest_hidden_indicators.map((ghi) => {
      return {
        id: ghi.id,
        title: ghi.title
      }
    }),
    availableTeams: guest.available_teams.map((team) => {
      return {
        id: team.id,
        title: team.title
      }
    }),
    guestHiddenTeams: guest.guest_hidden_teams.map((team) => {
      return {
        id: team.id,
        title: team.title
      }
    })
  }
}

function* loadGuest(action) {
  const res = yield call(callApi, `${config.BASE_URL}/admin/api/v1/teams/${action.teamId}/guests/${action.guestId}`)
  yield put(getGuestSuccess(mapGuest(res.data)))
}

function* updateGuest(action) {
  const res = yield call(callApi,
    `${config.BASE_URL}/admin/api/v1/teams/${action.teamId}/guests/${action.query.guestId}`,
    { method: 'PUT', data: action.query.data })
  yield put(updateGuestSuccess(mapGuest(res.data)))
}

export const guestsSagas = [
  takeLatest([TypeKeys.GET_GUEST_REQUEST], loadGuest),
  takeLatest([TypeKeys.UPDATE_GUEST_REQUEST], updateGuest),
]
