import { takeLatest, put, call } from 'redux-saga/effects'
import { TypeKeys } from '../actions/actionTypes'
import { getCalendarSuccess, updateCalendarSuccess }
  from '../actions/actionCreators/calendarsActions'
import callApi from '../utils/callApi'
import config from '../config'

function* loadCalendar(action) {
  const res = yield call(callApi, `${config.BASE_URL}/admin/api/v1/calendars?date=${action.year}-${action.month}-01&company_id=${action.companyId}`)
  yield put(getCalendarSuccess(res.data))
}

function* updateCalendar(action) {
  const res = yield call(callApi,
    `${config.BASE_URL}/admin/api/v1/calendars`,
    { method: 'PUT', data: {data: action.data, year: action.year, month: action.month, company_id: action.companyId }})
  yield put(updateCalendarSuccess(res.data))
}

export const calendarsSagas = [
  takeLatest([TypeKeys.GET_CALENDAR_REQUEST], loadCalendar),
  takeLatest([TypeKeys.UPDATE_CALENDAR_REQUEST], updateCalendar),
]
