
import { TypeKeys } from '../actionTypes'

export const getEducationsRequest = (categoryId) => ({
  type: TypeKeys.GET_EDUCATIONS_REQUEST, categoryId
})

export const getEducationsSuccess = (educations) => ({
  type: TypeKeys.GET_EDUCATIONS_SUCCESS,
  educations
})

export const getEducationRequest = (categoryId, id) => ({
  type: TypeKeys.GET_EDUCATION_REQUEST, categoryId, id
})

export const getEducationSuccess = (education) => ({
  type: TypeKeys.GET_EDUCATION_SUCCESS,
  education
})

export const createEducationRequest = (query) => ({ type: TypeKeys.CREATE_EDUCATION_REQUEST, query })

export const createEducationSuccess = (education) => ({
  type: TypeKeys.CREATE_EDUCATION_SUCCESS,
  education
})

export const updateEducationRequest = (query) => ({ type: TypeKeys.UPDATE_EDUCATION_REQUEST, query })

export const updateEducationSuccess = (education) => ({
  type: TypeKeys.UPDATE_EDUCATION_SUCCESS,
  education
})
