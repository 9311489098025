import React from 'react'
import { RootRouter } from './Router'
import { BrowserRouter } from 'react-router-dom'
import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import { rootReducer, history } from './reducers'
import createSagaMiddleware from 'redux-saga'
import rootSaga from './sagas'
import { createLogger } from 'redux-logger'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { Popup } from './components'

const sagaMiddleware = createSagaMiddleware(rootSaga)
const store = createStore(
  rootReducer(history),
  applyMiddleware(
    sagaMiddleware,
    createLogger({
      collapsed: true,
      diff: true,
      colors: { title: () => '#4db6ac' },
    })
  )
)
sagaMiddleware.run(rootSaga)

const theme = createTheme({
  palette: {
    primary: { main: '#1565c0' },
    secondary: { main: '#d32f2f' },
  },
})

export default function App() {
  const popupContextValue = Popup.useContext()
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Popup.Context.Provider value={popupContextValue}>
          <Provider store={store}>
            <RootRouter />
          </Provider>
          <Popup.Component />
        </Popup.Context.Provider>
      </ThemeProvider>
    </BrowserRouter>
  )
}
