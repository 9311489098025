export const initialChecklistCategories = () => {
  return {
    checklistCategories: [],
    checklistCategory: {
      id: undefined,
      title: "",
      company: {}
    },
    loading: true
  }
}
