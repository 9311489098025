import React from "react";
import moment from 'moment'
import 'moment/locale/ru'
import PropTypes from 'prop-types';
import { useEffect, useState } from "react";
import { connect } from 'react-redux'
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import clsx from 'clsx';
import { Table, TableBody, TableCell, TableContainer, TableHead, TextField,
  TableRow, TableFooter, IconButton, Avatar, List, ListItem, Breadcrumbs,
  ListItemText, ListItemAvatar, AppBar, Container, ButtonGroup,
  Paper, Grid, Typography, Hidden, Button, Collapse, Box, Chip, CircularProgress
  } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import GroupIcon from '@mui/icons-material/Group';
import GetAppIcon from '@mui/icons-material/GetApp';
import PersonIcon from '@mui/icons-material/Person';
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingBottom: theme.spacing(6),
    paddingTop: theme.spacing(4)
  },
  paper: {
    marginBottom: theme.spacing(6)
  },
  buttonGroup: {
    paddingTop: theme.spacing(2)
  },
  noPadding: {padding: theme.spacing(0)},
  fab: {
    [theme.breakpoints.down('md')]: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  },
}));

function Row(props) {
  const { team, depth } = props;
  const [open, setOpen] = React.useState(false);

  const padding = makeStyles((theme) => ({
    teamPadding: { padding: theme.spacing(0), paddingLeft: theme.spacing(depth) },
    userPadding: { padding: theme.spacing(0), paddingLeft: theme.spacing(depth+2) },
    small: { width: theme.spacing(4), height: theme.spacing(4) },
    noPadding: {padding: theme.spacing(0)},
    tableIcon: { paddingRight: theme.spacing(1), paddingTop: theme.spacing(1) },
    greyRow: { backgroundColor: theme.palette.action.hover }
  }))();

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <Link to={`/dashboard/${team.id}/team_summary`} className={padding.teamPadding}>{team.title}</Link>
        </TableCell>
        <TableCell>{team.admin}</TableCell>
        <TableCell>
          {team.data.admin_plan &&
            <IconButton className={padding.noPadding} aria-label="expand row"
              size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>}
        </TableCell>
        {team.data.admin_plan ? <React.Fragment>
            <TableCell align="right">{team.data.admin_plan}</TableCell>
            <TableCell align="right">{team.data.admin_fact}</TableCell>
            <TableCell align="right">
              <Typography variant="body2" color={team.data.admin_rr >= 0 ? "primary" : "error"} >
                {team.data.admin_rr}%
              </Typography>
            </TableCell>
          </React.Fragment> : <TableCell colSpan={3} align="center">KPI не назначен</TableCell>}
      </TableRow>
      {open && team.data.admin_plan &&
        <React.Fragment>
        { team.children.length > 0 ?
          team.children.map((team) => (
            <Row key={team.id} team={team} depth={depth+2}/>
          )) : team.data.members_rr.map((member, index) => (
          <TableRow key={`${team.id}-${index}`} sx={{ '& > *': { borderBottom: 'unset' } }}>
            <TableCell colSpan={3} >
              <span className={padding.userPadding}>{member.name}</span>
            </TableCell>
            {member.plan ? <React.Fragment>
              <TableCell align="right">{member.plan}</TableCell>
              <TableCell align="right">{member.fact}</TableCell>
              <TableCell align="right">{member.rr}%</TableCell>
              </React.Fragment> : <TableCell colSpan={3} align="center">KPI не назначен</TableCell>}
          </TableRow>
        ))}
      </React.Fragment>}
    </React.Fragment>
  );
}

const PlanCompleteTable = (props) => {
  moment.locale('ru')
  const classes = useStyles()
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))
  const { team } = props

  if (props.kpiId) {
    return (
      props.loading ? <CircularProgress/> :
        <Grid item xs={12}>
          <Table aria-label="collapsible table" size="small">
            <TableHead>
              <TableRow>
                <TableCell>Название</TableCell>
                <TableCell>Администратор</TableCell>
                <TableCell/>
                <TableCell align="right">План</TableCell>
                <TableCell align="right">Факт</TableCell>
                <TableCell align="right">Run rate</TableCell>
              </TableRow>
              <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>Дивизион</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell align="right">{team.data.admin_plan}</TableCell>
                <TableCell align="right">{team.data.admin_fact}</TableCell>
                <TableCell align="right">
                  <Typography variant="body2" color={team.data.admin_rr >= 0 ? "primary" : "error"} >
                    {team.data.admin_rr}%
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {team.children.map((team) => (<Row key={team.id} team={team} depth={2}/>))}
            </TableBody>
          </Table>
        </Grid>
    )
  } else {
    return(<span/>)
  }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(PlanCompleteTable)
