import { takeLatest, put, call } from 'redux-saga/effects'
import { TypeKeys } from '../actions/actionTypes'
import { getMedalsSuccess, getMedalSuccess, updateMedalSuccess }
  from '../actions/actionCreators/medalsActions'
import callApi from '../utils/callApi'
import config from '../config'

const mapMedal = (medal) => {
  return {
    id: medal.id,
    title: medal.title,
    description: medal.description,
    weight: medal.weight,
    imageUrl: medal.image_url,
    createdAt: medal.created_at,
    updatedAt: medal.updated_at
  }
}

function* loadMedals(action) {
  const res = yield call(callApi, `${config.BASE_URL}/admin/api/v1/medals`)
  yield put(getMedalsSuccess(res.data.map(mapMedal)))
}

function* loadMedal(action) {
  const res = yield call(callApi, `${config.BASE_URL}/admin/api/v1/medals/${action.id}`)
  yield put(getMedalSuccess(mapMedal(res.data)))
}

function* updateMedal(action) {
  const res = yield call(callApi,
    `${config.BASE_URL}/admin/api/v1/medals/${action.query.medalId}`,
    { method: 'PUT', data: action.query.data })
  yield put(updateMedalSuccess(res.data.map(mapMedal)))
}

export const medalsSagas = [
  takeLatest([TypeKeys.GET_MEDALS_REQUEST], loadMedals),
  takeLatest([TypeKeys.GET_MEDAL_REQUEST], loadMedal),
  takeLatest([TypeKeys.UPDATE_MEDAL_REQUEST], updateMedal),
]
