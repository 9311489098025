import React from "react";
import DivisionForm from "./DivisionForm"
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { useEffect } from "react";
import { getDivisionRequest, updateDivisionRequest }
  from "../../actions/actionCreators/divisionsActions"

const DivisionEdit = ({ division, loadDivision, updateDivision, history, companies }) => {
  const params = useParams();
  useEffect(() => {
    loadDivision(params.id)
  }, [params.id]);

  if (division) {
    return(<DivisionForm key={`${division.id}-${division.updatedAt}`} division={division}
      submit={updateDivision} type={"edit"} history={history}
      companies={companies}></DivisionForm>)
  } else {
    return(<div></div>)
  }
}

const mapStateToProps = (state) => ({
  division: state.divisionsReducer.division,
  companies: state.profileReducer.companies
})

const mapDispatchToProps = (dispatch) => ({
  loadDivision: (id) => dispatch(getDivisionRequest(id)),
  updateDivision: (query) => dispatch(updateDivisionRequest(query)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DivisionEdit)
