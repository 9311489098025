import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { Typography, Button, Table, TableBody, TableCell, TableHead,
  TableRow, Container, Box, CircularProgress, IconButton } from '@mui/material'
import { Add, Edit, ImportExport } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'

import { DndContext, closestCenter, KeyboardSensor, MouseSensor, PointerSensor, useSensor, useSensors, DragOverlay } from '@dnd-kit/core'
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy, useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

import moment from 'moment'

const useStyles = makeStyles((theme) => ({
  ignoreVisited: {
    color: theme.palette.common.black,
    textDecoration: "none"
  },
}));

const DraggableRow = ({test, education}) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({id: test.id});
  const style = { transform: CSS.Transform.toString(transform), transition, zIndex: 9999};
  const classes = useStyles()

  return (
    <TableRow key={test.id} ref={setNodeRef} style={style} sx={{backgroundColor: "#FFF"}}>
      <TableCell align="left" {...attributes} {...listeners}>
        <IconButton aria-label="delete" size="large">
          <ImportExport fontSize="inherit" />
        </IconButton>
      </TableCell>
      <TableCell align="left">
        <Box component={Link} className={classes.ignoreVisited}
          to={`/education_categories/${education.educationCategory.id}/educations/${education.id}/education_tests/${test.id}`}>
          <Typography component="span" variant="body1">{test.description}</Typography>
        </Box>
      </TableCell>
      <TableCell align="left">
        <Typography component="span" variant="body1">{`Вариантов ответа: ${test.answerOptions.length}`}</Typography>
      </TableCell>
      <TableCell align="left">
        <Typography component="span" variant="body1">{moment(test.updatedAt).format('DD.MM.YYYY HH:mm:ss')}</Typography>
      </TableCell>
      <TableCell align="left">
        <Box component={Link} className={classes.ignoreVisited}
        to={`/education_categories/${education.educationCategory.id}/educations/${education.id}/education_tests/${test.id}`}>
          <Edit />
        </Box>
      </TableCell>
    </TableRow>
  );
}

const Tests = (props) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  const [activeId, setActiveId] = useState(null);
  function handleDragStart(event) {
    setActiveId(event.active.id);
  }
  function handleDragEnd(event) {
    const {active, over} = event
    setActiveId(null)
    if (active.id !== over.id) {
      setTests((items) => {
        const oldIndex = items.map(function(i) { return i.id; }).indexOf(active.id)
        const newIndex = items.map(function(i) { return i.id; }).indexOf(over.id)
        return arrayMove(items, oldIndex, newIndex)
      })
    }
  }

  const {education, educationTestsLoading, educationTests} = props
  const classes = useStyles()
  const [tests, setTests] = useState([])
  const [originalTestIds, setOriginalTestIds] = useState([])

  useEffect(() => {
    setTests(educationTests)
    setOriginalTestIds(educationTests.map(b => b.id))
  }, [educationTests])

  useEffect(() => {
    if (tests.length > 0 && educationTests.length > 0 &&
      !arraysEqual(tests.map(b => b.id), originalTestIds)) {
      props.updateEducationRequest({
        educationCategoryId: education.educationCategory.id,
        educationId: education.id,
        data: {
          tests_order: tests.map(b => b.id)
        }
      })
      setOriginalTestIds(tests.map(b => b.id))
    }
  }, [tests])

  const arraysEqual = (array1, array2) => {
    if (array1.length != array2.length) { return false }
    for (var i = 0, l=array1.length; i < l; i++) {
      if (array1[i] != array2[i]) { return false }
    }
    return true
  }

  return(
    <Table className={classes.table} aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell colSpan={6}>
            <Typography variant="h6" sx={{marginBottom: "16px", marginTop: "16px"}}>
              Вопросы
              <Link to={`/education_categories/${education.educationCategory.id}/educations/${education.id}/education_tests/new`}
                className={classes.ignoreVisited}>
                <Button variant="contained" sx={{marginLeft: "16px", padding: "4px 16px 4px 8px"}}>
                  <Add sx={{marginRight: "8px"}}/>
                  Добавить
                </Button>
              </Link>
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell></TableCell>
          <TableCell align="left">Название</TableCell>
          <TableCell align="left"></TableCell>
          <TableCell align="left">Обновлён</TableCell>
          <TableCell align="left"></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        { educationTestsLoading &&
          <TableRow>
            <TableCell colSpan={3} align="center"><CircularProgress/></TableCell>
          </TableRow> }
        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
          <SortableContext items={tests} strategy={verticalListSortingStrategy}>
            {tests.map(test => <DraggableRow key={test.id} test={test}
              education={education} deleteAnswer={() => {}}
              />)}
          </SortableContext>
        </DndContext>
      </TableBody>
    </Table>
  )
}

export default Tests
