import { initialTeamUserIndicators } from "../initial/initialTeamUserIndicators.js"
import { TypeKeys } from "../actions/actionTypes"

export const teamUserIndicatorsReducer = (state = initialTeamUserIndicators(), action) => {
  switch (action.type) {
    case TypeKeys.GET_TEAM_USER_INDICATORS_REQUEST:
      return { ...state, userIndicators: [], loading: true }
    case TypeKeys.GET_TEAM_USER_INDICATORS_SUCCESS:
      return { ...state, userIndicators: action.userIndicators,
        performanceIndicator: action.performanceIndicator, loading: false, updatedAt: Date.now() }
    case TypeKeys.SAVE_TEAM_USER_INDICATORS_REQUEST:
      return { ...state, loading: true }
    case TypeKeys.SAVE_TEAM_USER_INDICATORS_SUCCESS:
      return { ...state, userIndicators: action.userIndicators,
        performanceIndicator: action.performanceIndicator, loading: false, updatedAt: Date.now() }
    case TypeKeys.UPDATE_TEAM_USER_INDICATOR_REQUEST:
      return { ...state, loading: true }
    case TypeKeys.UPDATE_TEAM_USER_INDICATOR_SUCCESS:
      return {
        ...state,
        userIndicators: state.userIndicators.map(
          item => item.id === action.userIndicator.id ? action.userIndicator : item
        ),
        loading: false,
        updatedAt: Date.now() }
    default:
    return { ...state }
  }
}

export default teamUserIndicatorsReducer
