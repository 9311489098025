import { TypeKeys } from '../actionTypes'

export const getTeamUserIndicatorsRequest = (teamId, performanceIndicatorId) =>
  ({ type: TypeKeys.GET_TEAM_USER_INDICATORS_REQUEST, teamId, performanceIndicatorId })

export const getTeamUserIndicatorsSuccess = (userIndicators, performanceIndicator) => ({
  type: TypeKeys.GET_TEAM_USER_INDICATORS_SUCCESS,
  userIndicators,
  performanceIndicator
})

export const updateTeamUserIndicatorRequest = (userIndicator) =>
  ({ type: TypeKeys.UPDATE_TEAM_USER_INDICATOR_REQUEST, userIndicator })

export const updateTeamUserIndicatorSuccess = (userIndicator) => ({
  type: TypeKeys.UPDATE_TEAM_USER_INDICATOR_SUCCESS,
  userIndicator
})

export const saveTeamUserIndicatorsRequest = (teamId, performanceIndicatorId, teamUserIndicators) =>
  ({ type: TypeKeys.SAVE_TEAM_USER_INDICATORS_REQUEST,
    teamId, performanceIndicatorId,
    teamUserIndicators })

export const saveTeamUserIndicatorsSuccess = (userIndicators, performanceIndicator) => ({
  type: TypeKeys.SAVE_TEAM_USER_INDICATORS_SUCCESS,
  userIndicators,
  performanceIndicator
})
