import React from "react"
import { useState, useEffect } from "react"
import {v4 as uuidv4} from 'uuid'
import { Grid, Typography, TextField, Button, ButtonGroup, Collapse,
FormControl, InputLabel, Select, MenuItem  } from '@mui/material';

import { MoveUp, MoveDown, Delete } from '@mui/icons-material';

const Question = ({question, updateBlockItem, removeBlockItem, moveItemDown,
  moveItemUp, canMoveUp, canMoveDown}) => {
  const [activeBlockItem, setActiveBlockItem] = useState({})
  const handeUpdate = () => { updateBlockItem(activeBlockItem) }

  useEffect(() => { setActiveBlockItem(question) }, [question]);

  const handleTitleChange = (e) => {
    return setActiveBlockItem({...activeBlockItem, title: e.target.value})
  }
  const handleTypeChange = (e) => {
    updateBlockItem({...activeBlockItem, item_type: e.target.value})
  }

  const handleOptionTitleChange = (uuid) => { return (e) => {
    setActiveBlockItem({...activeBlockItem, options: activeBlockItem.options.map(
      (option) => {
        return (option.uuid === uuid) ? {...option, title: e.target.value} : option
      }
    )})
  }}

  const handleOptionScoreChange = (uuid) => { return (e) => {
    setActiveBlockItem({...activeBlockItem, options: activeBlockItem.options.map(
      (option) => {
        return (option.uuid === uuid) ? {...option, score: parseInt(e.target.value)} : option
      }
    )})
  }}

  const addOption = () => {
    updateBlockItem({...activeBlockItem, options: [
        ...activeBlockItem.options,
        { uuid: uuidv4(), score: 0, title: "", value: null, selected: false }
    ]})
  }

  const removeOption = (uuid) => {
    return () => {
      if (window.confirm("Вы уверены что хотите удалить вариант ответа? Эта операция необратима.")) {
        updateBlockItem({...activeBlockItem,
          options: activeBlockItem.options.filter((option) => option.uuid !== uuid)
        })
      }
    }
  }

  const moveUpHandler = () => {
    return moveItemUp()
  }

  const moveUp = (index) => {
    return () => {
      updateBlockItem({...activeBlockItem,
        options:[
          ...(activeBlockItem.options.slice(0, index-1)),
          activeBlockItem.options[index],
          activeBlockItem.options[index-1],
          ...(activeBlockItem.options.slice(index+1))
        ]
      })
    }
  }

  const moveDown = (index) => {
    return () => {
      updateBlockItem({...activeBlockItem,
        options:[
          ...(activeBlockItem.options.slice(0, index)),
          activeBlockItem.options[index+1],
          activeBlockItem.options[index],
          ...(activeBlockItem.options.slice(index+2))
        ]
      })
    }
  }

  return(
    <Grid container spacing={2} key={activeBlockItem.uuid}
      sx={{marginTop: "16px", marginLeft: "16px", borderLeft: `3px solid green`}}
      key={activeBlockItem.uuid}>
      <Grid item xs={12}>
        <Typography variant="h6" sx={{marginBottom: "16px", marginTop: "-16px"}}>
          {`Вопрос: ${activeBlockItem.title}`}
        </Typography>
        <ButtonGroup sx={{marginBottom: `16px`}}>
          <Button variant="text" onClick={removeBlockItem(activeBlockItem.uuid)}><Delete/></Button>
          <Button variant="text" onClick={moveItemUp} disabled={canMoveUp}><MoveUp/></Button>
          <Button variant="text" onClick={moveItemDown} disabled={canMoveDown}><MoveDown/></Button>
        </ButtonGroup>
        <TextField multiline required label="Вопрос" value={activeBlockItem.title}
          sx={{marginRight: "16px", marginBottom: "16px"}} variant="outlined" fullWidth
          onChange={handleTitleChange} onBlur={handeUpdate}/>
      </Grid>
      <Grid item xs={12}>
        <FormControl sx={{marginBottom: "16px"}}>
          <InputLabel id={`questionType${activeBlockItem.uuid}`}>Тип вопроса</InputLabel>
          <Select
            labelId={`questionType${activeBlockItem.uuid}`}
            value={activeBlockItem.item_type}
            label="Тип вопроса"
            onChange={handleTypeChange}
          >
            <MenuItem value={"select"}>Один ответ</MenuItem>
            <MenuItem value={"multiselect"}>Несколько ответов</MenuItem>
            <MenuItem value={"input"}>Свободный ответ</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      { Array.isArray(activeBlockItem.options) && activeBlockItem.options.map((option, index) =>
        <Grid item xs={12} sx={{paddingLeft: `16px`}} key={option.uuid}>
          <Typography variant="h6">Вариант ответа</Typography>
          <ButtonGroup sx={{marginBottom: `16px`}}>
            <Button variant="text" onClick={removeOption(option.uuid)}><Delete/></Button>
            <Button variant="text" onClick={moveUp(index)} disabled={index === 0}><MoveUp/></Button>
            <Button variant="text" onClick={moveDown(index)} disabled={index === activeBlockItem.options.length - 1}><MoveDown/></Button>
          </ButtonGroup>
          <TextField multiline required label="Ответ" value={option.title}
            sx={{marginBottom: "16px"}} variant="outlined" fullWidth
            onChange={handleOptionTitleChange(option.uuid)}
            onBlur={handeUpdate}/>
          <TextField label="Баллы" type="number" value={option.score}
            InputLabelProps={{shrink: true}}
            variant="outlined" onChange={handleOptionScoreChange(option.uuid)}
            onBlur={handeUpdate}/>
        </Grid>
      )}
      <Grid item xs={8}>
        <Button sx={{marginTop: "16px"}} variant="outlined" onClick={addOption}>
          Добавить вариант ответа
        </Button>
      </Grid>
    </Grid>
  )
}

export default Question
