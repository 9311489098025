import { takeLatest, put, call, delay } from 'redux-saga/effects'
import { TypeKeys } from '../actions/actionTypes'
import { getUserRewardsSuccess, createUserRewardSuccess, destroyUserRewardSuccess } from '../actions/actionCreators/userRewardsActions'
import callApi from '../utils/callApi'
import config from '../config'

const mapRewards = (reward) => ({
  id: reward.id,
  comment: reward.comment,
  date: reward.date,
  weight: reward.weight,
  from_user: reward.from_user,
  medal: reward.medal,
  award_type: reward.award_type,
  createdAt: reward.created_at
})
function* loadUserRewards(action) {
  const res = yield call(callApi, `${config.BASE_URL}/admin/api/v1/users/${action.id}/user_awards`)
  const rewards = res.data
  const rewardsData = rewards.map(mapRewards)
  yield put(getUserRewardsSuccess(rewardsData))
}

function* createUserReward(action) {
  const res = yield call(callApi, `${config.BASE_URL}/admin/api/v1/users/${action.query.userId}/user_awards`,
    { method: 'POST', data: action.query.data })
  const rewards = res.data
  const rewardsData = rewards.map(mapRewards)
  yield put(createUserRewardSuccess(rewardsData))
}

function* destroyUserReward(action) {
  const res = yield call(callApi, `${config.BASE_URL}/admin/api/v1/users/${action.query.userId}/user_awards/${action.query.awardId}`,
    { method: 'DELETE' })
  const rewards = res.data
  const rewardsData = rewards.map(mapRewards)
  yield put(destroyUserRewardSuccess(rewardsData))
}

export const userRewardsSagas = [
  takeLatest([ TypeKeys.GET_USER_REWARDS_REQUEST], loadUserRewards),
  takeLatest([ TypeKeys.CREATE_USER_REWARD_REQUEST], createUserReward),
  takeLatest([ TypeKeys.DESTROY_USER_REWARD_REQUEST], destroyUserReward)
]
