import { initialChecklistCategories } from "../initial/initialChecklistCategories"
import { TypeKeys } from "../actions/actionTypes"

export const checklistCategoriesReducer = (state = initialChecklistCategories(), action) => {
  switch (action.type) {
    case TypeKeys.GET_CHECKLIST_CATEGORIES_REQUEST:
      return { ...state, checklistCategories: initialChecklistCategories().checklistCategories, loading: true }
    case TypeKeys.GET_CHECKLIST_CATEGORIES_SUCCESS:
      return { ...state, checklistCategories: action.checklistCategories, loading: false }
    case TypeKeys.GET_CHECKLIST_CATEGORY_REQUEST:
      return { ...state, checklistCategory: initialChecklistCategories().checklistCategory, loading: true }
    case TypeKeys.GET_CHECKLIST_CATEGORY_SUCCESS:
      return { ...state, checklistCategory: action.checklistCategory, loading: false }
    case TypeKeys.CREATE_CHECKLIST_CATEGORY_REQUEST:
      return { ...state, checklistCategory: initialChecklistCategories().checklistCategory, loading: true }
    case TypeKeys.CREATE_CHECKLIST_CATEGORY_SUCCESS:
      return { ...state, checklistCategory: action.checklistCategory, loading: false }
    case TypeKeys.UPDATE_CHECKLIST_CATEGORY_REQUEST:
      return { ...state, checklistCategory: initialChecklistCategories().checklistCategory, loading: true }
    case TypeKeys.UPDATE_CHECKLIST_CATEGORY_SUCCESS:
      return { ...state, checklistCategory: action.checklistCategory, loading: false }
    default:
    return { ...state }
  }
}

export default checklistCategoriesReducer
