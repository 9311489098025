import { takeLatest, put, call } from 'redux-saga/effects'
import { TypeKeys } from '../actions/actionTypes'
import { getChecklistsSuccess, getChecklistSuccess,
  createChecklistSuccess, updateChecklistSuccess }
  from '../actions/actionCreators/checklistsActions'
import callApi from '../utils/callApi'
import config from '../config'

const mapChecklist = (checklist) => {
  return {
    id: checklist.id,
    payload: checklist.payload,
    checklist_category: checklist.checklist_category,
    createdAt: checklist.created_at,
    updatedAt: checklist.updated_at,
  }
}

function* loadChecklists(action) {
  const res = yield call(callApi, `${config.BASE_URL}/admin/api/v1/checklist_categories/${action.categoryId}/checklists`)
  yield put(getChecklistsSuccess(res.data.map(mapChecklist)))
}

function* loadChecklist(action) {
  const res = yield call(callApi,
    `${config.BASE_URL}/admin/api/v1/checklist_categories/${action.categoryId}/checklists/${action.id}`)
  yield put(getChecklistSuccess(mapChecklist(res.data)))
}

function* createChecklist(action) {
  const res = yield call(callApi,
    `${config.BASE_URL}/admin/api/v1/checklist_categories/${action.query.checklistCategoryId}/checklists/`,
    { method: 'POST', data: action.query.data })
  yield put(createChecklistSuccess(mapChecklist(res.data)))
}

function* updateChecklist(action) {
  const res = yield call(callApi,
    `${config.BASE_URL}/admin/api/v1/checklist_categories/${action.query.checklistCategoryId}/checklists/${action.query.checklistId}`,
    { method: 'PUT', data: action.query.data })
  yield put(updateChecklistSuccess(mapChecklist(res.data)))
}

export const checklistsSagas = [
  takeLatest([TypeKeys.GET_CHECKLISTS_REQUEST], loadChecklists),
  takeLatest([TypeKeys.GET_CHECKLIST_REQUEST], loadChecklist),
  takeLatest([TypeKeys.CREATE_CHECKLIST_REQUEST], createChecklist),
  takeLatest([TypeKeys.UPDATE_CHECKLIST_REQUEST], updateChecklist),
]
