import React from "react"
import { Typography, ImageList, ImageListItem, ImageListItemBar, Button, IconButton, Tooltip } from '@mui/material'
import { DndContext, closestCenter, KeyboardSensor, MouseSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import { arrayMove, SortableContext, sortableKeyboardCoordinates, rectSortingStrategy, useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import DeleteIcon from '@mui/icons-material/Delete'

const DraggableImage = (props) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({id: props.image.id});
  const style = { transform: CSS.Transform.toString(transform), transition};

  return (
    <ImageListItem key={props.image.id} cols={2} rows={3} sx={{margin: "8px"}}>
      <img src={props.image.image} loading="lazy" id={`new_image${props.image.id}`} ref={setNodeRef}
        style={style} {...attributes} {...listeners}/>
      <ImageListItemBar
              sx={{
                background:
                  'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                  'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
              }}
              position="top" actionPosition="right"
              actionIcon={
                <Tooltip title="Удалить">
                  <IconButton sx={{ color: 'white' }} onClick={props.purgeImage}>
                    <DeleteIcon/>
                  </IconButton>
                </Tooltip>
              }
            />
    </ImageListItem>
  );
}

const NewImages = ({images, setImages}) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  function handleDragEnd(event) {
    const {active, over} = event;

    if (active.id !== over.id) {
      setImages((items) => {
        const oldIndex = items.map(function(i) { return i.id; }).indexOf(active.id);
        const newIndex = items.map(function(i) { return i.id; }).indexOf(over.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  }

  const appendImage = (image) => {
    setImages(old => [...old, {image: image, id: old.length + 1}])
  }

  const purgeImage = (id) => {
    return (e) => setImages(old => old.filter(i => i.id !== id))
  }

  const updateImages = (e) =>  {
    let files = e.target.files;
    let readFiles = Array.from(files).map((file) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        appendImage(e.target.result)
      }
    })
  }

  return(
    <React.Fragment>
      {images.length > 0 && <Typography variant="h6" sx={{marginBottom: "16px"}}>
        Слайды для добавления, сохраните блок чтобы прикрепить.
      </Typography>}
      {images.length > 0 && <ImageList cols={12} rowHeight={120} gap={2}>
        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
          <SortableContext items={images} strategy={rectSortingStrategy} removable>
            {images.map(image => <DraggableImage key={image.id} image={image} purgeImage={purgeImage(image.id)}/>)}
          </SortableContext>
        </DndContext>
      </ImageList>}
      <Button variant="contained" component="label">
        Добавить изображения
        <input accept=".png, .jpg, .jpeg" type="file" hidden multiple onChange={updateImages}/>
      </Button>
    </React.Fragment>
  )
}
export default NewImages
