import React from 'react'
import { TableCell, TableRow } from '@mui/material'

/**
 *
 *
 * @param {{ item: {
 *   user: string,
 *   user_id: number,
 *   ucls_total: number,
 *   ucl_ids: number[],
 *   ucls_filled: number,
 *   mean_score: number
 * }, level: number}} props
 */
export default function ChecklistMemberRow(props) {
  const { item, level } = props
	return (
		<TableRow style={{ backgroundColor: 'lightblue' }}>
			{/* Команда */}
			<TableCell style={{ ...styles.cell, paddingLeft: level * 16 }}>{item.user}</TableCell>
			{/* Администратор */}
			<TableCell style={styles.cell}></TableCell>
			{/* Кнопка */}
			<TableCell style={styles.cell}></TableCell>
			{/* Всего сотрудников */}
			<TableCell style={styles.cell}></TableCell>
			{/* Заполнено чек-листов */}
			<TableCell style={styles.cell} align='left'>
				{item.ucls_total}
			</TableCell>
			{/* Чек-листов на 1 сотрудника */}
			<TableCell style={styles.cell} align='left'>
				{item.ucls_total}
			</TableCell>
			{/* Процент */}
			<TableCell style={styles.cell} align='left'>
				{item.mean_percentage}
			</TableCell>
		</TableRow>
	)
}

const styles = {
	cell: {
		padding: 0,
		paddingTop: 15,
		paddingBottom: 15,
	},
}
