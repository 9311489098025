export { clearToken, getToken } from "./getToken";
export { default as Logger } from "./Logger";
export {
    default as Request,
    HTTPMethod,
} from "./Request";

export const wait = (duration) =>
    new Promise((resolve) => setTimeout(resolve, duration));

export const getBase64 = async (file) =>
    new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = ({ target }) =>
            target?.result && resolve(target.result);
    });
