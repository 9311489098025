import React from "react";
import IntegrationTokenForm from "./IntegrationTokenForm"
import { useParams } from "react-router-dom"
import { connect } from 'react-redux'
import { useEffect } from "react";
import { getIntegrationTokenRequest, updateIntegrationTokenRequest }
  from "../../actions/actionCreators/integrationTokensActions"

const IntegrationTokenEdit = ({ integrationToken, loadIntegrationToken, updateIntegrationToken, history, companies, requestStatus }) => {
  const params = useParams()
  useEffect(() => {
    loadIntegrationToken(params.id)
  }, [params.id]);

  if (integrationToken) {
    return(<IntegrationTokenForm key={`${integrationToken.id}-${integrationToken.updatedAt}-${requestStatus}`}
      integrationToken={integrationToken} submit={updateIntegrationToken} type={"edit"}
      history={history} companies={companies} requestStatus={requestStatus}></IntegrationTokenForm>)
  } else {
    return(<div></div>)
  }
}

const mapStateToProps = (state) => ({
  integrationToken: state.integrationTokensReducer.integrationToken,
  companies: state.profileReducer.companies,
  requestStatus: state.integrationTokensReducer.requestStatus
})

const mapDispatchToProps = (dispatch) => ({
  loadIntegrationToken: (id) => dispatch(getIntegrationTokenRequest(id)),
  updateIntegrationToken: (query) => dispatch(updateIntegrationTokenRequest(query)),
})

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationTokenEdit)
