import React from 'react'
import { Link } from "react-router-dom";
import { ButtonGroup, Button } from '@mui/material';

const Pagination = ({data, location}) => {
  const { nextPage, prevPage, currentPage, totalPages } = data
  const { pathname, search } = location
  const query = new URLSearchParams(search)
  const genUri = (page) => {
    query.set("page", page)
    return `${pathname}?${query.toString()}`
  }
  return (
        <ButtonGroup aria-label="text primary button group" >
          <Button component={Link} to={genUri(prevPage)} disabled={prevPage===null}>
            {"<"}
          </Button>
          { prevPage && prevPage !== 1 &&
            <Button component={Link} to={genUri(1)}>
              1
            </Button> }
          { totalPages > 4 && currentPage > 3 && <Button>...</Button> }
          { prevPage &&
            <Button component={Link} to={genUri(prevPage)}>
              {prevPage}
            </Button> }
          <Button variant="contained" color="primary">{currentPage}</Button>
          { nextPage &&
            <Button component={Link} to={genUri(nextPage)}>
              {nextPage}
            </Button> }
          { (currentPage < totalPages - 2) &&
            <Button>...</Button> }
          { (nextPage && nextPage !== totalPages) &&
            <Button component={Link} to={genUri(totalPages)}>
              {totalPages}
            </Button> }
          <Button component={Link} to={genUri(nextPage)} disabled={nextPage===null}>
            {">"}
          </Button>
        </ButtonGroup>
  )
}

export default Pagination
