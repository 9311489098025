import React from "react";
import AwardTypeForm from "./AwardTypeForm"
import { useParams } from "react-router-dom"
import { connect } from 'react-redux'
import { useEffect } from "react";
import { getAwardTypeRequest, updateAwardTypeRequest }
  from "../../actions/actionCreators/awardTypesActions"

const AwardTypeEdit = ({ awardType, loadAwardType, updateAwardType, history, companies }) => {
  const params = useParams()
  useEffect(() => {
    loadAwardType(params.id)
  }, [params.id]);

  if (awardType) {
    return(<AwardTypeForm key={`${awardType.id}-${awardType.updatedAt}`} awardType={awardType}
      submit={updateAwardType} type={"edit"} history={history} companies={companies}></AwardTypeForm>)
  } else {
    return(<div></div>)
  }
}

const mapStateToProps = (state) => ({
  awardType: state.awardTypesReducer.awardType,
  companies: state.profileReducer.companies
})

const mapDispatchToProps = (dispatch) => ({
  loadAwardType: (id) => dispatch(getAwardTypeRequest(id)),
  updateAwardType: (query) => dispatch(updateAwardTypeRequest(query)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AwardTypeEdit)
