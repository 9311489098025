import { TypeKeys } from '../actionTypes'

export const getMedalsRequest = () => ({ type: TypeKeys.GET_MEDALS_REQUEST })

export const getMedalsSuccess = (medals) => ({
  type: TypeKeys.GET_MEDALS_SUCCESS,
  medals
})

export const getMedalRequest = (id) => ({ type: TypeKeys.GET_MEDAL_REQUEST, id })

export const getMedalSuccess = (medal) => ({
  type: TypeKeys.GET_MEDAL_SUCCESS,
  medal
})

export const updateMedalRequest = (query) => ({ type: TypeKeys.UPDATE_MEDAL_REQUEST, query })

export const updateMedalSuccess = (medals) => ({
  type: TypeKeys.UPDATE_MEDAL_SUCCESS,
  medals
})
