import React, { useEffect, useState } from 'react'
import moment from 'moment'
import 'moment/locale/ru'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { makeStyles, useTheme, withStyles } from '@mui/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Table, TableBody, TableCell, TableContainer, TableHead, TextField,
  TableRow, TableFooter, IconButton, Avatar, List, ListItem, Breadcrumbs,
  ListItemText, ListItemAvatar, AppBar, Container, ButtonGroup,
  Paper, Grid, Typography, Hidden, Button, Collapse, Box, Chip, CircularProgress
  } from '@mui/material'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { Link } from 'react-router-dom'
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingBottom: theme.spacing(6),
    paddingTop: theme.spacing(4)
  },
  paper: {
    marginBottom: theme.spacing(6)
  },
  buttonGroup: {
    paddingTop: theme.spacing(2)
  },
  fab: {
    [theme.breakpoints.down('md')]: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  }
}));

function Row(props) {
  const { team, depth } = props;
  const [open, setOpen] = React.useState(false);

  const padding = makeStyles((theme) => ({
    teamPadding: { padding: theme.spacing(0), paddingLeft: theme.spacing(depth) },
    userPadding: { padding: theme.spacing(0), paddingLeft: theme.spacing(depth+2) },
    small: { width: theme.spacing(4), height: theme.spacing(4) },
    tableIcon: { paddingRight: theme.spacing(1), paddingTop: theme.spacing(1) },
    greyRow: { backgroundColor: theme.palette.action.hover }
  }))();

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <Link to={`/dashboard/${team.id}/team_summary`} className={padding.teamPadding}>{team.title}</Link>
        </TableCell>
        <TableCell>{team.admin}</TableCell>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        { team.children_size === 0 ?
          <TableCell colSpan={2} align="center">
            <Chip variant="outlined" color={team.data.admin_key_dates[0].decision ? "primary" : "secondary"} label={
              `Закрыто ${team.data.admin_key_dates[0].percent}% дней `+
              `(${team.data.admin_key_dates[0].closed} из ${team.data.admin_key_dates[0].total})`
            }/>
          </TableCell> :
          <React.Fragment>
            <TableCell align="right">{team.data?.admin_total}</TableCell>
            <TableCell align="right">{team.data?.admin_closed} {team.data?.admin_closed > 0 && `(${team.data?.admin_percent}%)`}</TableCell>
          </React.Fragment>
        }
        <TableCell align="right">{team.data.users_total}</TableCell>
        <TableCell align="right">{team.data.users_closed} {team.data.users_closed > 0 && `(${team.data.user_percent}%)`}</TableCell>
      </TableRow>
      {open &&
        <React.Fragment>
        { team.children.length > 0 ?
          team.children.map((team) => (
            <Row key={team.id} team={team} depth={depth+2}/>
          )) : team.data.user_key_dates.map((key_date, index) => (
          <TableRow key={`${team.id}-${index}`} sx={{ '& > *': { borderBottom: 'unset' } }}>
            <TableCell colSpan={3}>
              <span className={padding.userPadding}>{key_date.user || "Участник удалён"}</span>
            </TableCell>
            <TableCell colSpan={2}></TableCell>
            <TableCell colSpan={2} align="center">
              <Chip variant="outlined" color={key_date.decision ? "primary" : "secondary"}
                label={`Закрыто ${key_date.percent}% дней (${key_date.closed} из ${key_date.total})`}/>
            </TableCell>
          </TableRow>
        ))}
      </React.Fragment>}
    </React.Fragment>
  );
}

const ClosedDatesTable = (props) => {
  moment.locale('ru')
  const classes = useStyles()
  const theme = useTheme()
  const { loading, team } = props

  return (
    props.loading ? <CircularProgress/> :
    <Grid item xs={12}>
      <Table aria-label="collapsible table" size="small">
        <TableHead>
          <TableRow>
            <TableCell colSpan={3}></TableCell>
            <TableCell colSpan={2} align="center">Руководители отделений</TableCell>
            <TableCell colSpan={2} align="center">Сотрудники</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Название</TableCell>
            <TableCell>Администратор</TableCell>
            <TableCell/>
            <TableCell align="right">Всего</TableCell>
            <TableCell align="right">Закрывают день</TableCell>
            <TableCell align="right">Всего</TableCell>
            <TableCell align="right">Закрывают день</TableCell>
          </TableRow>
          <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
            <TableCell>Дивизион - <Link to={`/dashboard/${team.id}/team_summary`}>{team.title}</Link></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell align="right">{team.data.admin_total}</TableCell>
            <TableCell align="right">{team.data.admin_closed} {team.data.admin_closed > 0 && `(${team.data.admin_percent}%)`}</TableCell>
            <TableCell align="right">{team.data.users_total}</TableCell>
            <TableCell align="right">{team.data.users_closed} {team.data.users_closed > 0 && `(${team.data.user_percent}%)`}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {team.children.map((team) => (<Row key={team.id} team={team} depth={2}/>))}
        </TableBody>
      </Table>
    </Grid>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(ClosedDatesTable)
