import { takeLatest, put, call } from 'redux-saga/effects'
import { TypeKeys } from '../actions/actionTypes'
import { getCompaniesSuccess, getCompanySuccess, createCompanySuccess,  updateCompanySuccess }
  from '../actions/actionCreators/companiesActions'
import callApi from '../utils/callApi'
import config from '../config'

const mapCompany = (company) => {
  return {
    id: company.id,
    title: company.title,
    sizeLimit: company.size_limit,
    owner: company.owner ?
      {
        id: company.owner.id,
        firstName: company.owner.first_name,
        lastName: company.owner.last_name,
        avatar: company.owner.full_avatar_url,
        phone: company.owner.phone,
        blocked: company.owner.blocked
      } : null,
    medal: company.medal_url,
    avatar: company.avatar_url,
    createdAt: company.created_at,
    updatedAt: company.updated_at,
    enabledScreens: company.enabled_screens
  }
}

function* loadCompanies(action) {
  const res = yield call(callApi, `${config.BASE_URL}/admin/api/v1/companies`)
  yield put(getCompaniesSuccess(res.data.map(mapCompany)))
}

function* loadCompany(action) {
  const res = yield call(callApi, `${config.BASE_URL}/admin/api/v1/companies/${action.id}`)
  yield put(getCompanySuccess(mapCompany(res.data)))
}

function* createCompany(action) {
  const res = yield call(callApi, `${config.BASE_URL}/admin/api/v1/companies`, { method: 'POST', data: action.query.data })
  yield put(createCompanySuccess(res.data.map(mapCompany)))
}

function* updateCompany(action) {
  const res = yield call(callApi,
    `${config.BASE_URL}/admin/api/v1/companies/${action.query.companyId}`,
    { method: 'PUT', data: action.query.data })
  yield put(updateCompanySuccess(res.data.map(mapCompany)))
}

export const companiesSagas = [
  takeLatest([TypeKeys.GET_COMPANIES_REQUEST], loadCompanies),
  takeLatest([TypeKeys.GET_COMPANY_REQUEST], loadCompany),
  takeLatest([TypeKeys.CREATE_COMPANY_REQUEST], createCompany),
  takeLatest([TypeKeys.UPDATE_COMPANY_REQUEST], updateCompany),
]
