import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React, { memo, useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'

export default memo(function Modal({ children, onDismiss, isVisible, isDark }) {
	const el = useRef(document.createElement('div'))

	useEffect(() => {
		document.body.appendChild(el.current)
		return () => {
			document.body.removeChild(el.current)
		}
	}, [])

	const dismiss = e => {
		e.preventDefault()
		onDismiss && onDismiss()
	}

	return createPortal(
		isVisible && (
			<Overlay isDark={isDark} onClick={dismiss}>
				{children}
			</Overlay>
		),
		el.current
	)
})

const Overlay = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	top: 0;
	height: 100vh;
	width: 100vw;
	z-index: 2000;
	${({ isDark }) =>
		isDark &&
		css`
			background-color: rgb(0, 0, 0, 0.5);
		`}
`

// = ({ isDark, ...props }) => (
// 	<div

// 		style={{
// 			display: 'flex',
// 			alignItems: 'center',
// 			justifyContent: 'center',
// 			position: 'fixed',
// 			top: 0,
// 			height: '100vh',
// 			width: '100vw',
// 			backgroundColor: isDark && 'rgb(0, 0, 0, 0.5)',
// 			zIndex: 2000,
// 		}}
// 		{...props}
// 	/>
// )
