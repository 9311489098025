import React from "react";
import TaskTypeForm from "./TaskTypeForm"

import { connect } from 'react-redux'
import { createTaskTypeRequest } from "../../actions/actionCreators/taskTypesActions"

const TaskTypeNew = ({ createTaskType, companies, history }) => {
  const taskType = {
    name: "",
    company: {
      value: (companies.length > 0) ? companies[0].id : undefined,
      label: (companies.length > 0) ? companies[0].title : undefined
    },
    atts: [],
    readOnlyAtts: []
  }
  return(<TaskTypeForm key={+ new Date()} taskType={taskType} companies={companies}
    submit={createTaskType} type={"new"} history={history}></TaskTypeForm>)
}

const mapStateToProps = (state) => ({
  companies: state.profileReducer.companies
})

const mapDispatchToProps = (dispatch) => ({
  createTaskType: (query) => dispatch(createTaskTypeRequest(query)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TaskTypeNew)
