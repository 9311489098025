import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import 'moment/locale/ru'
import { downloadDashboard } from '../../../utils/downloadFile'
import { getDashboardPlanCompleteRequest } from '../../../actions/actionCreators/dashboardActions'
import { getPerformanceIndicatorsRequest } from '../../../actions/actionCreators/performanceIndicatorsActions'
import { getTeamRequest } from '../../../actions/actionCreators/teamsActions'
import { makeStyles, useTheme, withStyles } from '@mui/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import clsx from 'clsx'
import { ButtonGroup, Grid, Typography, Button, Select, FormControl,
  InputLabel, MenuItem } from '@mui/material'
import GetAppIcon from '@mui/icons-material/GetApp'
import DashboardLayout from '../DashboardLayout'
import PlanCompleteTable from "./Table"

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingBottom: theme.spacing(6),
    paddingTop: theme.spacing(4)
  },
  paper: {
    marginBottom: theme.spacing(6)
  },
  buttonGroup: {
    paddingTop: theme.spacing(2)
  },
  bottomPadding: {
    paddingBottom: theme.spacing(2)
  }
}));

const PlanCompleteDashboard = ({team, loading, loadPlanComplete, currentTeam, loadTeam,
    loadPerformanceIndicators, performanceIndicators, ...props}) => {
  moment.locale('ru')
  const params = useParams();

  const [kpiId, setKpiId] = useState('');
  const updateKpi = () => { return (e) => setKpiId(e.target.value) }

  const [dates, setDates] = React.useState(moment().date() > 2 ? {
    startDate: moment().startOf('month').format("yyyy-MM-DD"),
    endDate: moment().subtract(1, 'days').format("yyyy-MM-DD"),
    active: 'thisMonth'} : {
    startDate: moment().subtract(1, 'months').startOf('month').format("yyyy-MM-DD"),
    endDate: moment().subtract(1, 'months').endOf('month').format("yyyy-MM-DD"),
    active: 'lastMonth'});

  const handleChange = (dates) => {
    return () => setDates(dates);
  };
  useEffect(() => {
    loadPerformanceIndicators(params.teamId)
    loadTeam(params.teamId)
  }, [params.teamId]);

  useEffect(() => {
    if (kpiId !== '') {
      loadPlanComplete(params.teamId, kpiId, dates.startDate, dates.endDate)
    }
  }, [kpiId, dates]);

  const classes = useStyles()
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <DashboardLayout teamId={currentTeam.id} teamTitle={currentTeam.title} loading={loading}
      title={"Выполнение плана"}
      table={<PlanCompleteTable team={team} kpiId={kpiId} loading={loading}/>}
      header={
        <Grid item xs={12} sx={{marginBottom: "16px"}}>
          <Grid container>
            <Grid item xs={6}>
              <Typography component="span" variant="h4">
                Выполнение плана
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <FormControl className={[classes.formControl, classes.bottomPadding].join(' ')} size="small">
                <Select
                  labelId="kpiSelect"
                  id="kpiSelect-select"
                  value={kpiId}
                  onChange={updateKpi()}
                  displayEmpty
                >
                  <MenuItem value={''} disabled>Выберите KPI</MenuItem>
                { performanceIndicators.map((pi) =>
                    <MenuItem key={pi.id} value={pi.id}>{pi.title}</MenuItem>
                ) }
                </Select>
              </FormControl>
              <ButtonGroup color="primary" aria-label="outlined primary button group" sx={{marginLeft: "16px"}}>
                <Button onClick={downloadDashboard(currentTeam.id, dates.startDate, dates.endDate, null, 'plan_complete_total_xlsx')}
                  endIcon={<GetAppIcon/>}>Расширенный отчёт</Button>
              </ButtonGroup>
            </Grid>
            <Grid item xs={6}>
              <Typography component="span" variant="body1">
                Витрина отображает выполнение плана по выбранной задаче до уровня сотрудников отделения
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <ButtonGroup color="primary" aria-label="outlined primary button group" className={classes.bottomPadding}>
                {moment().date() > 2 && <Button variant={dates.active === 'thisMonth' ? 'contained' : 'outlined'} onClick={handleChange({
                    startDate: moment().startOf('month').format("yyyy-MM-DD"),
                    endDate: moment().subtract(1, 'days').format("yyyy-MM-DD"),
                    active: 'thisMonth'})}>Этот месяц</Button>}
                <Button variant={dates.active === 'lastMonth' ? 'contained' : 'outlined'} onClick={handleChange({
                    startDate: moment().subtract(1, 'months').startOf('month').format("yyyy-MM-DD"),
                    endDate: moment().subtract(1, 'months').endOf('month').format("yyyy-MM-DD"),
                    active: 'lastMonth'})}>Прошлый месяц</Button>
                <Button onClick={downloadDashboard(currentTeam.id, dates.startDate, dates.endDate, kpiId, 'plan_complete_xlsx')}
                  endIcon={<GetAppIcon/>} disabled={kpiId === ''}>Скачать</Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        </Grid>
      }
      />
  )
}

const mapStateToProps = (state) => ({
  team: state.dashboardReducer.team,
  loading: state.dashboardReducer.loading,
  currentTeam: state.teamReducer.team,
  performanceIndicators: state.performanceIndicatorsReducer.performanceIndicators,
})

const mapDispatchToProps = (dispatch) => ({
  loadPlanComplete: (id, kpiId, startDate, endDate) => dispatch(getDashboardPlanCompleteRequest(id, kpiId, startDate, endDate)),
  loadPerformanceIndicators: (id) => dispatch(getPerformanceIndicatorsRequest(id)),
  loadTeam: (id) => dispatch(getTeamRequest(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PlanCompleteDashboard)
