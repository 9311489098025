import { initialKpiChannels } from "../initial/initialKpiChannels.js"
import { TypeKeys } from "../actions/actionTypes"

export const kpiChannelsReducer = (state = initialKpiChannels(), action) => {
  switch (action.type) {
    case TypeKeys.GET_KPI_CHANNELS_REQUEST:
      return { ...state, kpiChannels: [], loading: true }
    case TypeKeys.GET_KPI_CHANNELS_SUCCESS:
      return { ...state, kpiChannels: action.kpiChannels, loading: false }
    case TypeKeys.GET_KPI_CHANNEL_REQUEST:
      return { ...state, kpiChannel: initialKpiChannels().kpiChannel, loading: true }
    case TypeKeys.GET_KPI_CHANNEL_SUCCESS:
      return { ...state, kpiChannel: action.kpiChannel, loading: false }
    case TypeKeys.CREATE_KPI_CHANNEL_REQUEST:
      return { ...state, kpiChannel: initialKpiChannels().kpiChannel, loading: true }
    case TypeKeys.CREATE_KPI_CHANNEL_SUCCESS:
      return { ...state, kpiChannel: action.kpiChannel, loading: false }
    case TypeKeys.UPDATE_KPI_CHANNEL_REQUEST:
      return { ...state, kpiChannel: initialKpiChannels().kpiChannel, loading: true }
    case TypeKeys.UPDATE_KPI_CHANNEL_SUCCESS:
      return { ...state, kpiChannel: action.kpiChannel, loading: false }
    default:
    return { ...state }
  }
}

export default kpiChannelsReducer
