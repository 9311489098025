import config from '../config'

export default class Logger {
	static colors = {
		error: '#F44336',
		success: '#4CAF50',
		attention: '#FDD835',
	}
	static isCanLog = process.env.NODE_ENV === 'development'

	static log(...args) {
		if (this.isCanLog) {
			console.log(...args)
		}
	}

	static error(...args) {
		if (this.isCanLog) {
			console.error(...args)
		}
	}

	static collapsedError(title, ...params) {
		if (this.isCanLog) {
			console.groupCollapsed(
				`%c error  %c${title}`,
				`color: ${this.colors.error}; font-weight: lighter;`,
				'color: white'
			)
			console.log(...params)
			console.groupEnd()
		}
	}

	static collapsedLog(title, ...params) {
		if (this.isCanLog) {
			console.groupCollapsed(`%c log    %c${title}`, `font-weight: lighter;`, 'color: white')
			console.log(...params)
			console.groupEnd()
		}
	}

	/**
	 * @static
	 * @param {string} title
	 * @param {"ERROR" | "SUCCESS" | "REQUEST"} status
	 * @param {any} data
	 * @memberof Logger
	 */
	static onRequest(title, status, data) {
		if (Logger.isCanLog) {
			console.groupCollapsed(
				'%c req   ' + `%c ${title}` + `%c ${status}`,
				'color: grey; font-weight: lighter;',
				'color: white;',
				`color: ${status === 'ERROR' ? '#F44336' : status === 'SUCCESS' ? '#4CAF50' : '#9575cd'}`
			)
			console.log('data', data)
			console.groupEnd()
		}
	}

	static curl(url, request) {
		let curl = `curl -X ${request.method} \\\n'${url}' `
		for (const header in request.headers) {
			curl += `\\\n -H '${header}: ${request.headers[header]}' `
		}
		if (request.body) {
			curl += `-d '${request.body}'`
		}
		if (Logger.isCanLog) {
			console.groupCollapsed(
				'%c API   ' +
					`%c ${request.method}` +
					`%c ${url.replace(config.BASE_URL + '/admin/api/v1/', '')}`,
				'color: #7986cb',
				'color: #white',
				'color: #ffecb3'
			)
			console.log(curl)
			console.groupEnd()
		}
		return curl
	}
}
