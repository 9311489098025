import React, { useRef } from "react";
import moment from "moment";
import "moment/locale/ru";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles, useTheme, withStyles } from "@mui/styles";
import InfoIcon from "@mui/icons-material/Info";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TextField,
  TableRow,
  TableFooter,
  IconButton,
  Avatar,
  List,
  ListItem,
  Breadcrumbs,
  ListItemText,
  ListItemAvatar,
  AppBar,
  Container,
  ButtonGroup,
  Paper,
  Grid,
  Typography,
  Hidden,
  Button,
  Collapse,
  Box,
  Chip,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import {Link} from "react-router-dom";

const TrapezoidCell = (props) => {
  const [width, setWidth] = useState(0);
  const ref = useRef(null);

  useEffect(() => {
    setWidth(ref.current.clientWidth);
  }, []);

  return (
    <TableCell sx={{ padding: 0, margin: 0 }}>
      <div style={{ paddingTop: 20, paddingBottom: 20 }} ref={ref}>
        <p style={{ position: "absolute", color: "white" }}>{props.number}</p>
        <div
          style={{
            width: "100%",
            height: props.height,
            borderLeft: `${width}px solid #2564B9`,
            borderTop: "5px solid transparent",
            borderBottom: "5px solid transparent",
          }}
        />

        {/*</div>*/}
      </div>
    </TableCell>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingBottom: theme.spacing(6),
    paddingTop: theme.spacing(4),
  },
  paper: {
    marginBottom: theme.spacing(6),
  },
  buttonGroup: {
    paddingTop: theme.spacing(2),
  },
  fab: {
    [theme.breakpoints.down("md")]: {
      position: "fixed",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  },
}));

const calculateStandardsColumn = (showCaseData) => {
  const sum =
    showCaseData?.data?.members_plans.count +
    showCaseData?.data?.results.count +
    showCaseData?.data?.social_activity.count +
    showCaseData?.data?.admin_close_day.count +
    showCaseData?.data?.tasks_activity.count;

  const result = ((sum / 5) / (showCaseData?.data.leaves_count || 1)) * 100;
  return Math.round(result)
};

const getStandardsColor = (number) => {
  if (number >= 70) {
    return '#59AB26'
  }

  if (number <= 30) {
    return '#D13535'
  }

  return 'black'
}

function Row(props) {
  const { team, depth } = props;
  const [open, setOpen] = React.useState(false);
  const standards = calculateStandardsColumn(team)

  const padding = makeStyles((theme) => ({
    teamPadding: { padding: theme.spacing(0), paddingLeft: theme.spacing(depth) },
  }))();


  return (
    <React.Fragment>
      <TableRow
        sx={{
          "& > *": {
            borderBottom: "unset",
            border: "1px solid rgba(224, 224, 224, 1)",
            maxWidth: 150,
          },
        }}
      >
        <TableCell sx={{ padding: 0, borderRightWidth: 0 }}>
          {team.children?.length ? (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          ) : null}
        </TableCell>
        <TableCell align="left">
          <Link className={padding.teamPadding} to={`/dashboard/${team.id}/team_summary`}>{team.title}</Link>
        </TableCell>
        <TableCell sx={{}} align="center">
          {team.data.leaves_count || "-"}
        </TableCell>
        <TableCell align="center">
          {team.data?.members_plans.count || "-"}
        </TableCell>
        <TableCell align="center">{team?.data.results.count || "-"}</TableCell>
        <TableCell align="center">
          {team?.data.social_activity.count || "-"}
        </TableCell>
        <TableCell align="center">
          {team?.data.admin_close_day.count || "-"}
        </TableCell>
        <TableCell align="center">
          {team?.data.tasks_activity.count || "-"}
        </TableCell>
        <TableCell align="center" sx={{color: getStandardsColor(standards)}}>{standards}%</TableCell>
      </TableRow>
      {open && (
        <React.Fragment>
          {team.children?.length > 0 &&
            team.children.map((team) => (
              <Row key={team.id} team={team} depth={depth + 2} />
            ))}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

const ShowCaseTable = (props) => {
  moment.locale("ru");
  const classes = useStyles();
  const theme = useTheme();
  const { loading, team, showcaseData } = props;
  const totalStandards = calculateStandardsColumn(showcaseData)


  return props.loading ? (
    <CircularProgress />
  ) : (
    <Grid item xs={12}>
      <Table aria-label="collapsible table" size="small">
        <TableHead>
          <TableRow
            sx={{
              "& > *": {
                borderBottom: "unset",
                border: "1px solid rgba(224, 224, 224, 1)",
              },
            }}
          >
            <TableCell align="center"></TableCell>
            <TableCell align="center"></TableCell>
            <TableCell align="center">Офисов</TableCell>
            <TableCell align="center">
              <div style={{ display: "flex", justifyContent: "center" }}>
                <p>Планы</p>
                <Tooltip top right title={"Выставлены планы"}>
                  <InfoIcon sx={{ color: "#2564B9" }} />
                </Tooltip>
              </div>
            </TableCell>
            <TableCell align="center">
              <div style={{ display: "flex", justifyContent: "center" }}>
                <p>Итоги</p>
                <Tooltip top right title={"+ Сотрудники направляют результаты"}>
                  <InfoIcon sx={{ color: "#2564B9" }} />
                </Tooltip>
              </div>
            </TableCell>
            <TableCell align="center">
              <div style={{ display: "flex", justifyContent: "center" }}>
                <p>Обсуждения</p>
                <Tooltip
                  top
                  right
                  title={"+ Команда оценивает\n" + "и обсуждает итоги"}
                >
                  <InfoIcon sx={{ color: "#2564B9" }} />
                </Tooltip>
              </div>
            </TableCell>
            <TableCell align="center">
              <div style={{ display: "flex", justifyContent: "center" }}>
                <p>Руководитель</p>
                <Tooltip
                  top
                  right
                  title={"+ Руководитель направляет результат"}
                >
                  <InfoIcon sx={{ color: "#2564B9" }} />
                </Tooltip>
              </div>
            </TableCell>
            <TableCell align="center">
              <div style={{ display: "flex", justifyContent: "center" }}>
                <p>Задачи</p>
                <Tooltip
                  top
                  right
                  title={"+ Сотрудникам выставлены индивидаульные задачи"}
                >
                  <InfoIcon sx={{ color: "#2564B9" }} />
                </Tooltip>
              </div>
            </TableCell>
            <TableCell align="center">Стандарты</TableCell>
          </TableRow>
          <TableRow
            sx={{
              "& > *": {
                borderBottom: "unset",
                border: "1px solid rgba(224, 224, 224, 1)",
                paddingTop: 4,
                paddingBottom: 4,
              },
            }}
          >
            <TableCell align="center"></TableCell>
            <TableCell align="center">Итого</TableCell>
            <TableCell align="center">
              {showcaseData.data.leaves_count}
            </TableCell>
            <TableCell align="center">
              {showcaseData.data?.members_plans.count}
            </TableCell>
            <TableCell align="center">
              {showcaseData.data.results.count}
            </TableCell>
            <TableCell align="center">
              {showcaseData.data.social_activity.count}
            </TableCell>
            <TableCell align="center">
              {showcaseData.data.admin_close_day.count}
            </TableCell>
            <TableCell align="center">
              {showcaseData.data.tasks_activity.count}
            </TableCell>
            <TableCell sx={{color: getStandardsColor(totalStandards)}} align="center">
              {totalStandards}%
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {showcaseData.children.map((team) => (
            <Row key={team.id} team={team} depth={2} />
          ))}
        </TableBody>
      </Table>
    </Grid>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ShowCaseTable);
