import React from "react";
import { useEffect, useState } from "react";
import { connect } from 'react-redux'
import { getUserIndicatorsRequest } from "../../actions/actionCreators/userIndicatorsActions"
import { saveTeamUserIndicatorsRequest } from "../../actions/actionCreators/teamUserIndicatorsActions"
import { makeStyles } from '@mui/styles';
import { Table, TableBody, TableCell, TableHead, TableRow, FormControlLabel, Checkbox } from '@mui/material';

const useStyles = makeStyles((theme) => ({}));

const UserIndicators = (props) => {
  const classes = useStyles();

  const { userId, userIndicators, updatedAt } = props

  useEffect(() => {
    userId && props.loadUserIndicators(userId)
  }, [userId, updatedAt]);

  const saveIndicators = (teamId, performanceIndicatorId, indicator, controlled) =>  {
    return (e) => {
      const userIndicator = {...indicator, controlled: e.target.checked}
      props.saveTeamUserIndicators(teamId, performanceIndicatorId, [userIndicator])
    }
  }

  return (
    <Table size="small" className={classes.table} aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell align="left">Команда</TableCell>
          <TableCell align="left">KPI</TableCell>
          <TableCell align="left">Месячный план</TableCell>
          <TableCell align="left">Дневной план</TableCell>
          <TableCell align="left">На контроле</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        { userIndicators.map((userIndicator) => {
          const { id, monthTarget, plannedTarget, controlled, team, performanceIndicator } = userIndicator
          return(
            <TableRow key={id}>
              <TableCell component="th" scope="row">{ team.title }</TableCell>
              <TableCell align="left">{ performanceIndicator.title }</TableCell>
              <TableCell align="left">{ monthTarget }</TableCell>
              <TableCell align="left">{ plannedTarget }</TableCell>
              <TableCell align="left">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={controlled}
                      name="long"
                      color="primary"
                      onChange={saveIndicators(team.id, performanceIndicator.id, userIndicator, controlled)}
                      // disabled={kpi.teamId !== team.id}
                    />
                  }
                />
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}

const mapStateToProps = (state) => ({
  userIndicators: state.userIndicatorsReducer.userIndicators,
  updatedAt: state.teamUserIndicatorsReducer.updatedAt
})

const mapDispatchToProps = (dispatch) => ({
  loadUserIndicators: (id) => dispatch(getUserIndicatorsRequest(id)),
  saveTeamUserIndicators: (teamId, performanceIndicatorId, teamUserIndicators) =>
    dispatch(saveTeamUserIndicatorsRequest(teamId, performanceIndicatorId, teamUserIndicators)),
})

export default connect(mapStateToProps, mapDispatchToProps)(UserIndicators)
