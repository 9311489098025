import { TypeKeys } from '../actionTypes'

export const getEducationCategoriesRequest = () => ({ type: TypeKeys.GET_EDUCATION_CATEGORIES_REQUEST })

export const getEducationCategoriesSuccess = (educationCategories) => ({
  type: TypeKeys.GET_EDUCATION_CATEGORIES_SUCCESS,
  educationCategories
})

export const getEducationCategoryRequest = (id) => ({ type: TypeKeys.GET_EDUCATION_CATEGORY_REQUEST, id })

export const getEducationCategorySuccess = (educationCategory) => ({
  type: TypeKeys.GET_EDUCATION_CATEGORY_SUCCESS,
  educationCategory
})

export const createEducationCategoryRequest = (query) => ({ type: TypeKeys.CREATE_EDUCATION_CATEGORY_REQUEST, query })

export const createEducationCategorySuccess = (educationCategory) => ({
  type: TypeKeys.CREATE_EDUCATION_CATEGORY_SUCCESS,
  educationCategory
})

export const updateEducationCategoryRequest = (query) => ({ type: TypeKeys.UPDATE_EDUCATION_CATEGORY_REQUEST, query })

export const updateEducationCategorySuccess = (educationCategory) => ({
  type: TypeKeys.UPDATE_EDUCATION_CATEGORY_SUCCESS,
  educationCategory
})
