import React from "react";
import IntegrationTokenForm from "./IntegrationTokenForm"

import { connect } from 'react-redux'
import { createIntegrationTokenRequest } from "../../actions/actionCreators/integrationTokensActions"

const IntegrationTokenNew = ({ createIntegrationToken, companies, history, requestStatus }) => {
  const integrationToken = {
    company: {
      value: (companies.length > 0) ? companies[0].id : undefined,
      label: (companies.length > 0) ? companies[0].title : undefined
    },
    enabled: true
  }
  return(<IntegrationTokenForm key={+ new Date()} integrationToken={integrationToken} companies={companies}
    submit={createIntegrationToken} type={"new"} history={history} requestStatus={requestStatus}></IntegrationTokenForm>)
}

const mapStateToProps = (state) => ({
  companies: state.profileReducer.companies,
  requestStatus: state.integrationTokensReducer.requestStatus
})

const mapDispatchToProps = (dispatch) => ({
  createIntegrationToken: (query) => dispatch(createIntegrationTokenRequest(query)),
})

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationTokenNew)
