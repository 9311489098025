import { callSignInApi, HTTPMethod } from '../utils/callApi'
import { TypeKeys } from '../actions/actionTypes'
import { signInSuccess, signInFail } from '../actions/actionCreators'
import { takeLatest, put, call } from 'redux-saga/effects'
import config from '../config'
import { history } from '../reducers'

function* signIn(action) {
  const data = {
    grant_type: 'password',
    phone: action.phoneNumber,
    password: action.password,
    client_id: config.CLIENT_ID
  }
  const method = HTTPMethod.POST
  const res = yield call(callSignInApi,
    ` ${config.BASE_URL}/oauth/token`,
    { data, method }
  )

  if (res.status === 200) {
    localStorage.setItem('token', res.data.access_token)
    localStorage.setItem('refreshToken', res.data.refresh_token)
    localStorage.setItem('expiresIn', res.data.expires_in)
    localStorage.setItem('createdAt', res.data.created_at)
    localStorage.setItem('currentUser', JSON.stringify(res.data.user))
    window.location.href = '/'
  } else {
    yield put(signInFail())
  }
}


export const authSagas = [
  takeLatest([TypeKeys.SIGN_IN_REQUEST], signIn)
]
