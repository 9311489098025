import { takeLatest, put, call } from 'redux-saga/effects'
import { TypeKeys } from '../actions/actionTypes'
import { getAwardTypesSuccess, getAwardTypeSuccess, createAwardTypeSuccess,
  updatAwardTypeSuccess, getAwardHistorySuccess }
  from '../actions/actionCreators/awardTypesActions'
import callApi from '../utils/callApi'
import config from '../config'

const mapAwardType = (awardType) => {
  return {
    id: awardType.id,
    description: awardType.description,
    weight: awardType.weight,
    company: {
      value: awardType.company.id,
      label: awardType.company.title
    },
    createdAt: awardType.created_at,
    updatedAt: awardType.updated_at,
    archived: awardType.archived
  }
}

const mapAwardHistory = (awardHistory) => {
  return {
    id: awardHistory.id,
    event: awardHistory.event,
    admin: {
      id: awardHistory.user.id,
      firstName: awardHistory.user.first_name,
      lastName: awardHistory.user.last_name,
      avatar: awardHistory.user.full_avatar_url,
      blocked: awardHistory.user.blocked
    },
    company: awardHistory.company,
    award: {
      id: awardHistory.snapshot.id,
      createdAt: awardHistory.snapshot.created_at,
      awardType: awardHistory.snapshot.award_type,
      medal: awardHistory.snapshot.medal,
      user: {
        id: awardHistory.snapshot.user.id,
        firstName: awardHistory.snapshot.user.first_name,
        lastName: awardHistory.snapshot.user.last_name,
        avatar: awardHistory.snapshot.user.full_avatar_url,
        blocked: awardHistory.snapshot.user.blocked
      }
    },
    createdAt: awardHistory.created_at,
  }
}

function* loadAwardTypes(action) {
  const res = yield call(callApi, `${config.BASE_URL}/admin/api/v1/award_types`)
  yield put(getAwardTypesSuccess(res.data.map(mapAwardType)))
}

function* loadAwardType(action) {
  const res = yield call(callApi,
    `${config.BASE_URL}/admin/api/v1/award_types/${action.id}`)
  yield put(getAwardTypeSuccess(mapAwardType(res.data)))
}

function* createAwardType(action) {
  const res = yield call(callApi,
    `${config.BASE_URL}/admin/api/v1/award_types`,
    { method: 'POST', data: action.query.data })
  yield put(createAwardTypeSuccess(res.data))
}

function* updateAwardType(action) {
  const res = yield call(callApi,
    `${config.BASE_URL}/admin/api/v1/award_types/${action.query.awardTypeId}`,
    { method: 'PUT', data: action.query.data })
  yield put(updatAwardTypeSuccess(res.data))
}

function* loadAwardHistory(action) {
  const res = yield call(callApi, `${config.BASE_URL}/admin/api/v1/award_types/history?${action.query}`)
  const { pagination, history } = res.data
  const historyData = history.map(mapAwardHistory)
  const paginationData = {
    currentPage: pagination.current_page,
    totalPages: pagination.total_pages,
    nextPage: pagination.next_page,
    prevPage: pagination.prev_page,
    totalCount: pagination.total_count
  }
  yield put(getAwardHistorySuccess(historyData, paginationData))
}

export const awardTypesSagas = [
  takeLatest([TypeKeys.GET_AWARD_TYPES_REQUEST], loadAwardTypes),
  takeLatest([TypeKeys.GET_AWARD_TYPE_REQUEST], loadAwardType),
  takeLatest([TypeKeys.CREATE_AWARD_TYPE_REQUEST], createAwardType),
  takeLatest([TypeKeys.UPDATE_AWARD_TYPE_REQUEST], updateAwardType),
  takeLatest([TypeKeys.GET_AWARD_HISTORY_REQUEST], loadAwardHistory),
]
