import React from "react";
import TaskForm from "./TaskForm"
import { useParams } from "react-router-dom"
import { connect } from 'react-redux'
import { useEffect } from "react";
import { getTaskRequest, updateTaskRequest } from "../../actions/actionCreators/tasksActions"
import { getPerformanceIndicatorsRequest } from "../../actions/actionCreators/performanceIndicatorsActions"
import CircularProgress from '@mui/material/CircularProgress'

const TaskEditPanel = ({ task, loading, loadTask, updateTask, history, companies, kpis, kpisLoading, loadKpis }) => {
  const params = useParams()
  useEffect(() => {
    loadTask(params.id)
  }, [params.id]);

  return(
    loading ?
    <CircularProgress/> :
    <TaskForm task={task} companies={companies} submit={updateTask} type={"edit"} history={history} kpis={kpis} kpisLoading={kpisLoading}
      loadKpis={loadKpis} key={`${task.id}-${task.updatedAt}`}>
    </TaskForm>)
}

const mapStateToProps = (state) => ({
  task: state.tasksReducer.task,
  loading: state.tasksReducer.loading,
  companies: state.profileReducer.companies,
  kpis: state.performanceIndicatorsReducer.performanceIndicators,
  kpisLoading: state.performanceIndicatorsReducer.loading
})

const mapDispatchToProps = (dispatch) => ({
  loadTask: (id) => dispatch(getTaskRequest(id)),
  updateTask: (query) => dispatch(updateTaskRequest(query)),
  loadKpis: (id) => dispatch(getPerformanceIndicatorsRequest(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TaskEditPanel)
