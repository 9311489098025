import { initialCalendars } from "../initial/initialCalendars"
import { TypeKeys } from "../actions/actionTypes"

export const calendarsReducer = (state = initialCalendars(), action) => {
  switch (action.type) {
    case TypeKeys.GET_CALENDAR_REQUEST:
      return { ...state, calendar: [], loading: true }
    case TypeKeys.GET_CALENDAR_SUCCESS:
      return { ...state, calendar: action.calendar, loading: false }
    case TypeKeys.UPDATE_CALENDAR_REQUEST:
      return { ...state, calendar: [], loading: true }
    case TypeKeys.UPDATE_CALENDAR_SUCCESS:
      return { ...state, calendar: action.calendar, loading: false }
    default:
    return { ...state }
  }
}

export default calendarsReducer
