import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import UserForm from './UserForm'
import { getKpiChannelsRequest } from '../../actions/actionCreators/kpiChannelsActions'
import { getUserRequest, updateUserRequest } from '../../actions/actionCreators/usersActions'

export default function UserEdit() {
	const params = useParams()
	const editUser = useEditUser()

	useEffect(() => {
		editUser.loadKpiChannels()
		editUser.loadUser(params.id)
	}, [params.id])

	if (!editUser.user) return null

	return (
		<UserForm
			isShowDelete={editUser.profile?.id == params?.id}
			type='edit'
			user={editUser.user}
			submit={editUser.updateUser}
			companies={editUser.companies}
			requestStatus={editUser.requestStatus}
			loading={editUser.loading}
			kpiChannels={editUser.kpiChannels}
			errors={editUser.errors}
		/>
	)
}

function useEditUser() {
	const dispatch = useDispatch()

	const { user, loading, errors, requestStatus } = useSelector(({ usersReducer }) => usersReducer)

	const companies = useSelector(({ profileReducer }) => profileReducer.companies)
	const profile = useSelector(({ profileReducer }) => profileReducer.user)
	const kpiChannels = useSelector(({ kpiChannelsReducer }) => kpiChannelsReducer.kpiChannels)

	const loadUser = id => dispatch(getUserRequest(id))
	const updateUser = query => dispatch(updateUserRequest(query))
	const loadKpiChannels = () => dispatch(getKpiChannelsRequest())

	return {
		user,
		profile,
		loading,
		errors,
		requestStatus,
		companies,
		kpiChannels,
		loadUser,
		updateUser,
		loadKpiChannels,
	}
}
