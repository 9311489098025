import React from 'react';
import { Link } from "react-router-dom";
import { Chip, Avatar } from '@mui/material'
import { makeStyles } from '@mui/styles';
import GroupIcon from '@mui/icons-material/Group';

const useStyles = makeStyles((theme) => ({
  teamChip: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    '&:hover': { cursor: "pointer" },
  }
}));

const TeamChip = ({ team }) => {

  const classes = useStyles()

  return(
    <Chip key={`teams-${team.id}`} variant="outlined"
      label={team.title} className={classes.teamChip}
      component={Link} to={`/teams/${team.id}`}
      color={team.archived ? "secondary" : "default"}
      avatar={
        <Avatar src={team.teamAvatarUrl}>
          <GroupIcon/>
        </Avatar>
      } />
  )
}

export default TeamChip
