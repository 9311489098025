import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'

import clsx from 'clsx';

import MomentUtils from '@date-io/moment'
import moment from 'moment'

import { makeStyles, useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Paper, Grid, Typography, Toolbar, Container, Hidden,
  Fab, TextField, InputAdornment, Button, Box, Chip, Avatar,
  FormControlLabel, Checkbox, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, TableFooter, CircularProgress,
  Divider } from '@mui/material'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Add, Search } from '@mui/icons-material'

import { getTeamsRequest } from "../../actions/actionCreators/teamsActions"
import Pagination from '../../components/Main/Pagination'
import TeamChip from "../../components/Main/TeamChip"
import UserBlock from "../../components/Main/UserBlock"

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2)
  },
  buttonGroup: {
    paddingTop: theme.spacing(2)
  },
  fab: {
    [theme.breakpoints.down('md')]: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  },
  teamChip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    '&:hover': { cursor: "pointer" },
  },
  avatarFont: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    letterSpacing: "-1px",
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      fontSize: "0.8rem"
    },
  },
  ignoreVisited: {
    color: theme.palette.common.black,
    textDecoration: "none"
  },
  noWrap: {
    whiteSpace: "nowrap"
  }
}));

const TeamsPanel = (props) => {
  const classes = useStyles()
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))

  const { teams, pagination, history, role, loading } = props
  const [collapsed, setCollapsed] = useState(false)
  const navigate = useNavigate();
  const location = useLocation();

  const query = new URLSearchParams(location.search)

  const [teamId, setTeamId] = useState(query.get("team_id") || "")
  const [createdAtAfter, setCreatedAtAfter] = useState(query.get("created_at_after") || null)
  const [createdAtBefore, setCreatedAtBefore] = useState(query.get("created_at_before") || null)
  const [parentTeam, setParentTeam] = useState(query.get("parent_team") || "")
  const [userTitle, setUserTitle] = useState(query.get("user_title") || "")
  const [title, setTitle] = useState(query.get("title") || "")
  const [archived, setArchived] = useState(query.get("archived") || false)

  useEffect(() => {
    props.loadTeams(query)
  }, [query.toString()]);

  const toggleCollapse = () => setCollapsed(!collapsed)
  const toggleArchived = (e) => setArchived(e.target.checked)
  const changeTeamId = (e) => setTeamId(e.target.value)
  const changeCreatedAtAfter = (date) => setCreatedAtAfter(date)
  const changeCreatedAtBefore = (date) => setCreatedAtBefore(date)
  const changeParentTeam = (e) => setParentTeam(e.target.value)
  const changeUserTitle = (e) => setUserTitle(e.target.value)
  const changeTitle = (e) => setTitle(e.target.value)

  const genSearchUri = () => {
    const newQuery = new URLSearchParams(location.search)
    newQuery.set("page", 1)
    archived ? newQuery.set("archived", archived) : newQuery.delete("archived")
    teamId ? newQuery.set("team_id", teamId) : newQuery.delete("team_id")
    createdAtAfter ? newQuery.set("created_at_after", createdAtAfter.format("YYYY-MM-DD")) : newQuery.delete("created_at_after")
    createdAtBefore ? newQuery.set("created_at_before", createdAtBefore.format("YYYY-MM-DD")) : newQuery.delete("created_at_before")
    parentTeam ? newQuery.set("parent_team", parentTeam) : newQuery.delete("parent_team")
    userTitle ? newQuery.set("user_title", userTitle) : newQuery.delete("user_title")
    title ? newQuery.set("title", title) : newQuery.delete("title")
    return `${location.pathname}?${newQuery.toString()}`
  }

  const performSearch = (key) => {
    if (key.key === "Enter") {
      navigate(genSearchUri())
    }
  }

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Grid container spacing={2} >
            <Grid item xs={12}>
              <Typography variant="h4" sx={{marginBottom:"16px"}}>
                Команды&nbsp;
                { false && <Link to={`/teams/new`}>
                  <Fab color="primary" aria-label="add" size={mobile ? "" : "small"} className={classes.fab}>
                    <Add />
                  </Fab>
                </Link> }
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} >
            <Grid item sm={9} xs={12}>
              <TextField label="Название" fullWidth variant="outlined" value={title}
                onChange={changeTitle} onKeyPress={performSearch} size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Search/>
                    </InputAdornment>
                  ),
                }}/>
            </Grid>
            <Grid item sm={3} xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={archived}
                  onChange={toggleArchived}
                  color="primary"
                />} label="Искать в архиве"/>
            </Grid>
            <Grid item sm={3} xs={6}>
              <TextField label="ID" fullWidth variant="outlined" value={teamId}
                onChange={changeTeamId} onKeyPress={performSearch} size="small"/>
            </Grid>
            <Grid item sm={3} xs={6}>
              <TextField label={mobile ? "Родитель" : "Родительская команда"} fullWidth variant="outlined" value={parentTeam}
                onChange={changeParentTeam} onKeyPress={performSearch} size="small"/>
            </Grid>
            <Grid item sm={3} xs={12}>
              <TextField label="Администратор" fullWidth variant="outlined" value={userTitle}
                onChange={changeUserTitle} onKeyPress={performSearch} size="small"/>
            </Grid>
            <Grid item sm={3}>
              <Button fullWidth variant="contained" color="primary" className="SearchButton"
                onClick={() => navigate(genSearchUri())}>
                Искать
              </Button>
            </Grid>
          </Grid>
          <Divider sx={{marginTop:"32px", marginBottom:"32px"}} />
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell align="left">Название</TableCell>
                <Hidden xsDown>
                  <TableCell align="left">Администратор</TableCell>
                </Hidden>
                <Hidden mdDown>
                  <TableCell align="left">Родительская</TableCell>
                  <TableCell align="left">Участники</TableCell>
                  { role === "super_admin" &&
                    <TableCell align="left">Компания</TableCell>}
                  <TableCell align="left">Создана</TableCell>
                </Hidden>
              </TableRow>
            </TableHead>
            <TableBody>
              { loading &&
                <TableRow>
                  <TableCell colSpan={5} align="center"><CircularProgress/></TableCell>
                </TableRow> }
              { teams.map((team) => {
                const { id, createdAt, title, parentTeam, admin, adminId,
                  company, membersCount, teamAvatarUrl, parentId } = team
                return(
                  <TableRow key={id}>
                    <TableCell>
                      <Box component={Link} to={`/teams/${id}`} className={classes.ignoreVisited}>
                        <Typography
                          component="span"
                          variant="body1">
                          {id}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="left">
                      <Box component={Link} to={`/teams/${id}`} className={classes.ignoreVisited}>
                        <Typography
                          component="span"
                          variant="body1">
                          {title}
                        </Typography>
                      </Box>
                    </TableCell>
                    <Hidden xsDown>
                      <TableCell>
                        { admin &&
                           <UserBlock user={admin}/> }
                      </TableCell>
                    </Hidden>
                    <Hidden mdDown>
                      <TableCell align="left">
                        { parentTeam && <TeamChip team={parentTeam}/>}
                      </TableCell>
                      <TableCell align="left">{membersCount}</TableCell>
                      { role === "super_admin" &&
                        <TableCell align="left">{company.title}</TableCell>}
                      <TableCell align="left">{moment(createdAt).format('DD.MM.YYYY')}</TableCell>
                    </Hidden>
                  </TableRow>
                )}
              )}
            </TableBody>
          </Table>
          <Box className={classes.buttonGroup}>
            { pagination.totalPages > 1 && <Pagination data={pagination} location={location}/>}
          </Box>
        </Paper>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = (state) => ({
  teams: state.teamsReducer.teams,
  pagination: state.teamsReducer.pagination,
  role: state.profileReducer.role,
  loading: state.teamsReducer.loading
})

const mapDispatchToProps = (dispatch) => ({
  loadTeams: (query) => dispatch(getTeamsRequest(query))
})

export default connect(mapStateToProps, mapDispatchToProps)(TeamsPanel)
