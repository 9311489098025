import React from "react";
import { useEffect } from "react";
import { connect } from 'react-redux'
import { getUserStoriesRequest } from "../../actions/actionCreators/userStoriesActions"

import { makeStyles } from '@mui/styles';
import { Typography, Divider, CircularProgress, Grid, Paper } from '@mui/material';

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
  root: {  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  paperSpacing: {
    padding: theme.spacing(1)
  }
}));

const Stories = (props) => {
  const classes = useStyles();

  const { userId, userStories, loading } = props

  useEffect(() => {
    userId && props.loadUserStories(userId)
  }, [userId]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
      {loading ?
        <CircularProgress/> :
        <Timeline align="right">
          { userStories.map((userStory) => {
              return(
                userStory.stories.length > 0 &&
                <TimelineItem>
                  <TimelineOppositeContent>
                    <Paper className={classes.paperSpacing}>
                      {userStory.stories.map((s, index) =>
                        <div>
                          <Typography variant="body1" color="textSecondary">
                            {s}
                          </Typography>
                          {userStory.stories.length - 1 !== index && <Divider/>}
                        </div>
                      )}
                    </Paper>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Typography variant="h5">
                      {userStory.team}
                    </Typography>
                    <Typography variant="h6">
                      {moment(userStory.date).format("dddd, D MMM YYYY")}
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
              )
            }
          )}
        </Timeline>
      }
    </Grid>
  </Grid>
  )
}

const mapStateToProps = (state) => ({
  userStories: state.userStoriesReducer.userStories,
  loading: state.userStoriesReducer.loading
})

const mapDispatchToProps = (dispatch) => ({
  loadUserStories: (id) => dispatch(getUserStoriesRequest(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(Stories)
