import React from "react"
import { useState, useEffect } from "react"
import {v4 as uuidv4} from 'uuid'
import { Grid, Typography, TextField, Button, ButtonGroup, Collapse } from '@mui/material';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles'
import { MoveUp, MoveDown, Delete } from '@mui/icons-material';
import Question from './Question'

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const Block = ({ block, updateBlock, classes, offset, removeBlock,
  moveBlockUp, moveBlockDown, canMoveUp, canMoveDown }) => {
  const [activeBlock, setBlock] = useState({})
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded)
  };

  useEffect(() => {
    setBlock(block)
  }, [block]);

  useEffect(() => {
    setExpanded(block.title === "")
  }, [block.uid]);

  const handleChange = (e) => { return setBlock({...activeBlock, title: e.target.value}) }
  const handleUpdate = (e) => { return updateBlock(activeBlock) }

  const addBlock = () => {
    updateBlock({...block, blocks: [...block.blocks,
      { uuid: uuidv4(), title: "", blocks: [], block_items: [] }
    ]})
  }

  const removeChildBlock = (uuid) => {
    return () => {
      if (window.confirm("Вы уверены что хотите удалить раздел? Эта операция необратима.")) {
        updateBlock({...block,
          blocks: block.blocks.filter((item) => item.uuid !== uuid)
        })
      }
    }
  }

  const moveChildBlockUp = (index) => {
    return () => {
      updateBlock({...activeBlock,
        blocks:[
          ...(activeBlock.blocks.slice(0, index-1)),
          activeBlock.blocks[index],
          activeBlock.blocks[index-1],
          ...(activeBlock.blocks.slice(index+1))
        ]
      })
    }
  }

  const moveChildBlockDown = (index) => {
    return () => {
      updateBlock({...activeBlock,
        blocks:[
          ...(activeBlock.blocks.slice(0, index)),
          activeBlock.blocks[index+1],
          activeBlock.blocks[index],
          ...(activeBlock.blocks.slice(index+2))
        ]
      })
    }
  }

  const addBlockItem = () => {
    updateBlock({...block, block_items: [
      ...block.block_items,
      { uuid: uuidv4(), title: "", comment: "", options: [], item_type: "select" }
    ]})
  }

  const removeBlockItem = (uuid) => {
    return () => {
      if (window.confirm("Вы уверены что хотите удалить вопрос? Эта операция необратима.")) {
        updateBlock({...block,
          block_items: block.block_items.filter((item) => item.uuid !== uuid)
        })
      }
    }
  }

  const updateBlockItem = (updatedItem) => {
    updateBlock({...activeBlock,
      block_items: activeBlock.block_items.map((item) => {
        return item.uuid === updatedItem.uuid ? updatedItem : item
      })
    })
  }

  const moveItemUp = (index) => {
    return () => {
      updateBlock({...activeBlock,
        block_items:[
          ...(activeBlock.block_items.slice(0, index-1)),
          activeBlock.block_items[index],
          activeBlock.block_items[index-1],
          ...(activeBlock.block_items.slice(index+1))
        ]
      })
    }
  }

  const moveItemDown = (index) => {
    return () => {
      updateBlock({...activeBlock,
        block_items:[
          ...(activeBlock.block_items.slice(0, index)),
          activeBlock.block_items[index+1],
          activeBlock.block_items[index],
          ...(activeBlock.block_items.slice(index+2))
        ]
      })
    }
  }

  return(
    <Grid container spacing={2} key={block.uuid}
      sx={{marginLeft: `${offset > 0 ? '16px' : '0px'}`, marginTop: `16px`, marginRight: "16px",
        borderLeft: `3px solid #1565c0`}}>
      <Grid item xs={12} sx={{paddingTop: '0px', paddingRight: `${offset > 0 ? '0px' : '16px'}`}}>
        <Typography variant="h6" sx={{marginBottom: "16px", marginTop: "-16px"}}>
          {`Раздел: ${activeBlock.title}`}
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </Typography>
        <ButtonGroup sx={{marginBottom: `16px`}}>
          <Button variant="text" onClick={removeBlock(activeBlock.uuid)}><Delete/></Button>
          <Button variant="text" onClick={moveBlockUp} disabled={canMoveUp}><MoveUp/></Button>
          <Button variant="text" onClick={moveBlockDown} disabled={canMoveDown}><MoveDown/></Button>
        </ButtonGroup>
        <TextField required multiline label="Название раздела" value={activeBlock.title}
          variant="outlined" fullWidth sx={{marginBottom: "16px"}}
          onBlur={handleUpdate} onChange={handleChange} />
      </Grid>
      <Collapse in={expanded} timeout="auto" unmountOnExit sx={{width:"100%"}}>
        <Grid item xs={11}>
        { Array.isArray(activeBlock.blocks) && activeBlock.blocks.map(
          (subBlock, index) => <Block key={subBlock.uuid} block={subBlock}
            updateBlock={updateBlock} offset={offset+1}
            removeBlock={removeChildBlock} moveBlockUp={moveChildBlockUp(index)}
            moveBlockDown={moveChildBlockDown(index)} canMoveUp={index === 0}
            canMoveDown={index === activeBlock.blocks.length - 1}/>
        )}
        { Array.isArray(activeBlock.block_items) && activeBlock.block_items.map(
          (question, index) => <Question question={question}
            updateBlockItem={updateBlockItem} removeBlockItem={removeBlockItem}
            moveItemUp={moveItemUp(index)} moveItemDown={moveItemDown(index)}
            canMoveUp={index === 0} canMoveDown={index === activeBlock.block_items.length - 1}/>
        )}
        <ButtonGroup>
          { (!Array.isArray(activeBlock.block_items) || activeBlock.block_items.length === 0) &&
            <Button variant="outlined" onClick={addBlock} sx={{marginTop: "16px", marginLeft: "16px"}}>Добавить раздел</Button> }
          { (!Array.isArray(activeBlock.blocks) || activeBlock.blocks.length === 0) &&
            <Button variant="outlined" onClick={addBlockItem} sx={{marginTop: "16px", marginLeft: "16px"}}>Добавить вопрос</Button> }
        </ButtonGroup>
        </Grid>
      </Collapse>
    </Grid>
  )
}

export default Block
