import React from 'react';
import { Link } from "react-router-dom";
import { Chip, Avatar } from '@mui/material'
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  teamChip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    '&:hover': { cursor: "pointer" },
  }
}));

const UserChip = ({ user, disabled }) => {

  const classes = useStyles()

  return(
    <Chip key={user.id} disabled={disabled} variant="outlined" label={`${user.firstName} ${user.lastName}`}
      className={classes.teamChip} component={Link} to={`/users/${user.id}`}
      color={user.blocked ? "secondary" : "primary"}
      avatar={
        <Avatar src={user.avatar} />
      } />
  )
}

export default UserChip
