import React from "react";
import KpiChannelForm from "./KpiChannelForm"

import { connect } from 'react-redux'
import { createKpiChannelRequest } from "../../actions/actionCreators/kpiChannelsActions"

const KpiChannelNew = ({ createKpiChannel, companies, history }) => {
  const kpiChannel = {
    title: "",
    company: {
      value: (companies.length > 0) ? companies[0].id : undefined,
      label: (companies.length > 0) ? companies[0].title : undefined
    }
  }
  return(<KpiChannelForm key={+ new Date()} kpiChannel={kpiChannel} companies={companies}
    submit={createKpiChannel} type={"new"} history={history}></KpiChannelForm>)
}

const mapStateToProps = (state) => ({
  companies: state.profileReducer.companies
})

const mapDispatchToProps = (dispatch) => ({
  createKpiChannel: (query) => dispatch(createKpiChannelRequest(query)),
})

export default connect(mapStateToProps, mapDispatchToProps)(KpiChannelNew)
