import { takeLatest, put, call } from 'redux-saga/effects'
import { TypeKeys } from '../actions/actionTypes'
import { getEducationTestsSuccess, getEducationTestSuccess,
  createEducationTestSuccess, updateEducationTestSuccess }
  from '../actions/actionCreators/educationTestsActions'
import callApi from '../utils/callApi'
import config from '../config'

const mapEducationTest = (educationTest) => {
  return {
    id: educationTest.id,
    description: educationTest.description,
    answerOptions: educationTest.answer_options,
    position: educationTest.image_order,
    createdAt: educationTest.created_at,
    updatedAt: educationTest.updated_at,
    education: {
      id: educationTest.education.id,
      title: educationTest.education.title,
      educationCategory: {
          id: educationTest.education.education_category.id,
          title: educationTest.education.education_category.title,
      }
    }
  }
}

function* loadEducationTests(action) {
  const res = yield call(callApi,
    `${config.BASE_URL}/admin/api/v1/education_categories/${action.educationCategoryId}/educations/${action.educationId}/education_tests`)
  yield put(getEducationTestsSuccess(res.data.map(mapEducationTest)))
}

function* loadEducationTest(action) {
  const res = yield call(callApi,
    `${config.BASE_URL}/admin/api/v1/education_categories/${action.educationCategoryId}/educations/${action.educationId}/education_tests/${action.id}`)
  yield put(getEducationTestSuccess(mapEducationTest(res.data)))
}

function* createEducationTest(action) {
  const res = yield call(callApi,
    `${config.BASE_URL}/admin/api/v1/education_categories/${action.query.educationCategoryId}/educations/${action.query.educationId}/education_tests`,
    { method: 'POST', data: action.query.data })
  yield put(createEducationTestSuccess(mapEducationTest(res.data)))
}

function* updateEducationTest(action) {
  const res = yield call(callApi,
    `${config.BASE_URL}/admin/api/v1/education_categories/${action.query.educationCategoryId}/educations/${action.query.educationId}/education_tests/${action.query.educationTestId}`,
    { method: 'PUT', data: action.query.data })
  yield put(updateEducationTestSuccess(mapEducationTest(res.data)))
}

export const educationTestsSagas = [
  takeLatest([TypeKeys.GET_EDUCATION_TESTS_REQUEST], loadEducationTests),
  takeLatest([TypeKeys.GET_EDUCATION_TEST_REQUEST], loadEducationTest),
  takeLatest([TypeKeys.CREATE_EDUCATION_TEST_REQUEST], createEducationTest),
  takeLatest([TypeKeys.UPDATE_EDUCATION_TEST_REQUEST], updateEducationTest),
]
