import { takeLatest, put, call } from 'redux-saga/effects'
import { TypeKeys } from '../actions/actionTypes'
import { getEducationBlocksSuccess, getEducationBlockSuccess,
  createEducationBlockSuccess, updateEducationBlockSuccess }
  from '../actions/actionCreators/educationBlocksActions'
import callApi from '../utils/callApi'
import config from '../config'

const mapEducationBlock = (educationBlock) => {
  return {
    id: educationBlock.id,
    title: educationBlock.title,
    description: educationBlock.description,
    images: educationBlock.images,
    imageOrder: educationBlock.image_order,
    createdAt: educationBlock.created_at,
    updatedAt: educationBlock.updated_at,
    position: educationBlock.position,
    education: {
      id: educationBlock.education.id,
      title: educationBlock.education.title,
      educationCategory: {
          id: educationBlock.education.education_category.id,
          title: educationBlock.education.education_category.title,
      }
    }
  }
}

function* loadEducationBlocks(action) {
  const res = yield call(callApi,
    `${config.BASE_URL}/admin/api/v1/education_categories/${action.educationCategoryId}/educations/${action.educationId}/education_blocks`)
  yield put(getEducationBlocksSuccess(res.data.map(mapEducationBlock)))
}

function* loadEducationBlock(action) {
  const res = yield call(callApi,
    `${config.BASE_URL}/admin/api/v1/education_categories/${action.educationCategoryId}/educations/${action.educationId}/education_blocks/${action.id}`)
  yield put(getEducationBlockSuccess(mapEducationBlock(res.data)))
}

function* createEducationBlock(action) {
  const res = yield call(callApi,
    `${config.BASE_URL}/admin/api/v1/education_categories/${action.query.educationCategoryId}/educations/${action.query.educationId}/education_blocks`,
    { method: 'POST', data: action.query.data })
  yield put(createEducationBlockSuccess(mapEducationBlock(res.data)))
}

function* updateEducationBlock(action) {
  const res = yield call(callApi,
    `${config.BASE_URL}/admin/api/v1/education_categories/${action.query.educationCategoryId}/educations/${action.query.educationId}/education_blocks/${action.query.educationBlockId}`,
    { method: 'PUT', data: action.query.data })
  yield put(updateEducationBlockSuccess(mapEducationBlock(res.data)))
}

export const educationBlocksSagas = [
  takeLatest([TypeKeys.GET_EDUCATION_BLOCKS_REQUEST], loadEducationBlocks),
  takeLatest([TypeKeys.GET_EDUCATION_BLOCK_REQUEST], loadEducationBlock),
  takeLatest([TypeKeys.CREATE_EDUCATION_BLOCK_REQUEST], createEducationBlock),
  takeLatest([TypeKeys.UPDATE_EDUCATION_BLOCK_REQUEST], updateEducationBlock),
]
