import { initialTeam } from "../initial/initialTeam.js"
import { TypeKeys } from "../actions/actionTypes"

export const teamReducer = (state = initialTeam(), action) => {
  switch (action.type) {
    case TypeKeys.GET_TEAM_REQUEST:
      return { ...state, team: initialTeam().team, loading: true }
    case TypeKeys.GET_TEAM_SUCCESS:
      return { ...state, team: action.team, loading: false }
    case TypeKeys.GET_TEAM_STATS_REQUEST:
      return { ...state, teamStats: initialTeam().teamStats, statsLoading: true }
    case TypeKeys.GET_TEAM_STATS_SUCCESS:
      return { ...state, teamStats: action.team, statsLoading: false }
    case TypeKeys.UPDATE_TEAM_REQUEST:
      return { ...state }
    case TypeKeys.UPDATE_TEAM_SUCCESS:
      return { ...state, team: action.team, loading: false }
    default:
    return { ...state }
  }
}

export default teamReducer
