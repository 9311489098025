import { history } from '../reducers'

export const getToken = () => {
  const { createdAt, expiresIn, refreshToken } = localStorage
  const isExpired = ((Number(createdAt) + Number(expiresIn)) * 1000) < new Date().getTime()
  const token = localStorage.getItem('token')
  if (isExpired || !token) {
    return window.location.href = '/login'
  }
  return token
}

export const clearToken = () => {
  localStorage.clear();
  return window.location.href = '/login';
}
