export const initialUserPositions = () => {
  return {
    userPositions: [],
    userPosition: {
      id: undefined,
      approved: false,
      company: {}
    },
    loading: true
  }
}
