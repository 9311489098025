export const initialEducationBlocks = () => {
  return {
    educationBlocks: [],
    educationBlock: {
      id: undefined,
      images: [],
      education: {
        educationCategory: {}
      }
    },
    loading: true
  }
}
