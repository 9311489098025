import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { getEducationsRequest } from "../../actions/actionCreators/educationsActions"

import { FormControl, InputLabel, Select, MenuItem, CircularProgress, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
const useStyles = makeStyles((theme) => ({}));

const EducationSelect = ({ companies, educationsList, loading, loadEducations,
    changeEducation, value, label, allowEmpty, categoryId }) => {
  const classes = useStyles()
  const changeSelectedEducation = (e, value) => changeEducation({value: value.props.value, label: value.props.label})
  const educations = educationsList.map((ed) => ({
    value: ed.id,
    label: `${ed.title}`
  }))

  useEffect(() => {
    categoryId !== "" && loadEducations(categoryId)
  }, [categoryId]);

  if (categoryId === "") {
    return (<Typography>Выберите категорию</Typography>)
  }

  if (loading) {
    return (<CircularProgress />)
  }

  if (educations.length === 0) {
    return (<Typography>Обучения не найдены</Typography>)
  }

  return (
    <FormControl fullWidth variant="outlined" size="small" className={classes.formControl}>
      <InputLabel>{label ? label : "Обучение"}</InputLabel>
      <Select fullWidth value={value} onChange={changeSelectedEducation} label={label ? label : "Обучение"}
        error={value === null || value === ''}>
      { educations.map((option) =>
        <MenuItem key={option.value} value={option.value} label={option.label}>
          {option.label}
        </MenuItem>) }
      </Select>
    </FormControl>
  )
}
const mapStateToProps = (state) => ({
  companies: state.profileReducer.companies,
  educationsList: state.educationsReducer.educations,
  loading: state.educationsReducer.loading
})

const mapDispatchToProps = (dispatch) => ({
  loadEducations: (categoryId) => dispatch(getEducationsRequest(categoryId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(EducationSelect)
