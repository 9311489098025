import React from "react";
import MedalForm from "./MedalForm"
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { useEffect } from "react";
import { getMedalRequest, updateMedalRequest }
  from "../../actions/actionCreators/medalsActions"

const MedalEdit = ({ medal, loadMedal, updateMedal }) => {
  const params = useParams()
  useEffect(() => {
    loadMedal(params.id)
  }, [params.id]);

  if (medal) {
    return(<MedalForm key={`${medal.id}-${medal.updatedAt}`} medal={medal}
      submit={updateMedal} type={"edit"}></MedalForm>)
  } else {
    return(<div></div>)
  }
}

const mapStateToProps = (state) => ({
  medal: state.medalsReducer.medal
})

const mapDispatchToProps = (dispatch) => ({
  loadMedal: (id) => dispatch(getMedalRequest(id)),
  updateMedal: (query) => dispatch(updateMedalRequest(query)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MedalEdit)
