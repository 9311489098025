
import { TypeKeys } from '../actionTypes'

export const getEducationTestsRequest = (educationCategoryId, educationId) => ({
  type: TypeKeys.GET_EDUCATION_TESTS_REQUEST, educationCategoryId, educationId
})

export const getEducationTestsSuccess = (educationTests) => ({
  type: TypeKeys.GET_EDUCATION_TESTS_SUCCESS,
  educationTests
})

export const getEducationTestRequest = (educationCategoryId, educationId, id) => ({
  type: TypeKeys.GET_EDUCATION_TEST_REQUEST, educationCategoryId, educationId, id
})

export const getEducationTestSuccess = (educationTest) => ({
  type: TypeKeys.GET_EDUCATION_TEST_SUCCESS,
  educationTest
})

export const createEducationTestRequest = (query) => ({ type: TypeKeys.CREATE_EDUCATION_TEST_REQUEST, query })

export const createEducationTestSuccess = (educationTest) => ({
  type: TypeKeys.CREATE_EDUCATION_TEST_SUCCESS,
  educationTest
})

export const updateEducationTestRequest = (query) => ({ type: TypeKeys.UPDATE_EDUCATION_TEST_REQUEST, query })

export const updateEducationTestSuccess = (educationTest) => ({
  type: TypeKeys.UPDATE_EDUCATION_TEST_SUCCESS,
  educationTest
})
