import React from "react";
import { useEffect } from "react";
import { connect } from 'react-redux'
import { getUserResultsRequest } from "../../actions/actionCreators/userResultsActions"
import { makeStyles } from '@mui/styles';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
  root: {},
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  smallCell: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  }
}));

const UserResults = (props) => {
  const classes = useStyles();

  const { userId, userResults } = props

  useEffect(() => {
    userId && props.loadUserResults(userId)
  }, [userId]);

  return (
    <Table size="small" className={classes.table} aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell align="left" className={classes.smallCell}>Команда</TableCell>
          <TableCell align="left" className={classes.smallCell}>KPI</TableCell>
          <TableCell align="left" className={classes.smallCell}>План день</TableCell>
          <TableCell align="left" className={classes.smallCell}>Результат</TableCell>
          <TableCell align="left" className={classes.smallCell}>Дата</TableCell>
          <TableCell align="left" className={classes.smallCell}>Создан</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        { userResults.map((userResult) => {
          const { id, plan, value, date, team, performanceIndicator, createdAt } = userResult
          return(
            <TableRow key={id}>
              <TableCell component="th" scope="row" className={classes.smallCell}>{ team.title }</TableCell>
              <TableCell align="left" className={classes.smallCell}>{ performanceIndicator && performanceIndicator.title }</TableCell>
              <TableCell align="left" className={classes.smallCell}>{ plan }</TableCell>
              <TableCell align="left" className={classes.smallCell}>{ value }</TableCell>
              <TableCell align="left" className={classes.smallCell}>{ date && moment(date).format('DD.MM.YYYY') }</TableCell>
              <TableCell align="left" className={classes.smallCell}>
                { createdAt && moment.utc(createdAt, "YYYY-MM-DDTHH:mm:ss.SSSSZ").local().format('DD.MM.YYYY HH:mm:ss') }
              </TableCell>
            </TableRow>
          )
          }
        )}
      </TableBody>
    </Table>
  )
}

const mapStateToProps = (state) => ({
  userResults: state.userResultsReducer.userResults
})

const mapDispatchToProps = (dispatch) => ({
  loadUserResults: (id) => dispatch(getUserResultsRequest(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(UserResults)
