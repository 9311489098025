import { initialEducationBlocks } from "../initial/initialEducationBlocks"
import { TypeKeys } from "../actions/actionTypes"

export const educationBlocksReducer = (state = initialEducationBlocks(), action) => {
  switch (action.type) {
    case TypeKeys.GET_EDUCATION_BLOCKS_REQUEST:
      return { ...state, educationBlocks: initialEducationBlocks().educationBlocks,
        loading: true, requestStatus: '' }
    case TypeKeys.GET_EDUCATION_BLOCKS_SUCCESS:
      return { ...state, educationBlocks: action.educationBlocks,
        loading: false, requestStatus: '' }
    case TypeKeys.GET_EDUCATION_BLOCK_REQUEST:
      return { ...state, educationBlock: initialEducationBlocks().educationBlock,
        loading: true, requestStatus: '' }
    case TypeKeys.GET_EDUCATION_BLOCK_SUCCESS:
      return { ...state, educationBlock: action.educationBlock,
        loading: false, requestStatus: '' }
    case TypeKeys.CREATE_EDUCATION_BLOCK_REQUEST:
      return { ...state, educationBlock: initialEducationBlocks().educationBlock,
        loading: true, requestStatus: '' }
    case TypeKeys.CREATE_EDUCATION_BLOCK_SUCCESS:
      return { ...state, educationBlock: action.educationBlock,
        loading: false, requestStatus: 'success' }
    case TypeKeys.UPDATE_EDUCATION_BLOCK_REQUEST:
      return { ...state, educationBlock: initialEducationBlocks().educationBlock,
        loading: true, requestStatus: '' }
    case TypeKeys.UPDATE_EDUCATION_BLOCK_SUCCESS:
      return { ...state, educationBlock: action.educationBlock,
        loading: false, requestStatus: 'success' }
    default:
    return { ...state }
  }
}

export default educationBlocksReducer
