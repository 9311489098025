import React, { useState, useEffect } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import moment from 'moment'
import { connect } from 'react-redux'
import { getTeamUserIndicatorsRequest,
  updateTeamUserIndicatorRequest,
  saveTeamUserIndicatorsRequest } from "../../actions/actionCreators/teamUserIndicatorsActions"
import { makeStyles, useTheme } from '@mui/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Typography, Container, Grid, Paper, Button, TextField } from '@mui/material'
import { Table, TableBody, TableCell, TableContainer, TableHead,
  TableRow, TableFooter, FormControlLabel, Checkbox } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  editButton: {
    marginRight: theme.spacing(2)
  }
}));

const TeamUserIndicatorsPanel = (props) => {
  const { teamId, performanceIndicatorId } = useParams()
  const { userIndicators, loadTeamUserIndicators, performanceIndicator,
    updateTeamUserIndicator, saveTeamUserIndicators } = props

  const classes = useStyles()
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))
  const navigate = useNavigate();
  useEffect(() => {
    loadTeamUserIndicators(teamId, performanceIndicatorId)
  }, []);

  const updatePlan = (userIndicator) => (e) => {
    const target = isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value)
    updateTeamUserIndicator({...userIndicator, monthTarget: target})
  }
  const updateDayPlan = (userIndicator) => (e) => {
    const target = isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value)
    updateTeamUserIndicator({...userIndicator, plannedTarget: target})
  }
  const updateControlled = (userIndicator) => (e) => {
    updateTeamUserIndicator({...userIndicator, controlled: e.target.checked})
  }
  const saveIndicators = () =>  {
    saveTeamUserIndicators(teamId, performanceIndicatorId, userIndicators)
    navigate(`/teams/${teamId}/kpi`);
  }
  const { id, title, team } = performanceIndicator

  return (
    <Grid container spacing={2} className={classes.root} >
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h4">
                {`${team} - ${title}`}
              </Typography>
            </Grid>
            <Grid item xs={12} align="right">
              <Table size="small" className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Id</TableCell>
                    <TableCell align="left">ФИО</TableCell>
                    <TableCell align="left">Команда</TableCell>
                    <TableCell align="left">Сегодняшний план</TableCell>
                    <TableCell align="left">Месячный план</TableCell>
                    <TableCell align="left">На контроле</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  { userIndicators.map((userIndicator) => {
                    const { id, monthTarget, plannedTarget, user, controlled, team, createdTeam } = userIndicator
                    return(
                      <TableRow key={id}>
                        <TableCell component="th" scope="row">{id}</TableCell>
                        <TableCell align="left">
                            { `${user.lastName} ${user.firstName} ` }
                        </TableCell>
                        <TableCell align="left">{createdTeam.title}</TableCell>
                        <TableCell align="left">
                          <TextField variant="outlined" size="small" value={parseInt(plannedTarget)}
                            onChange={updateDayPlan(userIndicator)} />
                        </TableCell>
                        <TableCell align="left">
                          <TextField variant="outlined" size="small"
                            value={monthTarget} onChange={updatePlan(userIndicator)} />
                        </TableCell>
                        <TableCell align="left">
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={controlled}
                                name="long"
                                color="primary"
                                onChange={updateControlled(userIndicator)}
                              />
                            }
                          />
                        </TableCell>
                      </TableRow>
                    )
                  })}
                  <TableRow key={id}>
                    <TableCell component="th" scope="row" colspan={3}>
                    </TableCell>
                    <TableCell align="left">
                      <Button className={classes.editButton} variant="outlined" component={Link} to={`/teams/${teamId}/kpi`}>
                        Назад
                      </Button>
                      <Button variant="contained" color="primary" onClick={saveIndicators}>Сохранить</Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}
const mapStateToProps = (state) => ({
  userIndicators: state.teamUserIndicatorsReducer.userIndicators,
  performanceIndicator: state.teamUserIndicatorsReducer.performanceIndicator,
})

const mapDispatchToProps = (dispatch) => ({
  loadTeamUserIndicators: (teamId, performanceIndicatorId) =>
    dispatch(getTeamUserIndicatorsRequest(teamId, performanceIndicatorId)),
  updateTeamUserIndicator: (userIndicator) =>
    dispatch(updateTeamUserIndicatorRequest(userIndicator)),
  saveTeamUserIndicators: (teamId, performanceIndicatorId, teamUserIndicators) =>
    dispatch(saveTeamUserIndicatorsRequest(teamId, performanceIndicatorId, teamUserIndicators)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TeamUserIndicatorsPanel);
