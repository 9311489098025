export const initialIntegrationTokens = () => {
  return {
    integrationTokens: [],
    integrationToken: {
      id: undefined,
      enabled: true,
      token: "",
      company: {}
    },
    loading: true,
    events: [],
    requestStatus: ''
  }
}
