import React, { useState, useEffect } from "react"
import { connect } from 'react-redux'
import { makeStyles, useTheme } from '@mui/styles'
import TeamSelect from "../../components/Main/TeamSelect"
import EducationCategorySelect from "../../components/Main/EducationCategorySelect"
import EducationSelect from "../../components/Main/EducationSelect"
import UserPositionsCheckbox from "../../components/Main/UserPositionsCheckbox"

import { assignUserEducationsRequest } from "../../actions/actionCreators/userEducationsActions"

import { Table, TableBody, TableCell, TableHead, TableRow, Container,
  Paper, Grid, Typography, Hidden, Divider, Button, Alert, AlertTitle, Snackbar } from '@mui/material'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(4)
  },
  divider: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2)
  },
  buttonGroup: {
    paddingTop: theme.spacing(2)
  },
  fab: {
    [theme.breakpoints.down('md')]: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  },
}));

const MassAssign = (props) => {
  const companies = props.companiesList.map((c) => c.id)

  const [values, setValues] = useState({
    team: {value: "", companyId: ""},
    category: {value: ""},
    education: {value: ""},
    userPositions: []
  })

  const changeSelectedTeam = (t) => setValues((_) => ({
    team: t, category: {value: ""}, education: {value: ""},
    userPositions: []}))

  const changeSelectedEducationCategory = (t) => setValues((oldState) => ({...oldState, category: t, education: {value: ""}}))
  const changeSelectedEducation = (t) => setValues((oldState) => ({...oldState, education: t}))
  const changeSelectedUserPositions = (t) => setValues((oldState) => ({...oldState, userPositions: t}))

  const classes = useStyles();
  const theme = useTheme();

  const assign = () => {
    props.assignUserEducations({
      team_id: values.team.value,
      education_id: values.education.value,
      user_positions: values.userPositions
        .filter((p) => p.selected)
        .map((p) => p.value)
    })
  }

  const [open, setOpen] = React.useState(false)
  const handleClose = () => { setOpen(false) }
  useEffect(() => {
    (props.status === 'success' || props.status === 'error') && setOpen(true)
  }, [props.status]);

  return (
    <Grid container spacing={4} className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Grid item xs={12}>
            <Typography variant="h4">
              Назначить обучение
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.divider}>
            <Divider/>
          </Grid>
          <Grid item xs={12} sx={{mt: theme.spacing(1)}}>
            <TeamSelect key={companies} companyIds={companies} changeTeam={changeSelectedTeam} value={values.team.value}/>
          </Grid>
          <Grid item xs={12} sx={{mt: "16px"}}>
            <EducationCategorySelect companyId={values.team.companyId}
              changeEducationCategory={changeSelectedEducationCategory} value={values.category.value}/>
          </Grid>
          <Grid item xs={12} sx={{mt: "16px"}}>
            <EducationSelect categoryId={values.category.value}
              changeEducation={changeSelectedEducation} value={values.education.value}/>
          </Grid>
          <Grid item xs={12} sx={{mt: "16px"}}>
            <UserPositionsCheckbox companyId={values.team.companyId}
              changeUserPositions={changeSelectedUserPositions}/>
          </Grid>
          <Grid item xs={12} className={classes.divider}>
            <Divider/>
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" size="small" color="primary" onClick={assign}
              disabled={values.userPositions.filter((p) => p.selected).length === 0 || values.education.value === ""}>
              Назначить
            </Button>
          </Grid>
        </Paper>
      </Grid>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert severity={props.code.length === 0 ? "error" : "success"} onClose={handleClose}>
          <AlertTitle>{props.code.length === 0 ? "Ошибка" : "Успешно"}</AlertTitle>
          {props.status === 'success' && (props.code.length === 0 ? "Не найдено пользователей по указанным параметрам!" :
            `Назначено обучение для ${props.code.length} ${props.code.length === 1 ? "пользователя" : "пользователей"}!`)}
          {props.status === 'error' && 'При назначении обучения произошла ошибка, свяжитесь с технической поддержкой'}
        </Alert>
      </Snackbar>
    </Grid>
  )
}

const mapStateToProps = (state) => ({
  companiesList: state.profileReducer.companies,
  status: state.userEducationsReducer.status,
  code: state.userEducationsReducer.code,
})

const mapDispatchToProps = (dispatch) => ({
  assignUserEducations: (query) => dispatch(assignUserEducationsRequest(query))
})

export default connect(mapStateToProps, mapDispatchToProps)(MassAssign)
