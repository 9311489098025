import { initialUserIndicators } from "../initial/initialUserIndicators.js"
import { TypeKeys } from "../actions/actionTypes"

export const userIndicatorsReducer = (state = initialUserIndicators(), action) => {
  switch (action.type) {
    case TypeKeys.GET_USER_INDICATORS_REQUEST:
      return { ...state, userIndicators: [], loading: true }
    case TypeKeys.GET_USER_INDICATOR_SUCCESS:
      return { ...state, userIndicators: action.userIndicators, loading: false }
    default:
    return { ...state }
  }
}

export default userIndicatorsReducer
