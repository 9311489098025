import { TypeKeys } from '../actionTypes'

export const signInRequest = (credentials) => ({
  type: TypeKeys.SIGN_IN_REQUEST,
  phoneNumber: credentials.phoneNumber,
  password: credentials.password
})

export const signInSuccess = () => ({ type: TypeKeys.SIGN_IN_SUCCESS })

export const signInFail = () => ({ type: TypeKeys.SIGN_IN_FAIL })

