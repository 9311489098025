import { initialProfile } from "../initial/initialProfile"
import { TypeKeys } from "../actions/actionTypes"

export const profileReducer = (state = initialProfile(), action) => {
  switch (action.type) {
    case TypeKeys.GET_PROFILE_REQUEST:
      return { ...state, loading: true }
    case TypeKeys.GET_PROFILE_SUCCESS:
      return { ...state, companies: action.profile.companies,
        role: action.profile.role, user: action.profile.user, loading: false }
    case TypeKeys.SIGN_IN_REQUEST:
      return { ...state, signInResult: true }
    case TypeKeys.SIGN_IN_FAIL:
      return { ...state, signInResult: false }
    case TypeKeys.SIGN_IN_SUCCESS:
      return { ...state, signInResult: true }
    default:
    return { ...state }
  }
}

export default profileReducer
