import { TypeKeys } from '../actionTypes'

export const getDivisionsRequest = () => ({ type: TypeKeys.GET_DIVISIONS_REQUEST })

export const getDivisionsSuccess = (divisions) => ({
  type: TypeKeys.GET_DIVISIONS_SUCCESS,
  divisions
})

export const getDivisionRequest = (id) => ({ type: TypeKeys.GET_DIVISION_REQUEST, id })

export const getDivisionSuccess = (division) => ({
  type: TypeKeys.GET_DIVISION_SUCCESS,
  division
})

export const createDivisionRequest = (query) => ({ type: TypeKeys.CREATE_DIVISION_REQUEST, query })

export const createDivisionSuccess = (division) => ({
  type: TypeKeys.CREATE_DIVISION_SUCCESS,
  division
})

export const updateDivisionRequest = (query) => ({ type: TypeKeys.UPDATE_DIVISION_REQUEST, query })

export const updateDivisionSuccess = (division) => ({
  type: TypeKeys.UPDATE_DIVISION_SUCCESS,
  division
})
