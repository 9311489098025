import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import moment from 'moment'
import 'moment/locale/ru'
import { downloadDashboard } from '../../../utils/downloadFile'
import { getDashboardPlansUpdatedRequest } from "../../../actions/actionCreators/dashboardActions";
import { makeStyles, useTheme, withStyles } from '@mui/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import clsx from 'clsx'
import { ButtonGroup, Grid, Typography, Button, Select, FormControl,
  InputLabel, MenuItem } from '@mui/material'
import GetAppIcon from '@mui/icons-material/GetApp'
import DashboardLayout from '../DashboardLayout'
import PlansUpdatedTable from "./Table"

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingBottom: theme.spacing(6),
    paddingTop: theme.spacing(4)
  },
  paper: {
    marginBottom: theme.spacing(6)
  },
  buttonGroup: {
    paddingTop: theme.spacing(2)
  },
  bottomPadding: {
    paddingBottom: theme.spacing(2)
  }
}));

const PlansUpdatedDashboard = ({team, loading, loadPlansUpdated, ...props}) => {

  moment.locale('ru')

  const params = useParams();
  const [dates, setDates] = React.useState({
    startDate: moment().subtract(1, 'days').format("yyyy-MM-DD"),
    endDate: moment().subtract(1, 'days').format("yyyy-MM-DD"),
    active: 'yesterday'});
  const handleChange = (dates) => {
    return () => setDates(dates);
  };
  useEffect(() => {
    loadPlansUpdated(params.teamId, dates.startDate, dates.endDate)
  }, [params.teamId, dates]);

  const classes = useStyles()
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <DashboardLayout teamId={params.teamId} teamTitle={team.title} loading={loading}
      title={"Выставление планов"}
      table={<PlansUpdatedTable team={team} loading={loading}/>}
      header={
        <Grid item xs={12} sx={{marginBottom: "16px"}}>
          <Grid container>
            <Grid item xs={9}>
              <Typography component="p" variant="h4" className={classes.bottomPadding}>
                Дата последней постановки плана ({moment(dates.startDate).format("MMMM")})
              </Typography>
              <Typography component="p" variant="body1">
                Витрина отображает даты последнего изменения плана руководителями отделений
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <ButtonGroup color="primary" aria-label="outlined primary button group">
                <Button onClick={downloadDashboard(params.teamId, dates.startDate, null, null, 'plans_updated_xlsx')}
                  endIcon={<GetAppIcon/>}>Скачать</Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        </Grid>
      }
      />
  )
}

const mapStateToProps = (state) => ({
  team: state.dashboardReducer.team,
  loading: state.dashboardReducer.loading,
})

const mapDispatchToProps = (dispatch) => ({
  loadPlansUpdated: (id, startDate, endDate) => dispatch(getDashboardPlansUpdatedRequest(id, startDate, endDate))
})

export default connect(mapStateToProps, mapDispatchToProps)(PlansUpdatedDashboard)
