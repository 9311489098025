import React from "react";
import { useState, useEffect, useCallback } from "react";

import { makeStyles } from '@mui/styles';
import { Grid, FormControl, Select, Button, Link,
  MenuItem, FormControlLabel, Checkbox, Typography,
  Snackbar, CircularProgress, TextField } from '@mui/material';

import MuiAlert from '@mui/material/Alert';
import { TableCell, TableRow } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  editButton: {
    marginLeft: theme.spacing(2)
  },
  smallCell: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  }
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const PerformanceIndicatorRow = (props) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [priority, setPriority] = useState('');

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const { kpi, team, updatePerformanceIndicator, kpiChannels, performanceIndicators } = props

  const updateChannel = (kpiId) => {
    return (e) => {
      updatePerformanceIndicator(team.id, kpiId, {kpi_channel_id: e.target.value})
      setOpen(true)
    }
  }
  const updateLong = (kpiId) => {
    return (e) => {
      updatePerformanceIndicator(team.id, kpiId, {long: e.target.checked})
      setOpen(true)
    }
  }
  const updateRecalculate = (kpiId) => {
    return (e) => {
      updatePerformanceIndicator(team.id, kpiId, {recalculate: e.target.checked})
      setOpen(true)
    }
  }
  const updatePriority = (kpiId) => {
    return (e) => {
      updatePerformanceIndicator(team.id, kpiId, {priority: priority})
      setOpen(true)
    }
  }
  const updateParent = (kpiId) => {
    return (e) => {
      updatePerformanceIndicator(team.id, kpiId, {parent_id: e.target.value})
      setOpen(true)
    }
  }
  useEffect(() => {
    setPriority(kpi.priority);
  }, [kpi]);

  return (
    <TableRow key={kpi.id}>
      <TableCell component="th" scope="row" className={classes.smallCell}>{ kpi.title }</TableCell>
      <TableCell align="left" className={classes.smallCell}>
        {team.root ? <FormControl fullWidth className={classes.formControl} sx={{ m: 1, minWidth: 120 }} size="small">
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={kpi.parent ? kpi.parent.id : ''}
            onChange={updateParent(kpi.id)}
            disabled={kpi.teamId !== team.id}
            default={null}
          >
            <MenuItem value={null}>Не выбран</MenuItem>
          { performanceIndicators.map((pi) =>
              <MenuItem key={`${kpi.id}-${pi.id}`} value={pi.id}>{pi.title}</MenuItem>
          ) }
          </Select>
        </FormControl>
        : <Typography>{kpi.parent ? kpi.parent.title : ''}</Typography>}
      </TableCell>
      <TableCell align="left" className={classes.smallCell}>
        <FormControlLabel
          control={
            <Checkbox
              checked={kpi.long}
              name="long"
              color="primary"
              onChange={updateLong(kpi.id)}
              disabled={kpi.teamId !== team.id}
            />
          }
        />
      </TableCell>
      <TableCell align="left" className={classes.smallCell}>
        {team.root ? <FormControl className={classes.formControl} sx={{ m: 1, minWidth: 120 }} size="small">
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={kpi.kpiChannelId || ''}
            onChange={updateChannel(kpi.id)}
            disabled={kpi.teamId !== team.id}
            default={null}
          >
            <MenuItem value={null}>Не выбран</MenuItem>
          { kpiChannels.filter((channel) => channel.company.value === team.company.id).map((channel) =>
              <MenuItem key={`${kpi.id}-${channel.id}`} value={channel.id}>{channel.title}</MenuItem>
          ) }
          </Select>
        </FormControl>
        : <Typography>{kpi.kpiChannel}</Typography>}
      </TableCell>
      <TableCell align="left" className={classes.smallCell}>
        <FormControlLabel
          control={
            <Checkbox
              checked={kpi.recalculate}
              name="recaculate"
              color="primary"
              onChange={updateRecalculate(kpi.id)}
              disabled={kpi.teamId !== team.id}
            />
          }
        />
      </TableCell>
      <TableCell component="th" scope="row" className={classes.smallCell}>
        <TextField id="standard-name" value={priority} onChange={(event) => { setPriority(event.target.value) }}
           onBlur={updatePriority(kpi.id)} disabled={kpi.teamId !== team.id} size="small"/>
      </TableCell>
      <TableCell align="left" className={classes.smallCell}>
        <Button className={classes.editButton} variant="outlined" size="small"
          component={Link} href={`/teams/${team.id}/performance_indicators/${kpi.id}`}>
          Редактировать план
        </Button>
      </TableCell>
    </TableRow>
  )
}

export default PerformanceIndicatorRow
