import { TypeKeys } from '../actionTypes'

export const getGuestRequest = (teamId, guestId) => ({ type: TypeKeys.GET_GUEST_REQUEST, teamId, guestId })

export const getGuestSuccess = (guest) => ({
  type: TypeKeys.GET_GUEST_SUCCESS,
  guest
})

export const updateGuestRequest = (teamId, query) => ({ type: TypeKeys.UPDATE_GUEST_REQUEST, teamId, query })

export const updateGuestSuccess = (guest) => ({
  type: TypeKeys.UPDATE_GUEST_SUCCESS,
  guest
})
