import { takeLatest, put, call } from 'redux-saga/effects'
import { TypeKeys } from '../actions/actionTypes'
import {
  getTeamFail,
  getRootTeamsSuccess,
  getTeamsSuccess,
  getTeamSuccess,
  getTeamStatsSuccess,
  getTeamsListSuccess,
  createTeamSuccess,
  updateTeamSuccess
} from '../actions/actionCreators/teamsActions'
import callApi from '../utils/callApi'
import config from '../config'

function* loadTeams(action) {
  const res = yield call(callApi, `${config.BASE_URL}/admin/api/v1/teams?${action.query}`)
  const { pagination, teams } = res.data
  const teamsData = teams.map((team) => ({
    id: team.id,
    createdAt: team.created_at,
    title: team.title,
    parentTeam: team.parent_team ?
      {
        id: team.parent_team.id,
        title: team.parent_team.title,
        archived: team.parent_team.archived,
        teamAvatarUrl: team.parent_team.team_avatar_url,
      } : null,
    admin: team.admin ?
      {
        id: team.admin.id,
        firstName: team.admin.first_name,
        lastName: team.admin.last_name,
        avatar: team.admin.full_avatar_url,
        phone: team.admin.phone,
        blocked: team.admin.blocked
      } : null,
    membersCount: team.members_count,
    teamAvatarUrl: team.team_avatar_url,
    parentId: team.parent_id,
    company: team.company,
    admin_nullify: team.admin_nullify,
    weekend_auto_close: team.weekend_auto_close
  }))
  const paginationData = {
    currentPage: pagination.current_page,
    totalPages: pagination.total_pages,
    nextPage: pagination.next_page,
    prevPage: pagination.prev_page,
    totalCount: pagination.total_count
  }
  yield put(getTeamsSuccess(teamsData, paginationData))
}

function mapTeam(teamData) {
  return {
    id: teamData.id,
    adminId: teamData.admin_id,
    createdAt: teamData.created_at,
    archived: teamData.archived,
    archivedAt: teamData.archived_at,
    title: teamData.title,
    admin: {
      firstName: teamData.admin.first_name,
      lastName: teamData.admin.last_name,
      id: teamData.admin.id,
      avatar: teamData.admin.full_avatar_url,
      blocked: teamData.admin.blocked
    },
    membersCount: teamData.members_count,
    teamAvatarUrl: teamData.team_avatar_url,
    parentId: teamData.parent_id,
    subtree: teamData.subtree,
    parentTeams: teamData.parent_teams.map(team => {
      return {
        id: team.id,
        title: team.title,
        teamAvatarUrl: team.team_avatar_url,
        archived: team.archived
      }
    }),
    childTeams: teamData.child_teams.map(team => {
      return {
        id: team.id,
        title: team.title,
        teamAvatarUrl: team.team_avatar_url,
        archived: team.archived
      }
    }),
    users: teamData.users.map(user => {
      return {
        id: user.id,
        firstName: user.first_name,
        lastName: user.last_name,
        avatar: user.full_avatar_url,
        blocked: user.blocked
      }
    }),
    teamMembers: teamData.team_members.map(member => {
      return {
        id: member.id,
        monthWorkingDays: member.month_working_days,
        createdAt: member.created_at,
        user: {
          id: member.user.id,
          firstName: member.user.first_name,
          lastName: member.user.last_name,
          avatar: member.user.full_avatar_url,
          blocked: member.user.blocked
        }
      }
    }),
    guests: teamData.guests.map(guest => {
      return {
        id: guest.id,
        createdAt: guest.created_at,
        settingsEnabled: guest.settings_enabled,
        user: {
          id: guest.user.id,
          firstName: guest.user.first_name,
          lastName: guest.user.last_name,
          avatar: guest.user.full_avatar_url,
          blocked: guest.user.blocked
        }
      }
    }),
    externalInvites: teamData.external_invites.map(invite => {
      return {
        id: invite.id,
        firstName: invite.first_name,
        lastName: invite.last_name,
        phone: invite.phone,
        createdAt: invite.created_at
      }
    }),
    company: {
      id: teamData.company.id,
      title: teamData.company.title
    },
    root: teamData.root,
    areaId: teamData.area_id,
    divisionId: teamData.division_id,
    adminNullify: teamData.admin_nullify,
    weekendAutoClose: teamData.weekend_auto_close
  }
}

function* loadTeamsList(action) {
  const res = yield call(callApi, `${config.BASE_URL}/admin/api/v1/teams/list`, { params: { company_id: action.companyId } })
  const teams = res.data
  const teamsData = teams.map((team) => ({
    value: team.value,
    label: team.label,
    company: team.company,
    companyId: team.company_id,
    depth: team.depth
  }))
  yield put(getTeamsListSuccess(teamsData))
}

function* loadRootTeams(action) {
  const response = yield call(callApi, `${config.BASE_URL}/admin/api/v1/teams/roots`)
  if (response.status === 200) {
    const teamsData = response.data.map((team) => ({
      id: team.id,
      createdAt: team.created_at,
      title: team.title,
      parentTeam: team.parent_team ?
        {
          id: team.parent_team.id,
          title: team.parent_team.title,
          archived: team.parent_team.archived,
          teamAvatarUrl: team.parent_team.team_avatar_url,
        } : null,
      admin: team.admin ?
        {
          id: team.admin.id,
          firstName: team.admin.first_name,
          lastName: team.admin.last_name,
          avatar: team.admin.full_avatar_url,
          phone: team.admin.phone,
          blocked: team.admin.blocked
        } : null,
      membersCount: team.members_count,
      teamAvatarUrl: team.team_avatar_url,
      parentId: team.parent_id,
      company: team.company,
      admin_nullify: team.admin_nullify,
      weekend_auto_close: team.weekend_auto_close
    }));
    yield put(getRootTeamsSuccess(teamsData));
  }
}

function* loadTeam(action) {
  const response = yield call(callApi, `${config.BASE_URL}/admin/api/v1/teams/${action.id}`)
  yield put(getTeamSuccess(mapTeam(response.data)))
}

function* loadTeamStats(action) {
  const response = yield call(callApi, `${config.BASE_URL}/admin/api/v1/teams/${action.id}/stats`)
  yield put(getTeamStatsSuccess(response.data))
}

function* createTeam(action) {
  const response = yield call(callApi, `${config.BASE_URL}/admin/api/v1/teams`, { method: 'POST', data: action.query.data })
  yield put(createTeamSuccess(mapTeam(response.data)))
}

function* updateTeam(action) {
  const response = yield call(callApi,
    `${config.BASE_URL}/admin/api/v1/teams/${action.query.teamId}`,
    { method: 'PUT', data: action.query.data })
  yield put(updateTeamSuccess(mapTeam(response.data)))
}

export const teamsSagas = [
  takeLatest([TypeKeys.GET_TEAMS_REQUEST], loadTeams),
  takeLatest([TypeKeys.GET_TEAM_REQUEST], loadTeam),
  takeLatest([TypeKeys.GET_TEAM_STATS_REQUEST], loadTeamStats),
  takeLatest([TypeKeys.GET_TEAMS_LIST_REQUEST], loadTeamsList),
  takeLatest([TypeKeys.GET_ROOT_TEAMS_REQUEST], loadRootTeams),
  takeLatest([TypeKeys.CREATE_TEAM_REQUEST], createTeam),
  takeLatest([TypeKeys.UPDATE_TEAM_REQUEST], updateTeam),
]
