import axios from "axios"
import config from '../config'
import { history } from '../reducers'
import { getToken } from './getToken'
import moment from 'moment'

import fileDownload from 'js-file-download'

export function downloadDashboard(teamId, startDate, endDate, kpiId, reportName) {
  return () => {
    const token = getToken()
    const customHeaders = { Authorization: `Bearer ${token}` }

    axios({
      method: 'get', responseType: 'blob', headers: customHeaders,
      url: `${config.BASE_URL}/admin/api/v1/dashboard/${teamId}/${reportName}?`+
        `start_date=${moment(startDate).format('YYYY-MM-DD')}&`+
        `end_date=${moment(endDate).format('YYYY-MM-DD')}&`+
        `kpi_id=${kpiId}`
    }).then(response => {
      fileDownload(
        response.data,
        response.headers["content-disposition"].match(/attachment; filename="(.*)"/)[1]
      )
    })
    .catch(e => {
      if (e.response.status === 401) {
        history.push('/')
        return localStorage.clear()
      }
    })
  }
}

export function downloadFile(id) {
  return () => {
    const token = getToken()
    const customHeaders = { Authorization: `Bearer ${token}` }

    axios({
      method: 'get', responseType: 'blob', headers: customHeaders,
      url: config.BASE_URL + '/admin/api/v1/reports/' + id
    }).then(response => {
      fileDownload(
        response.data,
        response.headers["content-disposition"].match(/attachment; filename="(.*)"/)[1]
      )
    })
    .catch(e => {
      if (e.response.status === 401) {
        history.push('/')
        return localStorage.clear()
      }
    })
  }
}
