import { TypeKeys } from '../actionTypes'

export const getAwardTypesRequest = () => ({ type: TypeKeys.GET_AWARD_TYPES_REQUEST })

export const getAwardTypesSuccess = (awardTypes) => ({
  type: TypeKeys.GET_AWARD_TYPES_SUCCESS,
  awardTypes
})

export const getAwardTypeRequest = (id) => ({ type: TypeKeys.GET_AWARD_TYPE_REQUEST, id })

export const getAwardTypeSuccess = (awardType) => ({
  type: TypeKeys.GET_AWARD_TYPE_SUCCESS,
  awardType
})

export const createAwardTypeRequest = (query) => ({ type: TypeKeys.CREATE_AWARD_TYPE_REQUEST, query })

export const createAwardTypeSuccess = (awardType) => ({
  type: TypeKeys.CREATE_AWARD_TYPE_SUCCESS,
  awardType
})

export const updateAwardTypeRequest = (query) => ({ type: TypeKeys.UPDATE_AWARD_TYPE_REQUEST, query })

export const updatAwardTypeSuccess = (awardType) => ({
  type: TypeKeys.UPDATE_AWARD_TYPE_SUCCESS,
  awardType
})

export const getAwardHistoryRequest = (query) => ({ type: TypeKeys.GET_AWARD_HISTORY_REQUEST, query })

export const getAwardHistorySuccess = (awardHistory, pagination) => ({
  type: TypeKeys.GET_AWARD_HISTORY_SUCCESS,
  awardHistory,
  pagination
})
