import React, { useState } from "react"
import { Link } from "react-router-dom"
import { Paper, Grid, Typography, Divider, TextField, Button, MenuItem,
  FormControl, InputLabel, Select, Stack, ButtonGroup } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { MoveUp, MoveDown, Delete } from '@mui/icons-material';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(4),
    '& .MuiTextField-root': {
      marginTop: theme.spacing(1),
      // width: '25ch',
    }
  },
  paper: {
    padding: theme.spacing(2)
  },
  idHeader: {
    marginBottom: theme.spacing(2),
  },
  margin: {
    margin: theme.spacing(0),
    marginRight:  theme.spacing(4),
  },
  formControl: {
    marginTop:  theme.spacing(0),
  },
  buttonMargin: {
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    textDecoration: "none"
  },
  fab: {
    [theme.breakpoints.down('md')]: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  },
}));

const TaskTypeForm = ({ taskType, companies, submit, type, history }) => {

  const { id } = taskType

  const [values, setValues] = useState({
    name: taskType.name,
    company: taskType.company,
    atts: taskType.atts,
    readOnlyAtts: taskType.readOnlyAtts
  });

  const mapCompanies = (cs) => {
    return cs.map((option, index) => <MenuItem key={index} value={option.id} label={option.title}>{option.title}</MenuItem> )
  }
  const setNameValue = (e) => {
    setValues({ ...values, name: e.target.value })
  }
  const setCompanyValue = (e, value) => {
    setValues({ ...values, company: {value: value.props.value, label: value.props.label} })
  }
  const addAtt = (type) => {
    return () => setValues({ ...values, [type]: [...values[type], {name: "", title: "", value: ""}]})
  }
  const delAtt = (type, att) => {
    return () => {
      setValues({ ...values, [type]: values[type].filter((attr) => attr.name !== att.name)})
    }
  }
  const uniqAtt = (type, att) => {
    return values[type].filter((attr) => attr.name === att.name).length > 1
  }
  const updateAttName = (type, index) => {
    return (e) => {setValues({ ...values, [type]: values[type].map(
      (attr, i) => index === i ? {...attr, name: e.target.value} : attr
    )})}
  }
  const updateAttTitle = (type, index) => {
    return (e) => {setValues({ ...values, [type]: values[type].map(
      (attr, i) => index === i ? {...attr, title: e.target.value} : attr
    )})}
  }
  const updateAttValue = (type, index) => {
    return (e) => {setValues({ ...values, [type]: values[type].map(
      (attr, i) => index === i ? {...attr, value: e.target.value} : attr
    )})}
  }
  const moveAttUp = (type, index) => { return () => {
    setValues({...values,
      [type]: [...(values[type].slice(0, index-1)), values[type][index], values[type][index-1], ...(values[type].slice(index+1))]
    })
  }}
  const moveAttDown = (type, index) => { return () => {
    setValues({...values,
      [type]: [...(values[type].slice(0, index)), values[type][index+1], values[type][index], ...(values[type].slice(index+2))]
    })
  }}
  const submitAction = () => {
    submit({
      taskTypeId: taskType.id,
      data: {
        name: values.name,
        company_id: values.company.value,
        additional_attributes: values.atts,
        additional_ro_attributes: values.readOnlyAtts
      }
    })
    history.goBack()
  }

  const classes = useStyles()

  return(
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Typography variant="h6" sx={{marginBottom: "16px"}}>
            { type==="edit" && "Изменить тип задачи" }
            { type==="new" && "Создать тип задачи" }
          </Typography>
          <form noValidate autoComplete="off">
            <Grid container spacing={2}>
              <Grid item sm={12}>
                <TextField required variant="outlined" fullWidth label="Название" value={values.name} onChange={setNameValue} />
              </Grid>
              {(companies.length > 1) && <Grid item sm={12}>
                <FormControl fullWidth variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">Компания</InputLabel>
                  <Select
                    fullWidth
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={values.company.value}
                    onChange={setCompanyValue}
                    label="Компания"
                    disabled={companies.length === 1}
                  >
                  { mapCompanies(companies) }
                  </Select>
                </FormControl>
              </Grid>}
              <Grid item sm={12}>
                <Typography variant="h6" sx={{marginBottom: "16px"}}>Поля для отображения</Typography>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Идентификатор</TableCell>
                        <TableCell>Заголовок</TableCell>
                        <TableCell>Значение</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {values.readOnlyAtts.map((att, index) => (
                        <TableRow key={`${index}`} sx={{ verticalAlign: 'top', '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell sx={{padding: '8px'}}>
                            <TextField sx={{margin: '8px'}} required variant="outlined" size="small" fullWidth
                            value={att.name} error={uniqAtt('readOnlyAtts', att)} helperText={uniqAtt('readOnlyAtts', att) ? "Должен быть уникальным." : ""}
                            onChange={updateAttName('readOnlyAtts', index)}/>
                          </TableCell>
                          <TableCell sx={{padding: '8px'}}>
                            <TextField sx={{margin: '8px'}} required variant="outlined" size="small" fullWidth
                              value={att.title} onChange={updateAttTitle('readOnlyAtts', index)}/>
                          </TableCell>
                          <TableCell sx={{padding: '8px'}}>
                            <TextField sx={{margin: '8px'}} required variant="outlined" size="small" fullWidth
                              value={att.value} onChange={updateAttValue('readOnlyAtts', index)}/>
                          </TableCell>
                          <TableCell>
                            <ButtonGroup sx={{margin: '0px'}}>
                              <Button variant="text" onClick={delAtt('readOnlyAtts', att)}><Delete/></Button>
                              <Button variant="text" onClick={moveAttUp('readOnlyAtts', index)}
                                disabled={index === 0}>
                                <MoveUp/>
                              </Button>
                              <Button variant="text" onClick={moveAttDown('readOnlyAtts', index)}
                                disabled={index === values.readOnlyAtts.length - 1}>
                                <MoveDown/>
                              </Button>
                            </ButtonGroup>
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell sx={{padding: '8px'}} colSpan={4}>
                          <Button variant="text" onClick={addAtt('readOnlyAtts')}>Добавить</Button>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item sm={12}>
                <Typography variant="h6" sx={{marginBottom: "16px"}}>Поля для ввода</Typography>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Идентификатор</TableCell>
                        <TableCell>Заголовок</TableCell>
                        <TableCell>Значение</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {values.atts.map((att, index) => (
                        <TableRow key={`${index}`} sx={{ verticalAlign: 'top', '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell sx={{padding: '8px'}}>
                            <TextField sx={{margin: '8px'}} required variant="outlined" size="small" fullWidth
                            value={att.name} error={uniqAtt('atts', att)} helperText={uniqAtt('atts', att) ? "Должен быть уникальным." : ""}
                            onChange={updateAttName('atts', index)}/>
                          </TableCell>
                          <TableCell sx={{padding: '8px'}}>
                            <TextField sx={{margin: '8px'}} required variant="outlined" size="small" fullWidth
                              value={att.title} onChange={updateAttTitle('atts', index)}/>
                          </TableCell>
                          <TableCell sx={{padding: '8px'}}>
                            <TextField sx={{margin: '8px'}} required variant="outlined" size="small" fullWidth
                              value={att.value} onChange={updateAttValue('atts', index)}/>
                          </TableCell>
                          <TableCell>
                            <ButtonGroup sx={{margin: '0px'}}>
                              <Button variant="text" onClick={delAtt('atts', att)}><Delete/></Button>
                              <Button variant="text" onClick={moveAttUp('atts', index)}
                                disabled={index === 0}>
                                <MoveUp/>
                              </Button>
                              <Button variant="text" onClick={moveAttDown('atts', index)}
                                disabled={index === values.atts.length - 1}>
                                <MoveDown/>
                              </Button>
                            </ButtonGroup>
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell sx={{padding: '8px'}} colSpan={4}>
                          <Button variant="text" onClick={addAtt('atts')}>Добавить</Button>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item sm={12}>
                <Stack spacing={2} direction="row">
                  <Button className={classes.buttonMargin} variant="outlined" to={`/task_types`} component={Link}>
                    Назад
                  </Button>
                  <Button className={classes.buttonMargin} variant="contained" onClick={submitAction} color="primary">
                    { type==="edit" && "Сохранить" }
                    { type==="new" && "Создать" }
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default TaskTypeForm;
