import { takeLatest, put, call, delay } from 'redux-saga/effects'
import { TypeKeys } from '../actions/actionTypes'
import { getNewsfeedMessagesSuccess, getNewsfeedEditorsSuccess, getNewsfeedTeamsSuccess,
  createNewsfeedMessageSuccess, createNewsfeedMessageError } from '../actions/actionCreators/newsfeedActions'
import callApi from '../utils/callApi'
import config from '../config'
import moment from 'moment'

function* loadNewsfeedMessages(action) {
  const res = yield call(callApi, `${config.BASE_URL}/admin/api/v1/newsfeeds?${action.query}`)
  const paginationData = {
    currentPage: res.data.pagination.current_page,
    totalPages: res.data.pagination.total_pages,
    nextPage: res.data.pagination.next_page,
    prevPage: res.data.pagination.prev_page,
    totalCount: res.data.pagination.total_count
  }
  yield put(getNewsfeedMessagesSuccess(res.data.messages, paginationData))
}

function* loadNewsfeedEditors(action) {
  const res = yield call(callApi, `${config.BASE_URL}/admin/api/v1/newsfeeds/editors`)
  yield put(getNewsfeedEditorsSuccess(res.data))
}

function* loadNewsfeedTeams(action) {
  const res = yield call(callApi, `${config.BASE_URL}/admin/api/v1/newsfeeds/teams`)
  yield put(getNewsfeedTeamsSuccess(res.data))
}

function* createNewsfeedMessage(action) {
  const res = yield call(callApi,
    `${config.BASE_URL}/admin/api/v1/newsfeeds`,
    { method: 'POST', data: action.query })
  if (res.status === 201) {
    yield put(createNewsfeedMessageSuccess(res.data))
  } else {
    yield put(createNewsfeedMessageError(res.status))
  }
}

export const newsfeedsSagas = [
  takeLatest([ TypeKeys.GET_NEWSFEED_MESSAGES_REQUEST], loadNewsfeedMessages),
  takeLatest([ TypeKeys.GET_NEWSFEED_EDITORS_REQUEST], loadNewsfeedEditors),
  takeLatest([ TypeKeys.GET_NEWSFEED_TEAMS_REQUEST], loadNewsfeedTeams),
  takeLatest([ TypeKeys.CREATE_NEWSFEED_MESSAGE_REQUEST], createNewsfeedMessage),
]
