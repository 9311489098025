import React, { useState } from "react"
import { Link } from "react-router-dom"
import { Paper, Grid, Typography, Divider, TextField, Button, MenuItem,
  FormControl, InputLabel, Select, Stack, ImageList, ImageListItem,
  ImageListItemBar, IconButton, CircularProgress, Table, TableHead, TableRow,
  TableCell, TableBody, Checkbox, Tooltip, Alert } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Add } from '@mui/icons-material'
import { DndContext, closestCenter, KeyboardSensor, MouseSensor, PointerSensor, useSensor, useSensors, DragOverlay } from '@dnd-kit/core'
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy, useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import DeleteIcon from '@mui/icons-material/Delete'
import ImportExportIcon from '@mui/icons-material/ImportExport'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(4),
    '& .MuiTextField-root': {
      marginTop: theme.spacing(1),
    }
  },
  paper: {
    padding: theme.spacing(2)
  },
  idHeader: {
    marginBottom: theme.spacing(2),
  },
  margin: {
    margin: theme.spacing(0),
    marginRight:  theme.spacing(4),
  },
  formControl: {
    marginTop:  theme.spacing(0),
  },
  buttonMargin: {
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    textDecoration: "none"
  },
  fab: {
    [theme.breakpoints.down('md')]: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  },
}));

const DraggableRow = (props) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({id: props.answer.id});
  const style = { transform: CSS.Transform.toString(transform), transition, zIndex: 9999};

  return (
    <TableRow key={props.answer.id} ref={setNodeRef} style={style} sx={{backgroundColor: "#FFF"}}>
      <TableCell align="left" {...attributes} {...listeners}>
        <IconButton aria-label="delete" size="large">
          <ImportExportIcon fontSize="inherit" />
        </IconButton>
      </TableCell>
      <TableCell align="left">
        <TextField variant="outlined" fullWidth label="Ответ"
          value={props.answer.title} multiline rows={2}
          onChange={props.setOptionTitleValue}/>
      </TableCell>
      <TableCell align="left">
        <Checkbox checked={props.answer.correct} onChange={props.toggleAnswerChecked}/>
      </TableCell>
      <TableCell align="left">
        <Tooltip title="Удалить">
          <IconButton sx={{ color: 'black' }} onClick={props.deleteAnswer}>
            <DeleteIcon/>
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
}

const EducationTestForm = ({ educationTest, submit, type, history, loading }) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  const [activeId, setActiveId] = useState(null);
  function handleDragStart(event) {
      setActiveId(event.active.id);
    }
  function handleDragEnd(event) {
    const {active, over} = event
    setActiveId(null)
    if (active.id !== over.id) {
      setAnswerOptions((items) => {
        const oldIndex = items.map(function(i) { return i.id; }).indexOf(active.id)
        const newIndex = items.map(function(i) { return i.id; }).indexOf(over.id)
        return arrayMove(items, oldIndex, newIndex)
      })
    }
  }

  const [values, setValues] = useState({
    description: educationTest.description
  });

  const [answerOptions, setAnswerOptions] = useState(educationTest.answerOptions.map(
    (answer, index) => {return { ...answer, id: index+1}}
  ));

  const addAnswerOption = () => {
    setAnswerOptions(old => [...old, {title: '', correct: false, id: answerOptions.length + 1}])
  }

  const deletAnswerOption = (id) => {
    return () => { setAnswerOptions(old => old.filter((o) => o.id !== id)) }
  }

  const toggleAnswerChecked = (id) => {
    return (_) => setAnswerOptions(old => old.map(
      option => ({...option, correct: option.id === id})
    ))
  }

  const setOptionTitleValue = (id) => {
    return (e) => setAnswerOptions(old => old.map(
      o => o.id === id ? {...o, title: e.target.value} : o
    ))
  }

  const setDescriptionValue = (e) => {
    setValues({ ...values, description: e.target.value })
  }

  const submitAction = () => {
    submit({
      educationCategoryId: educationTest.education.educationCategory.id,
      educationId: educationTest.education.id,
      educationTestId: educationTest.id,
      data: {
        description: values.description,
        answer_options: answerOptions.map(
          (o) => { return { title: o.title, correct: o.correct } }
        )
      }
    })
  }


  const classes = useStyles()

  return(
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={12}>
      {loading ?  <CircularProgress/> :
        <Paper className={classes.paper}>
          <Typography variant="h4" sx={{marginBottom: "16px"}}>
            { type==="edit" && "Изменить вопрос теста" }
            { type==="new" && "Создать вопрос теста" }
          </Typography>
          <form noValidate autoComplete="off">
            <Grid container spacing={2}>
              <Grid item sm={12}>
                <TextField required variant="outlined" fullWidth label="Описание" value={values.description} onChange={setDescriptionValue} />
              </Grid>
              <Grid item sm={12}>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={8}>
                        <Typography variant="h6" sx={{marginBottom: "16px", marginTop: "16px"}}>
                          Варианты ответов
                          <Button variant="contained" sx={{marginLeft: "16px", padding: "4px 16px 4px 8px"}}
                            onClick={addAnswerOption}>
                            <Add sx={{marginRight: "8px"}}/>
                            Добавить
                          </Button>
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left"></TableCell>
                      <TableCell align="left">Ответ</TableCell>
                      <TableCell align="left">Верный ответ</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
                      <SortableContext items={answerOptions} strategy={verticalListSortingStrategy}>
                        {answerOptions.map(answer => <DraggableRow key={answer.id} answer={answer}
                          setOptionTitleValue={setOptionTitleValue(answer.id)}
                          toggleAnswerChecked={toggleAnswerChecked(answer.id)}
                          deleteAnswer={deletAnswerOption(answer.id)}
                          />)}
                      </SortableContext>
                    </DndContext>
                  </TableBody>
                </Table>
              </Grid>
              <Grid item sm={12}>
                <Button className={classes.buttonMargin} variant="outlined" to={`/education_categories/${educationTest.education.educationCategory.id}/educations/${educationTest.education.id}`} component={Link}>
                  Назад
                </Button>
                <Button className={classes.buttonMargin} variant="contained" onClick={submitAction}
                  color="primary" sx={{ml: "8px"}} disabled={values.title === ''}>
                  { type==="edit" && "Сохранить" }
                  { type==="new" && "Создать" }
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>}
      </Grid>
    </Grid>
  )
}

export default EducationTestForm
