import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import "moment/locale/ru";
import { getDashboardShowcaseRequest } from "../../../actions/actionCreators/dashboardActions";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";
import { ButtonGroup, Grid, Typography, Button } from "@mui/material";
import ShowCaseTable from "./Table";
import DashboardLayout from "../DashboardLayout";

const TaskStatsDashboard = ({
  team,
  loading,
  loadDashboardShowcase,
  showcaseData,
  ...props
}) => {
  moment.locale("ru");
  const [displayModal, setDisplayModal] = useState(false);
  const params = useParams();
  const [dates, setDates] = useState([
    {
      startDate: moment().startOf("month").format("yyyy-MM-DD"),
      endDate: moment().subtract(1, "days").format("yyyy-MM-DD"),
      kind: "thisMonth",
      active: true,
    },
    {
      startDate: moment()
        .subtract(1, "months")
        .startOf("month")
        .format("yyyy-MM-DD"),
      endDate: moment()
        .subtract(1, "months")
        .endOf("month")
        .format("yyyy-MM-DD"),
      kind: "lastMonth",
      active: false,
    },
    {
      startDate: moment().subtract(1, "days").format("yyyy-MM-DD"),
      endDate: moment().subtract(1, "days").format("yyyy-MM-DD"),
      kind: "yesterday",
      active: false,
    },
  ]);

  const handleChange = (kind) => {
    const newDates = dates.map((date) => {
      if (date.kind === kind) {
        return {
          ...date,
          active: true,
        };
      }

      return {
        ...date,
        active: false,
      };
    });
    setDates(newDates);
  };
  useEffect(() => {
    const activeDate = dates.find((date) => date.active);
    loadDashboardShowcase(
      params.teamId,
      activeDate.startDate,
      activeDate.endDate
    );

    return () => {
      // callDashboardReset();
    };
  }, [params.teamId, dates]);

  return (
    <DashboardLayout
      teamId={params.teamId}
      teamTitle={team?.title}
      loading={loading}
      title={"Статусы отделений"}
      table={
        <ShowCaseTable
          team={team}
          showcaseData={showcaseData}
          loading={loading}
        />
      }
      header={
        <Grid item xs={12} sx={{ marginBottom: "16px" }}>
          <Grid container>
            <Grid item xs={6}>
              <Typography component="span" variant="h4">
                Статусы управленческого цикла
              </Typography>
            </Grid>
            <Grid item align={"right"} xs={6}>
              <ButtonGroup
                color="primary"
                aria-label="outlined primary button group"
              >
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  className="SearchButton"
                  onClick={() => setDisplayModal(true)}
                >
                  Описание статусов
                </Button>
              </ButtonGroup>
            </Grid>
            <Grid item xs={6}>
              <Typography component="span" variant="body1">
                Витрина отображает статусы внедрения управленческого цикла в
                отделениях
              </Typography>
            </Grid>
            <Grid item align={"right"} xs={6}>
              <ButtonGroup
                color="primary"
                aria-label="outlined primary button group"
              >
                <Button
                  variant={
                    dates.find(
                      (date) => date.active && date.kind === "yesterday"
                    )
                      ? "contained"
                      : "outlined"
                  }
                  onClick={() => handleChange("yesterday")}
                >
                  Вчера
                </Button>
                {moment().date() > 2 && (
                  <Button
                    variant={
                      dates.find(
                        (date) => date.active && date.kind === "thisMonth"
                      )
                        ? "contained"
                        : "outlined"
                    }
                    onClick={() => handleChange("thisMonth")}
                  >
                    Этот месяц
                  </Button>
                )}
                <Button
                  variant={
                    dates.find(
                      (date) => date.active && date.kind === "lastMonth"
                    )
                      ? "contained"
                      : "outlined"
                  }
                  onClick={() => handleChange("lastMonth")}
                >
                  Прошлый месяц
                </Button>
              </ButtonGroup>
            </Grid>
            <Modal
              open={displayModal}
              onClose={() => null}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flex: 1,
                  height: "100%",
                }}
              >
                <div
                  style={{ alignSelf: "center", width: "75%" }}
                  onClick={() => setDisplayModal(false)}
                >
                  <CloseIcon
                    style={{
                      position: "absolute",
                      right: "14%",
                      marginTop: 20,
                    }}
                  />
                  <img
                    src={require("../../../images/shocase_statuses_tip.png")}
                    alt="logo"
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
            </Modal>
          </Grid>
        </Grid>
      }
    />
  );
};

const mapStateToProps = (state) => ({
  team: state.dashboardReducer.team,
  showcaseData: state.dashboardReducer.showcaseData,
  loading: state.dashboardReducer.loading,
});

const mapDispatchToProps = (dispatch) => ({
  loadDashboardShowcase: (id, startDate, endDate) =>
    dispatch(getDashboardShowcaseRequest(id, startDate, endDate)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskStatsDashboard);
