import { useCallback, useEffect, useMemo, useState } from 'react'
import { Upload } from '../modals'

export default function useSelect() {
	const [data, setData] = useState([])

	useEffect(() => {
		Upload.getTypes().then(setData)
	}, [])

	const [value, setValue] = useState()
	const set = useCallback(({ target }) => target.value && setValue(target.value), [])

	const selected = useMemo(() => data.find(byId(value)), [data, value])

	return { data, value, selected, set }
}

const byId =
	value =>
	({ id }) =>
		id === value
