import React from "react";
import EducationForm from "./EducationForm"
import { useParams } from "react-router-dom"
import { connect } from 'react-redux'
import { useEffect } from "react";
import { createEducationRequest } from "../../actions/actionCreators/educationsActions"
import { getEducationCategoryRequest } from "../../actions/actionCreators/educationCategoriesActions"

const EducationNewForm = ({ history, createEducation, educationCategory, educationCategoryLoading,
  loadEducationCategory }) => {
  const params = useParams()

  const education = {
    title: '',
    image: null
  }

  useEffect(() => {
    loadEducationCategory(params.educationCategoryId)
  }, [params.educationCategoryId]);

  return(
    <EducationForm key={+ new Date()} education={education} educationCategory={educationCategory}
      type={"new"} history={history} submit={createEducation}></EducationForm>
  )
}

const mapStateToProps = (state) => ({
  educationCategory: state.educationCategoriesReducer.educationCategory,
  educationCategoryLoading: state.educationCategoriesReducer.loading,
})

const mapDispatchToProps = (dispatch) => ({
  loadEducationCategory: (educationCategoryId) => dispatch(getEducationCategoryRequest(educationCategoryId)),
  createEducation: (query) => dispatch(createEducationRequest(query)),
})

export default connect(mapStateToProps, mapDispatchToProps)(EducationNewForm)
