import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { connect } from 'react-redux'
import { getUserEducationsRequest } from "../../actions/actionCreators/userEducationsActions"

import moment from 'moment'
import Pagination from '../../components/Main/Pagination'
import TeamSelect from "../../components/Main/TeamSelect"
import UserChip from "../../components/Main/UserChip"
import { makeStyles, useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import clsx from 'clsx';

import { Table, TableBody, TableCell, TableContainer, TableHead,
  TableRow, TableFooter } from '@mui/material'
import { Paper, Grid, Typography, Toolbar, Container, Hidden,
  Fab, TextField, InputAdornment, Button, Box, Chip, Avatar,
  FormControlLabel, Checkbox, Divider, Select, MenuItem, FormControl,
  InputLabel, Tooltip } from '@mui/material'
import { List, ListItem, ListItemAvatar, ListItemText } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import CircularProgress from '@mui/material/CircularProgress'
import InfoIcon from '@mui/icons-material/Info'

import { orange, red } from '@mui/material/colors'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4),
    },
  },
  divider: {
    margin: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2)
  },
  buttonGroup: {
    paddingTop: theme.spacing(2)
  },
  fab: {
  },
  teamChip: {
    marginLeft: theme.spacing(1),
    // marginTop: theme.spacing(1),
    '&:hover': { cursor: "pointer" },
  },
  avatarFont: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    letterSpacing: "-1px",
    fontSize: "0.8rem",
    backgroundColor: theme.palette.primary.main,
    margin: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      fontSize: "0.8rem"
    },
  },
  ignoreVisited: {
    color: theme.palette.common.blue,
    textDecoration: 'none'
  },
  noWrap: {
    whiteSpace: "nowrap"
  }
}));

const UserEducationsPanel = (props) => {

  const { userEducations, pagination, loading, companies } = props

  const [collapsed, setCollapsed] = useState(false)
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search)

  const [userName, setUserName] = useState(query.get("user_name") || "")
  const [education, setEducation] = useState(query.get("education") || "")

  useEffect(() => {
    props.loadUserEducations(query)
  }, [query.toString()]);

  const toggleCollapse = () => setCollapsed(!collapsed)

  const changeUserName = (e) => setUserName(e.target.value)
  const changeEducation = (e) => setEducation(e.target.value)

  const genSearchUri = () => {
    const newQuery = new URLSearchParams(location.search)
    newQuery.set("page", 1)
    userName ? newQuery.set("user_name", userName) : newQuery.delete("user_name")
    education ? newQuery.set("education", education) : newQuery.delete("education")
    return `${location.pathname}?${newQuery.toString()}`
  }

  const performSearch = (key) => {
    if (key.key === "Enter") {
      navigate(genSearchUri())
    }
  }

  const classes = useStyles()
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Grid container spacing={2} >
            <Grid item xs={12}>
              <Typography variant="h4" sx={{marginBottom:"16px"}}>
                Назначенные обучения&nbsp;
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} >
            <Grid item xs={12} sm={4}>
              <TextField id="title" label="Пользователь" fullWidth variant="outlined" value={userName}
                onChange={changeUserName} onKeyPress={performSearch} size="small"/>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField id="assignee" label="Обучение" fullWidth variant="outlined" value={education}
                onChange={changeEducation} onKeyPress={performSearch} size="small"/>
            </Grid>
            <Grid item xs={6} sm={4}>
              <Button fullWidth variant="contained" color="primary" className="SearchButton" onClick={() => navigate(genSearchUri())}>
                Искать
              </Button>
            </Grid>
          </Grid>
          <Divider sx={{marginTop:"32px", marginBottom:"32px"}} />
          <Table className={classes.table} aria-label="simple table" size="small">
            <TableHead>
              <TableRow>
                <TableCell>Пользователь</TableCell>
                <TableCell>Обучение</TableCell>
                <TableCell>Результат</TableCell>
                <TableCell>Назначено</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { loading &&
                <TableRow>
                  <TableCell colSpan={7} align="center"><CircularProgress/></TableCell>
                </TableRow> }
              { userEducations.map((userEducation) => {
                const { id, user, education, result, attempt, attemptAt, createdAt } = userEducation
                return(
                  <TableRow key={id}>
                    <TableCell>
                      <UserChip user={user}/>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">
                        {education.title}
                      </Typography>
                      <Typography variant="body2" sx={{color: "#666"}}>
                        {education.educationCategory}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">
                        {attempt === 0 ? "Результат отсутствует" : `${result}%`}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1" className={classes.ignoreVisited}>
                        {moment(createdAt).format('DD.MM.YYYY')}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        component={Link} to={`/user_educations/${id}`}
                        variant="body1"
                        className={classes.ignoreVisited}>
                        <InfoIcon/>
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              )}
            </TableBody>
          </Table>
          <Box className={classes.buttonGroup}>
            { pagination.totalPages > 1 && <Pagination data={pagination} location={location}/>}
          </Box>
        </Paper>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = (state) => ({
  userEducations: state.userEducationsReducer.userEducations,
  pagination: state.userEducationsReducer.pagination,
  loading: state.userEducationsReducer.loading,
  companies: state.profileReducer.companies,
})

const mapDispatchToProps = (dispatch) => ({
  loadUserEducations: (query) => dispatch(getUserEducationsRequest(query))
})

export default connect(mapStateToProps, mapDispatchToProps)(UserEducationsPanel)
