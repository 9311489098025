import React from "react";
import UserPositionForm from "./UserPositionForm"

import { connect } from 'react-redux'
import { createUserPositionRequest } from "../../actions/actionCreators/userPositionsActions"

const UserPositionNew = ({ createUserPosition, companies, history }) => {
  const userPosition = {
    title: "",
    company: {
      value: (companies.length > 0) ? companies[0].id : undefined,
      label: (companies.length > 0) ? companies[0].title : undefined
    }
  }
  return(<UserPositionForm key={+ new Date()} userPosition={userPosition} companies={companies}
    submit={createUserPosition} type={"new"} history={history}></UserPositionForm>)
}

const mapStateToProps = (state) => ({
  companies: state.profileReducer.companies
})

const mapDispatchToProps = (dispatch) => ({
  createUserPosition: (query) => dispatch(createUserPositionRequest(query)),
})

export default connect(mapStateToProps, mapDispatchToProps)(UserPositionNew)
