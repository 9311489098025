import React, { useEffect } from "react";
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { makeStyles, useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import moment from 'moment';

import { Paper, Grid, Typography, Container, Box,
  Button, CircularProgress, Table, TableBody, TableCell,
  TableHead, TableRow, IconButton } from '@mui/material';
import { Add, History, Edit } from '@mui/icons-material';

import { getIntegrationTokensRequest } from "../../actions/actionCreators/integrationTokensActions"

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2)
  },
  fab: {
    [theme.breakpoints.down('md')]: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  },
  ignoreVisited: {
    // marginLeft: theme.spacing(2),
    color: theme.palette.common.black,
    textDecoration: "none"
  },
}));

const IntegrationTokensPanel = (props) => {

  const { integrationTokens, loading, companies } = props

  useEffect(() => {
    props.loadIntegrationTokens()
  }, []);

  const classes = useStyles()
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Grid container spacing={2} >
            <Grid item xs container direction="column">
              <Typography variant="h4">
                Токены интеграции
              </Typography>
            </Grid>
            <Grid item justify="flex-end">
              <Link to={`/integration_tokens/new`} className={classes.ignoreVisited}>
                <Button variant="contained">
                  <Add />
                  &nbsp;Добавить&nbsp;
                </Button>
              </Link>
            </Grid>
          </Grid>
          <Table className={classes.table} size={mobile ? "" : "small"} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell align="left">Токен</TableCell>
                <TableCell align="left">Активен</TableCell>
                <TableCell align="left">Дата создания</TableCell>
                <TableCell align="left">Дата обновления</TableCell>
                { companies.length > 1 && <TableCell align="left" >Компания</TableCell> }
                <TableCell align="left">Истекает</TableCell>
                <TableCell align="left">Создан</TableCell>
                <TableCell align="left">История запросов</TableCell>
                <TableCell align="left"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { loading &&
                <TableRow>
                  <TableCell colSpan={5} align="center"><CircularProgress/></TableCell>
                </TableRow> }
              { !loading && integrationTokens.map((integrationToken) => {
                const { id, token, company, enabled, createdAt, updatedAt, user, expiresAt } = integrationToken
                return(
                  <TableRow key={id}>
                    <TableCell>
                      <Box component={Link} to={`/integration_tokens/${id}`} className={classes.ignoreVisited}>
                        <Typography
                          component="span"
                          variant="body1">
                          {id}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="left">
                      <Box component={Link} to={`/integration_tokens/${id}`} className={classes.ignoreVisited}>
                        <Typography
                          component="span"
                          variant="body1">
                          {token.slice(0, 4)}****{token.slice(-4)}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="left">
                      <Typography
                        component="span"
                        variant="body1">
                        {enabled ? 'Активный' : 'Неактивный'}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography
                        component="span"
                        variant="body1">
                        {moment(createdAt).format('DD.MM.YYYY')}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography
                        component="span"
                        variant="body1">
                        {moment(updatedAt).format('DD.MM.YYYY')}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography
                        component="span"
                        variant="body1">
                        {moment(expiresAt).format('DD.MM.YYYY')}
                      </Typography>
                    </TableCell>
                    { companies.length > 1 && <TableCell align="left">{company.label}</TableCell>}
                    <TableCell align="left">
                      <Typography
                        component="span"
                        variant="body1">
                        {user && `${user.firstName} ${user.lastName}`}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <IconButton color="primary" component={Link} to={`/integration_tokens/${id}/history`}>
                        <History />
                      </IconButton>
                    </TableCell>
                    <TableCell align="left">
                      <IconButton color="primary" component={Link} to={`/integration_tokens/${id}`}>
                        <Edit />
                      </IconButton>
                    </TableCell>
                  </TableRow>
              )}
            )}
            </TableBody>
          </Table>
        </Paper>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = (state) => ({
  integrationTokens: state.integrationTokensReducer.integrationTokens,
  loading: state.integrationTokensReducer.loading,
  companies: state.profileReducer.companies
})

const mapDispatchToProps = (dispatch) => ({
  loadIntegrationTokens: () => dispatch(getIntegrationTokensRequest())
})

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationTokensPanel)
