import { TypeKeys } from '../actionTypes'

export const getCompaniesRequest = () => ({ type: TypeKeys.GET_COMPANIES_REQUEST })

export const getCompaniesSuccess = (companies) => ({
  type: TypeKeys.GET_COMPANIES_SUCCESS,
  companies
})

export const getCompanyRequest = (id) => ({ type: TypeKeys.GET_COMPANY_REQUEST, id })

export const getCompanySuccess = (company) => ({
  type: TypeKeys.GET_COMPANY_SUCCESS,
  company
})

export const getCompanyFail = (company) => ({
  type: TypeKeys.GET_COMPANY_FAIL,
  company
})

export const createCompanyRequest = (query) => ({ type: TypeKeys.CREATE_COMPANY_REQUEST, query })

export const createCompanySuccess = (companies) => ({
  type: TypeKeys.CREATE_COMPANY_SUCCESS,
  companies
})

export const updateCompanyRequest = (query) => ({ type: TypeKeys.UPDATE_COMPANY_REQUEST, query })

export const updateCompanySuccess = (companies) => ({
  type: TypeKeys.UPDATE_COMPANY_SUCCESS,
  companies
})
