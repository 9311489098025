import React from 'react'
import svg from '../../../assets/svg'

// type Props = {
//   name: keyof typeof svg
// } & SVGProps<SVGSVGElement>

export default function Icon({ name, ...props }) {
	// export default function Icon({ name, ...props }: Props) {
	const Component = svg[name]
	return <Component {...props} />
}
