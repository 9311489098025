import { initialUserEducations } from "../initial/initialUserEducations"
import { TypeKeys } from "../actions/actionTypes"

export const userEducationsReducer = (state = initialUserEducations(), action) => {
  switch (action.type) {
    case TypeKeys.GET_USER_EDUCATIONS_REQUEST:
      return { ...state, userEducations: initialUserEducations().userEducations, loading: true, status: "processing" }
    case TypeKeys.GET_USER_EDUCATIONS_SUCCESS:
      return { ...state, userEducations: action.userEducations, loading: false, pagination: {...action.pagination }, status: "ready" }
    case TypeKeys.GET_USER_EDUCATION_REQUEST:
      return { ...state, userEducation: initialUserEducations().userEducation, loading: true, status: "processing" }
    case TypeKeys.GET_USER_EDUCATION_SUCCESS:
      return { ...state, userEducation: action.userEducation, loading: false, status: "ready" }
    case TypeKeys.ASSIGN_USER_EDUCATIONS_REQUEST:
      return { ...state, status: "processing" }
    case TypeKeys.ASSIGN_USER_EDUCATIONS_SUCCESS:
      return { ...state, status: "success", code: action.status }
    case TypeKeys.ASSIGN_USER_EDUCATIONS_ERROR:
      return { ...state, status: "error", code: [], errorCode: action.status }
    default:
    return { ...state }
  }
}

export default userEducationsReducer
