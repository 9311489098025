import { takeLatest, put, call } from 'redux-saga/effects'
import { TypeKeys } from '../actions/actionTypes'
import { getTasksSuccess, getTaskSuccess, createTaskSuccess, updateTaskSuccess }
  from '../actions/actionCreators/tasksActions'
import callApi from '../utils/callApi'
import config from '../config'

const mapComment = (c) => {
  return {
    id: c.id,
    comment: c.comment,
    imageMainUrl: c.image_main_url,
    replyTo: c.reply_to ? mapComment(c.reply_to) : null,
    createdAt: c.created_at,
    author: {
      id: c.author.id,
      firstName: c.author.first_name,
      lastName: c.author.last_name,
      avatar: c.author.full_avatar_url,
      phone: c.author.phone,
      blocked: c.author.blocked
    },
  }
}

const mapTask = (task) => {
  return {
    id: task.id,
    title: task.title,
    description: task.description,
    dueDate: task.due_date,
    workflowState: task.workflow_state,
    localWorkflowState: task.local_workflow_state,
    urgent: task.urgent,
    archivedAt: task.archived_at,
    createdAt: task.created_at,
    updatedAt: task.updated_at,
    availableEvents: task.available_events,
    performanceIndicator: task.performance_indicator,
    performanceIndicatorId: task.performance_indicator_id || '',
    assignee: {
        id: task.assignee.id,
        firstName: task.assignee.first_name,
        lastName: task.assignee.last_name,
        phone: task.assignee.phone,
        avatar: task.assignee.avatar
    },
    reporter: {
        id: task.reporter.id,
        firstName: task.reporter.first_name,
        lastName: task.reporter.last_name,
        phone: task.reporter.phone,
        avatar: task.reporter.avatar
    },
    taskType: task.task_type,
    taskTypeId: task.task_type_id || '',
    team: {
      id: task.team.id,
      title: task.team.title,
      archived: task.team.archived,
      teamAvatarUrl: task.team.team_avatar_url,
    },
    participant: task.participant,
    comments: task.comments ? task.comments.map(mapComment) : null,
    additionalAttributes: task.additional_attributes,
    additionalRoAttributes: task.additional_ro_attributes
  }
}

function* loadTasks(action) {
  const res = yield call(callApi, `${config.BASE_URL}/admin/api/v1/tasks?${action.query}`)
  const { pagination, tasks } = res.data
  const paginationData = {
    currentPage: pagination.current_page,
    totalPages: pagination.total_pages,
    nextPage: pagination.next_page,
    prevPage: pagination.prev_page,
    totalCount: pagination.total_count
  }
  yield put(getTasksSuccess(tasks.map(mapTask), paginationData))
}

function* loadTask(action) {
  const res = yield call(callApi,
    `${config.BASE_URL}/admin/api/v1/tasks/${action.id}`)
  yield put(getTaskSuccess(mapTask(res.data)))
}

function* createTask(action) {
  const res = yield call(callApi,
    `${config.BASE_URL}/admin/api/v1/tasks`,
    { method: 'POST', data: action.query.data })
  yield put(createTaskSuccess(mapTask(res.data)))
}

function* updateTask(action) {
  const res = yield call(callApi,
    `${config.BASE_URL}/admin/api/v1/tasks/${action.query.taskId}`,
    { method: 'PUT', data: action.query.data })
  yield put(updateTaskSuccess(mapTask(res.data)))
}

export const tasksSagas = [
  takeLatest([TypeKeys.GET_TASKS_REQUEST], loadTasks),
  takeLatest([TypeKeys.GET_TASK_REQUEST], loadTask),
  takeLatest([TypeKeys.CREATE_TASK_REQUEST], createTask),
  takeLatest([TypeKeys.UPDATE_TASK_REQUEST], updateTask),
]
