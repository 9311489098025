import React from "react";
import EducationForm from "./EducationForm"
import { useParams } from "react-router-dom"
import { connect } from 'react-redux'
import { useEffect } from "react"
import { getEducationCategoryRequest } from "../../actions/actionCreators/educationCategoriesActions"
import { getEducationRequest, updateEducationRequest } from "../../actions/actionCreators/educationsActions"

const EducationEdit = ({ educationCategory, educationCategoryLoading, education,
  educationLoading, loadEducation, loadEducationCategory, updateEducationRequest,
  history }) => {
  const params = useParams()

  useEffect(() => {
    loadEducationCategory(params.educationCategoryId)
    loadEducation(params.educationCategoryId, params.id)
  }, [params.educationCategoryId, params.id]);

  if (education) {
    return(<EducationForm key={`${education.id}-${education.updated_at}`} education={education}
      educationCategory={educationCategory}
      type={"edit"} history={history} submit={updateEducationRequest}></EducationForm>)
  } else {
    return(<div></div>)
  }
}

const mapStateToProps = (state) => ({
  educationCategory: state.educationCategoriesReducer.educationCategory,
  educationCategoryLoading: state.educationCategoriesReducer.loading,
  education: state.educationsReducer.education,
  educationLoading: state.educationsReducer.loading,
})

const mapDispatchToProps = (dispatch) => ({
  loadEducationCategory: (id) => dispatch(getEducationCategoryRequest(id)),
  loadEducation: (categoryId, id) => dispatch(getEducationRequest(categoryId, id)),
  updateEducationRequest: (query) => dispatch(updateEducationRequest(query))
})

export default connect(mapStateToProps, mapDispatchToProps)(EducationEdit)
