export const TypeKeys = {
	// Dashboard
	GET_DASHBOARD_CLOSED_DATES_REQUEST: 'GET_DASHBOARD_CLOSED_DATES_REQUEST',
	GET_DASHBOARD_CLOSED_DATES_SUCCESS: 'GET_DASHBOARD_CLOSED_DATES_SUCCESS',
	GET_DASHBOARD_PLANS_UPDATED_REQUEST: 'GET_DASHBOARD_PLANS_UPDATED_REQUEST',
	GET_DASHBOARD_PLANS_UPDATED_SUCCESS: 'GET_DASHBOARD_PLANS_UPDATED_SUCCESS',
	GET_DASHBOARD_PLAN_VALUES_REQUEST: 'GET_DASHBOARD_PLAN_VALUES_REQUEST',
	GET_DASHBOARD_PLAN_VALUES_SUCCESS: 'GET_DASHBOARD_PLAN_VALUES_SUCCESS',
	GET_DASHBOARD_PLAN_COMPLETE_REQUEST: 'GET_DASHBOARD_PLAN_COMPLETE_REQUEST',
	GET_DASHBOARD_PLAN_COMPLETE_SUCCESS: 'GET_DASHBOARD_PLAN_COMPLETE_SUCCESS',
	GET_DASHBOARD_ZERO_RESULTS_REQUEST: 'GET_DASHBOARD_ZERO_RESULTS_REQUEST',
	GET_DASHBOARD_ZERO_RESULTS_SUCCESS: 'GET_DASHBOARD_ZERO_RESULTS_SUCCESS',
	GET_DASHBOARD_ZERO_TEAMS_RESULTS_REQUEST: 'GET_DASHBOARD_ZERO_TEAMS_RESULTS_REQUEST',
	GET_DASHBOARD_ZERO_TEAMS_RESULTS_SUCCESS: 'GET_DASHBOARD_ZERO_TEAMS_RESULTS_SUCCESS',
	GET_DASHBOARD_RESULTS_LOSS_REQUEST: 'GET_DASHBOARD_RESULTS_LOSS_REQUEST',
	GET_DASHBOARD_RESULTS_LOSS_SUCCESS: 'GET_DASHBOARD_RESULTS_LOSS_SUCCESS',
	GET_DASHBOARD_SOCIAL_ACTIVITY_REQUEST: 'GET_DASHBOARD_SOCIAL_ACTIVITY_REQUEST',
	GET_DASHBOARD_SOCIAL_ACTIVITY_SUCCESS: 'GET_DASHBOARD_SOCIAL_ACTIVITY_SUCCESS',
	GET_DASHBOARD_TASK_STATS_REQUEST: 'GET_DASHBOARD_TASK_STATS_REQUEST',
	GET_DASHBOARD_TASK_STATS_SUCCESS: 'GET_DASHBOARD_TASK_STATS_SUCCESS',
	GET_DASHBOARD_SHOWCASE_REQUEST: 'GET_DASHBOARD_SHOWCASE_REQUEST',
	GET_DASHBOARD_SHOWCASE_SUCCESS: 'GET_DASHBOARD_SHOWCASE_SUCCESS',
	GET_DASHBOARD_TEAM_SUMMARY_REQUEST: 'GET_DASHBOARD_TEAM_SUMMARY_REQUEST',
	GET_DASHBOARD_TEAM_SUMMARY_SUCCESS: 'GET_DASHBOARD_TEAM_SUMMARY_SUCCESS',
	DASHBOARD_RESET_REQUEST: 'DASHBOARD_RESET_REQUEST',
	DASHBOARD_RESET_SUCCESS: 'DASHBOARD_RESET_SUCCESS',
	// Calendars
	GET_CALENDAR_REQUEST: 'GET_CALENDAR_REQUEST',
	GET_CALENDAR_SUCCESS: 'GET_CALENDAR_SUCCESS',
	UPDATE_CALENDAR_REQUEST: 'UPDATE_CALENDAR_REQUEST',
	UPDATE_CALENDAR_SUCCESS: 'UPDATE_CALENDAR_SUCCESS',
	// Teams
	GET_TEAMS_REQUEST: 'GET_TEAMS_REQUEST',
	GET_TEAMS_SUCCESS: 'GET_TEAMS_SUCCESS',
	GET_TEAMS_FAIL: 'GET_TEAMS_FAIL',
	GET_TEAM_REQUEST: 'GET_TEAM_REQUEST',
	GET_TEAM_SUCCESS: 'GET_TEAM_SUCCESS',
	GET_TEAM_FAIL: 'GET_TEAM_FAIL',
	GET_TEAM_STATS_REQUEST: 'GET_TEAM_STATS_REQUEST',
	GET_TEAM_STATS_SUCCESS: 'GET_TEAM_STATS_SUCCESS',
	GET_TEAM_STATS_FAIL: 'GET_TEAM_STATS_FAIL',
	GET_TEAMS_LIST_REQUEST: 'GET_TEAMS_LIST_REQUEST',
	GET_TEAMS_LIST_SUCCESS: 'GET_TEAMS_LIST_SUCCESS',
	GET_ROOT_TEAMS_REQUEST: 'GET_ROOT_TEAMS_REQUEST',
	GET_ROOT_TEAMS_SUCCESS: 'GET_ROOT_TEAMS_SUCCESS',
	CREATE_TEAM_REQUEST: 'CREATE_TEAM_REQUEST',
	CREATE_TEAM_SUCCESS: 'CREATE_TEAM_SUCCESS',
	UPDATE_TEAM_REQUEST: 'UPDATE_TEAM_REQUEST',
	UPDATE_TEAM_SUCCESS: 'UPDATE_TEAM_SUCCESS',
	// Companies
	GET_COMPANIES_REQUEST: 'GET_COMPANIES_REQUEST',
	GET_COMPANIES_SUCCESS: 'GET_COMPANIES_SUCCESS',
	GET_COMPANIES_FAIL: 'GET_COMPANIES_FAIL',
	GET_COMPANY_REQUEST: 'GET_COMPANY_REQUEST',
	GET_COMPANY_SUCCESS: 'GET_COMPANY_SUCCESS',
	GET_COMPANY_FAIL: 'GET_COMPANY_FAIL',
	CREATE_COMPANY_REQUEST: 'CREATE_COMPANY_REQUEST',
	CREATE_COMPANY_SUCCESS: 'CREATE_COMPANY_SUCCESS',
	UPDATE_COMPANY_REQUEST: 'UPDATE_COMPANY_REQUEST',
	UPDATE_COMPANY_SUCCESS: 'UPDATE_COMPANY_SUCCESS',
	// Users
	GET_USERS_REQUEST: 'GET_USERS_REQUEST',
	GET_USERS_SUCCESS: 'GET_USERS_SUCCESS',
	GET_USERS_FAIL: 'GET_USERS_FAIL',
	GET_USER_REQUEST: 'GET_USER_REQUEST',
	GET_USER_SUCCESS: 'GET_USER_SUCCESS',
	GET_USER_FAIL: 'GET_USER_FAIL',
	GET_USER_STATS_REQUEST: 'GET_USER_STATS_REQUEST',
	GET_USER_STATS_SUCCESS: 'GET_USER_STATS_SUCCESS',
	GET_USER_STATS_FAIL: 'GET_USER_STATS_FAIL',
	GET_USERS_LIST_REQUEST: 'GET_USERS_LIST_REQUEST',
	GET_USERS_LIST_SUCCESS: 'GET_USERS_LIST_SUCCESS',
	CREATE_USER_REQUEST: 'CREATE_USER_REQUEST',
	CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
	UPDATE_USER_REQUEST: 'UPDATE_USER_REQUEST',
	UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
	UPDATE_USER_ERROR: 'UPDATE_USER_ERROR',
	// Guests
	GET_GUEST_REQUEST: 'GET_GUEST_REQUEST',
	GET_GUEST_SUCCESS: 'GET_GUEST_SUCCESS',
	GET_GUEST_FAIL: 'GET_GUEST_FAIL',
	UPDATE_GUEST_REQUEST: 'UPDATE_GUEST_REQUEST',
	UPDATE_GUEST_SUCCESS: 'UPDATE_GUEST_SUCCESS',
	// Sign in/out
	SIGN_IN_REQUEST: 'SIGN_IN_REQUEST',
	SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
	SIGN_IN_FAIL: 'SIGN_IN_FAIL',
	// Medals
	GET_MEDALS_REQUEST: 'GET_MEDALS_REQUEST',
	GET_MEDALS_SUCCESS: 'GET_MEDALS_SUCCESS',
	GET_MEDALS_FAIL: 'GET_MEDALS_FAIL',
	GET_MEDAL_REQUEST: 'GET_MEDAL_REQUEST',
	GET_MEDAL_SUCCESS: 'GET_MEDAL_SUCCESS',
	GET_MEDAL_FAIL: 'GET_MEDAL_FAIL',
	UPDATE_MEDAL_REQUEST: 'UPDATE_MEDAL_REQUEST',
	UPDATE_MEDAL_SUCCESS: 'UPDATE_MEDAL_SUCCESS',
	UPDATE_MEDAL_FAIL: 'UPDATE_MEDAL_FAIL',
	// Profile
	GET_PROFILE_REQUEST: 'GET_PROFILE_REQUEST',
	GET_PROFILE_SUCCESS: 'GET_PROFILE_SUCCESS',
	GET_PROFILE_FAIL: 'GET_PROFILE_FAIL',
	// User indicators
	GET_USER_INDICATORS_REQUEST: 'GET_USER_INDICATORS_REQUEST',
	GET_USER_INDICATOR_SUCCESS: 'GET_USER_INDICATOR_SUCCESS',
	GET_USER_INDICATOR_FAIL: 'GET_USER_INDICATOR_FAIL',
	// User key dates
	GET_KEY_DATES_REQUEST: 'GET_KEY_DATES_REQUEST',
	GET_KEY_DATES_SUCCESS: 'GET_KEY_DATES_SUCCESS',
	GET_KEY_DATES_FAIL: 'GET_KEY_DATES_FAIL',
	// Results
	GET_USER_RESULTS_REQUEST: 'GET_USER_RESULTS_REQUEST',
	GET_USER_RESULTS_SUCCESS: 'GET_USER_RESULTS_SUCCESS',
	GET_USER_RESULTS_FAIL: 'GET_USER_RESULTS_FAIL',
	// Stories
	GET_USER_STORIES_REQUEST: 'GET_USER_STORIES_REQUEST',
	GET_USER_STORIES_SUCCESS: 'GET_USER_STORIES_SUCCESS',
	GET_USER_STORIES_FAIL: 'GET_USER_STORIES_FAIL',
	// Rewards
	GET_USER_REWARDS_REQUEST: 'GET_USER_REWARDS_REQUEST',
	GET_USER_REWARDS_SUCCESS: 'GET_USER_REWARDS_SUCCESS',
	GET_USER_REWARDS_FAIL: 'GET_USER_REWARDS_FAIL',
	CREATE_USER_REWARD_REQUEST: 'CREATE_USER_REWARD_REQUEST',
	CREATE_USER_REWARD_SUCCESS: 'CREATE_USER_REWARD_SUCCESS',
	CREATE_USER_REWARD_FAIL: 'CREATE_USER_REWARD_FAIL',
	DESTROY_USER_REWARD_REQUEST: 'DESTROY_USER_REWARD_REQUEST',
	DESTROY_USER_REWARD_SUCCESS: 'DESTROY_USER_REWARD_SUCCESS',
	DESTROY_USER_REWARD_FAIL: 'DESTROY_USER_REWARD_FAIL',
	//Reports
	GET_REPORTS_REQUEST: 'GET_REPORTS_REQUEST',
	GET_REPORTS_SUCCESS: 'GET_REPORTS_SUCCESS',
	GET_REPORTS_FAIL: 'GET_REPORTS_FAIL',
	CREATE_REPORT_REQUEST: 'CREATE_REPORT_REQUEST',
	CREATE_REPORT_SUCCESS: 'CREATE_REPORT_SUCCESS',
	CREATE_REPORT_FAIL: 'CREATE_REPORT_FAIL',
	// Competitions
	GET_COMPETITIONS_REQUEST: 'GET_COMPETITIONS_REQUEST',
	GET_COMPETITIONS_SUCCESS: 'GET_COMPETITIONS_SUCCESS',
	// Team indicators
	GET_TEAM_USER_INDICATORS_REQUEST: 'GET_TEAM_USER_INDICATORS_REQUEST',
	GET_TEAM_USER_INDICATORS_SUCCESS: 'GET_TEAM_USER_INDICATORS_SUCCESS',
	UPDATE_TEAM_USER_INDICATOR_REQUEST: 'UPDATE_TEAM_USER_INDICATOR_REQUEST',
	UPDATE_TEAM_USER_INDICATOR_SUCCESS: 'UPDATE_TEAM_USER_INDICATOR_SUCCESS',
	SAVE_TEAM_USER_INDICATORS_REQUEST: 'SAVE_TEAM_USER_INDICATORS_REQUEST',
	SAVE_TEAM_USER_INDICATORS_SUCCESS: 'SAVE_TEAM_USER_INDICATORS_SUCCESS',
	// Award types
	GET_AWARD_TYPES_REQUEST: 'GET_AWARD_TYPES_REQUEST',
	GET_AWARD_TYPES_SUCCESS: 'GET_AWARD_TYPES_SUCCESS',
	GET_AWARD_TYPE_REQUEST: 'GET_AWARD_TYPE_REQUEST',
	GET_AWARD_TYPE_SUCCESS: 'GET_AWARD_TYPE_SUCCESS',
	CREATE_AWARD_TYPE_REQUEST: 'CREATE_AWARD_TYPE_REQUEST',
	CREATE_AWARD_TYPE_SUCCESS: 'CREATE_AWARD_TYPE_SUCCESS',
	UPDATE_AWARD_TYPE_REQUEST: 'UPDATE_AWARD_TYPE_REQUEST',
	UPDATE_AWARD_TYPE_SUCCESS: 'UPDATE_AWARD_TYPE_SUCCESS',
	GET_AWARD_HISTORY_REQUEST: 'GET_AWARD_HISTORY_REQUEST',
	GET_AWARD_HISTORY_SUCCESS: 'GET_AWARD_HISTORY_SUCCESS',
	// Kpi Channels
	GET_KPI_CHANNELS_REQUEST: 'GET_KPI_CHANNELS_REQUEST',
	GET_KPI_CHANNELS_SUCCESS: 'GET_KPI_CHANNELS_SUCCESS',
	GET_KPI_CHANNEL_REQUEST: 'GET_KPI_CHANNEL_REQUEST',
	GET_KPI_CHANNEL_SUCCESS: 'GET_KPI_CHANNEL_SUCCESS',
	CREATE_KPI_CHANNEL_REQUEST: 'CREATE_KPI_CHANNEL_REQUEST',
	CREATE_KPI_CHANNEL_SUCCESS: 'CREATE_KPI_CHANNEL_SUCCESS',
	UPDATE_KPI_CHANNEL_REQUEST: 'UPDATE_KPI_CHANNEL_REQUEST',
	UPDATE_KPI_CHANNEL_SUCCESS: 'UPDATE_KPI_CHANNEL_SUCCESS',
	// Performance indicators
	GET_PERFORMANCE_INDICATORS_REQUEST: 'GET_PERFORMANCE_INDICATORS_REQUEST',
	GET_PERFORMANCE_INDICATORS_SUCCESS: 'GET_PERFORMANCE_INDICATORS_SUCCESS',
	UPDATE_PERFORMANCE_INDICATOR_REQUEST: 'UPDATE_PERFORMANCE_INDICATOR_REQUEST',
	UPDATE_PERFORMANCE_INDICATOR_SUCCESS: 'UPDATE_PERFORMANCE_INDICATOR_SUCCESS',
	// User positions
	GET_USER_POSITIONS_REQUEST: 'GET_USER_POSITIONS_REQUEST',
	GET_USER_POSITIONS_SUCCESS: 'GET_USER_POSITIONS_SUCCESS',
	GET_USER_POSITIONS_ERROR: 'GET_USER_POSITIONS_ERROR',
	GET_USER_POSITION_REQUEST: 'GET_USER_POSITION_REQUEST',
	GET_USER_POSITION_SUCCESS: 'GET_USER_POSITION_SUCCESS',
	CREATE_USER_POSITION_REQUEST: 'CREATE_USER_POSITION_REQUEST',
	CREATE_USER_POSITION_SUCCESS: 'CREATE_USER_POSITION_SUCCESS',
	UPDATE_USER_POSITION_REQUEST: 'UPDATE_USER_POSITION_REQUEST',
	UPDATE_USER_POSITION_SUCCESS: 'UPDATE_USER_POSITION_SUCCESS',
	// Areas
	GET_AREAS_REQUEST: 'GET_AREAS_REQUEST',
	GET_AREAS_SUCCESS: 'GET_AREAS_SUCCESS',
	GET_AREA_REQUEST: 'GET_AREA_REQUEST',
	GET_AREA_SUCCESS: 'GET_AREA_SUCCESS',
	UPDATE_AREA_REQUEST: 'UPDATE_AREA_REQUEST',
	UPDATE_AREA_SUCCESS: 'UPDATE_AREA_SUCCESS',
	CREATE_AREA_REQUEST: 'CREATE_AREA_REQUEST',
	CREATE_AREA_SUCCESS: 'CREATE_AREA_SUCCESS',
	// Divisions
	GET_DIVISIONS_REQUEST: 'GET_DIVISIONS_REQUEST',
	GET_DIVISIONS_SUCCESS: 'GET_DIVISIONS_SUCCESS',
	GET_DIVISION_REQUEST: 'GET_DIVISION_REQUEST',
	GET_DIVISION_SUCCESS: 'GET_DIVISION_SUCCESS',
	UPDATE_DIVISION_REQUEST: 'UPDATE_DIVISION_REQUEST',
	UPDATE_DIVISION_SUCCESS: 'UPDATE_DIVISION_SUCCESS',
	CREATE_DIVISION_REQUEST: 'CREATE_DIVISION_REQUEST',
	CREATE_DIVISION_SUCCESS: 'CREATE_DIVISION_SUCCESS',
	// Newsfeeds
	GET_NEWSFEED_EDITORS_REQUEST: 'GET_NEWSFEED_EDITORS_REQUEST',
	GET_NEWSFEED_EDITORS_SUCCESS: 'GET_NEWSFEED_EDITORS_SUCCESS',
	GET_NEWSFEED_TEAMS_REQUEST: 'GET_NEWSFEED_TEAMS_REQUEST',
	GET_NEWSFEED_TEAMS_SUCCESS: 'GET_NEWSFEED_TEAMS_SUCCESS',
	GET_NEWSFEED_MESSAGES_REQUEST: 'GET_NEWSFEED_MESSAGES_REQUEST',
	GET_NEWSFEED_MESSAGES_SUCCESS: 'GET_NEWSFEED_MESSAGES_SUCCESS',
	CREATE_NEWSFEED_MESSAGE_REQUEST: 'CREATE_NEWSFEED_MESSAGE_REQUEST',
	CREATE_NEWSFEED_MESSAGE_SUCCESS: 'CREATE_NEWSFEED_MESSAGE_SUCCESS',
	CREATE_NEWSFEED_MESSAGE_ERROR: 'CREATE_NEWSFEED_MESSAGE_ERROR',
	// ChecklistCategories
	GET_CHECKLIST_CATEGORIES_REQUEST: 'GET_CHECKLIST_CATEGORIES_REQUEST',
	GET_CHECKLIST_CATEGORIES_SUCCESS: 'GET_CHECKLIST_CATEGORIES_SUCCESS',
	GET_CHECKLIST_CATEGORY_REQUEST: 'GET_CHECKLIST_CATEGORY_REQUEST',
	GET_CHECKLIST_CATEGORY_SUCCESS: 'GET_CHECKLIST_CATEGORY_SUCCESS',
	UPDATE_CHECKLIST_CATEGORY_REQUEST: 'UPDATE_CHECKLIST_CATEGORY_REQUEST',
	UPDATE_CHECKLIST_CATEGORY_SUCCESS: 'UPDATE_CHECKLIST_CATEGORY_SUCCESS',
	CREATE_CHECKLIST_CATEGORY_REQUEST: 'CREATE_CHECKLIST_CATEGORY_REQUEST',
	CREATE_CHECKLIST_CATEGORY_SUCCESS: 'CREATE_CHECKLIST_CATEGORY_SUCCESS',
	// Checklists
	GET_CHECKLISTS_REQUEST: 'GET_CHECKLISTS_REQUEST',
	GET_CHECKLISTS_SUCCESS: 'GET_CHECKLISTS_SUCCESS',
	GET_CHECKLIST_REQUEST: 'GET_CHECKLIST_REQUEST',
	GET_CHECKLIST_SUCCESS: 'GET_CHECKLIST_SUCCESS',
	UPDATE_CHECKLIST_REQUEST: 'UPDATE_CHECKLIST_REQUEST',
	UPDATE_CHECKLIST_SUCCESS: 'UPDATE_CHECKLIST_SUCCESS',
	CREATE_CHECKLIST_REQUEST: 'CREATE_CHECKLIST_REQUEST',
	CREATE_CHECKLIST_SUCCESS: 'CREATE_CHECKLIST_SUCCESS',
	// Tasks
	GET_TASKS_REQUEST: 'GET_TASKS_REQUEST',
	GET_TASKS_SUCCESS: 'GET_TASKS_SUCCESS',
	GET_TASK_REQUEST: 'GET_TASK_REQUEST',
	GET_TASK_SUCCESS: 'GET_TASK_SUCCESS',
	CREATE_TASK_REQUEST: 'CREATE_TASK_REQUEST',
	CREATE_TASK_SUCCESS: 'CREATE_TASK_SUCCESS',
	UPDATE_TASK_REQUEST: 'UPDATE_TASK_REQUEST',
	UPDATE_TASK_SUCCESS: 'UPDATE_TASK_SUCCESS',
	// Task types
	GET_TASK_TYPES_REQUEST: 'GET_TASK_TYPES_REQUEST',
	GET_TASK_TYPES_SUCCESS: 'GET_TASK_TYPES_SUCCESS',
	GET_TASK_TYPE_REQUEST: 'GET_TASK_TYPE_REQUEST',
	GET_TASK_TYPE_SUCCESS: 'GET_TASK_TYPE_SUCCESS',
	CREATE_TASK_TYPE_REQUEST: 'CREATE_TASK_TYPE_REQUEST',
	CREATE_TASK_TYPE_SUCCESS: 'CREATE_TASK_TYPE_SUCCESS',
	UPDATE_TASK_TYPE_REQUEST: 'UPDATE_TASK_TYPE_REQUEST',
	UPDATE_TASK_TYPE_SUCCESS: 'UPDATE_TASK_TYPE_SUCCESS',
	// Areas
	GET_INTEGRATION_TOKENS_REQUEST: 'GET_INTEGRATION_TOKENS_REQUEST',
	GET_INTEGRATION_TOKENS_SUCCESS: 'GET_INTEGRATION_TOKENS_SUCCESS',
	GET_INTEGRATION_TOKEN_REQUEST: 'GET_INTEGRATION_TOKEN_REQUEST',
	GET_INTEGRATION_TOKEN_SUCCESS: 'GET_INTEGRATION_TOKEN_SUCCESS',
	UPDATE_INTEGRATION_TOKEN_REQUEST: 'UPDATE_INTEGRATION_TOKEN_REQUEST',
	UPDATE_INTEGRATION_TOKEN_SUCCESS: 'UPDATE_INTEGRATION_TOKEN_SUCCESS',
	CREATE_INTEGRATION_TOKEN_REQUEST: 'CREATE_INTEGRATION_TOKEN_REQUEST',
	CREATE_INTEGRATION_TOKEN_SUCCESS: 'CREATE_INTEGRATION_TOKEN_SUCCESS',
	GET_INTEGRATION_TOKEN_HISTORY_REQUEST: 'GET_INTEGRATION_TOKEN_HISTORY_REQUEST',
	GET_INTEGRATION_TOKEN_HISTORY_SUCCESS: 'GET_INTEGRATION_TOKEN_HISTORY_SUCCESS',
	// Education Categories
	GET_EDUCATION_CATEGORIES_REQUEST: 'GET_EDUCATION_CATEGORIES_REQUEST',
	GET_EDUCATION_CATEGORIES_SUCCESS: 'GET_EDUCATION_CATEGORIES_SUCCESS',
	GET_EDUCATION_CATEGORY_REQUEST: 'GET_EDUCATION_CATEGORY_REQUEST',
	GET_EDUCATION_CATEGORY_SUCCESS: 'GET_EDUCATION_CATEGORY_SUCCESS',
	CREATE_EDUCATION_CATEGORY_REQUEST: 'CREATE_EDUCATION_CATEGORY_REQUEST',
	CREATE_EDUCATION_CATEGORY_SUCCESS: 'CREATE_EDUCATION_CATEGORY_SUCCESS',
	UPDATE_EDUCATION_CATEGORY_REQUEST: 'UPDATE_EDUCATION_CATEGORY_REQUEST',
	UPDATE_EDUCATION_CATEGORY_SUCCESS: 'UPDATE_EDUCATION_CATEGORY_SUCCESS',
	// Educations
	GET_EDUCATIONS_REQUEST: 'GET_EDUCATIONS_REQUEST',
	GET_EDUCATIONS_SUCCESS: 'GET_EDUCATIONS_SUCCESS',
	GET_EDUCATION_REQUEST: 'GET_EDUCATION_REQUEST',
	GET_EDUCATION_SUCCESS: 'GET_EDUCATION_SUCCESS',
	CREATE_EDUCATION_REQUEST: 'CREATE_EDUCATION_REQUEST',
	CREATE_EDUCATION_SUCCESS: 'CREATE_EDUCATION_SUCCESS',
	UPDATE_EDUCATION_REQUEST: 'UPDATE_EDUCATION_REQUEST',
	UPDATE_EDUCATION_SUCCESS: 'UPDATE_EDUCATION_SUCCESS',
	// Education blocks
	GET_EDUCATION_BLOCKS_REQUEST: 'GET_EDUCATION_BLOCKS_REQUEST',
	GET_EDUCATION_BLOCKS_SUCCESS: 'GET_EDUCATION_BLOCKS_SUCCESS',
	GET_EDUCATION_BLOCK_REQUEST: 'GET_EDUCATION_BLOCK_REQUEST',
	GET_EDUCATION_BLOCK_SUCCESS: 'GET_EDUCATION_BLOCK_SUCCESS',
	CREATE_EDUCATION_BLOCK_REQUEST: 'CREATE_EDUCATION_BLOCK_REQUEST',
	CREATE_EDUCATION_BLOCK_SUCCESS: 'CREATE_EDUCATION_BLOCK_SUCCESS',
	UPDATE_EDUCATION_BLOCK_REQUEST: 'UPDATE_EDUCATION_BLOCK_REQUEST',
	UPDATE_EDUCATION_BLOCK_SUCCESS: 'UPDATE_EDUCATION_BLOCK_SUCCESS',
	// Education tests
	GET_EDUCATION_TESTS_REQUEST: 'GET_EDUCATION_TESTS_REQUEST',
	GET_EDUCATION_TESTS_SUCCESS: 'GET_EDUCATION_TESTS_SUCCESS',
	GET_EDUCATION_TEST_REQUEST: 'GET_EDUCATION_TEST_REQUEST',
	GET_EDUCATION_TEST_SUCCESS: 'GET_EDUCATION_TEST_SUCCESS',
	CREATE_EDUCATION_TEST_REQUEST: 'CREATE_EDUCATION_TEST_REQUEST',
	CREATE_EDUCATION_TEST_SUCCESS: 'CREATE_EDUCATION_TEST_SUCCESS',
	UPDATE_EDUCATION_TEST_REQUEST: 'UPDATE_EDUCATION_TEST_REQUEST',
	UPDATE_EDUCATION_TEST_SUCCESS: 'UPDATE_EDUCATION_TEST_SUCCESS',
	// User Educations
	GET_USER_EDUCATIONS_REQUEST: 'GET_USER_EDUCATIONS_REQUEST',
	GET_USER_EDUCATIONS_SUCCESS: 'GET_USER_EDUCATIONS_SUCCESS',
	GET_USER_EDUCATION_REQUEST: 'GET_USER_EDUCATION_REQUEST',
	GET_USER_EDUCATION_SUCCESS: 'GET_USER_EDUCATION_SUCCESS',
	ASSIGN_USER_EDUCATIONS_REQUEST: 'ASSIGN_USER_EDUCATIONS_REQUEST',
	ASSIGN_USER_EDUCATIONS_SUCCESS: 'ASSIGN_USER_EDUCATIONS_SUCCESS',
	ASSIGN_USER_EDUCATIONS_ERROR: 'ASSIGN_USER_EDUCATIONS_ERROR',
}
