import React from "react";
import Checklist from "./Checklist"
import { useParams } from "react-router-dom"
import { connect } from 'react-redux'
import { useEffect } from "react";
import { getChecklistRequest, updateChecklistRequest } from "../../actions/actionCreators/checklistsActions"

const ChecklistEdit = ({ checklist, loading, loadChecklist, updateChecklistRequest, history }) => {
  const params = useParams()

  useEffect(() => {
    loadChecklist(params.checklistCategoryId, params.id)
  }, [params.checklistCategoryId, params.id]);

  if (checklist) {
    return(<Checklist key={`${checklist.id}-${checklist.updated_at}`} checklist={checklist}
      type={"edit"} history={history} submit={updateChecklistRequest}></Checklist>)
  } else {
    return(<div></div>)
  }
}

const mapStateToProps = (state) => ({
  checklist: state.checklistsReducer.checklist,
  loading: state.checklistsReducer.loading,
})

const mapDispatchToProps = (dispatch) => ({
  loadChecklist: (categoryId, id) => dispatch(getChecklistRequest(categoryId, id)),
  updateChecklistRequest: (query) => dispatch(updateChecklistRequest(query))
})

export default connect(mapStateToProps, mapDispatchToProps)(ChecklistEdit)
