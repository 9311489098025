import { initialTasks } from "../initial/initialTasks.js"
import { TypeKeys } from "../actions/actionTypes"

export const tasksReducer = (state = initialTasks(), action) => {
  switch (action.type) {
    case TypeKeys.GET_TASKS_REQUEST:
      return { ...state, tasks: initialTasks().tasks, loading: true, status: "processing" }
    case TypeKeys.GET_TASKS_SUCCESS:
      return { ...state, tasks: action.tasks, loading: false, pagination: {...action.pagination }, status: "ready" }
    case TypeKeys.GET_TASK_REQUEST:
      return { ...state, task: initialTasks().task, loading: true, status: "processing" }
    case TypeKeys.GET_TASK_SUCCESS:
      return { ...state, task: action.task, loading: false, status: "ready" }
    case TypeKeys.CREATE_TASK_REQUEST:
      return { ...state, task: initialTasks().task, loading: true, status: "processing" }
    case TypeKeys.CREATE_TASK_SUCCESS:
      return { ...state, task: action.task, loading: false, status: "success" }
    case TypeKeys.UPDATE_TASK_REQUEST:
      return { ...state, task: initialTasks().task, loading: true, status: "processing" }
    case TypeKeys.UPDATE_TASK_SUCCESS:
      return { ...state, task: action.task, loading: false, status: "success" }
    default:
    return { ...state }
  }
}

export default tasksReducer
