import React from "react";

import { downloadFile } from '../../utils/downloadFile'
import moment from 'moment'
import 'moment/locale/ru'

import { TableCell, TableRow } from '@mui/material';
import { Hidden, Button, CircularProgress } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';

const ReportRow = ({ report }) => {

  moment.locale('ru')

  const reportName = (name) => {
    switch (name) {
      case 'team_tree':
        return 'Отчет по составу команд'
        break;
      case 'team_plans':
        return 'Отчет по выставлению планов'
        break;
      case 'active_users':
        return 'Отчет о количестве пользователей'
        break;
      case 'closed_dates':
        return 'Отчет по закрытию дня'
        break;
      case 'kpi_results':
        return 'Отчет по KPI'
        break;
      case 'competition_results':
        return 'Отчет по акциям'
        break;
      case 'awards':
        return 'Отчет по наградам'
        break;
      case 'social_activity':
        return 'Отчет по социальной активности'
        break;
      case 'stories':
        return 'Отчет по историям'
        break;
      case 'empty_results':
        return 'Отчет по нулевым результатам'
        break;
      case 'results_efficiency':
        return 'Отчет по эффективности'
        break;
      case 'group_chats':
        return 'Отчет по групповым чатам'
        break;
      case 'team_social_activity':
        return 'Отчет по социальной активности по командам'
        break;
      case 'tasks':
        return 'Отчет по задачам'
        break;
      case 'advanced_tasks':
        return 'Отчет по задачам(расширенный)'
        break;
      case 'geopoints':
        return 'Отчет по локациям'
        break;
      case 'checklists':
        return 'Отчет по чеклистам'
        break;
      case 'educations':
        return 'Отчет по обучению'
        break;
      case 'deals':
        return 'Отчет по сделкам'
        break;
      default:
        return 'Неизвестный тип отчета'
    }
  }

  const statusName = (name) => {
    switch (name) {
      case 'done':
        return 'Готов'
        break;
      case 'in_progress':
        return 'Выполняется'
        break;
      case 'error':
        return 'Ошибка'
        break;
      case 'queue':
        return 'В очереди'
        break;
      default:
        return 'Неизвестный статус'
    }
  }

  const reportDetals = (report) => {
    switch (report.reportType) {
      case 'competition_results':
        return `Акция: ${report.reportParams.competition_title}`
        break;
      case 'active_users':
        return ''
        break;
      case 'checklists':
        return `Компания: ${report.reportParams.company_title}`
        break;
      case 'educations':
        return `Компания: ${report.reportParams.company_title}`
        break;
      case 'social_activity':
        return ''
        break;
      case 'group_chats':
        return `Компания: ${report.reportParams.company_title}`
        break;
      default:
        return `Команда: ${report.reportParams.team_title},`
    }
  }

  const { id, jobError, reportStatus, reportParams, reportType, createdAt, updatedAt } = report

  return (
    <TableRow key={id}>
      <TableCell component="th" scope="row">
        {statusName(reportStatus)}
      </TableCell>
      <TableCell align="left">
        {reportName(reportType)}
      </TableCell>
      <TableCell align="left">
        {reportParams.company_title}
      </TableCell>
      <Hidden mdDown>
        <TableCell align="left">
          { reportDetals(report) }
          <br/>
          { report.reportParams.month_id && `Месяц: ${report.reportParams.month_title}`}
        </TableCell>
        <TableCell align="left">
          {moment.utc(createdAt, moment.HTML5_FMT.DATETIME_LOCAL_M).local().format('DD.MM.YYYY HH:mm:ss')}
        </TableCell>
        <TableCell align="left">
          {moment.utc(updatedAt, moment.HTML5_FMT.DATETIME_LOCAL_M).local().format('DD.MM.YYYY HH:mm:ss')}
        </TableCell>
      </Hidden>
      <TableCell align="left">
        {reportStatus === 'done' && <Button variant="outlined" color="primary" onClick={downloadFile(id)}>
            <GetAppIcon/>
            Скачать
          </Button>}
        {(reportStatus === 'in_progress' || reportStatus === 'queue') && <CircularProgress />}
      </TableCell>
    </TableRow>
  )
}

export default ReportRow
