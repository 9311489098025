import { initialEducationCategories } from "../initial/initialEducationCategories"
import { TypeKeys } from "../actions/actionTypes"

export const educationCategoriesReducer = (state = initialEducationCategories(), action) => {
  switch (action.type) {
    case TypeKeys.GET_EDUCATION_CATEGORIES_REQUEST:
      return { ...state,
        educationCategories: initialEducationCategories().educationCategories,
        loading: true, requestStatus: '' }
    case TypeKeys.GET_EDUCATION_CATEGORIES_SUCCESS:
      return { ...state, educationCategories: action.educationCategories,
        loading: false, requestStatus: '' }
    case TypeKeys.GET_EDUCATION_CATEGORY_REQUEST:
      return { ...state,
        educationCategory: initialEducationCategories().educationCategory,
        loading: true, requestStatus: '' }
    case TypeKeys.GET_EDUCATION_CATEGORY_SUCCESS:
      return { ...state, educationCategory: action.educationCategory,
        loading: false, requestStatus: '' }
    case TypeKeys.CREATE_EDUCATION_CATEGORY_REQUEST:
      return { ...state, educationCategory:
        initialEducationCategories().educationCategory,
        loading: true, requestStatus: '' }
    case TypeKeys.CREATE_EDUCATION_CATEGORY_SUCCESS:
      return { ...state, educationCategory: action.educationCategory,
        loading: false, requestStatus: 'success' }
    case TypeKeys.UPDATE_EDUCATION_CATEGORY_REQUEST:
      return { ...state, educationCategory:
        initialEducationCategories().educationCategory, loading: true, requestStatus: '' }
    case TypeKeys.UPDATE_EDUCATION_CATEGORY_SUCCESS:
      return { ...state, educationCategory: action.educationCategory,
        loading: false, requestStatus: 'success' }
    default:
    return { ...state }
  }
}

export default educationCategoriesReducer
