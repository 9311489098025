import React from "react";
import ChecklistCategoryForm from "./ChecklistCategoryForm"

import { connect } from 'react-redux'
import { createChecklistCategoryRequest } from "../../actions/actionCreators/checklistCategoriesActions"

const ChecklistCategoryNew = ({ createChecklistCategory, companies, history }) => {
  const checklistCategory = {
    title: "",
    company: {
      value: (companies.length > 0) ? companies[0].id : undefined,
      label: (companies.length > 0) ? companies[0].title : undefined
    }
  }

  return(<ChecklistCategoryForm key={+ new Date()} checklistCategory={checklistCategory}
    companies={companies} type={"new"} history={history} submit={createChecklistCategory}
    ></ChecklistCategoryForm>)
}

const mapStateToProps = (state) => ({
  companies: state.profileReducer.companies
})

const mapDispatchToProps = (dispatch) => ({
  createChecklistCategory: (query) => dispatch(createChecklistCategoryRequest(query)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ChecklistCategoryNew)
