import React, { useEffect } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { connect } from 'react-redux'
import { createEducationTestRequest } from "../../actions/actionCreators/educationTestsActions"
import EducationTestForm from "./EducationTestForm"

const EducationTestNewForm = ({ history, createEducationTest, status }) => {
  const params = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    status === 'success' && navigate(`/education_categories/${params.educationCategoryId}/educations/${params.educationId}`)
  }, [status]);

  const educationTest = {
    id: undefined,
    description: '',
    answerOptions: [],
    education: {
      id: params.educationId,
      educationCategory: {
        id: params.educationCategoryId
      }
    }
  }

  return(
    <EducationTestForm key={+ new Date()} type={"new"} history={history}
    submit={createEducationTest} loading={false} educationTest={educationTest}/>
  )
}

const mapStateToProps = (state) => ({
  status: state.educationTestsReducer.requestStatus
})

const mapDispatchToProps = (dispatch) => ({
  createEducationTest: (query) => dispatch(createEducationTestRequest(query)),
})

export default connect(mapStateToProps, mapDispatchToProps)(EducationTestNewForm)
