import React, { useState, useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import { Paper, Grid, Typography, Divider, TextField, Button, MenuItem,
  FormControl, FormControlLabel, FormGroup, InputLabel, Select, Stack,
  Checkbox } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { makeStyles } from '@mui/styles';
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(4),
    '& .MuiTextField-root': {
      marginTop: theme.spacing(1),
      // width: '25ch',
    }
  },
  paper: {
    padding: theme.spacing(2)
  },
  idHeader: {
    marginBottom: theme.spacing(2),
  },
  margin: {
    margin: theme.spacing(0),
    marginRight:  theme.spacing(4),
  },
  formControl: {
    marginTop:  theme.spacing(0),
  },
  buttonMargin: {
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    textDecoration: "none"
  },
  fab: {
    [theme.breakpoints.down('md')]: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  },
}));

const IntegrationTokenForm = ({ integrationToken, companies, submit, type, history, requestStatus }) => {

  const { id } = integrationToken

  const navigate = useNavigate()

  const [values, setValues] = useState({
    enabled: integrationToken.enabled,
    company: integrationToken.company
  });
  const [showToken, setShowToken] = useState(false);
  const mapCompanies = (cs) => {
    return cs.map((option, index) => <MenuItem key={index} value={option.id} label={option.title}>{option.title}</MenuItem> )
  }

  const setEnabledValue = (e) => {
    setValues({ ...values, enabled: e.target.checked })
  }
  const setCompanyValue = (e, value) => {
    setValues({ ...values, company: {value: value.props.value, label: value.props.label} })
  }
  const submitAction = () => {
    submit({
      integrationTokenId: integrationToken.id,
      data: {
        enabled: values.enabled,
        company_id: values.company.value
      }
    })
  }

  useEffect(() => {
    if (requestStatus === "success") {
      navigate(`/integration_tokens`)
    }
  },[requestStatus])

  const classes = useStyles()

  return(
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Typography variant="h6" sx={{marginBottom: "16px"}}>
            { type==="edit" && "Изменить токен интеграции" }
            { type==="new" && "Создать токен интеграции" }
          </Typography>
          <form noValidate autoComplete="off">
            <Grid container spacing={2}>
              { type==="edit" && <Grid item sm={12}>
                <Typography
                  component="p"
                  variant="h6">
                  {showToken ? integrationToken.token : `${integrationToken.token.slice(0, 4)}****${integrationToken.token.slice(-4)}`}
                </Typography>
                <Button variant="contained" sx={{mt: "8px", mb: "8px"}} startIcon={
                  <VisibilityIcon sx={{color: "#FFF"}}/>
                } onClick={() => setShowToken(!showToken)}>
                  {showToken ? "Скрыть" : "Показать"}
                </Button>
                <Typography
                  component="p"
                  variant="h6">
                  Истекает: {moment(integrationToken.expiresAt).format('DD.MM.YYYY')}
                </Typography>
              </Grid> }
              <Grid item sm={12}>
                <FormGroup>
                  <FormControlLabel control={<Checkbox checked={values.enabled} onChange={setEnabledValue}/>} label="Активен" />
                </FormGroup>
              </Grid>
              {(companies.length > 1) &&
                <Grid item sm={12}>
                  <FormControl fullWidth variant="outlined" className={classes.formControl}>
                    <InputLabel id="demo-simple-select-outlined-label">Компания</InputLabel>
                    <Select
                      fullWidth
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={values.company.value}
                      onChange={setCompanyValue}
                      label="Компания"
                      disabled={companies.length === 1}
                    >
                    { mapCompanies(companies) }
                    </Select>
                  </FormControl>
                </Grid>}
              <Grid item sm={12}>
                <Stack spacing={2} direction="row">
                  <Button className={classes.buttonMargin} variant="outlined" to={`/integration_tokens`} component={Link}>
                    Назад
                  </Button>
                  <Button className={classes.buttonMargin} variant="contained" onClick={submitAction} color="primary">
                    { type==="edit" && "Сохранить" }
                    { type==="new" && "Создать" }
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default IntegrationTokenForm;
