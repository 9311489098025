import { TypeKeys } from '../actionTypes'

export const getTaskTypesRequest = () => ({ type: TypeKeys.GET_TASK_TYPES_REQUEST })

export const getTaskTypesSuccess = (taskTypes) => ({
  type: TypeKeys.GET_TASK_TYPES_SUCCESS,
  taskTypes
})

export const getTaskTypeRequest = (id) => ({ type: TypeKeys.GET_TASK_TYPE_REQUEST, id })

export const getTaskTypeSuccess = (taskType) => ({
  type: TypeKeys.GET_TASK_TYPE_SUCCESS,
  taskType
})

export const createTaskTypeRequest = (query) => ({ type: TypeKeys.CREATE_TASK_TYPE_REQUEST, query })

export const createTaskTypeSuccess = (taskType) => ({
  type: TypeKeys.CREATE_TASK_TYPE_SUCCESS,
  taskType
})

export const updateTaskTypeRequest = (query) => ({ type: TypeKeys.UPDATE_TASK_TYPE_REQUEST, query })

export const updateTaskTypeSuccess = (taskType) => ({
  type: TypeKeys.UPDATE_TASK_TYPE_SUCCESS,
  taskType
})
