export const initialNewsfeeds = () => {
  return {
    rootTeams: [],
    editors: [],
    messages: [],
    pagination: {},
    loading: true,
    status: '',
    messageIds: [],
    errors: [],
  }
}
