import React from "react";
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { useEffect } from "react";
import { getAreasRequest } from "../../actions/actionCreators/areasActions"

import { makeStyles, useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { Table, TableBody, TableCell, TableHead,
  TableRow } from '@mui/material';
import { Paper, Grid, Typography, Container, Box,
  Button } from '@mui/material';
import {Add} from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2)
  },
  fab: {
    [theme.breakpoints.down('md')]: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  },
  ignoreVisited: {
    marginLeft: theme.spacing(2),
    color: theme.palette.common.black,
    textDecoration: "none"
  },
}));

const AreasPanel = (props) => {

  const { areas, loading, companies } = props

  useEffect(() => {
    props.loadAreas()
  }, []);

  const classes = useStyles()
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Grid container spacing={2} >
            <Grid item xs container direction="column">
              <Typography variant="h4">
                Эрии участников
              </Typography>
            </Grid>
            <Grid item justify="flex-end">
              <Link to={`/areas/new`} className={classes.ignoreVisited}>
                <Button variant="contained">
                  <Add />
                  &nbsp;Добавить&nbsp;
                </Button>
              </Link>
            </Grid>
          </Grid>
          <Table className={classes.table} size={mobile ? "" : "small"} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell align="left">Название</TableCell>
                { companies.length > 1 && <TableCell align="left" >Компания</TableCell> }
              </TableRow>
            </TableHead>
            <TableBody>
              { loading &&
                <TableRow>
                  <TableCell colSpan={5} align="center"><CircularProgress/></TableCell>
                </TableRow> }
              { !loading && areas.map((area) => {
                const { id, title, company } = area
                return(
                  <TableRow key={id}>
                    <TableCell component="th" scope="row">
                      <Box component={Link} to={`/areas/${id}`} className={classes.ignoreVisited}>
                        <Typography
                          component="span"
                          variant="body1">
                          {id}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="left">
                      <Box component={Link} to={`/areas/${id}`} className={classes.ignoreVisited}>
                        <Typography
                          component="span"
                          variant="body1">
                          {title}
                        </Typography>
                      </Box>
                    </TableCell>
                    { companies.length > 1 && <TableCell align="left">{company.label}</TableCell>}
                  </TableRow>
              )}
            )}
            </TableBody>
          </Table>
        </Paper>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = (state) => ({
  areas: state.areasReducer.areas,
  loading: state.areasReducer.loading,
  companies: state.profileReducer.companies
})

const mapDispatchToProps = (dispatch) => ({
  loadAreas: () => dispatch(getAreasRequest())
})

export default connect(mapStateToProps, mapDispatchToProps)(AreasPanel)
