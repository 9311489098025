import { takeLatest, put, call } from 'redux-saga/effects'
import { TypeKeys } from '../actions/actionTypes'
import { getTaskTypesSuccess, getTaskTypeSuccess, createTaskTypeSuccess,
  updateTaskTypeSuccess }
  from '../actions/actionCreators/taskTypesActions'
import callApi from '../utils/callApi'
import config from '../config'

const mapTaskType = (taskType) => {
  return {
    id: taskType.id,
    name: taskType.name,
    company: {
      value: taskType.company.id,
      label: taskType.company.title
    },
    createdAt: taskType.created_at,
    updatedAt: taskType.updated_at,
    atts: taskType.additional_attributes,
    readOnlyAtts: taskType.additional_ro_attributes,
  }
}

function* loadTaskTypes(action) {
  const res = yield call(callApi, `${config.BASE_URL}/admin/api/v1/task_types`)
  yield put(getTaskTypesSuccess(res.data.map(mapTaskType)))
}

function* loadTaskType(action) {
  const res = yield call(callApi,
    `${config.BASE_URL}/admin/api/v1/task_types/${action.id}`)
  yield put(getTaskTypeSuccess(mapTaskType(res.data)))
}

function* createTaskType(action) {
  const res = yield call(callApi,
    `${config.BASE_URL}/admin/api/v1/task_types`,
    { method: 'POST', data: action.query.data })
  yield put(createTaskTypeSuccess(mapTaskType(res.data)))
}

function* updateTaskType(action) {
  const res = yield call(callApi,
    `${config.BASE_URL}/admin/api/v1/task_types/${action.query.taskTypeId}`,
    { method: 'PUT', data: action.query.data })
  yield put(updateTaskTypeSuccess(mapTaskType(res.data)))
}

export const taskTypesSagas = [
  takeLatest([TypeKeys.GET_TASK_TYPES_REQUEST], loadTaskTypes),
  takeLatest([TypeKeys.GET_TASK_TYPE_REQUEST], loadTaskType),
  takeLatest([TypeKeys.CREATE_TASK_TYPE_REQUEST], createTaskType),
  takeLatest([TypeKeys.UPDATE_TASK_TYPE_REQUEST], updateTaskType),
]
