import React, { useEffect } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { connect } from 'react-redux'
import { createEducationBlockRequest } from "../../actions/actionCreators/educationBlocksActions"
import EducationBlockForm from "./EducationBlockForm"

const EducationBlockNewForm = ({ history, createEducationBlock, status }) => {
  const params = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    status === 'success' && navigate(`/education_categories/${params.educationCategoryId}/educations/${params.educationId}`)
  }, [status]);

  const educationBlock = {
    id: undefined,
    title: '',
    description: '',
    images: [],
    education: {
      id: params.educationId,
      educationCategory: {
        id: params.educationCategoryId
      }
    }
  }

  return(
    <EducationBlockForm key={+ new Date()} type={"new"} history={history} status={status}
    submit={createEducationBlock} loading={false} educationBlock={educationBlock}/>
  )
}

const mapStateToProps = (state) => ({
  status: state.educationBlocksReducer.requestStatus
})

const mapDispatchToProps = (dispatch) => ({
  createEducationBlock: (query) => dispatch(createEducationBlockRequest(query)),
})

export default connect(mapStateToProps, mapDispatchToProps)(EducationBlockNewForm)
