import { Button, Grid, Typography } from '@mui/material'
import React from 'react'
 
export default function SelectFile({ onChangeInput, onClick, file, inputRef }) {
	return (
		<>
			<Typography variant='h5' mb={1}>
				1. Выберите файл
			</Typography>
			<Typography variant='body1' mb={2}>
				Загрузите файл с устройства
			</Typography>
			<Grid container alignItems='center' spacing={1}>
				<Grid item xs={6}>
					<Button variant='outlined' onClick={onClick}>
						Выберите файл
						<input
							ref={inputRef}
							hidden
							accept='.csv'
							multiple
							type='file'
							onChange={onChangeInput}
						/>
					</Button>
				</Grid>

				{file && (
					<Grid item xs={6}>
						<Typography variant='body2'>{file.name}</Typography>
					</Grid>
				)}
			</Grid>
		</>
	)
}
