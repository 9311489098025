import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { Box, Button, Fade, Grid, Modal, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { DeleteUserAlert, DeleteUserForm } from '../moleculs'

export default function ModalDeleteUser({ isOpen, onClose, onClickDelete }) {
	const profile = useSelector(({ profileReducer }) => profileReducer)

	const [isShowForm, setShowForm] = useState(false)
	const openDeleteForm = useCallback(() => setShowForm(true), [])

	return (
		<Modal
			open={isOpen}
			onClose={onClose}
			aria-labelledby='modal-alert-delete'
			aria-describedby='modal-alert-delete-account'
			sx={{
				display: 'flex',
				p: 1,
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			{/* <Fade in={isOpen}> */}
			{isShowForm ? (
				<DeleteUserForm onClose={onClose} onClickDelete={onClickDelete} />
			) : (
				<DeleteUserAlert onClose={onClose} onClickDelete={openDeleteForm} profile={profile} />
			)}
			{/* </Fade> */}
		</Modal>
	)
}

// const Modal = styled(Material.Modal)``

// const Modal = styled(Material.)``
// const Modal = styled(Material.Modal)``
// const Modal = styled(Material.Modal)``
// const Modal = styled(Material.Modal)``

// const styles = {
// 	modal: {
// 		position: 'absolute',
// 		top: '50%',
// 		left: '50%',
// 		transform: 'translate(-50%, -50%)',
// 		width: 400,
// 		bgcolor: 'background.paper',
// 		boxShadow: 24,
// 		// p: 4,
// 	},
// }

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		padding: theme.spacing(4),

		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 400,
		bgcolor: 'background.paper',
		boxShadow: 24,
	},
}))

// example Profile
// {
//     "companies": [
//         {
//             "id": 5,
//             "title": "Чат 2тест",
//             "created_at": "2019-10-15T11:19:23.347Z",
//             "updated_at": "2021-03-31T08:08:59.190Z",
//             "owner_id": 31,
//             "avatar_file_name": null,
//             "avatar_content_type": null,
//             "avatar_file_size": null,
//             "avatar_updated_at": null,
//             "size_limit": 100,
//             "enabled_screens": {
//                 "chats": true,
//                 "reports": true,
//                 "competitions": true,
//                 "ratings": true,
//                 "library": true,
//                 "newsfeed": true,
//                 "hall_of_fame": true
//             },
//             "check_password_expiration": false,
//             "session_timeout_minutes": 43830,
//             "rating_params": [
//                 {
//                     "rating_type": "bottom_teams",
//                     "default_sort": true,
//                     "rating_theme": "regata"
//                 },
//                 {
//                     "rating_type": "top_teams",
//                     "default_sort": true,
//                     "rating_theme": "regata"
//                 },
//                 {
//                     "rating_type": "members",
//                     "default_sort": true,
//                     "rating_theme": "regata"
//                 }
//             ]
//         },
//         {
//             "id": 1,
//             "title": "Бизинтех",
//             "created_at": "2019-09-17T12:23:05.030Z",
//             "updated_at": "2021-12-24T08:47:56.156Z",
//             "owner_id": 1,
//             "avatar_file_name": null,
//             "avatar_content_type": null,
//             "avatar_file_size": null,
//             "avatar_updated_at": null,
//             "size_limit": 100,
//             "enabled_screens": {
//                 "chats": true,
//                 "reports": true,
//                 "competitions": true,
//                 "ratings": true,
//                 "library": true,
//                 "newsfeed": true,
//                 "hall_of_fame": false
//             },
//             "check_password_expiration": false,
//             "session_timeout_minutes": 48300,
//             "rating_params": [
//                 {
//                     "rating_type": "bottom_teams",
//                     "default_sort": true,
//                     "rating_theme": "regata"
//                 },
//                 {
//                     "rating_type": "top_teams",
//                     "default_sort": true,
//                     "rating_theme": "regata"
//                 },
//                 {
//                     "rating_type": "members",
//                     "default_sort": true,
//                     "rating_theme": "regata"
//                 }
//             ]
//         },
//         {
//             "id": 2,
//             "title": "Kirill Inc",
//             "created_at": "2019-09-17T13:57:59.699Z",
//             "updated_at": "2022-03-16T09:55:42.510Z",
//             "owner_id": 2,
//             "avatar_file_name": null,
//             "avatar_content_type": null,
//             "avatar_file_size": null,
//             "avatar_updated_at": null,
//             "size_limit": 150,
//             "enabled_screens": {
//                 "chats": true,
//                 "reports": true,
//                 "competitions": true,
//                 "ratings": true,
//                 "library": true,
//                 "newsfeed": true,
//                 "hall_of_fame": true
//             },
//             "check_password_expiration": false,
//             "session_timeout_minutes": 250000,
//             "rating_params": [
//                 {
//                     "rating_type": "bottom_teams",
//                     "default_sort": true,
//                     "rating_theme": "regata"
//                 },
//                 {
//                     "rating_type": "top_teams",
//                     "default_sort": true,
//                     "rating_theme": "regata"
//                 },
//                 {
//                     "rating_type": "members",
//                     "default_sort": true,
//                     "rating_theme": "regata"
//                 }
//             ]
//         }
//     ],
//     "role": "user",
//     "loading": false,
//     "signInResult": true,
//     "user": {
//         "id": 40,
//         "firstName": "Михаил",
//         "lastName": "Девятнадцатый",
//         "phone": "+7 900 000-00-19",
//         "avatar": "https://s3.bizintex.ru:443/staging/users/40/5a4a65ded0fa60f68e126b2a7ff0545acf5a6e1c.jpg?1688651064",
//         "blocked": null
//     }
// }
