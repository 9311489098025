export const initialEducationCategories = () => {
  return {
    educationCategories: [],
    educationCategory: {
      id: undefined,
      company: {}
    },
    loading: true
  }
}
