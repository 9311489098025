import { takeLatest, put, call, delay } from 'redux-saga/effects'
import { TypeKeys } from '../actions/actionTypes'
import {
	getUsersSuccess,
	getUserSuccess,
	getUsersListSuccess,
	createUserSuccess,
	updateUserSuccess,
	updateUserError,
	getUserStatsSuccess,
	getUserError,
} from '../actions/actionCreators/usersActions'
import callApi from '../utils/callApi'
import config from '../config'
import { Logger } from '../utils'

function mapTeam(team) {
	return {
		id: team.id,
		adminId: team.admin_id,
		createdAt: team.created_at,
		title: team.title,
		parentTeam: team.parent_team,
		admin: team.admin ? `${team.admin.first_name} ${team.admin.last_name}` : 'Не назначен',
		membersCount: team.members_count,
		teamAvatarUrl: team.team_avatar_url,
		parentId: team.parent_id,
		archived: team.archived,
	}
}

function mapUser(user) {
	return {
		id: user.id,
		firstName: user.first_name,
		lastName: user.last_name,
		phone: user.phone,
		createdAt: user.created_at,
		updatedAt: user.updated_at,
		color: user.color,
		createdTeams: user.created_teams?.map(mapTeam) || [],
		teams: user.teams?.map(mapTeam) || [],
		guestTeams: user.guest_teams?.map(mapTeam) || [],
		avatar: user.full_avatar_url,
		runRate: user.run_rate,
		blocked: user.blocked,
		lockedAt: user.locked_at,
		failedAttempts: user.failed_attempts,
		externalId: user.external_id,
		kpiChannelId: user.kpi_channel_id,
		kpiChannelTitle: user.kpi_channel_id ? user.kpi_channel.title : null,
		userPositionId: user.user_position_id,
		userPosition: user.user_position_id
			? { title: user.user_position.title, approved: user.user_position.approved }
			: {},
	}
}

function* loadUsers(action) {
	const res = yield call(callApi, `${config.BASE_URL}/admin/api/v1/users?${action.query}`)
	const { pagination, users } = res.data
	const usersData = users.map(mapUser)
	const paginationData = {
		currentPage: pagination.current_page,
		totalPages: pagination.total_pages,
		nextPage: pagination.next_page,
		prevPage: pagination.prev_page,
		totalCount: pagination.total_count,
	}
	yield put(getUsersSuccess(usersData, paginationData))
}

function* loadUser(action) {
	try {
		const res = yield call(callApi, `${config.BASE_URL}/admin/api/v1/users/${action.id}`)
		yield put(getUserSuccess(mapUser(res.data)))
	} catch (error) {
		Logger.log(error)
		yield put(getUserError())
	}
}

function* loadUserStats(action) {
	const response = yield call(callApi, `${config.BASE_URL}/admin/api/v1/users/${action.id}/stats`)
	yield put(getUserStatsSuccess(response.data))
}

function* loadUsersList(action) {
	const res = yield call(
		callApi,
		`${config.BASE_URL}/admin/api/v1/users/list${action.teamId ? `?team_id=${action.teamId}` : ``}`
	)
	const users = res.data
	const usersData = users.map(user => ({
		value: user.value,
		label: user.label,
	}))
	yield put(getUsersListSuccess(usersData))
}

function* createUser(action) {
	const res = yield call(callApi, `${config.BASE_URL}/admin/api/v1/users`, {
		method: 'POST',
		data: action.query.data,
	})
	yield put(createUserSuccess(mapUser(res.data)))
}

function* updateUser(action) {
	const res = yield call(callApi, `${config.BASE_URL}/admin/api/v1/users/${action.query.userId}`, {
		method: 'PUT',
		data: action.query.data,
	})
	if (res.status === 200) {
		yield put(updateUserSuccess(mapUser(res.data)))
	} else {
		yield put(updateUserError(res.data.errors))
	}
}

export const usersSagas = [
	takeLatest([TypeKeys.GET_USERS_REQUEST], loadUsers),
	takeLatest([TypeKeys.GET_USER_REQUEST], loadUser),
	takeLatest([TypeKeys.GET_USER_STATS_REQUEST], loadUserStats),
	takeLatest([TypeKeys.GET_USERS_LIST_REQUEST], loadUsersList),
	takeLatest([TypeKeys.CREATE_USER_REQUEST], createUser),
	takeLatest([TypeKeys.UPDATE_USER_REQUEST], updateUser),
]
