export const initialTasks = () => {
  return {
    tasks: [],
    task: {
      id: undefined,
      title: undefined,
      description: undefined,
      dueDate: undefined,
      team: {},
      assignee: {},
      reporter: {},
      workflowState: undefined,
      urgent: undefined,
      tags: [],
      availableEvents: [],
      commentsCount: undefined,
      likesCount: undefined,
      dislikesCount: undefined,
      performanceIndicator: undefined,
      taskType: undefined,
      archivedAt: undefined,
      createdAt: undefined,
      updatedAt: undefined,
      comments: [],
      additionalAttributes: [],
      additionalRoAttributes: []
    },
    loading: true,
    pagination: {
      totalPages: 0
    }
  }
}
