import React from "react";
import DivisionForm from "./DivisionForm"

import { connect } from 'react-redux'
import { createDivisionRequest } from "../../actions/actionCreators/divisionsActions"

const DivisionNew = ({ createDivision, companies, history }) => {
  const division = {
    title: "",
    company: {
      value: (companies.length > 0) ? companies[0].id : undefined,
      label: (companies.length > 0) ? companies[0].title : undefined
    }
  }
  return(<DivisionForm key={+ new Date()} division={division} companies={companies}
    submit={createDivision} type={"new"} history={history}></DivisionForm>)
}

const mapStateToProps = (state) => ({
  companies: state.profileReducer.companies
})

const mapDispatchToProps = (dispatch) => ({
  createDivision: (query) => dispatch(createDivisionRequest(query)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DivisionNew)
