import { TypeKeys } from '../actionTypes'

export const getPerformanceIndicatorsRequest = (teamId) => ({ type: TypeKeys.GET_PERFORMANCE_INDICATORS_REQUEST, teamId })

export const getPerformanceIndicatorsSuccess = (performanceIndicators) => ({
  type: TypeKeys.GET_PERFORMANCE_INDICATORS_SUCCESS,
  performanceIndicators
})

export const updatePerformanceIndicatorRequest = (teamId, performanceIndicatorId, query) => (
  { type: TypeKeys.UPDATE_PERFORMANCE_INDICATOR_REQUEST, teamId, performanceIndicatorId, query }
)

export const updatePerformanceIndicatorSuccess = (performanceIndicators) => ({
  type: TypeKeys.UPDATE_PERFORMANCE_INDICATOR_SUCCESS,
  performanceIndicators
})
