import React from "react";
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';

import {Grid, Paper, Typography} from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
  block: {
    margin: theme.spacing(12),
  }
}));

const NotFound = () => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12}>
        <Grid container justify="center" spacing={2}>
            <Grid item className={classes.block} align="center">
              <Typography variant="h1">
                404
              </Typography>
                <Typography variant="h2">
                  Страница не существует
                </Typography>
            </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default NotFound;
