import { HTTPMethod, Request } from '../../../utils'

export default class User {
	static async delete() {
		const { data } = await new Request(
			'Delete User Account',
			HTTPMethod.POST,
			'/api/v1/me/remove_account'
		).start()
		return data
	}
}
