import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { getTeamsListRequest } from "../../actions/actionCreators/teamsActions"

import { FormControl, InputLabel, Select, MenuItem, CircularProgress, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
const useStyles = makeStyles((theme) => ({}));

const TeamSelect = ({ teamsList, loading, loadTeamsList,
    changeTeam, value, label, allowEmpty, companyIds }) => {
  const classes = useStyles()

  const changeSelectedTeam = (e, value) => changeTeam(
    teamsList.find((t) => t.value === value.props.value)
  )

  useEffect(() => {
    if (companyIds && companyIds.length > 0) { loadTeamsList(companyIds) }
  }, [companyIds]);

  if (loading) {
    return (<CircularProgress />)
  }

  if (teamsList.length === 0) {
    return (<Typography>Команды не найдены</Typography>)
  }

  return (
    <FormControl fullWidth variant="outlined" size="small" className={classes.formControl}>
      <InputLabel>{label ? label : "Команда"}</InputLabel>
      <Select fullWidth value={value} onChange={changeSelectedTeam} label={label ? label : "Команда"}
        error={!allowEmpty && (value === null || value === '')}>
      {allowEmpty && <MenuItem key={-1} label={'Все команды'} value={""}>
        Все команды
      </MenuItem>}
      { teamsList.map((option, index) =>
        <MenuItem key={index} value={option.value} label={option.label}
          sx={{paddingLeft: `${16 + 16*option.depth}px`}}>
          {`${companyIds.length > 1 ? `${option.label} - ${option.company}`: option.label}`}
        </MenuItem>) }
      </Select>
    </FormControl>
  )
}
const mapStateToProps = (state) => ({
  teamsList: state.teamsListReducer.teamsList,
  loading: state.teamsListReducer.loading
})

const mapDispatchToProps = (dispatch) => ({
  loadTeamsList: (companyId) => dispatch(getTeamsListRequest(companyId)),
})

const areEqual = (prevProps, nextProps) => {
  const array1 = prevProps.companyIds
  const array2 = nextProps.companyIds
  if (prevProps.value !== nextProps.value) { return false }
  if (array1.length != array2.length) { return false }
  for (var i = 0, l=array1.length; i < l; i++) {
    if (array1[i] != array2[i]) { return false }
  }
  return true
}

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(TeamSelect), areEqual)
