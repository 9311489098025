import React from 'react'
import { Box, Button, Grid, Typography } from '@mui/material'

export default function DeleteUserAlert({ onClose, onClickDelete, profile }) {
	if (!profile.user.is_admin) {
		return (
			<Box
				sx={{
					position: 'relative',
					borderRadius: 4,
					width: 400,
					bgcolor: 'background.paper',
					boxShadow: theme => theme.shadows[5],
					p: 2,
				}}
			>
				<Grid container spacing={4}>
					<Grid item xs={12}>
						<Typography id='modal-alert-delete' variant='h6' component='h6'>
							Удаление аккаунта
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography id='modal-alert-delete-account' sx={{ mt: 2 }}>
							После нажатия кнопки все ваши данные будут удалены. Восстановление станет невозможно.
							Вы действительно хотите удалить аккаунт?
						</Typography>
					</Grid>

					<Grid item xs={6}>
						<Button fullWidth size='large' onClick={onClose}>
							Отменить
						</Button>
					</Grid>
					<Grid item xs={6}>
						<Button fullWidth size='large' onClick={onClickDelete} variant='contained'>
							Да, удалить
						</Button>
					</Grid>
				</Grid>
			</Box>
		)
	} else {
		return (
			<Box
				sx={{
					position: 'relative',
					borderRadius: 4,
					width: 400,
					bgcolor: 'background.paper',
					boxShadow: theme => theme.shadows[5],
					p: 2,
				}}
			>
				<Grid container spacing={4}>
					<Grid item xs={12}>
						<Typography id='modal-alert-delete' variant='h6' component='h6'>
							Удаление аккаунта
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography id='modal-alert-delete-account' sx={{ mt: 2 }}>
							Вы руководитель команды, удаление аккаунта невозможно. Вам необходимо делегировать
							полномочия в настройках команды в мобильном приложении. После того, как вы передадите
							роль администратора вашему коллеге, вы можете повторить удаление аккаунта в этом
							разделе
						</Typography>
					</Grid>

					<Grid item xs={6} />
					<Grid item xs={6}>
						<Button fullWidth size='large' onClick={onClose} variant='contained'>
							Закрыть
						</Button>
					</Grid>
				</Grid>
			</Box>
		)
	}
}
