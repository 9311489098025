import React from "react";
import { useState, useEffect, useCallback } from "react";
import { connect } from 'react-redux';
import { getAreasRequest } from "../../actions/actionCreators/areasActions";
import { getDivisionsRequest } from "../../actions/actionCreators/divisionsActions";
import { updateTeamRequest } from "../../actions/actionCreators/teamsActions";
import { Grid, Select, MenuItem, Typography, Snackbar, CircularProgress, FormControl,
  FormControlLabel, Checkbox, InputLabel } from '@mui/material';

import MuiAlert from '@mui/material/Alert';
import { makeStyles } from '@mui/styles';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(4),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  editButton: {
    marginLeft: theme.spacing(2)
  },
  smallCell: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  }
}));

const Settings = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const { areas, loadAreas, divisions, loadDivisions, team,
    loadingAreas, loadingDivisions, updateTeamRequest } = props

  const updateArea = (e) => {
    updateTeamRequest({teamId: team.id, data: {area_id: e.target.value}})
    setOpen(true)
  }
  const updateDivision = (e) => {
    updateTeamRequest({teamId: team.id, data: {division_id: e.target.value}})
    setOpen(true)
  }

  const updateAdminNullify = (e) => {
    updateTeamRequest({teamId: team.id, data: {admin_nullify: e.target.checked}})
    setOpen(true)
  }

  const updateWeekendAutoClose = (e) => {
    updateTeamRequest({teamId: team.id, data: {weekend_auto_close: e.target.checked}})
    setOpen(true)
  }

  const load = useCallback(() => {
    loadAreas();
    loadDivisions();
  }, [loadAreas, loadDivisions])

  useEffect(() => {
    load()
  }, [team, load]);

  return (
    <Grid container spacing={4} className={classes.root}>
      {team.root && <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControlLabel control={<Checkbox
            checked={team.adminNullify}
            name="long"
            color="primary"
            onChange={updateAdminNullify}
          />} label="Обнуление результатов супервизоров в конце дня" />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel control={<Checkbox
            checked={team.weekendAutoClose}
            name="long"
            color="primary"
            onChange={updateWeekendAutoClose}
          />} label="Автоматическое закрытие для команд, работающих в выходные" />
        </Grid>
      </Grid>}
      {loadingAreas || loadingDivisions ? <CircularProgress />
        :
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl sx={{ m: 1, minWidth: 280 }} size="small">
              <InputLabel id="membersDivision">Эрия супервизора</InputLabel>
              <Select
                labelId="membersDivision"
                id="membersDivisionSelect"
                value={team.divisionId || ''}
                onChange={updateDivision}
                default={null}
                label="Эрия супервизора"
              >
                <MenuItem value={null}>Не выбран</MenuItem>
                { divisions.filter((d) => d.company.value === team.company.id).map((division) =>
                  <MenuItem key={`${division.id}`} value={division.id}>{division.title}</MenuItem>
                ) }
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl sx={{ m: 1, minWidth: 280 }} size="small">
              <InputLabel id="membersArea">Эрия участников</InputLabel>
              <Select
                labelId="membersArea"
                id="membersAreaSelect"
                value={team.areaId || ''}
                onChange={updateArea}
                default={null}
                label="Эрия участников"
              >
                <MenuItem value={null}>Не выбран</MenuItem>
                { areas.filter((a) => a.company.value === team.company.id).map((area) =>
                  <MenuItem key={`${area.id}`} value={area.id}>{area.title}</MenuItem>
                ) }
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      }
    </Grid>
  )
}

const mapStateToProps = (state) => ({
  areas: state.areasReducer.areas,
  divisions: state.divisionsReducer.divisions,
  loadingAreas: state.areasReducer.loading,
  loadingDivisions: state.divisionsReducer.loading,
})

const mapDispatchToProps = (dispatch) => ({
  loadAreas: () => dispatch(getAreasRequest()),
  loadDivisions: () => dispatch(getDivisionsRequest()),
  updateTeamRequest: (query) => dispatch(updateTeamRequest(query))

})

export default connect(mapStateToProps, mapDispatchToProps)(Settings)
