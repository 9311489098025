import React from "react";
import AreaForm from "./AreaForm"

import { connect } from 'react-redux'
import { createAreaRequest } from "../../actions/actionCreators/areasActions"

const AreaNew = ({ createArea, companies, history }) => {
  const area = {
    title: "",
    company: {
      value: (companies.length > 0) ? companies[0].id : undefined,
      label: (companies.length > 0) ? companies[0].title : undefined
    }
  }
  return(<AreaForm key={+ new Date()} area={area} companies={companies}
    submit={createArea} type={"new"} history={history}></AreaForm>)
}

const mapStateToProps = (state) => ({
  companies: state.profileReducer.companies
})

const mapDispatchToProps = (dispatch) => ({
  createArea: (query) => dispatch(createAreaRequest(query)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AreaNew)
