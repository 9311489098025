import { initialAreas } from "../initial/initialAreas.js"
import { TypeKeys } from "../actions/actionTypes"

export const areasReducer = (state = initialAreas(), action) => {
  switch (action.type) {
    case TypeKeys.GET_AREAS_REQUEST:
      return { ...state, areas: initialAreas().areas, loading: true }
    case TypeKeys.GET_AREAS_SUCCESS:
      return { ...state, areas: action.areas, loading: false }
    case TypeKeys.GET_AREA_REQUEST:
      return { ...state, area: initialAreas().area, loading: true }
    case TypeKeys.GET_AREA_SUCCESS:
      return { ...state, area: action.area, loading: false }
    case TypeKeys.CREATE_AREA_REQUEST:
      return { ...state, area: initialAreas().area, loading: true }
    case TypeKeys.CREATE_AREA_SUCCESS:
      return { ...state, area: action.area, loading: false }
    case TypeKeys.UPDATE_AREA_REQUEST:
      return { ...state, area: initialAreas().area, loading: true }
    case TypeKeys.UPDATE_AREA_SUCCESS:
      return { ...state, area: action.area, loading: false }
    default:
    return { ...state }
  }
}

export default areasReducer
