import { TypeKeys } from '../actionTypes'

export const getTasksRequest = (query) => ({ type: TypeKeys.GET_TASKS_REQUEST, query })

export const getTasksSuccess = (tasks, pagination) => ({
  type: TypeKeys.GET_TASKS_SUCCESS,
  tasks, pagination
})

export const getTaskRequest = (id) => ({ type: TypeKeys.GET_TASK_REQUEST, id })

export const getTaskSuccess = (task) => ({
  type: TypeKeys.GET_TASK_SUCCESS,
  task
})

export const createTaskRequest = (query) => ({ type: TypeKeys.CREATE_TASK_REQUEST, query })

export const createTaskSuccess = (task) => ({
  type: TypeKeys.CREATE_TASK_SUCCESS,
  task
})

export const updateTaskRequest = (query) => ({ type: TypeKeys.UPDATE_TASK_REQUEST, query })

export const updateTaskSuccess = (task) => ({
  type: TypeKeys.UPDATE_TASK_SUCCESS,
  task
})
