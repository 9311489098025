import { TypeKeys } from '../actionTypes'

export const getUsersRequest = query => ({ type: TypeKeys.GET_USERS_REQUEST, query })

export const getUsersSuccess = (users, pagination) => ({
	type: TypeKeys.GET_USERS_SUCCESS,
	users,
	pagination,
})

export const getUsersListRequest = teamId => ({ type: TypeKeys.GET_USERS_LIST_REQUEST, teamId })

export const getUsersListSuccess = users => ({
	type: TypeKeys.GET_USERS_LIST_SUCCESS,
	users,
})

export const getUserRequest = id => ({ type: TypeKeys.GET_USER_REQUEST, id })

export const getUserSuccess = user => ({
	type: TypeKeys.GET_USER_SUCCESS,
	user,
})
export const getUserError = user => ({
	type: 'GET_USER_ERROR',
})

export const getUserStatsRequest = id => ({ type: TypeKeys.GET_USER_STATS_REQUEST, id })
export const getUserStatsSuccess = user => ({ type: TypeKeys.GET_USER_STATS_SUCCESS, user })
export const getUserStatsFail = user => ({ type: TypeKeys.GET_USER_STATS_FAIL, user })

export const createUserRequest = query => ({ type: TypeKeys.CREATE_USER_REQUEST, query })

export const createUserSuccess = user => ({
	type: TypeKeys.CREATE_USER_SUCCESS,
	user,
})

export const updateUserRequest = query => ({ type: TypeKeys.UPDATE_USER_REQUEST, query })
export const updateUserSuccess = user => ({
	type: TypeKeys.UPDATE_USER_SUCCESS,
	user,
})
export const updateUserError = errors => ({
	type: TypeKeys.UPDATE_USER_ERROR,
	errors,
})
