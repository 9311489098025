import { initialEducationTests } from "../initial/initialEducationTests"
import { TypeKeys } from "../actions/actionTypes"

export const educationTestsReducer = (state = initialEducationTests(), action) => {
  switch (action.type) {
    case TypeKeys.GET_EDUCATION_TESTS_REQUEST:
      return { ...state, educationTests: initialEducationTests().educationTests,
        loading: true, requestStatus: '' }
    case TypeKeys.GET_EDUCATION_TESTS_SUCCESS:
      return { ...state, educationTests: action.educationTests,
        loading: false, requestStatus: '' }
    case TypeKeys.GET_EDUCATION_TEST_REQUEST:
      return { ...state, educationTest: initialEducationTests().educationTest,
        loading: true, requestStatus: '' }
    case TypeKeys.GET_EDUCATION_TEST_SUCCESS:
      return { ...state, educationTest: action.educationTest,
        loading: false, requestStatus: '' }
    case TypeKeys.CREATE_EDUCATION_TEST_REQUEST:
      return { ...state, educationTest: initialEducationTests().educationTest,
        loading: true, requestStatus: '' }
    case TypeKeys.CREATE_EDUCATION_TEST_SUCCESS:
      return { ...state, educationTest: action.educationTest,
        loading: false, requestStatus: 'success' }
    case TypeKeys.UPDATE_EDUCATION_TEST_REQUEST:
      return { ...state, educationTest: initialEducationTests().educationTest,
        loading: true, requestStatus: '' }
    case TypeKeys.UPDATE_EDUCATION_TEST_SUCCESS:
      return { ...state, educationTest: action.educationTest,
        loading: false, requestStatus: 'success' }
    default:
    return { ...state }
  }
}

export default educationTestsReducer
