import React from "react"
import EducationTestForm from "./EducationTestForm"
import { useParams, useNavigate } from "react-router-dom"
import { connect } from 'react-redux'
import { useEffect } from "react"
import { getEducationTestRequest, updateEducationTestRequest } from "../../actions/actionCreators/educationTestsActions"

const EducationTestEditForm = (props) => {
  const { educationTest, educationTestLoading, status } = props
  const { loadEducationTest, updateEducationTestRequest, history } = props
  const params = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    loadEducationTest(params.educationCategoryId, params.educationId, params.id)
  }, [params.id]);

  useEffect(() => {
    status === 'success' && navigate(`/education_categories/${params.educationCategoryId}/educations/${params.educationId}`)
  }, [status]);

  if (educationTest) {
    return(
      <EducationTestForm key={`${educationTest.id}-${educationTest.updatedAt}`} educationTest={educationTest}
      type={"edit"} history={history} submit={updateEducationTestRequest} loading={educationTestLoading}></EducationTestForm>)
  } else {
    return(<div></div>)
  }
}

const mapStateToProps = (state) => ({
  educationTest: state.educationTestsReducer.educationTest,
  educationTestLoading: state.educationTestsReducer.loading,
  status: state.educationTestsReducer.requestStatus
})

const mapDispatchToProps = (dispatch) => ({
  loadEducationTest: (categoryId, educationId, id) => dispatch(getEducationTestRequest(categoryId, educationId, id)),
  updateEducationTestRequest: (query) => dispatch(updateEducationTestRequest(query))
})

export default connect(mapStateToProps, mapDispatchToProps)(EducationTestEditForm)
