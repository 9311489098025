import React from 'react'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { getTeamRequest } from '../../actions/actionCreators/teamsActions'
import { Link, useParams } from 'react-router-dom'
import { makeStyles, useTheme } from '@mui/styles'

import {
	Card,
	CardContent,
	CardHeader,
	CardActions,
	Container,
	Grid,
	Typography,
	Button,
	Breadcrumbs,
} from '@mui/material'

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		padding: theme.spacing(4),
	},
	paper: {
		padding: theme.spacing(2),
	},
	buttonGroup: {
		paddingTop: theme.spacing(2),
	},
	fab: {
		[theme.breakpoints.down('md')]: {
			position: 'fixed',
			bottom: theme.spacing(2),
			right: theme.spacing(2),
		},
	},
}))

const TeamIndexPanel = props => {
	const params = useParams()
	const { team, loading, loadTeam } = props
	const classes = useStyles()

	useEffect(() => {
		loadTeam(params.teamId)
	}, [params.teamId])

	return (
		<Grid container spacing={2} className={classes.root}>
			<Grid item sm={12}>
				<Breadcrumbs aria-label='breadcrumb'>
					<Link color='inherit' to={'/dashboard'}>
						Аналитика
					</Link>
					<Typography color='textPrimary'>{team.title || '...'}</Typography>
				</Breadcrumbs>
			</Grid>
			<Grid item xs={12} sm={4}>
				<AnaliticCard
					title='Статусы управленческого цикла'
					description='Витрина отображает работу с задачами по командам'
					to={`/dashboard/${team.id}/showcase`}
				/>
			</Grid>
			<Grid item xs={12} sm={4}>
				<AnaliticCard
					title='Закрытие дня'
					description='Число руководителей отделений и сотрудников закрывающих день'
					to={`/dashboard/${team.id}/closed_dates`}
				/>
			</Grid>
			<Grid item xs={12} sm={4}>
				<AnaliticCard
					title='Дата последней постановки плана'
					description='Витрина отображает даты последнего изменения плана руководителями отделений'
					to={`/dashboard/${team.id}/plans_updated`}
				/>
			</Grid>
			<Grid item xs={12} sm={4}>
				<AnaliticCard
					title='Распределение плана'
					description='Витрина отображает распределение плана по выбранной задаче до уровня сотрудников отделения'
					to={`/dashboard/${team.id}/plan_value`}
				/>
			</Grid>
			<Grid item xs={12} sm={4}>
				<AnaliticCard
					title='Выполнение плана'
					description='Витрина отображает выполнение плана по выбранной задаче до уровня сотрудников отделения'
					to={`/dashboard/${team.id}/plan_complete`}
				/>
			</Grid>
			<Grid item xs={12} sm={4}>
				<AnaliticCard
					title='Нулевые результаты сотрудников'
					description='Витрина отображает нулевые продажи сотрудников по всем KPI за закрытые рабочие дни.'
					to={`/dashboard/${team.id}/zero_results`}
				/>
			</Grid>
			<Grid item xs={12} sm={4}>
				<AnaliticCard
					title='Нулевые результаты отделений'
					description='Витрина отображает нулевые продажи отделений по всем KPI за закрытые рабочие дни.'
					to={`/dashboard/${team.id}/zero_teams_results`}
				/>
			</Grid>
			<Grid item xs={12} sm={4}>
				<AnaliticCard
					title='Потери'
					description='Потери считаются как доля смен, закрытых с нулями, от общего количества отработанных смен.'
					to={`/dashboard/${team.id}/results_loss`}
				/>
			</Grid>
			<Grid item xs={12} sm={4}>
				<AnaliticCard
					title='Обсуждение результатов и итогов дня'
					description='Витрина отображает расчет % обсужденных результатов и итогов дня.'
					to={`/dashboard/${team.id}/social_activity`}
				/>
			</Grid>
			<Grid item xs={12} sm={4}>
				<AnaliticCard
					title='Выполнение задач по участникам'
					description='Витрина отображает работу с задачами по участникам'
					to={`/dashboard/${team.id}/task_stats`}
				/>
			</Grid>
			<Grid item xs={12} sm={4}>
				<AnaliticCard
					title='Выполнение задач по командам'
					description='Витрина отображает работу с задачами по командам'
					to={`/dashboard/${team.id}/task_team_stats`}
				/>
			</Grid>
			<Grid item xs={12} sm={4}>
				<AnaliticCard
					title='Витрина чек-листов'
					description='Витрина отображает работу с чек-листами'
					to={`/dashboard/${team.id}/checklists`}
				/>
			</Grid>
		</Grid>
	)
}

function AnaliticCard({ title, description, to }) {
	return (
		<Card style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
			<CardHeader
				style={{ flexGrow: 1, alignItems: 'start' }}
				component={Link}
				to={to}
				title={<Typography variant='h6'>{title}</Typography>}
			/>
			<CardContent>
				<Typography variant='body2' color='textSecondary' component='p'>
					{description}
				</Typography>
			</CardContent>
			<CardActions>
				<Button size='small' component={Link} to={to}>
					Открыть
				</Button>
			</CardActions>
		</Card>
	)
}

const mapStateToProps = state => ({
	team: state.teamReducer.team,
	loading: state.teamReducer.loading,
})

const mapDispatchToProps = dispatch => ({
	loadTeam: id => dispatch(getTeamRequest(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TeamIndexPanel)
