export const initialEducationTests = () => {
  return {
    educationTests: [],
    educationTest: {
      id: undefined,
      description: '',
      answerOptions: [],
      education: {
        educationCategory: {}
      }
    },
    loading: true
  }
}
