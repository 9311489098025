import React from "react";
import TaskForm from "./TaskForm"

import { connect } from 'react-redux'
import { createTaskRequest } from "../../actions/actionCreators/tasksActions"

const TaskNewPanel = ({ createTask, history, companies }) => {
  return(<TaskForm key={+ new Date()} task={{
    id: undefined,
    title: "",
    description: "",
    dueDate: "",
    performanceIndicatorId: "",
    taskTypeId: "",
    assignee: {id: undefined},
    team: {id: ''},
    additionalAttributes: [],
    additionalRoAttributes: []
  }} submit={createTask} type={"new"} history={history} companies={companies}></TaskForm>)
}

const mapStateToProps = (state) => ({
  companies: state.profileReducer.companies
})

const mapDispatchToProps = (dispatch) => ({
  createTask: (query) => dispatch(createTaskRequest(query))
})

export default connect(mapStateToProps, mapDispatchToProps)(TaskNewPanel)
