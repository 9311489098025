import { initialCompetitions } from "../initial/initialCompetitions.js"
import { TypeKeys } from "../actions/actionTypes"

export const CompetitionsListReducer = (state = initialCompetitions(), action) => {
  switch (action.type) {
    case TypeKeys.GET_COMPETITIONS_REQUEST:
      return { ...state, competitionsList: [], loading: true }
    case TypeKeys.GET_COMPETITIONS_SUCCESS:
      return { ...state, competitionsList: action.competitions, loading: false }
    default:
    return { ...state }
  }
}

export default CompetitionsListReducer
