import { initialTaskTypes } from "../initial/initialTaskTypes.js"
import { TypeKeys } from "../actions/actionTypes"

export const taskTypesReducer = (state = initialTaskTypes(), action) => {
  switch (action.type) {
    case TypeKeys.GET_TASK_TYPES_REQUEST:
      return { ...state, taskTypes: initialTaskTypes().taskTypes, loading: true }
    case TypeKeys.GET_TASK_TYPES_SUCCESS:
      return { ...state, taskTypes: action.taskTypes, loading: false }
    case TypeKeys.GET_TASK_TYPE_REQUEST:
      return { ...state, taskType: initialTaskTypes().taskType, loading: true }
    case TypeKeys.GET_TASK_TYPE_SUCCESS:
      return { ...state, taskType: action.taskType, loading: false }
    case TypeKeys.CREATE_TASK_TYPE_REQUEST:
      return { ...state, taskType: initialTaskTypes().taskType, loading: true }
    case TypeKeys.CREATE_TASK_TYPE_SUCCESS:
      return { ...state, taskType: action.taskType, loading: false }
    case TypeKeys.UPDATE_TASK_TYPE_REQUEST:
      return { ...state, taskType: initialTaskTypes().taskType, loading: true }
    case TypeKeys.UPDATE_TASK_TYPE_SUCCESS:
      return { ...state, taskType: action.taskType, loading: false }
    default:
    return { ...state }
  }
}

export default taskTypesReducer
