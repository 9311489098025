import React from 'react';
import { Link, useParams } from "react-router-dom";

import PropTypes from 'prop-types';

import { useState, useEffect } from "react";

import UserIndicators from "../../components/Users/UserIndicators"
import KeyDates from "../../components/Users/KeyDates"
import UserResults from "../../components/Users/UserResults"
import UserRewards from "../../components/Users/UserRewards"
import RunRate from "../../components/Users/RunRate"
import Stories from "../../components/Users/Stories"
import TeamChip from "../../components/Main/TeamChip"

import moment from 'moment'
import { connect } from 'react-redux'
import { getUserRequest } from "../../actions/actionCreators/usersActions"

import { Paper, Grid, Typography, Hidden,
  Button, Box, Avatar,
  Divider, Tabs, Tab, AppBar } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import ScreenRotationIcon from '@mui/icons-material/ScreenRotation'
import CircularProgress from '@mui/material/CircularProgress'
import { makeStyles, useTheme } from '@mui/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(6)
  },
  paper: {
    // padding: theme.spacing(2),
  },
  editButton: {
    // marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2)
  },
  button: {
    // marginTop: theme.spacing(2)
  },
  teamChip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    '&:hover': { cursor: "pointer" },
  },
  ignoreVisited: {
    color: theme.palette.common.black
  },
  rotateIcon: {
    width: theme.spacing(12),
    height: theme.spacing(12)
  },
  badgeCustom: {
    color: theme.palette.secondary.main,
    border: "1px solid red",
    padding: theme.spacing(1),
    borderRadius: theme.spacing(4),
    marginLeft: theme.spacing(1)
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function appyProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const UserDescription = (props) => {

  const classes = useStyles()
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))

  const params = useParams()

  useEffect(() => {
    props.loadUser(params.id)
  }, [params.id]);

  const { id, createdAt, firstName, lastName, phone, createdTeams,
    teams, avatar, guestTeams, runRate, blocked, lockedAt, failedAttempts } = props.user

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    props.loading ?
      <CircularProgress/>
    : <Grid container spacing={4} className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Grid container spacing={2} align={mobile ? "center" : null}>
            <Grid item md={1} xs={12}>
              <Avatar src={avatar} className={classes.avatarFont} color="primary" sx={{
                  width: theme.spacing(8),
                  height: theme.spacing(8),
                  marginBottom: '16px', marginLeft: '16px',
                  letterSpacing: "-1px",
                  backgroundColor: theme.palette.primary.main,
                  [theme.breakpoints.down('sm')]: {
                    width: theme.spacing(12),
                    height: theme.spacing(12)
                  },
                }}>
                {`${firstName && firstName[0]} ${lastName && lastName[0]}`}
              </Avatar>
            </Grid>
            <Grid item md={11} xs={12}>
              <Grid container spacing={2} align={mobile ? "center" : null}>
                <Grid item md={8} xs={12}>
                  <Typography variant="h4">
                    {firstName}&nbsp;{lastName} {phone}
                  </Typography>
                </Grid>
                <Grid item md={4} xs={12} align={mobile ? "center" : 'right'}>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{mr: theme.spacing(2)}}
                    endIcon={<EditIcon/>}
                    component={Link} to={`/users/${id}/edit`}
                  >
                    Редактировать
                  </Button>
                </Grid>
                <Grid item xs={12} sx={{marginBottom: '16px'}}>
                  <Typography variant="body1">
                    ID: {id}, Создан {moment(createdAt).format('DD.MM.YYYY')}
                    {blocked && <span className={classes.badgeCustom}>Ручная блокировка</span>}
                    {lockedAt && <span className={classes.badgeCustom}>{`Блокировка за неудачные попытки входа (${failedAttempts})`}</span>}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab component="h4" label="Команды" {...appyProps(0)}/>
              <Tab label="RR" {...appyProps(1)}/>
              <Tab label="Планы" {...appyProps(2)}/>
              <Tab label="Ключевые даты" {...appyProps(3)}/>
              <Tab label="Результаты" {...appyProps(4)}/>
              <Tab label="Награды" {...appyProps(5)}/>
              <Tab label="Истории" {...appyProps(6)}/>
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">
                  Администратор
                </Typography>
              </Grid>
              <Grid item xs={12}>
              { createdTeams.length > 0 ?
                createdTeams.map(team => <TeamChip team={team}/>)
                : <Typography variant="body2">
                  Нет команд
                </Typography>
              }
              </Grid>
              <Grid item xs={12}>
                <Divider/>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">
                  Участник
                </Typography>
              </Grid>
              <Grid item xs={12}>
                { teams.length > 0 ?
                  teams.map(team => <TeamChip team={team}/>)
                  : <Typography variant="body2">
                    Нет команд
                  </Typography>
                }
              </Grid>
              <Grid item xs={12}>
                <Divider/>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">
                  Гость
                </Typography>
              </Grid>
              <Grid item xs={12}>
                { guestTeams.length > 0 ?
                  guestTeams.map(team => <TeamChip team={team}/>)
                  : <Typography variant="body2">
                    Нет команд
                  </Typography>
                }
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Grid item xs={12}>
              {runRate.map((rr) => {
                return (
                  <RunRate runRate={rr}/>
                )
              })}
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <UserIndicators userId={id}/>
          </TabPanel>
          <TabPanel value={value} index={3}>
            <KeyDates userId={id}/>
          </TabPanel>
          <TabPanel value={value} index={4}>
            <UserResults userId={id}/>
          </TabPanel>
          <TabPanel value={value} index={5}>
            <UserRewards userId={id}/>
          </TabPanel>
          <TabPanel value={value} index={6}>
            <Stories userId={id}/>
          </TabPanel>
        </Paper>
      </Grid>
      <Hidden smUp>
        <Grid item xs={12} align="center">
          <ScreenRotationIcon className={classes.rotateIcon}/>
        </Grid>
      </Hidden>
    </Grid>
  )
}

const mapStateToProps = (state) => ({
  user: state.usersReducer.user,
  loading: state.usersReducer.loading
})

const mapDispatchToProps = (dispatch) => ({
  loadUser: (id) => dispatch(getUserRequest(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(UserDescription);
