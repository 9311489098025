import React from "react";
import { useState, useEffect, useCallback } from "react";
import { connect } from 'react-redux';
import { getKpiChannelsRequest }
  from "../../actions/actionCreators/kpiChannelsActions";
import { getPerformanceIndicatorsRequest, updatePerformanceIndicatorRequest }
  from "../../actions/actionCreators/performanceIndicatorsActions";

import { makeStyles } from '@mui/styles';
import { Grid, FormControl, Select, Button, Link,
  MenuItem, FormControlLabel, Checkbox, Typography,
  Snackbar, CircularProgress, TextField } from '@mui/material';
import { Table, TableBody, TableCell, TableHead,
  TableRow } from '@mui/material';
import PerformanceIndicatorRow from './PerformanceIndicatorRow';

const useStyles = makeStyles((theme) => ({
  root: {  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  smallCell: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  }
}));

const PerformanceIndicators = (props) => {
  const classes = useStyles();

  const { kpiChannels, loadKpiChannels, performanceIndicators, updatePerformanceIndicator,
    loadPerformanceIndicators, team, loading } = props

  const load = useCallback((teamId) => {
    loadPerformanceIndicators(teamId);
    loadKpiChannels();
  }, [loadPerformanceIndicators, loadKpiChannels])

  const rootIndicators = () => {
    return performanceIndicators.filter((pi) => !pi.parent)
  }

  useEffect(() => {
    load(team.id);
  }, [team, load]);

  return (
    <Grid container spacing={2}>
      {loading ? <CircularProgress />
        :
        <Grid item xs={12}>
          <Table size="small" className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left" className={classes.smallCell}>Название</TableCell>
                <TableCell align="left" className={classes.smallCell}>Родительский KPI</TableCell>
                <TableCell align="left" className={classes.smallCell}>Длинный KPI</TableCell>
                <TableCell align="left" className={classes.smallCell}>Канал</TableCell>
                <TableCell align="left" className={classes.smallCell}>Пересчёт плана</TableCell>
                <TableCell align="left" className={classes.smallCell}>Порядок вывода</TableCell>
                <TableCell align="left" className={classes.smallCell}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { performanceIndicators.map((kpi) => {
                return(<PerformanceIndicatorRow kpi={kpi} team={team} updatePerformanceIndicator={updatePerformanceIndicator}
                  kpiChannels={kpiChannels} performanceIndicators={rootIndicators()}/>)
              })}
            </TableBody>
          </Table>
        </Grid>
      }
    </Grid>
  )
}

const mapStateToProps = (state) => ({
  kpiChannels: state.kpiChannelsReducer.kpiChannels,
  performanceIndicators: state.performanceIndicatorsReducer.performanceIndicators,
  loading: state.performanceIndicatorsReducer.loading
})

const mapDispatchToProps = (dispatch) => ({
  loadKpiChannels: () => dispatch(getKpiChannelsRequest()),
  loadPerformanceIndicators: (id) => dispatch(getPerformanceIndicatorsRequest(id)),
  updatePerformanceIndicator: (teamId, performanceIndicatorId, query) =>
    dispatch(updatePerformanceIndicatorRequest(teamId, performanceIndicatorId, query))
})

export default connect(mapStateToProps, mapDispatchToProps)(PerformanceIndicators)
