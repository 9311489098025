import { takeLatest, put, call, delay } from 'redux-saga/effects'
import { TypeKeys } from '../actions/actionTypes'
import { getTeamUserIndicatorsSuccess,
  updateTeamUserIndicatorSuccess,
  saveTeamUserIndicatorsSuccess } from '../actions/actionCreators/teamUserIndicatorsActions'
import callApi from '../utils/callApi'
import config from '../config'

function* loadTeamUserIndicators(action) {
  const res = yield call(callApi,
    `${config.BASE_URL}/admin/api/v1/teams/${action.teamId}/performance_indicators/${action.performanceIndicatorId}/user_indicators`)
  const userIndicators = res.data.user_indicators
  const userIndicatorsData = userIndicators.map((userIndicator) => ({
    id: userIndicator.id,
    monthTarget: userIndicator.month_target,
    plannedTarget: userIndicator.planned_target,
    controlled: userIndicator.controlled,
    user: {
      firstName: userIndicator.user.first_name,
      lastName: userIndicator.user.last_name,
    },
    createdTeam: {
      title: userIndicator.created_team ? userIndicator.created_team.title : null,
    }
  }))
  const performanceIndicatorData = {
    id: res.data.performance_indicator.id,
    title: res.data.performance_indicator.title,
    team: res.data.team.title
  }
  yield put(getTeamUserIndicatorsSuccess(userIndicatorsData, performanceIndicatorData))
}

function* saveTeamUserIndicators(action) {
  const res = yield call(
    callApi,
    `${config.BASE_URL}/admin/api/v1/teams/${action.teamId}/performance_indicators/${action.performanceIndicatorId}/user_indicators`,
    {
      method: 'PUT',
      data: { user_indicators: action.teamUserIndicators.map(
        indicator => ({
          id: indicator.id,
          month_target: indicator.monthTarget,
          controlled: indicator.controlled,
          planned_target: indicator.plannedTarget
        })
      )}
    }
  )
  const userIndicators = res.data.user_indicators
  const userIndicatorsData = userIndicators.map((userIndicator) => ({
    id: userIndicator.id,
    monthTarget: userIndicator.month_target,
    plannedTarget: userIndicator.planned_target,
    controlled: userIndicator.controlled,
    user: {
      firstName: userIndicator.user.first_name,
      lastName: userIndicator.user.last_name,
    }
  }))
  const performanceIndicatorData = {
    id: res.data.performance_indicator.id,
    title: res.data.performance_indicator.title,
    team: res.data.team.title
  }
  yield put(saveTeamUserIndicatorsSuccess(userIndicatorsData, performanceIndicatorData))
}

function* updateTeamUserIndicator(action) {
  yield put(updateTeamUserIndicatorSuccess(action.userIndicator))
}

export const teamUserIndicatorsSagas = [
  takeLatest([ TypeKeys.GET_TEAM_USER_INDICATORS_REQUEST], loadTeamUserIndicators),
  takeLatest([ TypeKeys.UPDATE_TEAM_USER_INDICATOR_REQUEST], updateTeamUserIndicator),
  takeLatest([ TypeKeys.SAVE_TEAM_USER_INDICATORS_REQUEST], saveTeamUserIndicators)
]
