import React from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { getTeamRequest } from "../../actions/actionCreators/teamsActions";
import { Link, useParams } from "react-router-dom";
import { makeStyles, useTheme } from "@mui/styles";
import { palette } from "@mui/system";

import moment from "moment";
import "moment/locale/ru";
import {
  getDashboardTeamSummaryRequest,
  dashboardResetRequest,
} from "../../actions/actionCreators/dashboardActions";

import { Paper, ButtonGroup, CircularProgress } from "@mui/material";
import GetAppIcon from "@mui/icons-material/GetApp";
import {
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Box,
  Container,
  Grid,
  Typography,
  Button,
  Breadcrumbs,
  Hidden,
} from "@mui/material";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import AssignmentLateIcon from "@mui/icons-material/AssignmentLate";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import ChatIcon from "@mui/icons-material/Chat";
import BallotIcon from "@mui/icons-material/Ballot";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(4),
  },
  padding: {
    padding: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
  },
  buttonGroup: {
    paddingTop: theme.spacing(2),
  },
  fab: {
    [theme.breakpoints.down("md")]: {
      position: "fixed",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  },
}));

const TeamSummaryDashboard = (props) => {
  const params = useParams();
  const {
    team,
    currentTeam,
    loading,
    loadTeamSummary,
    callDashboardReset,
    loadTeam,
  } = props;
  const classes = useStyles();

  const [dates, setDates] = React.useState({
    startDate: moment().startOf("month").format("yyyy-MM-DD"),
    endDate: moment().subtract(1, "days").format("yyyy-MM-DD"),
    active: "thisMonth",
  });
  const handleChange = (dates) => {
    return () => setDates(dates);
  };

  useEffect(() => {
    loadTeamSummary(params.teamId, dates.startDate, dates.endDate);
    loadTeam(params.teamId);
    return () => {
      callDashboardReset();
    };
  }, [params.teamId, dates]);

  const percent = (total, share) => {
    if (total === 0 || share === 0) {
      return 0;
    }
    return Math.round(100 * (share / total));
  };

  const teamId = () => {
    return team.parents.length > 0 ? team.parents[0].id : team.team.id;
  };

  return loading ? (
    <Grid
      container
      alignItems="stretch"
      className={classes.root}
      justifyContent="center"
    >
      <CircularProgress sx={{ margin: "32px" }} size={50} />
    </Grid>
  ) : (
    <Grid container alignItems="stretch" className={classes.root}>
      <Grid item xs={12} className={classes.padding}>
        <Breadcrumbs aria-label="breadcrumb">
          {team.parents.length > 0 &&
            team.parents.map((t) => (
              <Typography
                key={t.id}
                color="textPrimary"
                component={Link}
                to={`/dashboard/${t.id}/team_summary`}
              >
                {t.title}
              </Typography>
            ))}
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12} className={classes.padding}>
        <Grid container component={Paper} className={classes.paper}>
          <Grid item xs={12} sm={6}>
            <Typography component="span" variant="h4">
              {team.team.title}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <ButtonGroup
              color="primary"
              aria-label="outlined primary button group"
            >
              {moment().date() > 2 && (
                <Button
                  variant={
                    dates.active === "thisMonth" ? "contained" : "outlined"
                  }
                  onClick={handleChange({
                    startDate: moment().startOf("month").format("yyyy-MM-DD"),
                    endDate: moment().subtract(1, "days").format("yyyy-MM-DD"),
                    active: "thisMonth",
                  })}
                >
                  Этот месяц
                </Button>
              )}
              <Button
                variant={
                  dates.active === "lastMonth" ? "contained" : "outlined"
                }
                onClick={handleChange({
                  startDate: moment()
                    .subtract(1, "months")
                    .startOf("month")
                    .format("yyyy-MM-DD"),
                  endDate: moment()
                    .subtract(1, "months")
                    .endOf("month")
                    .format("yyyy-MM-DD"),
                  active: "lastMonth",
                })}
              >
                Прошлый месяц
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} component={Paper} className={classes.padding}>
        <Grid item xs={12} sm={9} className={classes.padding}>
          <Typography variant="h6" color="primary">
            <AssignmentIndIcon
              style={{ marginBottom: "-3px", marginRight: "3px" }}
            />
            Статусы управленческого цикла
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.padding}>
          Витрина отображает статусы внедрения управленческого цикла в
          отделениях
        </Grid>

        <Table aria-label="collapsible table" size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                Постановка
                <br />
                планов
              </TableCell>
              <TableCell align="center">
                Сотрудники
                <br />
                отправляют итоги
              </TableCell>
              <TableCell align="center">
                Обсуждение
                <br />
                результатов
              </TableCell>
              <TableCell align="center">
                Отправка
                <br />
                результата выше
              </TableCell>
              <TableCell align="center">
                Постановка
                <br />
                задач
              </TableCell>
              <TableCell align="center">
                Не работают
                <br />
                (чел)
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell
                align="center"
                sx={{
                  paddingTop: 2,
                  paddingBottom: 2,
                }}
              >
                {team.summary.data.members_plans.count}
              </TableCell>
              <TableCell align="center">
                {team.summary.data.results.count}
              </TableCell>
              <TableCell align="center">
                {team.summary.data.social_activity.count}
              </TableCell>
              <TableCell align="center">
                {team.summary.data.admin_close_day.count}
              </TableCell>
              <TableCell align="center">
                {team.summary.data.tasks_activity.count}
              </TableCell>
              <TableCell align="center">
                {team.summary.data.leaves_count}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.padding}>
        <Grid container component={Paper} className={classes.paper}>
          <Grid item xs={12} sm={9} className={classes.padding}>
            <Typography variant="h6" color="primary">
              <AssignmentIndIcon
                style={{ marginBottom: "-3px", marginRight: "3px" }}
              />
              Закрытие дня
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3} className={classes.padding}>
            <Typography
              variant="subtitle1"
              style={{ color: "#9196A8", textDecoration: "none" }}
              component={Link}
              to={`/dashboard/${teamId()}/closed_dates`}
            >
              Подробнее >
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.padding}>
            Число руководителей отделений и сотрудников закрывающих день
          </Grid>
          {team.closed_dates.children.length > 0 ? (
            <Grid item xs={12} sm={6}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Руководители</TableCell>
                    <TableCell>Закрывают</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{`${team.closed_dates.data?.admin_total}`}</TableCell>
                    <TableCell>{`${team.closed_dates.data?.admin_closed} (${team.closed_dates.data.admin_percent}%)`}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          ) : (
            <Grid item xs={12} sm={12}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Сотрудников закрывает день</TableCell>
                    <TableCell>Руководитель закрывает день</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      {`${team.closed_dates.data.user_percent}% (${team.closed_dates.data.users_closed} из
                        ${team.closed_dates.data.users_total} сотрудников)`}
                    </TableCell>
                    <TableCell>
                      {`${team.closed_dates.data.admin_key_dates[0].percent}% (${team.closed_dates.data.admin_key_dates[0].closed} из
                        ${team.closed_dates.data.admin_key_dates[0].total} дней)`}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          )}
          {team.closed_dates.children.length > 0 && (
            <Grid item xs={12} sm={6}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Сотрудники</TableCell>
                    <TableCell>Закрывают</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{`${team.closed_dates.data.users_total}`}</TableCell>
                    <TableCell>{`${team.closed_dates.data.users_closed} (${team.closed_dates.data.user_percent}%)`}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.padding}>
        <Grid container component={Paper} className={classes.paper}>
          <Grid item xs={12} sm={9} className={classes.padding}>
            <Typography variant="h6" color="primary">
              <EventAvailableIcon
                style={{ marginBottom: "-3px", marginRight: "3px" }}
              />
              Постановка плана
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3} className={classes.padding}>
            <Typography
              variant="subtitle1"
              style={{ color: "#9196A8", textDecoration: "none" }}
              component={Link}
              to={`/dashboard/${teamId()}/plans_updated`}
            >
              Подробнее >
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.padding}>
            Даты последних изменений планов руководителями отделений
          </Grid>
          <Grid item xs={12}>
            {team.closed_dates.children.length > 0 ? (
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Руководителей всего</TableCell>
                    <TableCell>Изменили план в этом месяце</TableCell>
                    <TableCell>Изменили план ранее</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{`${team.plans_updated.data.leaves_size}`}</TableCell>
                    <TableCell>{`${team.plans_updated.data.updated_this_month}`}</TableCell>
                    <TableCell>{`${team.plans_updated.data.updated_long_ago}`}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            ) : (
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Изменение плана</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{`${moment(
                      team.plans_updated.data.plans_updated_at
                    ).format("DD MM YYYY")}`}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.padding}>
        <Grid container component={Paper} className={classes.paper}>
          <Grid item xs={12} sm={9} className={classes.padding}>
            <Typography variant="h6" color="primary">
              <AssignmentLateIcon
                style={{ marginBottom: "-3px", marginRight: "3px" }}
              />
              Нулевые результаты сотрудников
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3} className={classes.padding}>
            <Typography
              variant="subtitle1"
              style={{ color: "#9196A8", textDecoration: "none" }}
              component={Link}
              to={`/dashboard/${teamId()}/zero_results`}
            >
              Подробнее >
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.padding}>
            Витрина отображает нулевые продажи сотрудников по всем KPI за
            закрытые рабочие дни.
          </Grid>
          <Grid item xs={12}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Рабочих дней</TableCell>
                  <TableCell>Закрыто дней</TableCell>
                  <TableCell>Закрыто с нулями</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{`${team.zero_results.data.workdays}`}</TableCell>
                  <TableCell>{`${team.zero_results.data.closed_dates}`}</TableCell>
                  <TableCell>{`${team.zero_results.data.zero_results}`}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.padding}>
        <Grid container component={Paper} className={classes.paper}>
          <Grid item xs={12} sm={9} className={classes.padding}>
            <Typography variant="h6" color="primary">
              <AssignmentLateIcon
                style={{ marginBottom: "-3px", marginRight: "3px" }}
              />
              Нулевые результаты отделений
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3} className={classes.padding}>
            <Typography
              variant="subtitle1"
              style={{ color: "#9196A8", textDecoration: "none" }}
              component={Link}
              to={`/dashboard/${teamId()}/zero_teams_results`}
            >
              Подробнее >
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.padding}>
            Витрина отображает нулевые продажи отделений по всем KPI за закрытые
            рабочие дни.
          </Grid>
          <Grid item xs={12}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Рабочих дней</TableCell>
                  <TableCell>Закрыто дней</TableCell>
                  <TableCell>Закрыто с нулями</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{`${team.zero_team_results.data.workdays}`}</TableCell>
                  <TableCell>{`${team.zero_team_results.data.closed_dates}`}</TableCell>
                  <TableCell>{`${team.zero_team_results.data.zero_results}`}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.padding}>
        <Grid container component={Paper} className={classes.paper}>
          <Grid item xs={12} sm={9} className={classes.padding}>
            <Typography variant="h6" color="primary">
              <MonetizationOnIcon
                style={{ marginBottom: "-3px", marginRight: "3px" }}
              />
              Потери
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3} className={classes.padding}>
            <Typography
              variant="subtitle1"
              style={{ color: "#9196A8", textDecoration: "none" }}
              component={Link}
              to={`/dashboard/${teamId()}/results_loss`}
            >
              Подробнее >
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.padding}>
            Доля смен с нулями от общего количества отработанных смен.
          </Grid>
          <Grid item xs={12}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <Hidden xsDown>
                    <TableCell>Сотрудников</TableCell>
                  </Hidden>
                  <TableCell>Закрыто дней</TableCell>
                  <TableCell>Закрыто с нулями</TableCell>
                  <TableCell>Потери</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <Hidden xsDown>
                    <TableCell>{`${team.results_loss.data.users_count}`}</TableCell>
                  </Hidden>
                  <TableCell>{`${team.results_loss.data.closed_dates}`}</TableCell>
                  <TableCell>{`${team.results_loss.data.zero_results}`}</TableCell>
                  <TableCell>
                    {percent(
                      team.results_loss.data.closed_dates,
                      team.results_loss.data.zero_results
                    )}
                    %
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.padding}>
        <Grid container component={Paper} className={classes.paper}>
          <Grid item xs={12} sm={9} className={classes.padding}>
            <Typography variant="h6" color="primary">
              <ChatIcon style={{ marginBottom: "-3px", marginRight: "3px" }} />
              Обсуждение результатов
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3} className={classes.padding}>
            <Typography
              variant="subtitle1"
              style={{ color: "#9196A8", textDecoration: "none" }}
              component={Link}
              to={`/dashboard/${teamId()}/social_activity`}
            >
              Подробнее >
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.padding}>
            Витрина отображает расчет % обсужденных результатов.
          </Grid>
          <Grid item xs={12}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <Hidden xsDown>
                    <TableCell>Результатов</TableCell>
                  </Hidden>
                  <TableCell>Комментариев / от руководителя</TableCell>
                  <TableCell>Лайков / от руководителя</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <Hidden xsDown>
                    <TableCell>{`${team.social_activity.data.story_sum}`}</TableCell>
                  </Hidden>
                  <TableCell>{`${team.social_activity.data.story_comments + team.social_activity.data.ukd_comments}/${team.social_activity.data.story_admin_comments + team.social_activity.data.ukd_admin_comments}`}</TableCell>
                  <TableCell>{`${team.social_activity.data.ukd_likes + team.social_activity.data.story_likes}/${team.social_activity.data.story_admin_likes + team.social_activity.data.ukd_admin_likes}`}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.padding}>
        <Grid container component={Paper} className={classes.paper}>
          <Grid item xs={12} sm={9} className={classes.padding}>
            <Typography variant="h6" color="primary">
              <AssignmentIcon
                style={{ marginBottom: "-3px", marginRight: "3px" }}
              />
              Распределение планов
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3} className={classes.padding}>
            <Typography
              variant="subtitle1"
              style={{ color: "#9196A8", textDecoration: "none" }}
              component={Link}
              to={`/dashboard/${teamId()}/plan_value`}
            >
              Подробнее >
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.padding}>
            Витрина отображает распределение плана
          </Grid>
          <Grid item xs={12}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <Hidden xsDown>
                    <TableCell>План</TableCell>
                    <TableCell>Распределено</TableCell>
                  </Hidden>
                  <TableCell>Дельта</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {team.plan_value.map((kpi) => (
                  <TableRow>
                    <TableCell>{`${kpi.performance_indicator.title}`}</TableCell>
                    <Hidden xsDown>
                      <TableCell>{`${kpi.team.data.admin_plan}`}</TableCell>
                      <TableCell>{`${kpi.team.data.leaves_plan}`}</TableCell>
                    </Hidden>
                    <TableCell>
                      <Typography
                        color={
                          kpi.team.data.leaves_plan - kpi.team.data.admin_plan >
                          0
                            ? "primary"
                            : "secondary"
                        }
                      >
                        {`${
                          kpi.team.data.leaves_plan - kpi.team.data.admin_plan
                        }`}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.padding}>
        <Grid container component={Paper} className={classes.paper}>
          <Grid item xs={12} sm={9} className={classes.padding}>
            <Typography variant="h6" color="primary">
              <AssignmentTurnedInIcon
                style={{ marginBottom: "-3px", marginRight: "3px" }}
              />
              Выполнение плана
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3} className={classes.padding}>
            <Typography
              variant="subtitle1"
              style={{ color: "#9196A8", textDecoration: "none" }}
              component={Link}
              to={`/dashboard/${teamId()}/plan_complete`}
            >
              Подробнее >
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.padding}>
            Витрина отображает выполнение плана
          </Grid>
          <Grid item xs={12}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>План</TableCell>
                  <TableCell>Факт</TableCell>
                  <Hidden xsDown>
                    <TableCell>RR%</TableCell>
                  </Hidden>
                </TableRow>
              </TableHead>
              <TableBody>
                {team.plan_complete.map((kpi) => (
                  <TableRow>
                    <TableCell>{`${kpi.performance_indicator.title}`}</TableCell>
                    <TableCell>{`${kpi.team.data.admin_plan || 0}`}</TableCell>
                    <TableCell>{`${kpi.team.data.admin_fact || 0}`}</TableCell>
                    <Hidden xsDown>
                      <TableCell>
                        <Typography
                          color={
                            kpi.team.data.admin_rr > 100
                              ? "primary"
                              : "secondary"
                          }
                        >
                          {`${kpi.team.data.admin_rr || 0}%`}
                        </Typography>
                      </TableCell>
                    </Hidden>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  team: state.dashboardReducer.team,
  currentTeam: state.teamReducer.team,
  loading: state.dashboardReducer.summaryLoading,
});

const mapDispatchToProps = (dispatch) => ({
  loadTeamSummary: (id, startDate, endDate) =>
    dispatch(getDashboardTeamSummaryRequest(id, startDate, endDate)),
  callDashboardReset: () => dispatch(dashboardResetRequest()),
  loadTeam: (id) => dispatch(getTeamRequest(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamSummaryDashboard);
