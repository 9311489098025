import { takeLatest, put, call, delay } from 'redux-saga/effects';
import { TypeKeys } from '../actions/actionTypes';
import {
  getDashboardClosedDatesSuccess, getDashboardPlansUpdatedSuccess,
  getDashboardPlanValuesSuccess, getDashboardPlanCompletesSuccess,
  getDashboardZeroResultsSuccess, getDashboardZeroTeamsResultsSuccess,
  getDashboardResultsLossSuccess, getDashboardSocialActivitySuccess,
  getDashboardTeamSummarySuccess, dashboardResetSuccess, getDashboardTaskStatsSuccess, getDashboardShowcaseSuccess
} from '../actions/actionCreators/dashboardActions';
import callApi from '../utils/callApi';
import config from '../config';
import moment from 'moment';

function* loadDashboardClosedDates(action) {
  const res = yield call(callApi,
    `${config.BASE_URL}/admin/api/v1/dashboard/${action.id}/closed_dates?`+
    `start_date=${moment(action.startDate).format('YYYY-MM-DD')}&`+
    `end_date=${moment(action.endDate).format('YYYY-MM-DD')}`)
  yield put(getDashboardClosedDatesSuccess(res.data))
}

function* loadDashboardPlansUpdated(action) {
  const res = yield call(callApi,
    `${config.BASE_URL}/admin/api/v1/dashboard/${action.id}/plans_updated?`+
    `start_date=${moment(action.startDate).format('YYYY-MM-DD')}`)
  yield put(getDashboardPlansUpdatedSuccess(res.data))
}

function* loadDashboardPlanValues(action) {
  const res = yield call(callApi,
    `${config.BASE_URL}/admin/api/v1/dashboard/${action.id}/plan_values?kpi_id=${action.kpiId}`)
  yield put(getDashboardPlanValuesSuccess(res.data))
}

function* loadDashboardPlanComplete(action) {
  const res = yield call(callApi,
    `${config.BASE_URL}/admin/api/v1/dashboard/${action.id}/plan_complete?kpi_id=${action.kpiId}&`+
    `start_date=${moment(action.startDate).format('YYYY-MM-DD')}&`+
    `end_date=${moment(action.endDate).format('YYYY-MM-DD')}`)
  yield put(getDashboardPlanCompletesSuccess(res.data))
}

function* loadDashboardZeroResults(action) {
  const res = yield call(callApi,
    `${config.BASE_URL}/admin/api/v1/dashboard/${action.id}/zero_results?`+
    `start_date=${moment(action.startDate).format('YYYY-MM-DD')}&`+
    `end_date=${moment(action.endDate).format('YYYY-MM-DD')}`)
  yield put(getDashboardZeroResultsSuccess(res.data))
}

function* loadDashboardZeroTeamsResults(action) {
  const res = yield call(callApi,
    `${config.BASE_URL}/admin/api/v1/dashboard/${action.id}/zero_team_results?`+
    `start_date=${moment(action.startDate).format('YYYY-MM-DD')}&`+
    `end_date=${moment(action.endDate).format('YYYY-MM-DD')}`)
  yield put(getDashboardZeroTeamsResultsSuccess(res.data))
}

function* loadDashboardResultsLoss(action) {
  const res = yield call(callApi,
    `${config.BASE_URL}/admin/api/v1/dashboard/${action.id}/results_loss?`+
    `start_date=${moment(action.startDate).format('YYYY-MM-DD')}&`+
    `end_date=${moment(action.endDate).format('YYYY-MM-DD')}`)
  yield put(getDashboardResultsLossSuccess(res.data))
}

function* loadDashboardSocialActivity(action) {
  const res = yield call(callApi,
    `${config.BASE_URL}/admin/api/v1/dashboard/${action.id}/social_activity?`+
    `start_date=${moment(action.startDate).format('YYYY-MM-DD')}&`+
    `end_date=${moment(action.endDate).format('YYYY-MM-DD')}`)
  yield put(getDashboardSocialActivitySuccess(res.data))
}

function* loadDashboardShowcase(action) {
  console.log('dasdasda')
  const res = yield call(callApi,
      `${config.BASE_URL}/admin/api/v1/dashboard/${action.id}/country_summary?`+
      `start_date=${moment(action.startDate).format('YYYY-MM-DD')}&`+
      `end_date=${moment(action.endDate).format('YYYY-MM-DD')}`)

  console.log('res', res)
  yield put(getDashboardShowcaseSuccess(res.data))
}


function* loadDashboardTaskStats(action) {
  const res = yield call(callApi,
    `${config.BASE_URL}/admin/api/v1/dashboard/${action.id}/task_stats?`+
    `start_date=${moment(action.startDate).format('YYYY-MM-DD')}&`+
    `end_date=${moment(action.endDate).format('YYYY-MM-DD')}`)
  yield put(getDashboardTaskStatsSuccess(res.data))
}

function* loadDashboardTeamSummary(action) {
  const res = yield call(callApi,
    `${config.BASE_URL}/admin/api/v1/dashboard/${action.id}/team_summary?`+
    `start_date=${moment(action.startDate).format('YYYY-MM-DD')}&`+
    `end_date=${moment(action.endDate).format('YYYY-MM-DD')}`)
  yield put(getDashboardTeamSummarySuccess(res.data))
}

function* callDashboardReset(action) {
  yield put(dashboardResetSuccess())
}

export const dashboardSagas = [
  takeLatest([ TypeKeys.GET_DASHBOARD_CLOSED_DATES_REQUEST], loadDashboardClosedDates),
  takeLatest([ TypeKeys.GET_DASHBOARD_PLANS_UPDATED_REQUEST], loadDashboardPlansUpdated),
  takeLatest([ TypeKeys.GET_DASHBOARD_PLAN_VALUES_REQUEST], loadDashboardPlanValues),
  takeLatest([ TypeKeys.GET_DASHBOARD_PLAN_COMPLETE_REQUEST], loadDashboardPlanComplete),
  takeLatest([ TypeKeys.GET_DASHBOARD_ZERO_RESULTS_REQUEST], loadDashboardZeroResults),
  takeLatest([ TypeKeys.GET_DASHBOARD_ZERO_TEAMS_RESULTS_REQUEST], loadDashboardZeroTeamsResults),
  takeLatest([ TypeKeys.GET_DASHBOARD_RESULTS_LOSS_REQUEST], loadDashboardResultsLoss),
  takeLatest([ TypeKeys.GET_DASHBOARD_SOCIAL_ACTIVITY_REQUEST], loadDashboardSocialActivity),
  takeLatest([ TypeKeys.GET_DASHBOARD_TEAM_SUMMARY_REQUEST], loadDashboardTeamSummary),
  takeLatest([ TypeKeys.GET_DASHBOARD_TASK_STATS_REQUEST], loadDashboardTaskStats),
  takeLatest([ TypeKeys.GET_DASHBOARD_SHOWCASE_REQUEST], loadDashboardShowcase),
  takeLatest([ TypeKeys.DASHBOARD_RESET_REQUEST], callDashboardReset),
]
