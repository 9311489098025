import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import moment from 'moment'
import 'moment/locale/ru'
import { downloadDashboard } from '../../../utils/downloadFile'
import { getDashboardPlanValuesRequest } from "../../../actions/actionCreators/dashboardActions";
import { getPerformanceIndicatorsRequest } from "../../../actions/actionCreators/performanceIndicatorsActions";
import { makeStyles, useTheme, withStyles } from '@mui/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import clsx from 'clsx'
import { ButtonGroup, Grid, Typography, Button, Select, FormControl,
  InputLabel, MenuItem } from '@mui/material'
import GetAppIcon from '@mui/icons-material/GetApp'
import DashboardLayout from '../DashboardLayout'
import PlanValuesTable from "./Table"

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingBottom: theme.spacing(6),
    paddingTop: theme.spacing(4)
  },
  paper: {
    marginBottom: theme.spacing(6)
  },
  buttonGroup: {
    paddingTop: theme.spacing(2)
  },
  bottomPadding: {
    paddingBottom: theme.spacing(2)
  }
}));

const PlanValueDashboard = ({team, loading, loadPlanValues,
    loadPerformanceIndicators, performanceIndicators, ...props}) => {
  moment.locale('ru')
  const params = useParams();

  const [kpiId, setKpiId] = useState('');
  const updateKpi = () => { return (e) => setKpiId(e.target.value) }

  useEffect(() => {
    loadPerformanceIndicators(params.teamId);
  }, [params.teamId]);

  useEffect(() => {
    if (kpiId !== '') {
      loadPlanValues(params.teamId, kpiId)
    }
  }, [kpiId]);

  const classes = useStyles()
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <DashboardLayout teamId={team.teamId} teamTitle={team.title} loading={loading}
      title={"Распределение плана"}
      table={<PlanValuesTable team={team} loading={loading} kpiId={kpiId}/>}
      header={
        <Grid item xs={12} sx={{marginBottom: "16px"}}>
          <Grid container>
            <Grid item xs={8}>
              <Typography component="p" variant="h4" className={classes.bottomPadding}>
                Распределение плана
              </Typography>
              <Typography component="p" variant="body1">
                Витрина отображает распределение плана по выбранной задаче до уровня сотрудников отделения
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <FormControl className={classes.formControl}>
                <Select
                  labelId="kpiSelect"
                  id="kpiSelect-select"
                  value={kpiId}
                  onChange={updateKpi()}
                  displayEmpty
                >
                  <MenuItem value={''} disabled>Выберите KPI</MenuItem>
                { performanceIndicators.map((pi) =>
                    <MenuItem key={pi.id} value={pi.id}>{pi.title}</MenuItem>
                ) }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <ButtonGroup color="primary" aria-label="outlined primary button group">
                <Button onClick={downloadDashboard(params.teamId, null, null, kpiId, 'plan_values_xlsx')}
                  endIcon={<GetAppIcon/>} disabled={kpiId === ''}>Скачать</Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        </Grid>
      }
      />
  )
}

const mapStateToProps = (state) => ({
  team: state.dashboardReducer.team,
  loading: state.dashboardReducer.loading,
  performanceIndicators: state.performanceIndicatorsReducer.performanceIndicators,
})

const mapDispatchToProps = (dispatch) => ({
  loadPlanValues: (id, kpiId) => dispatch(getDashboardPlanValuesRequest(id, kpiId)),
  loadPerformanceIndicators: (id) => dispatch(getPerformanceIndicatorsRequest(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PlanValueDashboard)
