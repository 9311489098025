import { initialTeamsList } from "../initial/initialTeamsList.js"
import { TypeKeys } from "../actions/actionTypes"

export const teamsListReducer = (state = initialTeamsList(), action) => {
  switch (action.type) {
    case TypeKeys.GET_TEAMS_LIST_REQUEST:
      return { ...state, teamsList: [], loading: true }
    case TypeKeys.GET_TEAMS_LIST_SUCCESS:
      return { ...state, teamsList: action.teams, loading: false }
    default:
    return { ...state }
  }
}

export default teamsListReducer
