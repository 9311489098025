import React from 'react'
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip,
	Typography,
	styled,
	tooltipClasses,
} from '@mui/material'
import { ChecklistTableRow } from '../moleculs'
import { Icon } from '../../../../../components/atomic/atoms'
import ChecklistMemberRow from '../moleculs/ChecklistMemberRow'

/**
 * @export
 * @param {{ renderRow, data, team }} props
 * @return {*}
 */
export default function TableChecklists(props) {
	const { data = { children: [] }, renderRow, team } = props
	return (
		<TableContainer>
			<Table sx={{ minWidth: 650 }} aria-label='simple table'>
				<TableHead>
					<TableRow>
						<TableCell style={styles.cell}>
							<Typography fontWeight='500' fontSize={13}>
								Команда
							</Typography>
						</TableCell>
						<TableCell style={styles.cell}>
							<Typography fontWeight='500' fontSize={13}>
								Администратор
							</Typography>
						</TableCell>
						<TableCell />
						<TableCell style={styles.cell}>
							<Typography fontWeight='500' fontSize={13}>
								Всего
								<br />
								сотрудников
							</Typography>
						</TableCell>
						<TableCell style={styles.cell}>
							<Typography fontWeight='500' fontSize={13}>
								Заполнено
								<br />
								чек&#8209;листов
							</Typography>
						</TableCell>
						<TableCell style={styles.cell}>
							<Typography fontWeight='500' fontSize={13}>
								Чек-лист
								<br />
								на&nbsp;1&nbsp;сотрудника
							</Typography>
						</TableCell>
						<TableCell style={{ ...styles.cell }}>
							<InfoTooltip title={tooltip.percent} placement='bottom-end'>
								<Typography
									fontWeight='500'
									fontSize={13}
									display='flex'
									flexDirection='row'
									alignItems='center'
									justifyContent='space-between'
								>
									Результат,&nbsp;%
									<Icon name='info' fill='#9196A8' />
								</Typography>
							</InfoTooltip>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					<ChecklistTableRow item={data} isTop />
					{data?.children.map((item, index) => renderRow({ item, index }))}
					{data?.data?.team_id !== team?.id &&
						data?.data?.members?.map(member => (
							<ChecklistMemberRow key={member?.id} item={member} level={0} />
						))}
				</TableBody>
			</Table>
		</TableContainer>
	)
}

const InfoTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: '#E0E5EA',
		color: '#485375',
		maxWidth: 220,
		fontSize: theme.typography.pxToRem(12),
		border: '4px',
	},
}))

const tooltip = {
	percent:
		'Результат чек-листа - это процент отношения набранных баллов по оцененным вопросам к максимально возможному количеству баллов по оцененным вопросам.',
}

const styles = {
	cell: { padding: 0, paddingTop: 15, paddingBottom: 15, paddingRight: 10 },
}
