import React from "react";
import { Link, useParams } from "react-router-dom"
import { Paper, Grid, Typography, Divider, TextField, FormControlLabel, FormGroup,
  Button, Tabs, Tab, Box, Hidden, CircularProgress, Checkbox } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles'

import PropTypes from 'prop-types';

import UserChip from "../../components/Main/UserChip"
import TeamChip from "../../components/Main/TeamChip"

import { connect } from 'react-redux'
import { useState, useEffect } from "react";
import { getGuestRequest, updateGuestRequest } from "../../actions/actionCreators/guestsActions"

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingTop: theme.spacing(2),
    '& .MuiTextField-root': {
      // marginTop: theme.spacing(1),
      // width: '25ch',
    }
  },
  checkbox: {
    marginLeft:  theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2)
  },
  idHeader: {
    marginBottom: theme.spacing(2),
  },
  margin: {
    margin: theme.spacing(0),
    marginRight:  theme.spacing(4),
  },
  buttonMargin: {
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    textDecoration: "none"
  },
  fab: {
    [theme.breakpoints.down('md')]: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  },
  teamMargin: {
    marginTop: theme.spacing(1),
  }
}));

const GuestEdit = ({ guest, loadGuest, updateGuest, requestStatus, loading }) => {
  const classes = useStyles()
  const params = useParams()
  const { id, user, team, settingsEnabled, availableIndicators,
        guestHiddenIndicators, availableTeams, guestHiddenTeams } = guest

  const [indicators, setIndicators] = useState([]);
  const [teams, setTeams] = useState([]);
  const [guestSettingsEnabled, setGuestSettingsEnabled] = useState(false);

  useEffect(() => {
    loadGuest(params.teamId, params.guestId)
  }, [params.teamId, params.guestId]);

  useEffect(() => {
    setIndicators(availableIndicators.map((indicator) => {
      return {...indicator, hidden: guestHiddenIndicators.filter(i => i.id === indicator.id).length > 0}
    }))
    setTeams(availableTeams.map((at) => {
      return {...at, hidden: guestHiddenTeams.filter(t => t.id === at.id).length > 0}
    }))
    setGuestSettingsEnabled(settingsEnabled || false)
  }, [id]);

  const updateIndicators = (indicatorId) => {
    return (e) => {
      setIndicators(indicators.map((indicator) => {
        if (indicator.id === indicatorId) {
          return {...indicator, hidden: e.target.checked}
        } else {
          return indicator
        }
      }))
    }
  }
  const updateTeams = (teamId) => {
    return (e) => {
      setTeams(teams.map((t) => {
        if (t.id === teamId) {
          return {...t, hidden: e.target.checked}
        } else {
          return t
        }
      }))
    }
  }
  const updateSettingsEnabled = (e) => {
    setGuestSettingsEnabled(e.target.checked)
  }

  const saveGuest = () => {
    updateGuest(team.id, {
      guestId: id,
      data: {
        settings_enabled: guestSettingsEnabled,
        hidden_indicator_ids: indicators.filter((i) => i.hidden).map((i) => i.id),
        hidden_team_ids: teams.filter((t) => t.hidden).map((t) => t.id)
      }
    })
  }

  return(
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Typography variant="h6">Редактирование гостя</Typography>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        {loading ? <CircularProgress/> :
          <Paper className={classes.paper}>
            <Typography className={classes.idHeader} variant="body1">
              #ID {id}
            </Typography>
            <Grid container spacing={2}>
              <Grid item sm={4} xs={12}>
                <Typography className={classes.idHeader} variant="h6">Пользователь</Typography>
                <UserChip key={`member-${user.id}`} user={user}/>
              </Grid>
              <Grid item sm={4} xs={12}>
                <Typography className={classes.idHeader} variant="h6">Команда</Typography>
                <TeamChip team={team}/>
              </Grid>
              <Grid item sm={4} xs={12}>
                <Typography className={classes.idHeader} variant="h6">Роль</Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={guestSettingsEnabled}
                      onChange={updateSettingsEnabled}
                      name="blocked"
                      color="primary"
                    />
                  }
                  label="Соруководитель"
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <Typography className={classes.idHeader} variant="h6">Скрыть индикаторы</Typography>
              </Grid>
              {indicators.map((indicator) => {
                return(
                  <Grid item sm={4} xs={12} key={indicator.id}>
                    <FormGroup>
                      <FormControlLabel control={
                        <Checkbox checked={indicator.hidden} value={indicator.hidden}
                          inputProps={{ 'aria-label': 'controlled' }}
                          onChange={updateIndicators(indicator.id)}/>}
                       label={indicator.title} />
                    </FormGroup>
                  </Grid>
                )
              })}
              <Grid item sm={12} xs={12}>
                <Typography className={classes.idHeader} variant="h6">Скрыть команды</Typography>
              </Grid>
              {teams.map((t) => {
                return(
                  <Grid item sm={4} xs={12} key={t.id}>
                    <FormGroup>
                      <FormControlLabel control={
                        <Checkbox checked={t.hidden} value={t.hidden}
                          inputProps={{ 'aria-label': 'controlled' }}
                          onChange={updateTeams(t.id)}/>}
                       label={t.title} />
                    </FormGroup>
                  </Grid>
                )
              })}
              <Grid item sm={12}>
                <Divider />
                <Button className={classes.buttonMargin} variant="contained"
                  to={`/teams/${params.teamId}`} component={Link}>Назад</Button>
                <Button className={classes.buttonMargin} variant="contained" color="primary" onClick={saveGuest}>Сохранить</Button>
              </Grid>
            </Grid>
          </Paper>
        }
      </Grid>
    </Grid>
  )
}

const mapStateToProps = (state) => ({
  guest: state.guestsReducer.guest,
  loading: state.guestsReducer.loading,
  requestStatus: state.guestsReducer.status
})

const mapDispatchToProps = (dispatch) => ({
  loadGuest: (teamId, guestId) => dispatch(getGuestRequest(teamId, guestId)),
  updateGuest: (teamId, query) => dispatch(updateGuestRequest(teamId, query))
})

export default connect(mapStateToProps, mapDispatchToProps)(GuestEdit)
