import React, { useState, useEffect } from "react"
import { connect } from 'react-redux'
import { signInRequest } from '../../actions/actionCreators'

import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import Container from '@mui/material/Container'
import red from '@mui/material/colors/red';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  errorText: {
    color: red,
  },
}));

const SignIn = ({ signIn, result }) => {
  const [phoneNumber, setPhoneNumber] = useState({ value: "", error: false })
  const [password, setPassword] = useState({ value: "", error: false })

  const classes = useStyles();

  const onSignIn = () => {
    if (phoneNumber.value === "") { setPhoneNumber({...phoneNumber, error: true}) }
    if (password.value === "") { setPassword({...password, error: true}) }
    if (phoneNumber.value !== "" && password.value !== "") {
      signIn({
        phoneNumber: phoneNumber.value,
        password: password.value
      })
    }
  }
  useEffect(() => {
    setPhoneNumber({...phoneNumber, error: !result})
    setPassword({...password, error: !result})
  },[result])

  const setPhoneNumberValue = (e) => {
    setPhoneNumber({
      value: e.target.value,
      error: e.target.value === ""
    })
  }
  const setPasswordValue = (e) => {
    setPassword({
      value: e.target.value,
      error: e.target.value === ""
    })
  }

  const handleKeyPress = (target, event) => {
    if (target.charCode === 13) {
      onSignIn()
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar} src="/logo.png">
        </Avatar>
        <Typography component="h1" variant="h5">
          Панель администратора
        </Typography>
        <form className={classes.form} noValidate autoComplete="nope">
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="phone"
            label="Телефон"
            name="phone"
            autoComplete="off"
            autoFocus
            size="small"
            error={phoneNumber.error}
            onChange={ setPhoneNumberValue }
            value={phoneNumber.value}
            onKeyPress={handleKeyPress}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            name="password"
            label="Пароль"
            type="password"
            id="password"
            autoComplete="current-password"
            size="small"
            error={password.error}
            onChange={ setPasswordValue }
            value={password.value}
            onKeyPress={handleKeyPress}
          />
          {!result && <Typography variant="body2" gutterBottom color="secondary">
            Неверный телефон или пароль
          </Typography>}
          <Button
            fullWidth
            variant="contained"
            size="large"
            color="primary"
            className={classes.submit}
            onClick={onSignIn}
          >
            Войти
          </Button>
          { false && <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid> }
        </form>
      </div>
    </Container>
  );
}
const mapStateToProps = (state) => ({
  result: state.profileReducer.signInResult
})

const mapDispatchToProps = (dispatch) => ({
  signIn: (credentials) => dispatch(signInRequest(credentials))
})

export default connect(mapStateToProps, mapDispatchToProps)(SignIn)
