export const initialCompanies = () => {
  return {
    companies: [],
    company: null,
    loading: true,
    pagination: {
      totalPages: 0
    }
  }
}
