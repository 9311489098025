import { initialDashboard } from "../initial/initialDashboard.js"
import { TypeKeys } from "../actions/actionTypes"

export const dashboardReducer = (state = initialDashboard(), action) => {
  switch (action.type) {
    case TypeKeys.GET_DASHBOARD_CLOSED_DATES_REQUEST:
      return { ...state, loading: true, team: {} }
    case TypeKeys.GET_DASHBOARD_CLOSED_DATES_SUCCESS:
      return { ...state, team: action.team, loading: false }
    case TypeKeys.GET_DASHBOARD_PLANS_UPDATED_REQUEST:
      return { ...state, loading: true, team: {} }
    case TypeKeys.GET_DASHBOARD_PLANS_UPDATED_SUCCESS:
      return { ...state, team: action.team, loading: false }
    case TypeKeys.GET_DASHBOARD_PLAN_VALUES_REQUEST:
      return { ...state, loading: true, team: {} }
    case TypeKeys.GET_DASHBOARD_PLAN_VALUES_SUCCESS:
      return { ...state, team: action.team, loading: false }
    case TypeKeys.GET_DASHBOARD_PLAN_COMPLETE_REQUEST:
      return { ...state, loading: true, team: {} }
    case TypeKeys.GET_DASHBOARD_PLAN_COMPLETE_SUCCESS:
      return { ...state, team: action.team, loading: false }
    case TypeKeys.GET_DASHBOARD_ZERO_RESULTS_REQUEST:
      return { ...state, loading: true, team: {} }
    case TypeKeys.GET_DASHBOARD_ZERO_RESULTS_SUCCESS:
      return { ...state, team: action.team, loading: false }
    case TypeKeys.GET_DASHBOARD_ZERO_TEAMS_RESULTS_REQUEST:
      return { ...state, loading: true, team: {} }
    case TypeKeys.GET_DASHBOARD_ZERO_TEAMS_RESULTS_SUCCESS:
      return { ...state, team: action.team, loading: false }
    case TypeKeys.GET_DASHBOARD_RESULTS_LOSS_REQUEST:
      return { ...state, loading: true, team: {} }
    case TypeKeys.GET_DASHBOARD_RESULTS_LOSS_SUCCESS:
      return { ...state, team: action.team, loading: false }
    case TypeKeys.GET_DASHBOARD_SOCIAL_ACTIVITY_REQUEST:
      return { ...state, loading: true, team: {} }
    case TypeKeys.GET_DASHBOARD_SOCIAL_ACTIVITY_SUCCESS:
      return { ...state, team: action.team, loading: false }
    case TypeKeys.GET_DASHBOARD_TASK_STATS_REQUEST:
      return { ...state, loading: true, team: {} }
    case TypeKeys.GET_DASHBOARD_TASK_STATS_SUCCESS:
      return { ...state, team: action.team, loading: false }
    case TypeKeys.GET_DASHBOARD_SHOWCASE_SUCCESS:
      return { ...state, showcaseData: action.data, loading: false }
    case TypeKeys.GET_DASHBOARD_TEAM_SUMMARY_REQUEST:
      return { ...state, summaryLoading: true, team: {} }
    case TypeKeys.GET_DASHBOARD_TEAM_SUMMARY_SUCCESS:
      return { ...state, team: action.team, summaryLoading: false }
    case TypeKeys.DASHBOARD_RESET_REQUEST:
      return initialDashboard()
    default:
    return { ...state }
  }
}

export default dashboardReducer
