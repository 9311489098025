import React from 'react';
import { Link, useParams } from "react-router-dom";
import PropTypes from 'prop-types';
import { useState, useEffect } from "react";
import moment from 'moment'
import { connect } from 'react-redux'
import { getUserEducationRequest } from "../../actions/actionCreators/userEducationsActions"
import { useHistory } from "react-router-dom";
import TeamChip from "../../components/Main/TeamChip"
import UserChip from "../../components/Main/UserChip"
import { Paper, Grid, Typography, Toolbar, Container, Hidden,
  Fab, TextField, InputAdornment, Button, Box, Chip, Avatar,
  Divider, Tabs, Tab, AppBar, Tooltip, Stack } from '@mui/material'
import { Table, TableBody, TableCell, TableContainer, TableHead,
  TableRow, TableFooter, List, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import CircularProgress from '@mui/material/CircularProgress'
import { makeStyles, useTheme } from '@mui/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { orange, red } from '@mui/material/colors'

import clsx from 'clsx';
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4),
    },
  },
  paper: {
    // padding: theme.spacing(4),s
  },
  editButton: {
    // marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2)
  },
  button: {
    // marginTop: theme.spacing(2)
  },
  teamChip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    '&:hover': { cursor: "pointer" },
  },
  ignoreVisited: {
    color: theme.palette.common.black
  },
  rotateIcon: {
    width: theme.spacing(12),
    height: theme.spacing(12)
  },
  badgeCustom: {
    color: theme.palette.secondary.main,
    border: "1px solid red",
    padding: theme.spacing(1),
    borderRadius: theme.spacing(4),
    marginLeft: theme.spacing(1)
  }
}));

const UserEducation = (props) => {

  const classes = useStyles()
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))

  const params = useParams()

  useEffect(() => {
    props.loadUserEducation(params.id)
  }, [params.id]);

  const { id, user, education, result, attempt, attemptAt, createdAt, answers } = props.userEducation

  return (
    props.loading ?
      <CircularProgress/>
    : <Grid container spacing={4} className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Grid container spacing={2} align={mobile ? "center" : null}>
            <Grid item xs={12}>
              <Grid container spacing={2} align={mobile ? "center" : null} sx={{padding: '16px', paddingTop: '0px'}}>
                <Grid item md={8} xs={12}>
                  <Typography variant="h4">
                    {`${education.title} (${education.educationCategory})`}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h5">
                    ID: {id}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider/>
                </Grid>
                <Grid item xs={12} md={4} align={'left'}>
                  <Typography variant="subtitle1" sx={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                    <strong>Пользователь</strong>
                  </Typography>
                  <UserChip user={user}/>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography variant="subtitle1" sx={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                    <strong>Назначено</strong>
                  </Typography>
                  <Typography variant="body1" align={'left'}>
                    {moment(createdAt).format('DD.MM.YYYY HH:mm:ss')}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} align={'left'}>
                  <Typography variant="subtitle1" sx={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                    <strong>Последняя попыка</strong>
                  </Typography>
                  <Typography variant="body1" align={'left'}>
                    {moment(attemptAt).format('DD.MM.YYYY HH:mm:ss')}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} align={'left'}>
                  <Typography variant="subtitle1" sx={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                    <strong>Результат</strong>
                  </Typography>
                  <Typography variant="body1" align={'left'}>
                    {result}%
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} align={'left'}>
                  <Typography variant="subtitle1" sx={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                    <strong>Попыток</strong>
                  </Typography>
                  <Typography variant="body1" align={'left'}>
                    {attempt}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider/>
                </Grid>
                <Grid item xs={12} align={'left'}>
                  <Typography variant="h5" sx={{mb: "16px"}}>
                    Ответы теста
                  </Typography>
                    <Divider/>
                  <Table className={classes.table} aria-label="simple table" size="small">
                    <TableBody>
                      { answers.map((answer) => {
                        const { description, answerOptions } = answer
                        return(
                          <TableRow key={id}>
                            <TableCell width={500}>
                              <Typography variant="body1">
                                {description}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <List dense={true}>
                                {answerOptions.map((answer) => {
                                  let icon = null
                                  if (answer.correct && answer.selected) {
                                    icon = 'check'
                                  } else if (!answer.correct && answer.selected) {
                                    icon = 'cancel'
                                  }
                                  return(<ListItem>
                                    <ListItemIcon>
                                      {icon === 'check' && <CheckCircleIcon sx={{color: 'green'}}/>}
                                      {icon === 'cancel' && <CancelIcon sx={{color: 'red'}}/>}
                                    </ListItemIcon>
                                    <ListItemText
                                      primary={answer.title}
                                    />
                                  </ListItem>)
                                })}
                              </List>
                            </TableCell>
                          </TableRow>
                        )}
                      )}
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = (state) => ({
  userEducation: state.userEducationsReducer.userEducation,
  loading: state.userEducationsReducer.loading
})

const mapDispatchToProps = (dispatch) => ({
  loadUserEducation: (id) => dispatch(getUserEducationRequest(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(UserEducation);
