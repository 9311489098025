import React from "react";
import { useEffect, useState } from "react"
import { Link, useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { getNewsfeedEditorsRequest, getNewsfeedTeamsRequest,
  createNewsfeedMessageRequest } from "../../actions/actionCreators/newsfeedActions"
import { Grid, Paper, Typography, Divider, TextField, FormControl, InputLabel,
  Select, Button, MenuItem, Checkbox, FormLabel, FormGroup, FormControlLabel,
  Stack, Snackbar, Alert, AlertTitle } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4)
    // marginTop: theme.spacing(2)
  },
  paper: {
    padding: theme.spacing(2)
  },
  buttonMargin: {
    marginLeft: theme.spacing(2)
  }
}));

const NewMessageForm = (props) => {
  const classes = useStyles()
  const navigate = useNavigate()

  const [editor, setEditor] = useState("");
  const [newsfeedMessage, setMessage] = useState("");
  const [image, setImage] = useState(null)

  const updateImage = (e) =>  {
    let files = e.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (e) => {
      setImage(e.target.result)
    }
  }
  const setEditorValue = (e) => {
    setEditor(e.target.value)
  }
  const setMessageValue = (e) => {
    setMessage(e.target.value)
  }

  const [teams, setTeams] = useState([]);

  useEffect(() => {
    props.loadEditors()
    props.loadTeams()
  }, []);

  useEffect(() => {
    setTeams(props.rootTeams.map((team) => {
      team.selected = false;
      return team;
    }))
  }, [props.rootTeams]);

  const [open, setOpen] = React.useState(false)
  const handleClose = () => { setOpen(false) }

  useEffect(() => {
    props.status === 'success' && navigate(`/newsfeeds`)
    props.status === 'error' && setOpen(true)
  }, [props.status]);


  const handleTeamChange = (event) => {
    setTeams(teams.map((team) => {
      if (team.id.toString() === event.target.name) {
        team.selected = event.target.checked
      }
      return team;
    }))
  };

  const submitAction = () => {
    props.createMessage({
      message: newsfeedMessage,
      editor_id: editor,
      team_ids: teams.filter((t) => t.selected ).map((t) => t.id ),
      image: image
    })
    // history.back()
  }

  return(
    <Grid container spacing={4} className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <form noValidate autoComplete="off">
            <Grid container spacing={2}>
              <Grid item sm={12}>
                <Typography variant="h4">Создать обращение в ленты</Typography>
              </Grid>
              <Grid item sm={12}>
                <InputLabel id="demo-simple-select-outlined-label"></InputLabel>
                  <Typography variant="h6">Текст обращения</Typography>
                  <TextField
                    multiline fullWidth rows={8}
                    variant="outlined"
                    value={newsfeedMessage} onChange={setMessageValue}
                  />
              </Grid>
              <Grid item sm={12}>
                { image && <img alt="Logo" src={image} style={{ width: 250 }}/> }
                <Button variant="contained" component="label">
                  Загрузить изображение
                  <input type="file" hidden onChange={updateImage}/>
                </Button>
              </Grid>
              <Grid item sm={12}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="demo-simple-select-outlined-label">Редактор</InputLabel>
                  <Select
                    fullWidth
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={editor}
                    onChange={setEditorValue}
                    label="Компания"
                    disabled={props.editors.length === 1}
                  >
                    { props.editors.map((editor) => {
                        return <MenuItem key={editor.id} value={editor.id}>
                          {editor.first_name} {editor.last_name} ({editor.phone})
                        </MenuItem>
                      }
                    ) }
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={12}>
                <FormControl component="fieldset" className={classes.formControl}>
                  <FormLabel component="legend">Команды для публикации</FormLabel>
                  <FormGroup>
                    <Grid container spacing={0}>
                      { teams.map((team) => {
                        return <Grid key={team.id} item sm={4}>
                          <FormControlLabel
                            control={<Checkbox checked={team.selected} onChange={handleTeamChange} name={team.id.toString()} />}
                            label={team.title}
                          />
                        </Grid>
                      })}
                    </Grid>
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid item sm={12}>
                <Stack spacing={2} direction="row">
                  <Button variant="outlined" to={'/newsfeeds'} component={Link}>
                    Назад
                  </Button>
                  <Button variant="contained" onClick={submitAction} color="primary">
                    Отправить
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
      <Snackbar open={open} autoHideDuration={10000} onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert severity="error" onClose={handleClose}>
          <AlertTitle>Ошибка</AlertTitle>
          При создании обращения произошла ошибка
        </Alert>
      </Snackbar>
    </Grid>)
}

const mapStateToProps = (state) => ({
  editors: state.newsfeedsReducer.editors,
  rootTeams: state.newsfeedsReducer.rootTeams,
  status: state.newsfeedsReducer.status
})

const mapDispatchToProps = (dispatch) => ({
  loadEditors: () => dispatch(getNewsfeedEditorsRequest()),
  loadTeams: () => dispatch(getNewsfeedTeamsRequest()),
  createMessage: (query) => dispatch(createNewsfeedMessageRequest(query)),
})

export default connect(mapStateToProps, mapDispatchToProps)(NewMessageForm)
