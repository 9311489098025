import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Grid, CircularProgress, Typography } from '@mui/material';
import Chart from "react-apexcharts";
import { getTeamStatsRequest } from "../../actions/actionCreators/teamsActions";
import moment from "moment";

const TeamResultCharts = (props) => {
  const { teamId, teamStats, statsLoading, loadTeamStats } = props

  useEffect(() => {
    loadTeamStats(teamId)
  }, [teamId]);

  const chartOptions = (indicator, teamId) => {
    return {
      chart: {
        height: 350,
        type: 'area',
        zoom: {
          enabled: true
        },
        group: 'kpi',
        id: `${teamId}-${indicator["indicator_id"]}`
      },
      xaxis: { categories: teamStats["dates"].map((date) => {
        return moment(date, "YYYY-MM-DD").format('ll')
      }) },
      dataLabels: { enabled: false },
      stroke: {
        width: [2,2],
        curve: 'smooth'
      },
      title: {
        text: indicator["indicator"],
        align: 'left'
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        },
      },
      colors: ['#128FD9', '#00A100'], //
      fill: {
        type: 'gradient',
        gradient: {
          type: "vertical",
          opacityFrom: 0.4,
          opacityTo: 0.3,
        }
      },
    }
  }

  const chartData = (indicator) => {
    return [
      {
        name: "План",
        type: 'area',
        data: indicator["results"].map((r) => r["plan"])
      },
      {
        name: "Факт",
        type: 'area',
        data: indicator["results"].map((r) => r["value"])
      },
    ]
  }

  return (
    <Grid container spacing={2}>
      { statsLoading ? <Grid item xs={12} align="center"><CircularProgress/></Grid> :
        teamStats["teams"].map((team) => {
          return <Grid item xs={12}>
            { teamStats["teams"].length > 1 && <Typography variant="h5">{team["team"]}</Typography> }
            <Grid container spacing={2}>
              { team["indicators"].map((indicator) => {
                return <Grid item md={6} xs={12}>
                  <Chart
                    options={chartOptions(indicator, team["team_id"])}
                    series={chartData(indicator)}
                    height={350}
                    type="line"
                    />
                </Grid>
              })}
            </Grid>
          </Grid>
        })
      }
    </Grid>
  )
}

const mapStateToProps = (state) => ({
  teamStats: state.teamReducer.teamStats,
  statsLoading: state.teamReducer.statsLoading,
})

const mapDispatchToProps = (dispatch) => ({
  loadTeamStats: (id) => dispatch(getTeamStatsRequest(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TeamResultCharts)
