import React from "react";
import CompanyForm from "./CompanyForm"

import { connect } from 'react-redux'
import { useState, useEffect } from "react";
import { Paper, Grid, Typography, Button, ButtonGroup } from '@mui/material'
import { getCalendarRequest, updateCalendarRequest }
  from "../../actions/actionCreators/calendarsActions"
import moment from 'moment'

import { makeStyles, useTheme } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2)
  },
  cellHeader: {
    border: "1px solid white",
    padding: theme.spacing(2)
  },
  working: {
    backgroundColor: theme.palette.info.light,
    border: "1px solid white",
    padding: theme.spacing(2),
    cursor: 'pointer'
  },
  dayOff: {
    backgroundColor: theme.palette.success.light,
    border: "1px solid white",
    padding: theme.spacing(2),
    cursor: 'pointer'
  },
  paddingTop: {
    paddingTop: theme.spacing(2)
  }
}));

const CalendarEdit = ({companyId, calendar, loadCalendar, updateCalendar, loading }) => {

  const [dates, setDates] = React.useState({
    month: moment().date(1).format("MM"), year: moment().date(1).format("YYYY"), active: 'this'
  });

  const handleChange = (dates) => {
    return () => setDates(dates);
  };

  const [cal, setCal] = useState([])

  const updateCal = (day, working) => {
    return () => {
      setCal(cal.map((week) => {
        return week.map((wday) => {
          if (wday.day === day) {
            return {...wday, working: working}
          } else {
            return wday
          }
        })
      }))
    }
  }

  const saveCalendar = () => {
    const data = cal.map((week) => {
      return week.filter((day) => day.enabled).map((day) => {
        return day.working ? '0' : '1'
      }).join('')
    }).join('')
    return updateCalendar(dates.year, dates.month, companyId, data)
  }

  useEffect(() => {
    companyId && loadCalendar(dates.year, dates.month, companyId)
  }, [companyId, dates]);

  useEffect(() => {
    setCal(calendar)
  }, [calendar]);

  const classes = useStyles()

  return(
    <Grid item xs={12}>
      {!loading && <Paper className={classes.paper}>
        <Grid container>
          <Grid item xs={12}>
            <ButtonGroup color="primary" aria-label="outlined primary button group">
              <Button variant={dates.active === 'this' ? 'contained' : 'outlined'} onClick={handleChange({
                  month: moment().date(1).format("MM"),
                  year: moment().date(1).format("YYYY"),
                  active: 'this'})}>{moment().date(1).format("MMMM")}</Button>
              <Button variant={dates.active === 'next' ? 'contained' : 'outlined'} onClick={handleChange({
                  month: moment().date(1).add(1, 'months').format("MM"),
                  year: moment().date(1).add(1, 'months').format("YYYY"),
                  active: 'next'})}>{moment().date(1).add(1, 'months').format("MMMM")}</Button>
            </ButtonGroup>
          </Grid>
          <Grid item xs={8}>
            <Grid container>
              <Grid item xs className={classes.cellHeader}>Пн</Grid>
              <Grid item xs className={classes.cellHeader}>Вт</Grid>
              <Grid item xs className={classes.cellHeader}>Ср</Grid>
              <Grid item xs className={classes.cellHeader}>Чт</Grid>
              <Grid item xs className={classes.cellHeader}>Пт</Grid>
              <Grid item xs className={classes.cellHeader}>Сб</Grid>
              <Grid item xs className={classes.cellHeader}>Вс</Grid>
            </Grid>
            {cal.map((week) =>
              <Grid container>
                {week.map((day) =>
                  day.enabled ?
                    <Grid item xs className={day.working ? classes.working : classes.dayOff}
                    onClick={updateCal(day.day, !day.working)}>
                      {day.enabled && day.day}
                    </Grid> : <Grid item xs className={classes.cellHeader}></Grid>
                )}
              </Grid>
            )}
          </Grid>
          <Grid item xs={4} className={classes.paper}>
            <Typography variant="subtitle1" className={classes.dayOff}>Выходной день</Typography>
            <Typography variant="subtitle1" className={classes.working}>Рабочий день</Typography>
          </Grid>
          <Grid item sm={12} className={classes.paddingTop}>
            <Button className={classes.buttonMargin} variant="contained" onClick={saveCalendar} color="primary">
              Сохранить
            </Button>
          </Grid>
        </Grid>
      </Paper>}
    </Grid>
  )
}

const mapStateToProps = (state) => ({
  calendar: state.calendarsReducer.calendar,
  loading: state.calendarsReducer.loading,
})

const mapDispatchToProps = (dispatch) => ({
  loadCalendar: (year, month, companyId) => dispatch(getCalendarRequest(year, month, companyId)),
  updateCalendar: (year, month, companyId, data) => dispatch(updateCalendarRequest(year, month, companyId, data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CalendarEdit)
