import { takeLatest, put, call, delay } from 'redux-saga/effects'
import { TypeKeys } from '../actions/actionTypes'
import { getProfileRequest, getProfileSuccess } from '../actions/actionCreators/profileActions'
import callApi from '../utils/callApi'
import config from '../config'
import { history } from '../reducers'

function* loadProfile(action) {
	const res = yield call(callApi, `${config.BASE_URL}/admin/api/v1/me/profile`)
	if (res.status === 200) {
		const { companies, role, user } = res.data
		const { first_name, last_name, full_avatar_url, ...apiUser } = user
		const profile = {
			companies,
			role,
			user: {
				...apiUser,
				firstName: first_name,
				lastName: last_name,
				avatar: full_avatar_url,
			},
		}
		yield put(getProfileSuccess(profile))
	}
}

export const profileSagas = [takeLatest([TypeKeys.GET_PROFILE_REQUEST], loadProfile)]
