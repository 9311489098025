import { takeLatest, put, call, delay } from 'redux-saga/effects'
import { TypeKeys } from '../actions/actionTypes'
import { getUserStoriesSuccess } from '../actions/actionCreators/userStoriesActions'
import callApi from '../utils/callApi'
import config from '../config'

function* loadUserStories(action) {
  const res = yield call(callApi, `${config.BASE_URL}/admin/api/v1/users/${action.id}/stories`)
  const stories = res.data
  const storiesData = stories.map((story) => ({
    id: story.id,
    date: story.date,
    team: story.team,
    stories: story.stories
  }))
  yield put(getUserStoriesSuccess(storiesData))
}

export const userStoriesSagas = [
  takeLatest([ TypeKeys.GET_USER_STORIES_REQUEST], loadUserStories)
]
